import { Box, Typography, Chip, Button, Grid, Card, Avatar, Tooltip } from '@mui/material';
import styles from './KeytoolDetail.module.scss';
import backIcon from "../../../assets/svg/arrowBack.svg"
import downloadIcon from "../../../assets/svg/Download.svg"
import shareIcon from "../../../assets/svg/Share.svg";
import deleteIcon from '../../../assets/svg/Delete.svg';
import avatar from '../../../assets/svg/Avatar.png';
import * as React from "react";
import KeyDownloadModal from './KeyDownloadModal';
import KeyDeleteModal from './KeyDeleteModal';
import { deleteApiCall, getApiCall, patchApiCall } from '../../../utils/Utils';
import { Link, useParams, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import ShareKeyModal from '../ShareKey/ShareKeyModal';
import DownloadModal from '../DownloadModal/DownloadModal';
import { useTooltipState } from '../tooltipUtils';
import truncate from "lodash/truncate";
import DeletedKeyIcon from "../../../assets/svg/DeletedKey.svg";
import popupStyles from '../AddKey/AddKey.module.scss';
import ImportKeyIcon from '../../../assets/svg/importKey.svg';
import ImportKeyModal from './ImportKeyModal';
import MissingExtensionPopup from '../MissingExtensionPopup/MissingExtensionPopup';
import { failureResponses } from '../../../constants/constants';
import RevokeIconRed from "../../../assets/svg/RevokeRed.svg";

const KeytoolDetail = () => {
  const params = useParams();
  const { projectId, keyId } = params;
  const navigate = useNavigate();
  const windowWidth = React.useRef(window.innerWidth);
  const [keyDetails, setKeyDetails] = React.useState<any>();
  const [downloadModalOpen, setDownloadModalOpen] = React.useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = React.useState(false);
  const [shareModalOpen, setShareModalOpen] = React.useState(false);
  const [normalDownload, setNormalDownload] = React.useState(false);
  const [importKey, setImportKey] = React.useState(false);
  const [missingModalOpen, setMissingModalOpen] = React.useState(false);
  const [showPopup, setPopup] = React.useState('');
  const showMissingExtensionPopup = React.useRef(true);

  const componentEvent = new CustomEvent('Component mounted');
  document.dispatchEvent(componentEvent);
  document.addEventListener(`extension connected`, ()=>{
    showMissingExtensionPopup.current = false;
  });

  const handleDownloadModalOpen = () => {
    setDownloadModalOpen(true)
  };

  const handleDownloadModalClose = () => {
    setDownloadModalOpen(false)
  };

  const handleDeleteModalOpen = () => {
    setDeleteModalOpen(true)
  };

  const handleDeleteModalClose = () => {
    setDeleteModalOpen(false)
  };

  const getKeyDetails = () => {
    getApiCall(`/projects/${projectId}/project_key/${keyId}/`, successCallbackFunction, getKeyDetailsFailureCallback)
  }

  const handleDeleteKey = () => {
    deleteApiCall(`/projects/${projectId}/project_key/${keyId}/`, deleteSuccessCallbackFunction, failureCallbackFunction)
  }

  const handleDownloadEncryptedText = () => {
    getApiCall(`/projects/${projectId}/project_key/${keyId}/keydata/`, downloadSuccessCallbackfunction, failureCallbackFunction)
  }

  const successCallbackFunction = (response: any) => {
    setKeyDetails(response);
  }

  const getKeyDetailsFailureCallback = (response: any) => {    
    const urlParams = new URLSearchParams(window.location.search);
    response === failureResponses.INVALID_TOKEN && (urlParams.get('email_redirect') === 'true' ? navigate('/', {state: {'redirect': window.location.pathname}}) : navigate('/')); 
    if (response === failureResponses.STATUS_404 || response === failureResponses.NO_PERMISSION) navigate("/");
  }

  const failureCallbackFunction = (response: any) => {
    if (response === failureResponses.NO_PERMISSION) navigate(`/project-details/${projectId}/keytool`);
    if (response === failureResponses.INVALID_TOKEN) navigate("/");
  }

  const deleteSuccessCallbackFunction = () => {
    handleDeleteModalClose();
    navigate(`/project-details/${projectId}/keytool`, { state: { message: "Key deleted successfully." } })
  }

  const trackKeySuccessCallBack = (response: any) => {
    //There is nothing to handle here for now
  }
  const trackKeyFailureCallBack = (response: any) => {
    if (response === failureResponses.INVALID_TOKEN) navigate('/')
    toast.success('Something went wrong please try again', { position: toast.POSITION.TOP_RIGHT })
  }

  const downloadSuccessCallbackfunction = (response: any) => {
    setNormalDownload(false)
    const blob = new Blob([response.password], { type: "text/plain" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = response.file_name.replaceAll(' ','_');
    link.click();

    // Track key status API 
    const postData = { "is_downloaded": true }
    patchApiCall(`/projects/${projectId}/project_key/${keyId}/update-status/`,
      postData,
      trackKeySuccessCallBack,
      trackKeyFailureCallBack)
  }

  const handleShareModalOpen = () => {
    setShareModalOpen(true)
  }

  const handleShareModalClose = () => {
    let event = new CustomEvent('close draggable popup from app')
    document.dispatchEvent(event)
    setShareModalOpen(false)
  }

  const handleImportKey = () => {
    setPopup('import');
    if (showMissingExtensionPopup.current===false) {
      setImportKey(true);
    } else {
      setImportKey(false);
      setMissingModalOpen(true);
    }
  };

  const handleImportKeyClose = () => {
    setImportKey(false);
  };

  React.useEffect(() => {
    getKeyDetails();
    // eslint-disable-next-line
  }, []);

  const handleKeyExtensionClick = (popup: string) => {
    if (showMissingExtensionPopup.current === true) {
      setPopup(popup);
      setMissingModalOpen(true);
      popup==='share' && setShareModalOpen(false);
      popup==='download' && setDownloadModalOpen(false);
    }
  };

  const onGoBackClick = () => {
    setMissingModalOpen(false);
    showPopup==='share' && setShareModalOpen(true);
    showPopup==='download' && setDownloadModalOpen(true);
  }

  const { hideParentTooltip, handleChildTooltipMouseOver, handleChildTooltipMouseOut } = useTooltipState();

  return (
    keyDetails &&
    <Box className={styles.keytoolDetailContainer} >
      <Box className={styles.keyDetailsHeader}>
        <Box className={styles.keyDetailsName}>
          <Link to={`/project-details/${projectId}/keytool`}>
            <img src={backIcon} alt="back" className={styles.backArrow} />
          </Link>
          <Tooltip title={keyDetails.name} placement="right">
            <Typography className={styles.keyTitle}>{keyDetails.name}</Typography>
          </Tooltip>
          <Box>
            {keyDetails.public_key.name && (
            <Tooltip title={!hideParentTooltip && keyDetails.public_key.name?.length>50 && keyDetails.public_key.name} placement="top">
              <Chip
                label={
                  <Box className={popupStyles.chipDeletedIcon}>
                    {
                      keyDetails.public_key.is_deleted && 
                      <Tooltip 
                        title={'The key has been deleted from the system'} 
                        placement="top"
                        onMouseEnter={handleChildTooltipMouseOver}
                        onMouseLeave={handleChildTooltipMouseOut}  
                      >                        
                        <img src={DeletedKeyIcon} alt="Deleted key icon"/>
                      </Tooltip>
                    }
                    {truncate(keyDetails.public_key.name, { length: 50 })}
                  </Box>
                }                
                variant="filled"
                size="small"
                className={`${popupStyles.keyChipDesign} 
                ${
                  keyDetails.is_owner
                    ? popupStyles.addedKey
                    : popupStyles.sharedKey
                }`}              />
            </Tooltip>
            )}      
            {keyDetails.status === "CLOSED" && (
              <Chip label="Closed" variant="filled" size="small"
                sx={{
                  background: "var(--light-custom-background-error-background, linear-gradient(0deg, rgba(255, 255, 255, 0.88) 0%, rgba(255, 255, 255, 0.88) 100%), #F44336)",
                  color: "var(--light-error-main, #FF4C51)", fontSize: "13px"
                }} />
            )}
          </Box>
        </Box>
        <Box className={styles.menuSection}>
          <Button variant="outlined" className={styles.downloadDecryptButton} onClick={handleDownloadModalOpen}>
            Decrypt & Download
          </Button>
          <Tooltip title={'Import Key'} placement="bottom">                        
            <Box onClick={handleImportKey} className={styles.cursorType}><img src={ImportKeyIcon} alt="import key" /></Box>
          </Tooltip>
          <Box onClick={() => setNormalDownload(true)} className={styles.cursorType}><img src={downloadIcon} alt="download" /></Box>
          {keyDetails.user_permission && <Box className={styles.cursorType}><img src={shareIcon} alt="share" onClick={handleShareModalOpen} /></Box>}
          <Box onClick={handleDeleteModalOpen} className={styles.cursorType}><img src={deleteIcon} alt="delete" /></Box>
        </Box>
      </Box>
      <Box className={styles.keyDetailsSection}>
        <Box className={styles.keyDetails}>
          <Typography className={styles.detailsTitle}>Keytype:</Typography>
          <Typography className={styles.keyType}>{keyDetails.type}</Typography>
        </Box>
        {
          keyDetails.file_name !== null &&
          <Box className={styles.keyDetails}>
            <Typography className={styles.detailsTitle}>Uploaded file:</Typography>
            <Typography>{keyDetails.file_name}</Typography>
          </Box>
        }
        <Box className={styles.keyDetails}>
          <Typography className={styles.detailsTitle}>Shared with:</Typography>
        </Box>
        <Grid container spacing={2} justifyContent="left" wrap="wrap" rowGap="23px">
          {keyDetails.shared_users && keyDetails.shared_users.map((item: any, index: number) =>
            <Grid item xs={8} sm={6} md={(windowWidth.current >= 1064 && windowWidth.current < 1199) ? 4 : 6}
              lg={(windowWidth.current >= 1199 && windowWidth.current < 1299) ? 4 :
                (windowWidth.current >= 1299 && windowWidth.current < 1599) ? 3 : 2.3} key={index}>
              <Card className={styles.sharedCard}>
                <Box className={styles.sharedUserContainer}>
                  <Box className={styles.sharedUserDetails}>
                    <Avatar src={item.user.profile_pic || avatar} alt='user profile picture' />
                    <div>
                      <Typography className={styles.username}>{item.user.name}</Typography>
                      {item.is_owner &&
                        <Chip label="Owner" variant="filled" size="small"
                          sx={{
                            width: "57px",
                            height: "18px",
                            color: "#fff",
                            fontSize: "12px",
                            lineHeight: "14px",
                            letterSpacing: "0.4px",
                            borderRadius: "16px",
                            border: "#757575",
                            background: "#757575"
                          }} />
                      }
                    </div>
                  </Box>
                  {item.is_revoked && <img className={styles.revokeIconRed} src={RevokeIconRed} alt='Key Revoked from user'/>}
                </Box>
                <Box className={styles.sharedUserDetails}>
                  <Chip label={item.can_share ? "Download & share" : "Download"} variant="filled" size="small"
                    sx={{
                      color: "rgba(58, 53, 65, 0.60)",
                      fontSize: "13px",
                      borderRadius: "10px",
                      border: "1px solid rgba(58, 53, 65, 0.26)",
                      background: "rgba(58, 53, 65, 0.04)"
                    }} />
                  <Typography className={styles.date}>{new Date(item.created_time).toLocaleString('en-us', { month: 'long', year: 'numeric', day: 'numeric' })}</Typography>
                </Box>
              </Card>
            </Grid>
          )}
        </Grid>
      </Box>
      <KeyDownloadModal
        open={downloadModalOpen}
        handleClose={handleDownloadModalClose}
        projectId={projectId}
        keyData={{...keyDetails,keyId: keyId}}
        handleKeyExtensionClick={handleKeyExtensionClick}/>
      <KeyDeleteModal
        open={deleteModalOpen}
        handleClose={handleDeleteModalClose}
        onDeleteClick={handleDeleteKey}
        keyName={keyDetails.name} />
      {shareModalOpen && <ShareKeyModal open={shareModalOpen} keyData={{...keyDetails,keyId: keyId}} handleModalClose={handleShareModalClose} projectId={projectId} keyToolListData={getKeyDetails} handleKeyExtensionClick={handleKeyExtensionClick}/>}
      <ToastContainer />
      <DownloadModal
      open={normalDownload}
      handleModalClose={() => setNormalDownload(false)}
      handleDownloadConfirm={handleDownloadEncryptedText} />
      <ImportKeyModal
          open={importKey}
          handleClose={handleImportKeyClose}
          clear={showMissingExtensionPopup.current}
      />
      <MissingExtensionPopup
        open={missingModalOpen}
        onGoBackClick={onGoBackClick}
        popup={showPopup}
      />
    </Box >
  )
}

export default KeytoolDetail;