import * as openpgp from 'openpgp';
import React from "react";
import styles from './AddKey.module.scss';
import { Modal, Typography, Box, Grid, TextField, FormControl, Select, MenuItem, InputLabel, InputAdornment, Button, FormHelperText } from '@mui/material';
import stylesProjectList from "../../ProjectList/ProjectList.module.scss";
import ShareKey from '../ShareKey/ShareKey';
import {useNavigate, useParams } from "react-router-dom";
import { postApiCall, getApiCall } from "../../../utils/Utils";
import { toast } from "react-toastify";
import { USER_DATA } from "../../../config";
import LazyLoader from "../../shared/LazyLoader/LazyLoader";
import LinearProgressWithLabel from '../../shared/ProgressBar/ProgressBar';
import closeIcon from '../../../assets/svg/closeIcon.svg'
import { failureResponses } from '../../../constants/constants';

const AddKey = (props: any) => {
  const [loader, setLoader] = React.useState(false);
  const params = useParams();
  const projectId = params.projectId;
  const [file, setFile] = React.useState<any>();
  const [expandClicked, setExpandClicked] = React.useState(false);
  const [shareWithUsers, setShareWithUsers] = React.useState<any>([]);
  const [keyDetails, setKeyDetails] = React.useState<{ name: string; key: string; type: string; }>({
    name: '',
    key: '',
    type: ''
  });
  const [validationMessage, setValidationMessage] = React.useState<{ name: string; file: string; type: string; password: string }>({
    name: '',
    file: '',
    type: '',
    password: ''
  });
  const [fileEncryptContent, setFileEncryptContent] = React.useState<any>();
  const [createButtonClicked, setCreateButtonClicked] = React.useState(false);
  const [progress, setProgress] = React.useState(0);
  const userPublicKeyId = React.useRef(null);

  const navigate = useNavigate()
  let createSuccess = false;

  const sharedUserList = React.useRef<number[]>([]);
  const lastShareCall = React.useRef<number>(0);
  const keyId = React.useRef<number>(0);

  const handleShareWithUsers = (value: any) => {
    setShareWithUsers(value)
  }

  const handleSharedUserPrivilegeChange = (event: any, userData: any) => {
    const index = shareWithUsers.findIndex((item: any) => item.id === userData.id)
    const updatedSharedUsers = [...shareWithUsers];
    updatedSharedUsers[index] = { id: userData.id, name: userData.name, privilege: event.target.value };
    setShareWithUsers(updatedSharedUsers);
  }

  const handleFieldValidation = () => {
    if (keyDetails.name === '')
      setValidationMessage((prev) => ({
        ...prev,
        name: "Please enter the key name"
      }))
    else {
      setValidationMessage((prev) => ({
        ...prev,
        name: ''
      }))
    }
    if (keyDetails.type === '')
      setValidationMessage((prev) => ({
        ...prev,
        type: "Please enter the key type"
      }))
    else {
      setValidationMessage((prev) => ({
        ...prev,
        type: ''
      }))
    }
    if (file && file.size > 1048576)
      setValidationMessage((prev) => ({
        ...prev,
        file: "File size should be less than 1MB"
      }))
    else
      setValidationMessage((prev) => ({
        ...prev,
        file: ''
      }))
    if (!file && keyDetails.key === '') {
      setValidationMessage((prev: any) => ({ ...prev, password: 'Enter a key or password' }))
    }
    if (keyDetails.name !== '' && keyDetails.type !== '' && (keyDetails.key !== '' || (file !== undefined && file !== '' && file.size <= 1048576)))
      return true
    else return false
  }

  const encrypt = async (publicKeyArmored: any, order: any = '', keyId: any = '') => {

    let dataToBeEncrypted = keyDetails.key === '' ? fileEncryptContent : keyDetails.key;
    const publicKey = await openpgp.readKey({ armoredKey: publicKeyArmored });
    const encrypted = await openpgp.encrypt({
      message: await openpgp.createMessage({ text: dataToBeEncrypted }), // input as Message object
      encryptionKeys: publicKey,
    });

    if (order?.id) {
      postApiCall(`/projects/${projectId}/project_key/${keyId}/shared_key/`,
        { password: encrypted, can_share: order.privilege === "DOWNLOAD" ? false : true, user: order.id, public_key_id:order.publicKeyId }, shareKeySuccessCallBackFunction, shareKeyFailureCallbackFunction)

    } else {
      postApiCall(`/projects/${projectId}/key/`,
        { key_data: { password: encrypted, public_key_id: userPublicKeyId.current }, name: keyDetails.name, type: keyDetails.type, file_name: file ? file.name : null },
        createSuccessCallBackFunction, failureCallBackFunction);
    }
  }

  const shareKeys = async (publicKeys: any, keyId: number) => { 
    shareWithUsers.forEach((item: any, index: number) => {
      const publicKey = (publicKeys.find((user: any) => user.user === item.id)).key;
      encrypt(publicKey, {...item,publicKeyId: publicKeys[index].id}, keyId);
    })
    const progressRate = 100 / (shareWithUsers.length * 100);
    for (let progress = 0; progress < 100; progress += progressRate) {
      await new Promise((resolve) => setTimeout(resolve, 3));
      setProgress(progress);
    }
  }

  const getSharedUserPublicKeys = (userArray: any[], keyId: number) => {
    getApiCall(`/projects/${projectId}/user/public_keys/?user_ids=${userArray}`, (response: any) => getPublicKeySuccessCallBackFunction(response, keyId), failureCallBackFunction)
  }

  const getKeyAuthorPublicKey = () => {
    const userData: any = localStorage.getItem(USER_DATA);
    const userId: any = JSON.parse(userData).user_id;
    getApiCall(`/projects/${projectId}/user/public_keys/?user_ids=${userId}`, getKeyAuthorPublicKeySuccessCallbackFunction, failureCallBackFunction)
  }

  const handleCreateKey = () => {
    if (handleFieldValidation()) {
      setCreateButtonClicked(true)
      getKeyAuthorPublicKey();
      setLoader(true)
    }
  }

  const handleFileUpload = async (event: any) => {
    const allowedExtensions = ['txt', 'crt', 'pem']; // Add your allowed extensions here

    const filePath = event.target.value;
    const fileName = filePath.split('\\').pop()?.split('/').pop();
    if (fileName) {
      const fileExtension = fileName.split('.').pop()?.toLowerCase(); // Extracting file extension

      if (fileExtension && allowedExtensions.includes(fileExtension)) {
        setValidationMessage((prev) => ({...prev, file: ''}))
        setFile(event.target.files?.[0])
        const reader = new FileReader();
        reader.readAsText(event.target.files?.[0], 'CP1251');
        reader.onload = function (e) {
          setFileEncryptContent(reader.result);
        };
      }
      else {
        setValidationMessage((prev) => ({...prev, file: 'Only txt, crt and pem files are allowed'}))
      }
    }
  }

  const createSuccessCallBackFunction = (response: any) => {
    keyId.current = response.id;
    props.setKeyToolData([])
    createSuccess = true;
    if (shareWithUsers.length !== 0) {
      let sharedUsers: any[] = [];
      shareWithUsers.forEach((item: any) => {
        sharedUsers.push(item.id)
      })
      getSharedUserPublicKeys(sharedUsers, response.id);
    } else {
      props.getKeyToolList(true);
      handleClose();
    }
    setLoader(false)
  }

  const failureCallBackFunction = (response: any) => {
    if (response.name && response.name[0] === failureResponses.KEY_NAME_TAKEN) {      
      setValidationMessage((prev) => ({
        ...prev,
        name: response.name[0]
      }))
    } else if (response === failureResponses.INVALID_TOKEN) {
      handleClose();
      navigate('/')
    } else {
      toast.error('Something went wrong');
    }
    setLoader(false)
  }

  const getPublicKeySuccessCallBackFunction = (response: any, keyId: number) => {
    shareKeys(response, keyId);
  }

  const shareKeySuccessCallBackFunction = (response: any) => {
    sharedUserList.current = [...sharedUserList.current, response.user];   
    lastShareCall.current = lastShareCall.current + 1; 
    if(lastShareCall.current===shareWithUsers.length) {
      props.getKeyToolList(true);
      props.getData()
      notifySender();
    }
  };

  const shareKeyFailureCallbackFunction = (response: any) => {
    lastShareCall.current = lastShareCall.current + 1; 
    lastShareCall.current===shareWithUsers.length && notifySender();
    if(response === failureResponses.INVALID_TOKEN) navigate('/');
  };

  const getKeyAuthorPublicKeySuccessCallbackFunction = (response: any) => {
    userPublicKeyId.current = response[0].id;
    encrypt(response[0].key);
  }

  const handleClose = () => {
    sharedUserList.current = [];
    lastShareCall.current = 0;
    setShareWithUsers([]);
    props.onClose();
    if (createSuccess) toast.success("Key created successfully.", {
      position: toast.POSITION.TOP_RIGHT,
    });
    setKeyDetails({
      name: '',
      key: '',
      type: ''
    })
    setValidationMessage({
      name: '',
      file: '',
      type: '',
      password: ''
    })
    setFile('')
    setExpandClicked(false)
    createSuccess = false;
    setCreateButtonClicked(false)
    setProgress(0)
  }

  const handleRemoveFile = () => {
    setFile('')
    setFileEncryptContent('')
  };

  const notifySender = () => {    
    if (keyId.current!==0) {
      postApiCall(`/projects/${projectId}/project_key/${keyId.current}/notify-sender/`,
      { shared_users_ids:sharedUserList.current },
      notifySenderSuccessCallBackFunction,
      notifySenderFailureCallBackFunction);
    }
  };

  const notifySenderSuccessCallBackFunction = () => {
    handleClose();
  }

  const notifySenderFailureCallBackFunction = (response: any) => {
    if(response === failureResponses.INVALID_TOKEN) navigate('/')
  };

  return (
    <>
      {loader && <LazyLoader />}
      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box className={styles.modalStyles}>
          <Box>
            <Grid item md={12}>
              <Typography id="modal-modal-title" variant="h6" component="h2" className={styles.modalTitle}>
                New Key
              </Typography>
            </Grid>
            <Grid item md={12} className={styles.addKeyMessage}>
              <Typography>
                The browser itself will encrypt the key/password, ensuring that only you have the ability to decrypt it. This encryption occurs both during transit and storage.
              </Typography>
            </Grid>
            <Grid item md={12} className={styles.addKeyFields}>
              <TextField
                id="outlined-basic"
                required
                error={validationMessage.name !== ''}
                helperText={validationMessage.name}
                value={keyDetails.name}
                onChange={(event) => {
                  setValidationMessage((prev) => ({ ...prev, name: '' }))
                  setKeyDetails((prev) => ({ ...prev, name: event.target.value }))
                }}
                variant="outlined"
                fullWidth
                label="Key Name"
                autoComplete="off" />
            </Grid>
            <Grid item md={12} className={styles.addKeyFields}>
              <TextField
                id="filled-multiline-static"
                value={keyDetails.key}
                onChange={(event) => {
                  file ? setKeyDetails((prev) => ({ ...prev, key: "" })) : setKeyDetails((prev) => ({ ...prev, key: event.target.value }))
                  setValidationMessage((prev) => ({
                    ...prev,
                    password: ""
                  }))
                }}
                disabled={file?true:false}
                className={file && styles.inputFieldDisabled}
                multiline
                fullWidth
                rows={3}
                autoComplete="off"
                label="Key/Password"
                error={validationMessage.password !== ''}
                helperText={validationMessage.password} />
            </Grid>
            <Box className={styles.fileFlex}>
              <Grid item md={6} className={styles.addKeyFields}>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  error={validationMessage.file !== ''}
                  helperText={validationMessage.file}
                  fullWidth
                  label="Upload File"
                  value={file ? file.name : ''}
                  autoComplete="off"
                  sx={{ width: "330px" }}
                  disabled={keyDetails.key? true : false}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {file && <img src={closeIcon} onClick={handleRemoveFile} style={{ cursor: "pointer", marginRight: "4px" }} />}
                        <Box sx={{ dispaly: "flex" }}>
                          <Button variant="contained" className={!keyDetails.key ? styles.browseButtonActive : styles.browseButtonDisabled } size="small">
                            browse
                            {keyDetails.key === "" &&
                              < input
                                type="file"
                                accept='.txt, .crt, .pem'
                                style={{ cursor: "pointer" }}
                                onChange={handleFileUpload}
                                value={""}
                              />
                            }
                          </Button>
                        </Box>
                      </InputAdornment>)
                  }}
                />
              </Grid>
              <Grid item md={6} className={styles.addKeyFields}>
                <FormControl fullWidth sx={{ width: "330px" }}
                  error={validationMessage.type !== ''}>
                  <InputLabel id="demo-simple-select-label">Key Type</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    value={keyDetails.type}
                    onChange={(event) => {
                      setValidationMessage((prev) => ({ ...prev, type: '' }))
                      setKeyDetails((prev) => ({ ...prev, type: event.target.value }))
                    }}
                    id="demo-simple-select"
                    label="Key Type"
                  >
                    <MenuItem value="PASSWORD">Password</MenuItem>
                    <MenuItem value="SSH">SSH Key</MenuItem>
                    <MenuItem value="GENERIC TEXT">Generic Text</MenuItem>
                  </Select>
                  <FormHelperText>
                    {validationMessage.type ?? ""}
                  </FormHelperText>
                </FormControl>
              </Grid>
            </Box>
            {(!expandClicked) &&
              <Grid item md={3} className={styles.shareExpandButton}
                onClick={() => setExpandClicked(true)}>
                + SHARE
              </Grid>
            }
            {expandClicked &&
              <ShareKey setShareWithUsers={handleShareWithUsers} shareWithUsers={shareWithUsers} handleSharedUserPrivilegeChange={handleSharedUserPrivilegeChange}></ShareKey>
            }
            {
              expandClicked && createButtonClicked && <Grid item md={12}>
                <LinearProgressWithLabel value={progress} />
              </Grid>
            }
            <Grid container justifyContent="center" className={stylesProjectList.buttons}>
              <Button variant="outlined"
                className={stylesProjectList.cancelButton} onClick={handleClose}>
                Cancel
              </Button>
              <Button variant="contained" className={stylesProjectList.submitButton} sx={{ minWidth: "100px" }} onClick={handleCreateKey}>
                Create
              </Button>
            </Grid>
          </Box>
        </Box>
      </Modal >
    </>
  )
}

export default AddKey;