import { Box, Modal, Button, Checkbox, Chip } from "@mui/material";
import { getApiCall, postApiCall } from "../../../utils/Utils";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";

import styles from "./RevokeAccessModal.module.scss";
import styled from "@emotion/styled";
import LazyLoader from "../../shared/LazyLoader/LazyLoader";

// images
import RevokeIconGray from "../../../assets/svg/RevokeGray.svg";
import RevokeIconRed from "../../../assets/svg/RevokeRed.svg";
import DownloadIconGray from "../../../assets/svg/DownloadGray.svg";
import ShareIconGray from "../../../assets/svg/ShareGray.svg";
import { failureResponses } from "../../../constants/constants";
import { useNavigate } from "react-router";

const CustomCheckBox = styled(Checkbox)({
  "&.Mui-checked": {
    color: "#444444",
  },
});

interface SharedUser {
  id: number;
  user_name: string;
  is_shared: boolean;
  is_downloaded: boolean;
}

const RevokeAccessModal = (props: any) => {
  const [sharedUserList, setSharedUserList] = useState<SharedUser[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<number[]>([]);
  const [selectAll, setSelectAll] = useState(false);
  const [loader, setLoader] = useState(false);

  const navigate = useNavigate();

  //   list of users to whom the key has been shared
  const getSharedUsers = () => {
    setLoader(true);
    getApiCall(
      `/projects/${props.projectId}/project_key/${props.keyId}/shared-users/`,
      sharedUserListsuccessCallBackFunction,
      sharedUserListfailureCallBackFunction
    );
  };

  const sharedUserListsuccessCallBackFunction = (response: any) => {
    setSharedUserList(response);
    setLoader(false);
  };

  const sharedUserListfailureCallBackFunction = (response: any) => {
    setLoader(false);
    if (response === failureResponses.INVALID_TOKEN) navigate("/");
  };

  useEffect(() => {
    props.keyId !== 0 && getSharedUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.keyId]);

  const handleModalClose = () => {
    setSharedUserList([]);
    setSelectedUsers([]);
    setSelectAll(false);
    props.handleModalClose();
  };

  //   function to handle checking of each user seperately
  const handleCheckboxChange = (userId: number) => {
    if (selectedUsers.includes(userId)) {
      setSelectedUsers(selectedUsers.filter((id) => id !== userId));
      setSelectAll(false);
    } else {
      const newSelectedArray = [...selectedUsers, userId];
      newSelectedArray.length === sharedUserList.length && setSelectAll(true);
      setSelectedUsers(newSelectedArray);
    }
  };

  //   function to handle select all
  const handleSelectAllChange = () => {
    if (selectAll) {
      setSelectedUsers([]);
      setSelectAll(false);
    } else {
      const allUserIds = sharedUserList.map((user) => user.id);
      setSelectedUsers(allUserIds);
      setSelectAll(true);
    }
  };

  //   key status (downloaded or shared)
  const showKeyStatusChip = (isShared: boolean, isDownloaded: boolean) => {
    if (isShared && isDownloaded) return "Downloaded & Shared";
    else if (isShared) return "Shared";
    else if (isDownloaded) return "Downloaded";
    else return "";
  };

  //   revoke access API call
  const handleRevokeButtonClick = () => {
    setLoader(true);
    postApiCall(
      `/projects/${props.projectId}/project_key/${props.keyId}/revoke-keys/`,
      { shared_key_ids: selectedUsers },
      revokeAccessSuccessCallbackFunction,
      revokeAccessFailureCallbackFunction
    );
  };

  const revokeAccessSuccessCallbackFunction = (response: any) => {
    setLoader(false);
    const message =
      selectedUsers.length === 1
        ? "The user's access to the key has been succesfully revoked"
        : "The users access to the key has been succesfully revoked";
    toast.success(message);
    handleModalClose();
    props.getKeyToolList(true);
  };

  const revokeAccessFailureCallbackFunction = (response: any) => {
    setLoader(false);
    if (response === failureResponses.INVALID_TOKEN) navigate("/");
  };

  return (
    <>
      {loader && <LazyLoader />}
      <Modal open={props.open} onClose={() => {}}>
        <Box className={styles.revokeModal}>
          <p className={styles.revokeText}>Revoke Key</p>
          <Box className={styles.revokeAccessWarning}>
            <p className={styles.revokeWarningText}>
              On revoking the access, users will no longer have the permission
              to access this key. If you need to reinstate access, please
              reshare the key with the user.
            </p>
            <p className={styles.revokeWarningText}>
              The labelled users have already downloaded or shared the key.{" "}
              <br />
              Revoking access for those users will only block their access to
              the key.
            </p>
          </Box>
          <Box className={styles.sharedUserList}>
            <Box className={styles.revokeAll}>
              <Box className={styles.checkbox}>
                <CustomCheckBox
                  className={styles.checkboxStyle}
                  checked={selectAll}
                  onChange={handleSelectAllChange}
                />
                <p className={styles.revokeAllText}>Revoke All</p>
              </Box>
              <img
                src={selectAll ? RevokeIconRed : RevokeIconGray}
                alt="Revoke icon"
              />
            </Box>
            <Box className={styles.userList}>
              {sharedUserList.map((user: SharedUser) => {
                return (
                  <Box className={styles.revokeIndividualUser}>
                    <Box className={styles.checkbox}>
                      <CustomCheckBox
                        className={styles.checkboxStyle}
                        checked={selectedUsers.includes(user.id)}
                        onChange={() => handleCheckboxChange(user.id)}
                      />
                      <p className={styles.userName}>{user.user_name}</p>
                      {(user.is_shared || user.is_downloaded) &&
                        selectedUsers.includes(user.id) && (
                          <Chip
                            label={
                              <Box className={styles.chipStyle}>
                                <img
                                  src={
                                    user.is_shared
                                      ? ShareIconGray
                                      : DownloadIconGray
                                  }
                                  alt="key status"
                                />
                                {showKeyStatusChip(
                                  user.is_shared,
                                  user.is_downloaded
                                )}
                              </Box>
                            }
                            variant="filled"
                            size="small"
                          />
                        )}
                    </Box>
                    <img
                      src={
                        selectedUsers.includes(user.id)
                          ? RevokeIconRed
                          : RevokeIconGray
                      }
                      alt="Revoke icon"
                    />
                  </Box>
                );
              })}
            </Box>
          </Box>
          <div className={styles.buttonContainer}>
            <Button className={styles.cancelButton} onClick={handleModalClose}>
              cancel
            </Button>
            <Button
              className={styles.revokeButton}
              onClick={handleRevokeButtonClick}
            >
              Revoke
            </Button>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default RevokeAccessModal;
