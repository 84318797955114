import styles from './ProjectDetails.module.scss';
import { Grid } from '@mui/material';
import union from "../../assets/svg/union.svg";
import React from 'react';
import { getApiCall } from '../../utils/Utils';
import { useNavigate } from 'react-router-dom';

const ProjectDetails = () => {
  const navigate = useNavigate()

  const authenticateSuccessCallBack = (response: any) => {
    // just varifying user
  }
  const authenticateFailureCallBack = (response: any) => {
    navigate("/")
  }
  React.useEffect(() => {
    getApiCall('/user/is_authenticated/',
    authenticateSuccessCallBack,
    authenticateFailureCallBack)
  }, [])
  return (
    <Grid container className={styles.comingSoonBox} direction="column"
      justifyContent="center"
      alignItems="center">
      <Grid>
        <img className={styles.logo} src={union} alt='' />
      </Grid>
      <Grid>
        <p className={styles.welcomeText}>Coming Soon!</p>
      </Grid>
    </Grid>
  )
}

export default ProjectDetails;