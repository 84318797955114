import styles from "./ProjectList.module.scss";
import * as React from "react";
import { Box, Button, Container, Grid, Modal, TextField, Typography, Card, Chip, Tooltip, InputAdornment, Avatar } from "@mui/material";
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { toast, ToastContainer } from "react-toastify";
import { getApiCall, postApiCall } from "../../utils/Utils";
import LazyLoader from "../shared/LazyLoader/LazyLoader";
import groupIcon from "../../assets/svg/Group.svg";
import Header from "../Header/Header";
import InfiniteScroll from 'react-infinite-scroll-component';
import noProjectsImg from '../../assets/svg/noProjects.png';
import noProjectForFilter from '../../assets/svg/No data-pana 1.svg'
import { failureResponses } from "../../constants/constants";
import styled from "@emotion/styled";
import { getDefaultProjectInitials } from "../../utils/utilityFunctions";
import instructionIcon from '../../assets/svg/instructionIcon.svg'
import activityStyle from '../ProjectActivity/ProjectActivity.module.scss'

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 450,
  bgcolor: '#fff',
  borderRadius: '6px',
  boxShadow: 24,
  padding: 2,
};

const timePickerStyle = {
  cursor: 'pointer',
  position: 'absolute',
  right: '14px',
  opacity: 0,
  width: '18px',
  height: '20px'
}

const CustomProjectLogo = styled(Avatar)({
  width: "56px",
  height: "56px",
  backgroundColor: "#7F7F80"
});

const ProjectList = ({ sideBarOpen }: any) => {
  const [hasMore, setHasMore] = React.useState(true)
  const windowWidth = React.useRef(window.innerWidth);
  const navigate = useNavigate();
  const location = useLocation();
  const [page, setPage] = React.useState(1);
  const [projects, setProjects] = React.useState<any[]>([]);
  const [loader, setLoader] = React.useState(true);
  const [projectName, setProjectName] = React.useState('');
  const [description, setDescription] = React.useState('');
  const [domain, setDomain] = React.useState('');
  const [standupTime, setSdandupTime] = React.useState("")
  const [standupTimeFocus, setSdandupTimeFocus] = React.useState(false)
  const [filterStatus, setFilterStatus] = React.useState('');
  const [searchQuery, setSearchQuery] = React.useState<string[]>([]);
  const [searchList, setSearchList] = React.useState<string[]>([]);
  const [projectSearch, setProjectSearch] = React.useState('');
  const [open, setOpen] = React.useState(false);

  interface keyData {
    have_key: boolean, 
    have_labelled_key: boolean
  }

  const [keyDetails, setKeyDetails] = React.useState<keyData>({
                                        have_key: true,
                                        have_labelled_key: true
                                      });
  const handleModalOpen = () => {
    setOpen(true);
    setBackendNameError("")
  }
  const handleModalClose = () => {
    setOpen(false);
    setBackendNameError("")
  }

  const [nameError, setNameError] = React.useState(false);
  const [backendNameError, setBackendNameError] = React.useState("")
  const [descriptionError, setDescriptionError] = React.useState('')
  const [domainError, setDomainError] = React.useState('')
  const saveSuccessCallBackFunction = (response: any) => {
    setLoader(false);
    toast.success("The project has successfully been created", {
      position: toast.POSITION.TOP_RIGHT,
    });
    setProjects([]);
    getProjectList();
    resetForm();
    setBackendNameError("")
  };

  const successCallBackFunction = (response: any) => {
    const pageCount = Math.ceil(response.count / 100)
    if (page < pageCount) setPage(page + 1)
    else setHasMore(false)
    setProjects((prev) => ([...prev, ...response.results]));
    setLoader(false);
  }

  const failureCallBackFunction = (response: any) => {
    setLoader(false);
    navigate('/');
  }

  React.useEffect(() => {
    setProjects([])
    getProjectList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterStatus, projectSearch]);

  const getProjectList = () => {
    getApiCall(`/projects/?status=${filterStatus}&search=${projectSearch}&page_size=100&page=${page}`,
      successCallBackFunction, failureCallBackFunction);
    setLoader(true);
  }

  const resetForm = () => {
    setNameError(false)
    setProjectName('');
    setDescription('');
    setDomain('');
    setDescriptionError('')
    setDomainError('')
    setSdandupTime('');
    handleModalClose();
  };

  const handleSubmit = () => {
    if (projectName === "") {
      setNameError(projectName === "");
    }
    else {
      let projectData = {
        title: projectName,
        description: description,
        domain: domain,
        ...(standupTime !== "" && { standup_time: standupTime })
      };
      postApiCall(
        `/projects/`,
        projectData,
        saveSuccessCallBackFunction,
        saveFailureCallBackFunction
      );
      setLoader(true);
    }
  }

  const saveFailureCallBackFunction = (
    errorResponse: any
  ) => {
    if (errorResponse.title) {
      setBackendNameError(errorResponse.title[0])
    }
    if (errorResponse.description) {
      setDescriptionError(errorResponse.description[0])
    }
    if (errorResponse.domain) {
      setDomainError(errorResponse.domain[0])
    }

    if (errorResponse.error) {
      setBackendNameError(errorResponse.error)
    }
    else if(errorResponse === failureResponses.INVALID_TOKEN) navigate('/')
    else if (!errorResponse.title && !errorResponse.description && !errorResponse.error) {
      resetForm();
      toast.error("Something went wrong. Try again.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    setLoader(false);

  };


  React.useEffect(() => {
    if (location.state?.message !== null) {
      toast.success(location.state?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      location.state = null;
      window.history.replaceState(null, document.title)
    }
  }, [location])

  const handleSearchQueryChange = (option: any) => {
    option !== null ? setSearchQuery([option]) : setSearchQuery(searchList)
    setProjectSearch(option === null ? '' : option)
  };

  const getSearchProjectList = () => {
    getApiCall(`/projects/names/`,
      searchProjectListSuccessCallBackFunction, failureCallBackFunction);
  }
  const searchProjectListSuccessCallBackFunction = (response: any) => {
    response.forEach((item: any) => {
      setSearchList(prev => !prev.includes(item) ? [...prev, item] : prev)
    })
  }
  const handleDescriptionValueChange = (value: any) => {
    setDescriptionError('')
    setDescription(value);
  }
  const handleDomainValueChange = (value: any) => {
    setDomainError('')
    setDomain(value);
  }
  const handleNameValueChange = (value: any) => {
    setBackendNameError('')
    setProjectName(value);
  }

  const keySuccessCallBack = (response: keyData) => {
    setKeyDetails(response)
  }

  const getKeyDetails = () => {
    getApiCall(`/user/public_keys/status/`,
    keySuccessCallBack,
    failureCallBackFunction)
  }
   React.useEffect(() => {
    getSearchProjectList()
    getKeyDetails()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const timePickerRef = React.useRef<HTMLInputElement>(null);

  const handleTimePickerIconClick = (event: any) => {
    event.preventDefault();
    timePickerRef.current?.showPicker();
  }

  return (
    <>
      <ToastContainer />
      <Header page="projectList" onClick={handleModalOpen}
        onChange={(event: any, newValue: any) => {
          if (newValue?.value) {
            setFilterStatus(newValue.value);
          } else {
            setFilterStatus("");
          }
        }}
        handleSearchQueryChange={handleSearchQueryChange}
        searchQuery={searchQuery}
        searchList={searchList}
      />
      {loader && <LazyLoader />}
      <Container className={styles.containerBg}>
        <Box id="scrollableDivProjects" className={styles.projectsScrollContainer}>
          <InfiniteScroll
            dataLength={projects.length}
            next={getProjectList}
            hasMore={hasMore}
            loader={loader && <LazyLoader />}
            scrollableTarget="scrollableDivProjects"
          >
            <Box className={styles.projectCardContainer} id="projectContainer">
            {(!keyDetails.have_key || !keyDetails.have_labelled_key) &&
            <div className={activityStyle.externalUserAlertDIv}>
                <img src={instructionIcon} alt="" />
                <p>
                {(!keyDetails.have_labelled_key && keyDetails.have_key) ? `No keys marked as default key. Please mark your keys as either 'Project Specific' or 'Generic' to enable full Keytool functionality.`
                : `No keys are set. Please set your keys to enable full Keytool functionality.`} <span className={styles.spanUnderLine} onClick={() => navigate('/settings')}>Click here</span> to set your keys for future use.
                </p>
              </div>}
              <Grid container spacing={5} justifyContent="center">
                {projects && projects
                  .map((row: any) => {
                    return (
                      <Grid item xs={12} sm={sideBarOpen ? 8 : 6} md={sideBarOpen ? 6 : 4}
                        lg={(windowWidth.current > 1199 && windowWidth.current < 1390 && sideBarOpen) ? 4 : 3} key={row.id}>
                        <Link to={`/project-details/${row.id}/`}>
                          <Card className={styles.projectCard}>
                            <Box className={styles.projectCardDetails}>
                              <Box component="span" className={styles.avatarCell}>
                              {row.logo ? (
                                  <img src={row.logo} alt="" />
                                ) :
                                <CustomProjectLogo src={row.logo} alt={row.title}>{getDefaultProjectInitials(row.title)}</CustomProjectLogo>
                                }
                              </Box>
                              <Box>
                                <Box className={styles.projectTitle}>
                                  <Tooltip title={row.title.length > 16 ? row.title : ''} placement="top-start" arrow>
                                    <p>{row.title}</p>
                                  </Tooltip>
                                </Box>
                                <Box className={styles.projectStatusDetails}>
                                  <Box>
                                    {row.status === "Active" && (
                                      <Chip label="Active" variant="filled" size="small"
                                        sx={{
                                          background: "var(--light-custom-background-success-background, linear-gradient(0deg, rgba(255, 255, 255, 0.88) 0%, rgba(255, 255, 255, 0.88) 100%), #4CAF50)"
                                          , color: "#56CA00", fontSize: "13px"
                                        }} />
                                    )}
                                    {row.status === "On Hold" && (
                                      <Chip label={"On Hold"} variant="filled" size="small"
                                        sx={{
                                          background: "var(--light-custom-background-warning-background, linear-gradient(0deg, rgba(255, 255, 255, 0.88) 0%, rgba(255, 255, 255, 0.88) 100%), #ED6C02)",
                                          color: "var(--light-warning-dark, #A37300)", fontSize: "13px"
                                        }} />
                                    )}
                                    {row.status === "Closed" && (
                                      <Chip label="Closed" variant="filled" size="small"
                                        sx={{
                                          background: "var(--light-custom-background-error-background, linear-gradient(0deg, rgba(255, 255, 255, 0.88) 0%, rgba(255, 255, 255, 0.88) 100%), #F44336)",
                                          color: "var(--light-error-main, #FF4C51)", fontSize: "13px"
                                        }} />
                                    )}
                                  </Box>
                                  <Box className={styles.resources}>
                                    <img src={groupIcon} alt="" className={styles.excludeIcon} />
                                    <Typography className={styles.resourceCount}>{row.resource_count}</Typography>
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                            {
                              row.description && <Box className={styles.projectDescription}>
                                <Tooltip title={row.description.length > 70 ? row.description : ''} placement="right" arrow>
                                  <p className={styles.truncatedText}>
                                    <span className={styles.truncatedSpan}>{row.description}</span>
                                  </p>
                                </Tooltip>
                              </Box>
                            }

                          </Card>
                        </Link>
                      </Grid>
                    )
                  })}
                {!loader && projects.length === 0 && filterStatus === '' &&
                  <Box sx={{ textAlign: "center", padding: "20px" }}><img src={noProjectsImg} alt="No projects" /></Box>}
                {!loader && projects.length === 0 && filterStatus !== '' &&
                  <div className={styles.noProjectFilter}>
                    <img src={noProjectForFilter} alt='No project' />
                    <div>
                      <p>No projects to display. Please adjust your filter settings or create a new project to populate the listing.</p>
                    </div>
                  </div>
                }
              </Grid>
            </Box>
          </InfiniteScroll>
        </Box>
      </Container >
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid item md={12} className={styles.modalHead}>
            <Typography id="modal-modal-title" variant="h6" component="h2" className={styles.modalTitle}>
              Add Project
            </Typography>
          </Grid>
          <Grid item md={12} className={styles.textField}>
            <TextField
              id="outlined-basic"
              label="Project Name"
              variant="outlined"
              fullWidth
              value={projectName}
              required
              helperText={nameError ? "Please enter the project name" : backendNameError}
              error={nameError || backendNameError !== ""}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                handleNameValueChange(event.target.value)
              }}
              autoComplete="off"
            />
          </Grid>
          <Grid item md={12} className={styles.textField}>
            <TextField
              id="filled-multiline-static"
              label="Description"
              multiline
              fullWidth
              rows={4}
              value={description}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                handleDescriptionValueChange(event.target.value)
              }}
              helperText={descriptionError}
              error={descriptionError !== ''}
              autoComplete="off"
            />
          </Grid>
          <Grid item md={12} className={styles.textField}>
            <TextField
              id="filled-multiline-static"
              label="Domain"
              multiline
              fullWidth
              rows={4}
              value={domain}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                handleDomainValueChange(event.target.value)
              }}
              helperText={domainError}
              error={domainError !== ''}
              autoComplete="off"
            />
          </Grid>
          <Grid item md={12} className={styles.textField}>
            <TextField
              inputRef={standupTimeFocus ? timePickerRef : null}
              id="filled-multiline-static"
              label="Standup Time"
              fullWidth
              type={standupTimeFocus ? "time" : "text"}
              value={standupTime ?? ""}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setSdandupTime(event.target.value);
              }}
              autoComplete="off"
              onBlur={() => setSdandupTimeFocus(false)}
              onClick={() => setSdandupTimeFocus(true)}
              inputMode="none"
              InputProps={{
                endAdornment: (
                  standupTimeFocus
                  &&
                  <InputAdornment
                    position="end"
                    sx={timePickerStyle}
                    onMouseDown={handleTimePickerIconClick}>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid container justifyContent="center" className={styles.buttons}>
            <Button variant="outlined" className={styles.cancelButton} onClick={resetForm}>Cancel</Button>
            <Button variant="contained" className={styles.submitButton} onClick={handleSubmit}>ADD PROJECT</Button>
          </Grid>
        </Box>
      </Modal>
    </>
  );
};

export default ProjectList;
