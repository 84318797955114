import React, { useEffect, useReducer, useState } from "react";
import styles from "./DailyStatus.module.scss";
import "../../App.scss";
import LazyLoader from "../shared/LazyLoader/LazyLoader";
import { ToastContainer, toast } from "react-toastify";
import Header from "../Header/Header";
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import { getApiCall } from "../../utils/Utils";
import { useNavigate } from "react-router";
import { activityTypes, failureResponses, hours, minutes } from "../../constants/constants";

// declaring interface for reducer initial state
interface Project {
  id: number;
  title: string;
}

interface State {
  selectedDate: string;
  activeProject: Project;
  activityType: string;
  timeInHours: string;
  timeInMinutes: string;
  statusDescription: string;
}

const initialState: State = {
  selectedDate: "",
  activeProject: {
    id: 0,
    title: "N/A",
  },
  activityType: "Select",
  timeInHours: "08",
  timeInMinutes: "00",
  statusDescription: "",
};

// action type for reducer
type Action = { type: string; element: string; value: string };

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "updateDailyStatus":
    case "clearStatusDescription":
      return {
        ...state,
        [action.element]: action.value,
      };
    default:
      return state;
  }
};

const DailyStatus = () => {
  // states
  const [state, dispatch] = useReducer(reducer, initialState);
  const [truncatedWarning, setTruncatedWarning] = useState(false);
  const [linkedProjects, setLinkedProjects] = useState([]);
  const [characterCount, setCharacterCount] = useState(250);
  const [loader, setLoader] = useState(false);

  const navigate = useNavigate();

  // function to format date in yyyy-mm-dd format
  const formatDate = (date: Date) => {
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  };

  // generate current date and 8 revious dates
  const generateDates = () => {
    const currentDate = new Date();
    const dates = [formatDate(currentDate)];
    for (let i = 1; i <= 8; i++) {
      const previousDate = new Date(currentDate);
      previousDate.setDate(currentDate.getDate() - i);
      dates.push(formatDate(previousDate));
    }
    return dates.reverse();
  };

  const dateArray = generateDates();

  // input onchange handler
  const handleInputChange = (element: string, value: any) => {
    if (element === 'statusDescription') setCharacterCount(250 - value.length);
    dispatch({
      type: "updateDailyStatus",
      element,
      value,
    });
  };

  // handle cancel status description
  const handleCancelStatusDescription = () => {
    setCharacterCount(250);
    dispatch({
      type: "clearStatusDescription",
      element: "statusDescription",
      value: "",
    });
    setTruncatedWarning(false);
  };

  // generate button disabling conditions and styling
  const generateButtonActive =
    (state.activityType === "Coding" || state.activityType === "Testing" || state.activityType === "Business Analysis") &&
    state.activeProject.title !== "N/A";
  const generateButtonStyle = generateButtonActive
    ? styles.generateButtonActive
    : styles.generateButtonInActive;

  // ITC to UTC convertion
  const converter = (dateString: string) => {
    const istDate = new Date(dateString);
    const utcString = istDate.toUTCString();

    // date formatting 
    const date = new Date(utcString);
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, "0");
    const day = String(date.getUTCDate()).padStart(2, "0");
    const hours = String(date.getUTCHours()).padStart(2, "0");
    const minutes = String(date.getUTCMinutes()).padStart(2, "0");
    let seconds = String(date.getUTCSeconds()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    return formattedDate;
  };

  // generate status API call
  const getGeneratedStatus = () => {
    const selectedDate = state.selectedDate || dateArray[8];
    const fromTime = converter(selectedDate + " 00:00:00");
    const toTime = converter(selectedDate + " 23:59:59");


    let activity_type;

    switch (state.activityType) {
      case "Coding":
        activity_type = "coding";
        break;
      case "Testing":
        activity_type = "testing";
        break;
      case "Business Analysis":
        activity_type = "business_analysis";
        break;
      default:
        activity_type = null;
    }

    setLoader(true);
    getApiCall(
      `/user/daily_status/${state.activeProject.id}/?start_date=${fromTime}&end_date=${toTime}&activity_type=${activity_type}`,
      generatedStatusSuccessCallback,
      generatedStatusFaliureCallback
    );
  };

  const generatedStatusSuccessCallback = (response: any) => {
    setLoader(false);
    setCharacterCount(250 - response.description.length);
    response.is_truncated || response.description===''
      ? setTruncatedWarning(true)
      : setTruncatedWarning(false);
    dispatch({
      type: "updateDailyStatus",
      element: "statusDescription",
      value: response.description,
    });
  };

  const generatedStatusFaliureCallback = (response: any) => {
    setLoader(false);
    if (response === failureResponses.INVALID_TOKEN) navigate('/');
    else {
      toast.error("Could not generate status. Please try again", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  // get linked space projects
  const getLinkedProjects = () => {
    setLoader(true);
    getApiCall(
      `/user/space_projects/`,
      spaceProjectsSuccessCallback,
      spaceProjectsFaliureCallback
    );
  };

  const spaceProjectsSuccessCallback = (response: any) => {
    setLoader(false);
    setLinkedProjects(response);
  };

  const spaceProjectsFaliureCallback = (response: any) => {
    if (response === failureResponses.INVALID_TOKEN) navigate('/');
    else {
      setLoader(false);
      toast.error("Error", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  // effect to call data needed on initial render
  useEffect(() => {
    getLinkedProjects();
  }, []);

  const dropdownHeight = {
    PaperProps: {
      style: {
        maxHeight: 300,
      },
    },
  };

  const comingSoonText = "For now, copy the activity description. Soon, you'll be able to save this data here.";
  const noDataTextStyles = truncatedWarning && state.statusDescription==='' ? styles.noDataToDisplay : '';

  return (
    <>
      {loader && <LazyLoader />}
      <ToastContainer />
      <Header page="Daily Activities" />

      <Box className={styles.dailyStatusBox}>
        <Box className={styles.dailyStatusBoxInputs}>
          <Box className={styles.dailyActivities}>
            <FormControl>
              <InputLabel id="demo-simple-select-label">Date</InputLabel>
              <Select
                className={styles.datePicker}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Date"
                onChange={(event) => {
                  handleInputChange("selectedDate", event.target.value);
                }}
                value={state.selectedDate || dateArray[8]}
                MenuProps={dropdownHeight}
              >
                {dateArray.map((item: any, index: number) => (
                  <MenuItem value={item} key={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Autocomplete
              className={styles.projectList}
              options={linkedProjects}
              filterOptions={(options) => options}
              getOptionLabel={(option: any) => option.title}
              renderInput={(params) => (
                <TextField
                  {...params}
                  type={"text"}
                  placeholder="Project"
                  value={"N/A"}
                  label={'Project'}
                />
              )}
              value={state.activeProject}
              onChange={(_, newValue) => {
                const selectedValue = newValue
                  ? newValue
                  : { id: null, title: "N/A" }; // Default to 'N/A' if no option is selected
                handleInputChange("activeProject", selectedValue);
              }}
            />

            <FormControl>
              <InputLabel id="demo-simple-select-label">
                Activity Type
              </InputLabel>
              <Select
                className={styles.activityType}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Activity Type"
                onChange={(event) => {
                  handleInputChange("activityType", event.target.value);
                }}
                value={state.activityType}
                MenuProps={dropdownHeight}
              >
                {activityTypes.map((item: any, index: number) => (
                  <MenuItem value={item} key={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Box>
              <FormControl>
                <InputLabel id="demo-simple-select-label">Time(H)</InputLabel>
                <Select
                  className={styles.timeSelect}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Time(H)"
                  onChange={(event) => {
                    handleInputChange("timeInHours", event.target.value);
                  }}
                  value={state.timeInHours}
                  MenuProps={dropdownHeight}
                >
                  {hours.map((item: any, index: number) => (
                    <MenuItem value={item} key={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl>
                <InputLabel id="demo-simple-select-label">Time(M)</InputLabel>
                <Select
                  className={styles.timeSelect}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Time(M)"
                  onChange={(event) => {
                    handleInputChange("timeInMinutes", event.target.value);
                  }}
                  value={state.timeInMinutes}
                >
                  {minutes.map((item: any, index: number) => (
                    <MenuItem value={item} key={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Box>

          <Box className={styles.activityDescription}>
            <Box className={styles.activityDescriptionHeader}>
              <p className={styles.headerText}>Activity Description</p>
              <Button
                className={generateButtonStyle}
                disabled={!generateButtonActive}
                onClick={getGeneratedStatus}
              >
                GENERATE
              </Button>
            </Box>

            <TextField
              type="text"
              placeholder={truncatedWarning && state.statusDescription === '' ? "No data to display" : "Limit for status description is 250 characters."}
              className={`${styles.statusDescriptionField} ${noDataTextStyles}`}
              multiline
              inputProps={{ maxLength: 250 }}
              onChange={(event) => {
                setTruncatedWarning(false);
                handleInputChange("statusDescription", event.target.value);
              }}
              value={state.statusDescription}
              error={truncatedWarning && state.statusDescription !== ''}
              helperText={
                truncatedWarning && state.statusDescription!=='' ?
                "Due to a character limit, the generated status is truncated to 250 characters. Please review and edit the description as needed before saving." : 
                <p className={styles.characterCount}>{characterCount}</p>
              }
            ></TextField>
          </Box>

          <Box className={styles.statusUpdateButtons}>
            <Button
              className={styles.cancelButton}
              onClick={handleCancelStatusDescription}
            >
              CANCEL
            </Button>
            <Tooltip title={comingSoonText} placement="top-start" className={styles.tooltip} arrow>
              <Button className={styles.confirmButton}>SAVE</Button>
            </Tooltip>
          </Box>
        </Box>

        <Box className={styles.statusHistory}>
          <p className={styles.statusHistoryHeader}>My History</p>
          <Box className={styles.dailyStatusCards}>
            <p className={styles.noHistoryMessage}>No History Available</p>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default DailyStatus;
