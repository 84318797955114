import { Box, Button, Modal, Tooltip } from "@mui/material";
import styles from './DownloadModal.module.scss'
import copyContent from '../../../assets/svg/ContentCopy.svg';
import contentCopeid from '../../../assets/svg/ContentCopied.svg'
import copy from 'copy-to-clipboard';
import React from "react";

const style = {
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 700,
  bgcolor: '#fff',
  borderRadius: '6px',
  boxShadow: 24,
  padding: 2,
};
const DownloadModal = (props: any) => {

  const [copied, setCopied] = React.useState(0);

  const handleCopy = (content: string, command: number) => {
    copy(content);
    setCopied(command);
    setTimeout(() => {
      setCopied(0);
    }, 2000);
  };
  return (
    <>
      <Modal
        open={props.open}>
        <Box sx={{ ...style }}>
          <p className={styles.downloadText}>Download</p>
          <div className={styles.contentDiv}>
            <p className={styles.instruction}>Use the following commands in the CLI to decrypt the downloaded file.</p>
            <div className={styles.commandContainer}>
              <div className={styles.command}>
                <p>
                  <code>gpg --import &lt;your private key filename with extension&gt; </code>
                </p>
                <Tooltip open={copied === 1} title="Copied" placement="bottom-end">
                  <span className={styles.copyButton}
                    onClick={() => handleCopy('gpg --import <your private key filename with extension>', 1)}>
                    {copied === 1 ? <img src={contentCopeid} alt="" /> : <img src={copyContent} alt="" />}
                  </span>
                </Tooltip>
              </div>
              <div className={styles.command}>
                <p> gpg -d &lt;downloaded filename with extension&gt; &gt; &lt;your preferred filname&gt;</p>
                <Tooltip open={copied === 2} title="Copied" placement="bottom-end">
                  <span className={styles.copyButton}
                    onClick={() => handleCopy('gpg -d <downloaded filename with extension> > <your preferred filname>', 2)}>
                    {copied === 2 ? <img src={contentCopeid} alt="" /> : <img src={copyContent} alt="" />}
                  </span>
                </Tooltip>
              </div>
            </div>
          </div>
          <div className={styles.buttonContainer}>
            <Button className={styles.cancelButton} onClick={props.handleModalClose}>cancel</Button>
            <Button className={styles.downloadButton} onClick={props.handleDownloadConfirm}>download</Button>
          </div>
        </Box>
      </Modal>
    </>
  )
}
export default DownloadModal;