import ReactLogo from "../assets/svg/react.svg";
import PythonLogo from "../assets/svg/python.svg";
import DefaultLogo from "../assets/svg/defaultAvatar.svg";
import VueLogo from "../assets/svg/vuejs.svg";
import styles from "../components/ProjectActivity/ActivityDetail/ActivityDetailSideBar.module.scss";

// test case and task icons used in activity detail page sidebar view 
import passedIcon from "../assets/svg/passed.svg"
import failedIcon from "../assets/svg/failed.svg"
import reopenedIcon from "../assets/svg/reopened.svg"
import blockedIcon from "../assets/svg/blocked.svg"
import createdIcon from "../assets/svg/created.svg"
import deletedIcon from "../assets/svg/deleted.svg"
import completedIcon from "../assets/svg/completed.svg"
import createdTestCase from "../assets/svg/created (test_case).svg"
import readyForQAIcon from '../assets/svg/QAReady.svg';
import removedIcon from "../assets/svg/removed.svg"

// extension instructions 
import ExtensionImage1 from "../assets/svg/ExtensionImage1.svg";
import ExtensionImage2 from "../assets/svg/ExtensionImage2.svg";
import ExtensionImage3 from "../assets/svg/ExtensionImage3.svg";

// sprint analysis story icons 
import bug from "../assets/svg/bug.svg";
import epic from "../assets/svg/epic.svg";
import defect from "../assets/svg/defect.svg";
import task from "../assets/svg/task.svg";

export const projectDomain = new Map([
    ["React", ReactLogo],
    ["Angular", DefaultLogo],
    ["Python", PythonLogo],
    ["Java", DefaultLogo],
    ["Vue", VueLogo]
]);

export const projectDetailTabValue: any = new Map([
    [0, "activity"],
    [1, "members"],
    [2, "pointing-poker"],
    [3, "status-label"],
    [4, "external-system"],
    [5, "settings"]
]);

export const failureResponses = {
  INVALID_TOKEN: "Token expired",
  STATUS_404: 'Request failed with status code 404',
  KEY_NAME_TAKEN: 'This keyname is already taken, please select a different name for your key',
  LAST_OWNER: "'You are the last owner in this project. To modify your role, please add another owner first to ensure continued access and management of the project'",
  SESSION_NAME_TAKEN: "This session name is already taken, please select another name",
  NO_PERMISSION: "You do not have permission to perform this action.",
  COLOR_EXISTS: "color already exists ",
  EXTERNAL_SYSTEM_ERROR: "The specified external system type already exists.",
  STORY_ALREADY_CREATED: "Another user has created an in-progress story. Only one allowed per session.",
  SPRINT_DATE_NOT_ADDED: "Please add the start date and end date for this sprint in GitLab"
}

export const activityTypes = [
  "Select",
  "Project Management",
  "Training",
  "Architecting",
  "Requirement Analysis",
  "System design",
  "Coding",
  "Graphic design",
  "Testing",
  "HTML/CSS",
  "Pre-sales",
  "Tech Support",
  "UX design",
  "Marketing",
  "Business Analysis",
  "Recruitment & HR",
  "other",
];

export const hours = [
  "00",
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
];

export const minutes = ["00", "15", "30", "45"];

export const colorMap:{ [key: string]: string } = {
  'PASSED' : styles.passedCaseColor,
  'FAILED' : styles.failedCaseColor,
  'CREATED' : styles.createdCaseColor,
  'BLOCKED' : styles.blockedCaseColor,
  'DELETED' : styles.failedCaseColor,
  'QA Passed' : styles.passedCaseColor,
  'QA Failed' : styles.failedCaseColor,
  'Ready for QA' : styles.blockedCaseColor
}

export const lastOwnerDeleteMessage = 'As the last owner in this project, role deletion is restricted. Please add another owner before attempting to delete your role to ensure continued access and effective project management.'

export const lastOwnerEditMessage = 'You are the last owner in this project. To modify your role, please add another owner first to ensure continued access and management of the project.'

export const iconMap: {[key: string]: string} = {
  "CREATED": createdTestCase,
  "CREATE" : createdIcon,
  "DELETE" : removedIcon,
  "DELETED" : deletedIcon,
  "REOPEN" : reopenedIcon,
  "COMPLETE" : completedIcon,
  "PASSED" : passedIcon,
  "FAILED" : failedIcon,
  "BLOCKED" : blockedIcon,
  "QA Passed" : passedIcon,
  "QA Failed" : failedIcon,
  "Ready for QA" : readyForQAIcon
};

export const instructions:{[key: string]: any} = {
  'Chrome': {
    browser: 'Google Chrome',
    steps: [
      {
        step: `Download and extract the zip file by clicking on the download icon shown in the page.`,
        image: null
      },      {
        step: `Add the extracted file to your browser from manage extension page (Make sure to refresh the Project Tools application after adding this extension to your browser).`,
        image: null
      },      {
        step: `Pin the extension to the Toolbar for quick access`,
        image: null
      },
    ]
  },
  'Firefox': {
    browser: 'Firefox',
    steps: [
      {
        step: `Download the .xpi file by clicking on the <strong>Download Extension</strong> button and choosing Firefox extension from the dropdown.`,
        image: null
      },
      {
        step: `Open the Application Menu of browser and choose <strong>"Add-ons and Themes"</strong>.`,
        image: null
      },
      {
        step: `Click on the Settings icon and then select <strong>"Install Add-on from file"</strong> and choose the file, Click <strong>"Open"</strong>.`,
        image: ExtensionImage1
      },
      {
        step: `Click on more icon of Project Tools Extension and select <strong>"Manage"</strong>.`,
        image: ExtensionImage2
      },
      {
        step: `Switch to the Permissions tab and enable any optional permissions based on your requirements.`,
        image: ExtensionImage3
      },
      {
        step: `Refresh the Project Tools page and your extension is now ready to be used.`,
        image: null
      },
    ]
  }
};

export const externalSystemError = "Since your External user is not currently linked with your Gitlab user, data is not accessible. Kindly request your PM to link your External user with your Gitlab user";

export const externalSystemInstructions = [
  {
    heading: 'Provide External System Information.',
    steps: ['Enter the name of the external system.', 'Specify the type of the external system.']
  },
  {
    heading: 'Configuration for Testmate:',
    subHeading: 'If the external system type is Testmate, follow these steps:',
    steps: ['For the "Project ID" field, input the project slug. (To find the project slug, navigate to Testmate, select the desired project, and copy the slug from the URL.)',
      'Enter the Testmate project URL in the "Project URL" field.']
  },
  {
    heading: 'Configuration for Gitlab:',
    subHeading: 'If the external system type is Gitlab, proceed with these steps:',
    steps: [
      'Obtain the "Access Token" by going to Gitlab Project : Settings > Access Token',
      'When creating the access token, ensure that the role is set as Developer , Owner or Maintainer.',
      'After creating the access token, copy it and paste it into the designated field.',
      'Copy the Project ID from the repository where the issues are created, ensuring it corresponds to the appropriate repository.',
      'In the "Project URL" field, enter the Gitlab project URL.'
    ]
  },
  {
    heading: 'Upon filling in the fields, proceed by clicking the "Confirm" button.'
  }
]

export const shareKeyModalValidationMessages = {
  keyError: "Please enter the key password",
  privateKeyError: "Please enter your private key",
  noSelectedUsersError: "Please select the users to share the key with"
};

export const monthNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const squadFilterNoOptionText = 'Add a new squad by entering the name';
export const EXTERNAL_USER_ALERT = 'A few members are not linked to their external users. Find them using the "<span>No Squad</span>" filter or add the external users from the Members page.'

export const allowedOrigins = ['http://localhost:3000',
  "https://project-tools.qburst.in",
  "https://calicut.qburst.in"];

export const importKeyWarning = "Ensure the imported key name matches the original public key name to allow proper system mapping for download and decryption, and sharing. Incorrect names may cause identification issues. If the key is solely for saving purposes, disregard the above instructions.";

export const accessTokensWarning =
  "To enable users to track activities for daily status, copy the necessary data from GitLab. Make sure these details are accurately transferred and configured in your application for seamless activity tracking.";

export const maturedStoryMessage = "All the stories with and after QA verified status and stories which has gone undergone 2 rounds of QA testing";

export const ticketIconMap:{ [key: string]: string } = {
  "bug" : bug,
  "epic" : epic,
  "story defect" : defect,
  "task" : task
};

export const sprintStatus = {
  "COMPLETED" : "Sprint has been completed",
  "EXPIRED" : "Sprint end date has expired",
  "NO_DATA" : "Sprint data not available"
};

export const progressSections: { [key: string]: string } = {
  "dev_progress" : "Development Progress",
  "qa_progress" : "Testing Progress",
  "other_status" : "Other Status"
};

export const overallProgress:{ [key: string]: string } = {
  "tickets" : "In-Progress Tickets",
  "story_points" : "SP of In-Progress Tickets",
  "tasks" : "Tasks completed",
  "qa passed"  : "QA Passed",
  "qa in progress" : "In-Progress Tickets",
  "blocker" : "Blockers",
  "deployed in prod" : "Deployed in Prod",
  "qa failed" : "QA Failed",
  "uat failed" : "UAT Failed",
  "ready for prod" : "Ready for Prod",
  "ready for qa" : "Dev complete tickets",
  "on hold" : "On hold"
};

export const sprintStoriesSortOptions: { [key: string]: string } = {
  "Lowest to highest story point": "sp_ascending",
  "Highest to lowest story point": "sp_descending",
  "Lowest to highest working hours": "dev_hrs_ascending",
  "Highest to lowest working hours": "dev_hrs_descending"
};

export const burndownChartWarning = "No visible changes in the graph, possibly due to the ticket being updated after the sprint's end date";