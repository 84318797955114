import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Footer from "../Footer/Footer";
import Sidebar from "../Sidebar/Sidebar";
import ProjectList from "../ProjectList/ProjectList";
import ProjectDetails from "../ProjectDetails/ProjectDetails";
import { Route, Routes, useNavigate } from "react-router-dom";
import ProjectDetailsMain from "../ProjectDetailsMain/ProjectDetailsMain";
import styles from "./PmToolMain.module.scss";
import DetailPlanningPokerSession from "../DetailPlanningPokerSession/DetailPlanningPokerSession";
import Members from "../Members/Members";
import ProjectActivity from "../ProjectActivity/ProjectActivity";
import PointingPoker from "../PointingPoker/PointingPoker";
import StatusLabels from "../StatusLabels/StatusLabels";
import ExternalSystems from "../ExternalSystems/ExternalSystems";
import ProjectSettings from "../ProjectSettings/ProjectSettings";
import KeytoolDetail from "../Keytool/KeytoolDetail/KeytoolDetail";
import { useState } from "react";
import Keytool from "../Keytool/KeytoolList/Keytool";
import Settings from "../Settings/Settings";
import ProjectContext from "../Helper/ProjectContext";
import ActivityDetail from "../ProjectActivity/ActivityDetail/ActivityDetail";
import Error from '../ErrorPage/Error';
import Instructions from "../Settings/Instructions";
import DailyStatus from "../DailyStatus/DailyStatus";
import DraggableDialog from "./ExtensionModal/Extension";
import SprintAnalysis from "../SprintAnalysis/SprintAnalysis";
import AccessTokens from "../AccessTokens/AccessTokens";

const mdTheme = createTheme();

const PmToolMain = () => {

  const navigate = useNavigate()
  const [showSidebar, setShowSidebar] = useState(true);
  const [sidebarOpen, setSidebarOpen] = useState(true)
  const sendDataToParent = (index: any) => {
    setSidebarOpen(index);
  };
  const [projectRole, setProjectRole] = useState('')

  document.addEventListener('navigate to settings page', function (event) {
    navigate('/settings')
  });

  return (
    <ThemeProvider theme={mdTheme}>
      <Box className={styles.drawerMainDiv}>
        <CssBaseline />
        {showSidebar && <Sidebar sendDataToParent={sendDataToParent} />}
        <Box
          component="main"
          sx={{
            backgroundColor: "#FFFFFF",
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <ProjectContext.Provider value={{ role: projectRole }}>
            <Container className={styles.drawerContainerDiv} sx={{ mb: 4 }} maxWidth={false}>
              <Routes>
                <Route path="/settings" element={<Settings />} />
                <Route path="/settings/help" element={<Instructions />} />
                <Route path="/project-list" element={<ProjectList sideBarOpen={sidebarOpen} />} />
                <Route path="/overview" element={<ProjectDetails />} />
                <Route path="/daily-status" element={<DailyStatus />} />
                <Route path="/inter-project-analytics" element={<ProjectDetails />} />
                <Route path="/my-bots" element={<ProjectDetails />} />
                <Route path="/commom-tools" element={<ProjectDetails />} />
                <Route path="/resource-management" element={<ProjectDetails />} />
                <Route path="/:projectId/poker-session/:sessionId" element={<DetailPlanningPokerSession  setShowSidebar={setShowSidebar}/>} />
                <Route path="/project-details/:projectId" element={<ProjectDetailsMain setRole={setProjectRole} />} />
                <Route path="/project-details/:projectId/activity" element={<ProjectActivity setRole={setProjectRole} />} />
                <Route path="/project-details/:projectId/activity/:userId/details" element={<ActivityDetail setRole={setProjectRole} role={projectRole} />} />
                <Route path="/project-details/:projectId/members" element={<Members setRole={setProjectRole} />} />
                <Route path="/project-details/:projectId/pointing-poker" element={<PointingPoker setRole={setProjectRole} setShowSidebar={setShowSidebar}/>} />
                <Route path="/project-details/:projectId/status-label" element={<StatusLabels />} />
                <Route path="/project-details/:projectId/external-system" element={<ExternalSystems setRole={setProjectRole} />} />
                <Route path="/project-details/:projectId/settings" element={<ProjectSettings setRole={setProjectRole} />} />
                <Route path="/project-details/:projectId/keytool" element={<Keytool />} />
                <Route path="/project-details/:projectId/key-details/:keyId/" element={<KeytoolDetail />} />
                <Route path="/project-details/:projectId/sprint-analysis" element={<SprintAnalysis />} />
                <Route path="/access-tokens" element={<AccessTokens />} />
                <Route path="/*" element={<Error setShowSidebar={setShowSidebar}/>} />
              </Routes>
            </Container>
          </ProjectContext.Provider>
        <DraggableDialog/>
          {window.innerWidth > 800 && <Footer />}
        </Box>
      <div className={styles.extensionDiv} id="showExtensionPopup"> <p id="#name#"></p><p id="#key#"></p></div>
      </Box>
    </ThemeProvider>
  );
}

export default PmToolMain;
