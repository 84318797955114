import { Box, Button, Grid, List, ListItem, ListItemAvatar, ListItemButton, ListItemIcon, ListItemText, MenuItem, Select, styled, Tooltip, Typography } from "@mui/material";
import styles from "./DetailPlanningPokerSession.module.scss";
import mobileStyle from './MobileView/DetailedPlaningPokerMobile.module.scss'
import CheckIcon from "../../assets/svg/checkIcon.svg";
import React, { useMemo } from "react";
import { getApiCall, patchApiCall } from "../../utils/Utils";
import ReconnectingWebSocket from "reconnecting-websocket";
import { ACCESS_TOKEN, REFRESH_TOKEN, USER_ID, WEB_SOCKET_BASE_URL } from "../../config";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import alertIcon from '../../assets/svg/alert.svg'
import notVotedIcon from '../../assets/svg/notVotedIcon.svg';
import absentIcon from '../../assets/svg/absentIcon.svg'
import defaultImage from '../../assets/svg/defaultPlayerImage.svg';
import upIcon from '../../assets/svg/chevron-up.svg'
import LazyLoader from "../shared/LazyLoader/LazyLoader";
import arrowDown from '../../assets/svg/chevron-down.svg'
import { failureResponses } from "../../constants/constants";


const StyledPoints = styled(ListItemButton)(({ theme }) => ({
  " &.Mui-selected": {
    border: "1px solid #444",
    background: "#444!important",
    color: "#FFF!important",
    position: "relative",
    top: "-10px",
    transition: "top ease 0.5s"
  },
  " &.Mui-disabled": {
    opacity: "0.5!important",
    border: "1px solid #444!important",
    background: "#FFF!important",
    color: "#444!important",
  },
  " &.Mui-disabled.Mui-selected": {
    background: "#444!important",
    color: "#FFF!important",
  }
}));

const StyledSelect = styled(Select)(({ theme }) => ({
  '.css-1rxz5jq-MuiSelect-select-MuiInputBase-input-MuiInput-input.css-1rxz5jq-MuiSelect-select-MuiInputBase-input-MuiInput-input.css-1rxz5jq-MuiSelect-select-MuiInputBase-input-MuiInput-input': {
    // paddingRight: '8px!important',
  }
}))

const DisplayPlayerCards = (props: any) => {
  // Web socket connection
  const rwsInitial = useMemo(() => {

    return new ReconnectingWebSocket(`${WEB_SOCKET_BASE_URL}/${props.storyId}/?token=${localStorage.getItem(REFRESH_TOKEN)}`);
    // eslint-disable-next-line
  }, []);

  const navigate = useNavigate();
  const [rws, setRWS] = React.useState(rwsInitial)
  const [isRevealStatus, setIsRevealStatus] = React.useState(false);
  const [chooseCardDisable, setChooseCardDisable] = React.useState(true);
  const [loader, setLoader] = React.useState(true);
  const [memberListCount] = React.useState();

  const [votedPlayers, setVotedPlayers] = React.useState(props.members.length)

  const [voteShareHashMap, setVoteShareHashMap] = React.useState(new Map());
  const [votedUserMap, setVotedUserMap] = React.useState(new Map());

  const storyPoints = ['skip', '1', '2', '3', '5', '8', '13', 'unknown'];

  const [memberTop, setMemberTop] = React.useState([]);
  const [memberBottom, setMemberBottom] = React.useState([]);

  const [summaryFlag, setSummaryFlag] = React.useState(false);
  const [isActiveRevealCard, setIsActiveRevealCard] = React.useState(true);
  const [isActiveMemberRevealCard, setIsActiveMemberRevealCard] = React.useState(true);

  const [voteShare, setVoteShare] = React.useState([]);

  const [selectedStoryPoint, setSelectedStoryPoint] = React.useState("");

  const [selectedIndex, setSelectedIndex] = React.useState(null);
  const selectedIndexRef = React.useRef(null)
  const windowWidth = React.useRef(window.innerWidth);
  const totalMembers = (Math.round((props.members.length - 2) / 2));
  const [activeMembers, setActiveMembers] = React.useState<number[]>([])
  const [memberList, setMemberList] = React.useState<MemberData[]>([])
  const [viewMore, setViewMore] = React.useState(false)

  React.useEffect(() => {
    const { setVoteTimer, timerMessage } = props;
    if (timerMessage === 'Time out') {
      if (votedPlayers) {
        revealCard();
      } else {
        setVoteTimer(false);
        setChooseCardDisable(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.timerMessage]);

  React.useEffect(() => {
    playerLists();
    getIsRevealedStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const playerLists = () => {
    setMemberTop(props.members.slice(2, totalMembers + 2));
    setMemberBottom(props.members.slice(totalMembers + 2, memberListCount));
  }


  React.useEffect(() => {
    if (!viewMore) {
      if (props.members.length > 30) {
        setMemberList(props.members.slice(0, 30))
      }
      else setMemberList(props.members)
    }
    if (viewMore) setMemberList(props.members)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewMore])
  React.useEffect(() => {
    if (props.timerMessage === 'Time out') {
      votedPlayers && setChooseCardDisable(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.timerMessage])
  /** Get Reveal card status and member choose card on refresh page */
  const getIsRevealedStatus = () => {
    getApiCall(
      `/projects/${props.projectId}/session/${props.sessionId}/story/${props.storyId}/revealed_status/`,
      revealStatusSuccessCallBackFunction,
      failureCallBackFunction
    )
    setLoader(true)
  }

  const revealStatusSuccessCallBackFunction = (response: any) => {
    props.setTimerButtonShow(true)
    response.vote_progress.forEach((element: any) => {
      if (element.status === "complete") {
        setIsActiveRevealCard(false)
        setVotedUserMap(votedUserMap.set(element.user, element.status))
      }
    });
    setVotedPlayers(votedUserMap.size);
    setIsRevealStatus(response.is_story_point_revealed);
    if (response.is_story_point_revealed) {
      props.setTimerMessage('')
      setSummaryFlag(true);
      setChooseCardDisable(true);
      props.setIsPmoReveal(false);
    }
    else if (!response.is_story_point_revealed && response.is_user_vote_revealed) {
      getRevealResult()
      setSummaryFlag(false);
      setIsActiveMemberRevealCard(true);
      setChooseCardDisable(true);
      setLoader(false)
    }
    else if (response.is_story_point_revealed !== true && response.is_user_vote_revealed !== true) {
      if (response.requested_user_vote !== "") {
        setTimeout(() => {
          selectedIndexRef.current = response.requested_user_vote
          setSelectedIndex(response.requested_user_vote);
          setIsActiveMemberRevealCard(false);
        }, 500)
        props.setAlert(false)
      }
      setChooseCardDisable(false);
      setLoader(false)
    }
  }

  const displayVotePoints = (points: any) => {
    if (points === 'unknown') return '?'
    else if (points === 'skip') return '0'
    else return points
  }
  const displayVotePointsMobile = (points: any) => {
    if (points === 'unknown') return 'K'
    else if (points === 'skip') return '0'
    else return points
  }
  /** On click of choose card */
  const chooseCard = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: any,
    points: any
  ) => {
    let selectedPoints = (points === "0" ? "skip" : (points === "?") ? "unknown" : points);
    props.setTimerMessage('')
    setSelectedIndex(points);
    selectedIndexRef.current = points
    let userId = localStorage.getItem(USER_ID);
    let votingData = { "action": "voting", "data": { "user_id": userId, "story_id": props.storyId, "vote": selectedPoints } }
    rws.send(JSON.stringify(votingData));
    setIsActiveMemberRevealCard(false);
    props.setAlert(false)
  };

  rws.onclose = function (event) {
    if (localStorage.getItem(ACCESS_TOKEN) && window.location.href.includes("poker-session") && props.tabValue.current === 1) {
      const rwsNew = new ReconnectingWebSocket(`${WEB_SOCKET_BASE_URL}/${props.storyId}/?token=${localStorage.getItem(REFRESH_TOKEN)}`);
      setRWS(rwsNew)
    }
  }
  /** On receiving message event from Websocket */
  rws.addEventListener('open', () => {
    rws.onmessage = message => {
      let socketResponse = JSON.parse(message.data)

      if (socketResponse.status_code === 401) {
        navigate('/');
      }

      if (socketResponse.status === 'completed' && socketResponse.user) {
        setVotedUserMap(votedUserMap.set(socketResponse.user, socketResponse.status))
        setVotedPlayers(votedUserMap.size);
        setIsActiveRevealCard(false);
        if (!votedUserMap.has(props.memberId)) props.setTimerMessage('Time is running out, caste your vote now!')
        if (votedUserMap.has(props.memberId)) props.setTimerMessage('')
      }

      if (socketResponse.status === "reveal_card") {
        setIsActiveMemberRevealCard(true)
        props.setSeconds(0)
        props.setMinutes(1)
        props.setVoteTimer(false)
        props.setTimerMessage('')
        props.setTimerRun(false)
        getRevealResult();
        setSummaryFlag(true);
        setIsRevealStatus(true);
        props.setIsPmoReveal(false);
        setChooseCardDisable(true);
      }
      if (socketResponse.status === "another_round") {
        setSummaryFlag(false)
        setIsActiveMemberRevealCard(true)
        props.anotherRoundTimer()
        props.setVoteTimer(false)
        props.setSeconds(0)
        props.setMinutes(1)
        props.setIsPmoReveal(true);
        setIsActiveRevealCard(true);
        votedUserMap.clear();
        setVotedPlayers(0)
        setSelectedIndex(null)
        selectedIndexRef.current = null
        setIsRevealStatus(false)
        voteShareHashMap.clear()
        setChooseCardDisable(false)
      }
      if (socketResponse.status === "timer_activated") {
        props.setVoteTimer(true)
        if (socketResponse.timer_status !== 'timer_ended') {
          let startDate = new Date(socketResponse.activated_time)
          startDate.setHours(startDate.getHours() + 5);
          startDate.setMinutes(startDate.getMinutes() + 30);
          const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
          const userLocalTime = new Date(startDate.toLocaleString('en-US', { timeZone: timeZone }))
          props.setCounterTime(userLocalTime)
          const currentDate = new Date()
          let seconds = (currentDate.getTime() - userLocalTime.getTime()) / 1000

          props.setTimerRun(true)
          props.setAlert(true)
          if (!votedUserMap.has(props.memberId)) props.setTimerMessage('Time is running out, caste your vote now!')
          if (votedUserMap.has(props.memberId)) props.setTimerMessage('')
          if (Math.round(seconds) === 0) {
            props.setSeconds(0)
            props.setMinutes(1)
          }
          else {
            if (Math.round(seconds) === -1) props.setSeconds(60 + Math.round(seconds))
            else {
              if (Math.round(seconds) < 0) {
                props.setMinutes(1)
                props.setSeconds(0)
              }
              else props.setSeconds(60 - Math.round(seconds))
            }
            props.setMinutes(0)
          }
        }
        if (socketResponse.timer_status === 'timer_ended') {
          props.setTimerMessage('Time out')
          props.setSeconds(0)
          props.setMinutes(0)
          setIsRevealStatus(false)
          setChooseCardDisable(true)
          props.setTimerRun(false)
        }
      }
      if (socketResponse.active_users) {
        setActiveMembers(socketResponse.active_users)
      }
      if (socketResponse.status === "complete") {
        props.timeOut()
        window.location.reload()
      }
      if (socketResponse.status === "cancel") {
        props.timeOut()
        window.location.reload()
      }
    };
  });

  rws.onerror = function (err) {
    console.log("onerror")
    rws.close();
  };

  /** On click of reveal card */
  const revealCard = () => {
    /** if member clicks reveal card update patch API */
    if (props.userRole === "MEMBER") {
      setIsActiveMemberRevealCard(true);
      patchApiCall(`/projects/${props.projectId}/session/${props.sessionId}/story/${props.storyId}/reveal/update/`,
        { is_revealed: "true" },
        revealSuccessCallBackFunction, revealFailureCallBackFunction);
      getRevealResult();
    }
    /** If PM/Owner clicks on reveal card send voting data to web socket */
    if (!isRevealStatus && props.userRole !== "MEMBER") {
      setIsRevealStatus(true);
      let userId = localStorage.getItem(USER_ID);
      let votingData = { "action": "alert", "data": { "user_id": userId, "story_id": props.storyId, "project_id": props.projectId, "status": "reveal_card" } }
      rws.send(JSON.stringify(votingData));
      setSummaryFlag(true);
      setChooseCardDisable(false);
    }
  };

  const revealFailureCallBackFunction = (
    errResponse: any
  ) => {
    toast.error("Something went wrong. Try again.", {
      position: toast.POSITION.TOP_RIGHT,
    });
    if(errResponse === failureResponses.INVALID_TOKEN) navigate('/')
  };

  const revealSuccessCallBackFunction = (response: any) => {
    return response;
  };

  /** To get voting result */
  const getRevealResult = () => {
    getApiCall(
      `/projects/${props.projectId}/session/${props.sessionId}/story/${props.storyId}/result`,
      successCallBackFunction,
      failureCallBackFunction
    );
    setLoader(true)
  }

  const failureCallBackFunction = (
    errResponse: any
  ) => {
    toast.error("Something went wrong. Try again.", {
      position: toast.POSITION.TOP_RIGHT,
    });
    setLoader(false)
  };

  const successCallBackFunction = (response: any) => {
    props.onSelectStory(response.story_point)
    response.vote_by_member.forEach((element: any) => {
      setVoteShareHashMap(voteShareHashMap.set(element.user_id, element.vote_points))
    });
    setSelectedStoryPoint(response.story_point);
    setVoteShare(response.vote_share);

    /** On refresh check choosen card for member */
    response.vote_by_member.forEach((element: any) => {
      if (element.user_id === props.memberId) {
        let votePoint = element.vote_points;
        setSelectedIndex(votePoint);
        selectedIndexRef.current = votePoint
        setChooseCardDisable(true);
        props.setIsPmoReveal(false);
      }
    });
    setLoader(false)
  };

  const exitSession = () => {
    if (props.status === "anotherRound") {
      let userId = localStorage.getItem(USER_ID);
      let requestData = { "action": "alert", "data": { "user_id": userId, "story_id": props.storyId, "project_id": props.projectId, "status": "another_round" } }
      rws.send(JSON.stringify(requestData));
      props.setStatus()
    }
    if (props.status === "completed") {
      let userId = localStorage.getItem(USER_ID);
      let requestData = { "action": "alert", "data": { "user_id": userId, "story_id": props.storyId, "project_id": props.projectId, "status": "complete" } }
      rws.send(JSON.stringify(requestData));
    }
    if (props.status === "deleteStory") {
      let userId = localStorage.getItem(USER_ID);
      let requestData = { "action": "alert", "data": { "user_id": userId, "story_id": props.storyId, "project_id": props.projectId, "status": "cancel" } }
      rws.send(JSON.stringify(requestData));
    }
    setTimeout(() => {
      if (props.status !== "anotherRound" && rws.readyState !== 3) {
        rws.close(1000, "session closed");
        window.location.reload()
      }
    }, 2000)
  }

  const convertName = (memberName: any) => {
    let nameparts = memberName.split(" ");
    let initials = nameparts[0].charAt(0).toUpperCase();

    return initials
  }
  const exitStatuses = ['completed', 'deleteStory', 'anotherRound']
  if (exitStatuses.includes(props.status)) {
    exitSession()
  }
  else if (props.status === 'Timer activated') {
    let userId = localStorage.getItem(USER_ID);
    let requestData = { "action": "timer", "data": { "user_id": userId, "story_id": props.storyId, "project_id": props.projectId } }
    rws.send(JSON.stringify(requestData))
    props.setStatus('inProgress')
  }
  React.useEffect(() => {
    return () => {
      rws.close()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  interface MemberData {
    email: string,
    id: number,
    name: string,
    profile_pic: string,
    role: string
  }

  const renderProfileImage = (profilePic: string | null, email: string, isFlipped: boolean) => {
    if (profilePic === null) {
      return <div className={isFlipped ? styles.flippedDefaultImg : styles.defaultAvatarImg}>{convertName(email)}</div>;
    }
    else return <div className={isFlipped ? styles.smallUserIcon : styles.userAvatarImg}><img src={profilePic} alt="" /></div>;
  };

  const renderFlippedCard = (row: any) => {
    const vote = voteShareHashMap.get(row.id);
    let displayVote: string;
    if (vote === 'skip') {
      displayVote = '0';
    } else if (vote === 'unknown') {
      displayVote = '?';
    } else {
      displayVote = vote;
    }
    return (
      <Box className={`${styles.flippedCard} ${votedUserMap.has(row.id) ? styles.customBorderGreen : styles.customBorderRed}`}>
        <div className={styles.flippedCardText}>{displayVote}</div>
        {renderProfileImage(row.profile_pic, row.email, true)}
        {votedUserMap.has(row.id) && renderStatusIcon(row)}
      </Box>
    );
  };

  const renderStatusIcon = (row: any) => {
    if (votedUserMap.has(row.id)) return (
      <div className={styles.checkUserIcon}>
        <Tooltip title='Voted' placement="right-end">
          <img src={CheckIcon} className={styles.iconImage} alt="" />
        </Tooltip></div>
    )
    else if (activeMembers.includes(row.id)) return (
      <div className={styles.checkUserIcon}>
        <Tooltip title='Not voted' placement="right-end" >
          <img src={notVotedIcon} className={styles.iconImage} alt='' />
        </Tooltip>
      </div>
    )
    else return (
      <div className={styles.checkUserIcon}>
        <Tooltip title='Absent' placement="right-end" >
          <img src={absentIcon} className={styles.iconImage} alt='' />
        </Tooltip>
      </div>
    )
  };
  const renderAvatarCard = (row: any) => {
    return (
      <Box className={`${styles.avatarCard} ${votedUserMap.has(row.id) ? styles.customBorderGreen : styles.customBorderRed}`}>
        {renderProfileImage(row.profile_pic, row.email, false)}
        {renderStatusIcon(row)}
      </Box>
    );
  };
  const renderRow = (row: any) => {
    return (
      <Grid item md={1} key={row.id} sx={{ marginRight: '0px' }}>
        <Tooltip title={row.name} placement="bottom-end">
          {voteShareHashMap.has(row.id)
            ? renderFlippedCard(row)
            : renderAvatarCard(row)}
        </Tooltip>
      </Grid>
    );
  };

  const mobileVotedCheck = (row: any, flipped: boolean) => {
    if (votedUserMap.has(row.id)) return (
      <div className={mobileStyle.checkUserIcon}>
        <Tooltip title='Voted' placement="right-end" enterTouchDelay={0}>
          <img src={CheckIcon} className={flipped ? mobileStyle.iconImage : mobileStyle.indicator} alt="" />
        </Tooltip></div>
    )
    else return null
  }
  const mobileActiveUsers = (row: any) => {
    let titleText = ''
    if (activeMembers.includes(row.id)) {
      titleText = 'Not voted'
    }
    else titleText = 'Absent'
    return (
      <div className={mobileStyle.checkUserIcon}>
        <Tooltip title={titleText} placement="right-end" enterTouchDelay={0}>
          <img src={activeMembers.includes(row.id) ? notVotedIcon : absentIcon} className={mobileStyle.indicator} alt='' />
        </Tooltip>
      </div>
    )
  }
  const mobileMemberCard = (row: any) => {
    let vote = ''
    if (voteShareHashMap.get(row.id)) vote = '0'
    else if (voteShareHashMap.get(row.id) === "unknown") vote = "-"
    else vote = voteShareHashMap.get(row.id)

    if (voteShareHashMap.has(row.id)) return (
      <Box className={mobileStyle.flippedCard} sx={{ border: votedUserMap.has(row.id) ? '1px solid #56ca00' : '1px solid #FD4E4E' }}>
        <div className={mobileStyle.flippedCardText}>{vote}</div>
        <div className={mobileStyle.smallUserIcon}><img src={row.profile_pic ?? defaultImage} alt="" className={mobileStyle.flippedImage} /></div>
        {mobileVotedCheck(row, true)}
      </Box>
    )
    else return (
      <Box className={mobileStyle.avatarCard} sx={{ border: votedUserMap.has(row.id) ? '2px solid #56ca00' : '2px solid #FD4E4E' }}>
        <div className={mobileStyle.userAvatarImg}><img src={row.profile_pic ?? defaultImage} alt="" className={mobileStyle.avatarPic} />
          {mobileVotedCheck(row, false)}
          {!votedUserMap.has(row.id) && mobileActiveUsers(row)}
        </div>
      </Box>
    )
  }

  const notVotedAlert = (id: number) => {
    if (!votedUserMap.has(id) && props.timerRun && props.userRole !== "MEMBER" && activeMembers.includes(id)) {
      return (
        <div className={styles.alertContainer}>
          <div className={styles.imgText}>
            <img src={alertIcon} alt='' />
            <p className={styles.alertHeader}>Voting Alert</p>
          </div>
          <div className={styles.alertContent}>
            <p>This participant hasn’t voted.</p>
          </div>
        </div>
      )
    }

  }

  const playersVoted = () => `${votedPlayers} player${votedPlayers === 1 ? '' : 's'} voted`
  const revealCardGridMd = (length: number) => {
    if (length === 1) return 2
    else return length
  }

  const selectComponent = (mobile: boolean) => {
    if(mobile) {
      if(props.userRole === "MEMBER") return (
        <p className={mobileStyle.storyPoint}>{selectedStoryPoint}</p>
      ) 
      else return (
        <StyledSelect
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          variant="standard"
          displayEmpty
          value={selectedStoryPoint}
          disableUnderline
          IconComponent={() => <img src={arrowDown} alt="" />}
          sx={{ fontSize: '12px', fontStyle: 'Inter', color: "#151515", fontWeight: "500", paddingRight: '0px!important' }}
          onChange={(event: any) => {
            const value = event.target.value
            setSelectedStoryPoint(value);
            props.onSelectStory(value);
          }}
        >
          {storyPoints.filter(points => !['skip', 'unknown'].includes(points)).map((points) => (
            <MenuItem key={points} value={points}>{points}</MenuItem>
          ))}
        </StyledSelect>
      )}
      else {
        if(props.userRole === "MEMBER") return (
          <span className={styles.spanStoryPoint}>{selectedStoryPoint}</span>
        ) 
        else return (
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            variant="standard"
            displayEmpty
            value={selectedStoryPoint}
            disableUnderline
            sx={{ fontSize: '24px', fontStyle: 'normal', color: "#3A3541", fontWeight: "500", marginLeft: "10px" }}
            onChange={(event) => {
              const value = event.target.value
              setSelectedStoryPoint(value);
              props.onSelectStory(value);
            }}
          >
            {storyPoints.filter(points => !['skip', 'unknown'].includes(points)).map((points) => (
              <MenuItem key={points} value={points}>{points}</MenuItem>
            ))}
          </Select>
        )}
    }
  return (
    <>
      {loader && <LazyLoader />}
      {!loader ?
        windowWidth.current > 800 ?
          <>
            <>
              {(props.members.length > 24) ? (
                <Grid container spacing={2} justifyContent="left" sx={{ marginLeft: "20px!important" }}>
                  {(!isRevealStatus) && (
                    <>
                      <Grid item md={9.5} display="flex" justifyContent="center" alignItems="center" className={styles.waitingPlayers}>
                        <div >
                          <Typography className={styles.waitingPlayersText}>
                            {playersVoted()}
                          </Typography>
                        </div>
                      </Grid>
                      {(!isRevealStatus && props.userRole !== "MEMBER") && (
                        <Grid item md={2} className={styles.buttons}>
                          <Button className={styles.submitButton} onClick={revealCard} disabled={isActiveRevealCard}
                            sx={{ marginTop: "20px!important"}}>REVEAL CARDS</Button>
                        </Grid>
                      )}
                      {(!summaryFlag && props.userRole === "MEMBER") && (
                        <Grid item md={2} className={styles.buttons}>
                          <Button className={styles.submitButton} onClick={revealCard} disabled={isActiveMemberRevealCard}
                            sx={{ marginTop: "20px!important"}}>REVEAL CARDS</Button>
                        </Grid>
                      )}
                    </>
                  )}
                  {isRevealStatus && (
                    <Grid item md={12}>
                      <Box className={styles.revealedCardBox}>
                        <Grid container sx={{ marginTop: "10px" }}>
                          <Grid item md={6}>
                            <Typography className={styles.revealedCardText}>REVEALED CARDS</Typography>
                          </Grid>
                          <Grid item md={6} display="flex" justifyContent="right" alignItems="right">
                            <div className={styles.playerListText}>
                              Story Summary
                              {selectComponent(false)}
                            </div>
                          </Grid>
                          <Grid item md={12} className={styles.divider}></Grid>
                        </Grid>

                        <Grid container md={12} sx={{ flexWrap: "wrap" }} gap={2}>

                          {voteShare.map((row: any) => {
                            return (
                              <div key={row.vote_points}>
                                <Grid item sx={{ minWidth: "105px", width: "105px" }}>
                                  <List className={styles.playerListText} sx={{ width: "95%" }}>
                                    <ListItem key={row.vote_points} disablePadding className={styles.votesList}>
                                      <ListItemAvatar className={styles.storyPoints}>
                                        {row.vote_points === "skip" ? "0" : (row.vote_points === "unknown") ? "?" : row.vote_points}
                                      </ListItemAvatar>
                                      <ListItemIcon className={styles.votesBox}>
                                        <Typography className={styles.votes}> {row.count}
                                          {row.count === 1 ? (
                                            <span className={styles.votesText}>Vote</span>

                                          ) : (
                                            <span className={styles.votesText}>Votes</span>
                                          )}
                                        </Typography>
                                      </ListItemIcon>
                                    </ListItem>
                                  </List>
                                </Grid>
                              </div>
                            )
                          }
                          )}
                        </Grid>

                      </Box>
                    </Grid >
                  )}
                </Grid >
              ) : (
                <Grid container spacing={2} justifyContent="left" sx={{ marginLeft: "20px!important" }}>
                  {!votedPlayers || !isRevealStatus && (
                    <>
                      <Grid item md={12} display="flex" justifyContent="center" alignItems="center" className={styles.waitingPlayers}>
                        <div >
                          <Typography className={styles.waitingPlayersText}>
                            {playersVoted()}
                          </Typography>
                        </div>
                      </Grid>
                    </>
                  )}
                </Grid>
              )}

              <Grid item md={12} className={styles.tableViewMiddleGrid}>
                {(props.members.length <= 24) &&
                  (
                    <Box>
                      <Grid container spacing={2} justifyContent="center" sx={{ textAlign: 'center' }}>
                        {memberTop.map((row: MemberData, index: number) => {
                          return (
                            <div key={row.id} className={styles.avatarCardContainer}>
                              <Grid item md={1}>
                                {index % 2 === 0 && notVotedAlert(row.id)}
                              </Grid>
                              <Grid item md={1} className={styles.avatarCardMargin}>
                                {renderRow(row)}
                              </Grid>
                              <Grid item md={1}>
                                {index % 2 !== 0 && notVotedAlert(row.id)}
                              </Grid>
                            </div>
                          )
                        })}
                      </Grid>

                      <Grid container spacing={2} justifyContent="center" md={12} gap={1} className={styles.tableViewMiddleColumn}>
                        <Grid item sx={{ marginTop: "50px" }} className={styles.tableViewUserCard}>

                          {notVotedAlert(props.members[0])}
                          {renderRow(props.members[0])}
                        </Grid>
                        {(!isRevealStatus && props.userRole !== "MEMBER") && (
                          <Grid item md={revealCardGridMd(memberTop.length)} sx={{ paddingLeft: "0px!important" }}>
                            <Box className={styles.revealCardBox} display="flex" justifyContent="center" alignItems="center">
                              <Button className={styles.revealButton} onClick={revealCard} disabled={isActiveRevealCard}>REVEAL CARDS</Button>
                            </Box>
                          </Grid>
                        )}
                        {(!summaryFlag && props.userRole === "MEMBER") && (
                          <Grid item md={revealCardGridMd(memberTop.length)} sx={{ paddingLeft: "0px!important" }}>
                            <Box className={styles.revealCardBox} display="flex" justifyContent="center" alignItems="center">
                              <Button className={styles.revealButton} onClick={revealCard} disabled={isActiveMemberRevealCard}>REVEAL CARDS</Button>
                            </Box>
                          </Grid>
                        )}
                        {isRevealStatus && (
                          <Grid item md={9} className={styles.revealedTable}>
                            <Box className={styles.revealedCardBox}>
                              <Grid container sx={{ marginTop: "10px" }}>
                                <Grid item md={6}>
                                  <Typography className={styles.revealedCardText}>REVEALED CARDS</Typography>
                                </Grid>
                                <Grid item md={6} display="flex" justifyContent="right" alignItems="right">
                                  <div className={styles.playerListText}>
                                    Story Summary
                                    {selectComponent(false)}
                                  </div>
                                </Grid>
                                <Grid item md={12} className={styles.divider}></Grid>
                              </Grid>

                              <Grid container md={12} sx={{ flexWrap: "wrap" }} gap={2}>
                                {voteShare.map((row: any) => {
                                  return (
                                    <Grid item sx={{ minWidth: "105px", width: "105px" }} key={row.vote_points}>
                                      <List className={styles.playerListText} sx={{ width: "95%" }}>
                                        <ListItem key={row.vote_points} disablePadding className={styles.votesList}>
                                          <ListItemAvatar className={styles.storyPoints}>
                                            {row.vote_points === "skip" ? "0" : (row.vote_points === "unknown") ? "?" : row.vote_points}
                                          </ListItemAvatar>
                                          <ListItemIcon className={styles.votesBox}>
                                            <Typography className={styles.votes}> {row.count}
                                              {row.count === 1 ? (
                                                <span className={styles.votesText}>Vote</span>

                                              ) : (
                                                <span className={styles.votesText}>Votes</span>
                                              )}
                                            </Typography>
                                          </ListItemIcon>
                                        </ListItem>
                                      </List>
                                    </Grid>
                                  )
                                }
                                )}
                              </Grid>
                            </Box>
                          </Grid>
                        )}
                        {props.members.length > 1 && (
                          <Grid item sx={{ marginTop: "50px", marginLeft: "0px" }} className={styles.tableViewUserCard}>
                            {notVotedAlert(props.members[1])}
                            {renderRow(props.members[1])}
                          </Grid>
                        )}
                      </Grid>

                      <Grid container spacing={2} justifyContent="center">
                        {memberBottom.map((row: any, index: any) => {
                          return (
                            <div key={row.id} className={styles.avatarCardContainer}>
                              <Grid item md={1}>
                                {!votedUserMap.has(row.id) && props.timerRun && index % 2 === 0 && props.userRole !== "MEMBER" && activeMembers.includes(row.id) &&
                                  <div className={styles.alertContainer}>
                                    <div className={styles.imgText}>
                                      <img src={alertIcon} alt='' />
                                      <p className={styles.alertHeader}>Voting Alert</p>
                                    </div>
                                    <div className={styles.alertContent}>
                                      <p>This participant hasn’t voted.</p>
                                    </div>
                                  </div>}
                              </Grid>
                              <Grid item md={1} className={styles.avatarCardMargin}>
                                {renderRow(row)}
                              </Grid>
                              <Grid item md={1}>
                                {!votedUserMap.has(row.id) && props.timerRun && index % 2 !== 0 && props.userRole !== "MEMBER" && activeMembers.includes(row.id) &&
                                  <div className={styles.alertContainer}>
                                    <div className={styles.imgText}>
                                      <img src={alertIcon} alt='' />
                                      <p className={styles.alertHeader}>Voting Alert</p>
                                    </div>
                                    <div className={styles.alertContent}>
                                      <p>This participant hasn’t voted.</p>
                                    </div>
                                  </div>}
                              </Grid>
                            </div>
                          )
                        })}
                      </Grid>
                    </Box>
                  )}

                {(props.members.length > 24) &&
                  (
                    <Grid container spacing={2} className={styles.morePlayerBox} sx={{ marginLeft: "10px" }}>
                      {props.members.map((row: any) => {
                        return (
                          <>
                            <Grid item md={1} key={row.id} sx={{ marginRight: "0px" }}>
                              {renderRow(row)}
                            </Grid>
                          </>
                        )
                      }
                      )}
                    </Grid>
                  )}

                <Grid container spacing={2} justifyContent="left">
                  <Grid item xs={12} sm={9} md={12}>
                    <Box className={styles.chooseCardBox} display="flex" justifyContent="left" alignItems="left">
                      <Grid item xs={12} sm={3}>
                        <Typography className={styles.chooseCardText}>Choose Your Card</Typography>
                      </Grid>
                      <Grid item xs={12} sm={9}>
                        <List component="nav" sx={{ display: "inline-flex" }}>
                          {storyPoints.map((points, index: number) => (
                            <Tooltip
                              key={points}
                              title={points === "skip" ? "skip" : (points === "unknown") ? "unknown" : ""}
                              placement="bottom-end">
                              <StyledPoints
                                selected={selectedIndexRef.current === points}
                                onClick={(event) => chooseCard(event, index, points)}
                                className={points === "13" ? styles.card13 : styles.selectCard}
                                key={index + 1}
                                disabled={chooseCardDisable}>
                                <ListItemText sx={{ minWidth: "0px!important" }} primary={displayVotePoints(points)} />
                              </StyledPoints>
                            </Tooltip>
                          ))}
                        </List>
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </>
          </>

          :
          <>
            <div className={mobileStyle.cardContainer}>
              <Typography className={mobileStyle.chooseCardText}>Choose Card</Typography>
              <div className={mobileStyle.gridContainer}>
                {storyPoints.map((points, index: number) => (
                  <div className={points !== selectedIndex ? chooseCardDisable ? mobileStyle.disabledCard : mobileStyle.card13 : mobileStyle.selectCard}
                    key={points}
                  >
                    <Tooltip
                      key={index}
                      title={points === "skip" ? "skip" : (points === "unknown") ? "unknown" : ""}
                      placement="bottom-end">
                      <Button
                        id={`${index}`}
                        className={points !== selectedIndex ? mobileStyle.card13 : mobileStyle.selectCard}
                        key={index + 1}
                        onClick={(event: any) => chooseCard(event, index, points)}
                        disabled={chooseCardDisable}>
                        <span>{points === "skip" ? "0" : points === "unknown" ? "K" : points}</span>
                      </Button>
                    </Tooltip>
                  </div>
                ))}
              </div>
            </div>
            <div className={mobileStyle.membersVoted}>
              <span className={mobileStyle.textSpan}>
                <p className={mobileStyle.participant}>Participants </p>
                <p className={mobileStyle.voteCount}>&nbsp;({votedPlayers} voted)</p>
              </span>
              {props.userRole !== "MEMBER" &&
                <Button className={mobileStyle.revealButton} onClick={revealCard} disabled={isActiveRevealCard || isRevealStatus}>REVEAL CARDS</Button>
              }
              {props.userRole === "MEMBER" &&
                <Button className={mobileStyle.revealButton} onClick={revealCard} disabled={isActiveMemberRevealCard}>REVEAL CARDS</Button>
              }
            </div>
            {isRevealStatus &&
              <div className={mobileStyle.storySummary}>
                {selectComponent(true)}
                <p className={mobileStyle.title}>Story Summary</p>
              </div>
            }
            {isRevealStatus &&
              <div className={mobileStyle.voteShareDiv}>
                {voteShare.map((point: any) => {
                  return (
                    <div className={mobileStyle.voteCard} key={point}>
                      <div className={mobileStyle.vote}>
                        <p>{displayVotePointsMobile(point.vote_points)}</p>
                      </div>
                      <div className={mobileStyle.count}>
                        <p>{point.count}</p>
                        <p className={mobileStyle.text}>Votes</p>
                      </div>
                    </div>
                  )
                })}
              </div>}

            <div style={{ width: '100%', marginRight: '20px', marginLeft: '8px' }}>
              <div className={mobileStyle.memberCardContainer}>
                {memberList.map((row: MemberData) => {
                  return (
                    <div key={row.id} className={mobileStyle.avatarCardContainer}>
                      <Grid item md={1} sx={{ marginLeft: '25px' }}>

                        <Tooltip title={row.name} placement="top" enterTouchDelay={0}>
                          {mobileMemberCard(row)}
                        </Tooltip>
                      </Grid>
                    </div>
                  )
                })}
              </div>
            </div>
            {props.members.length > 30 &&
              <div className={mobileStyle.viewMoreContainer}>
                <div className={mobileStyle.viewMore} onClick={() => setViewMore(!viewMore)}>
                  <img src={viewMore ? upIcon : arrowDown} alt="" />
                </div>
              </div>}
          </>
        :
        ''
      }
    </>
  )
}

export default DisplayPlayerCards;
