import { Box, Modal, TextField, Button } from "@mui/material";
import styles from "./KeytoolDetail.module.scss";
import React, { useState } from "react";
import { toast } from "react-toastify";
import InstructionSvg from "../../../assets/svg/instructionIcon.svg";
import { importKeyWarning } from "../../../constants/constants";

const ImportKeyModal = (props: any) => {
  const [keyName, setkeyName] = useState('');
  const [privateKey, setPrivateKey] = useState('');
  const [toastMessage, setToastMessage] = useState(false)
  const [error, setError] = useState({
    keyNameExist: false,
    keyNameError : false,
    privateKeyError : false
  });

  React.useEffect(() => {
    if(toastMessage && props.page === 'extension' && !window.location.href.includes('settings')) {
      toast.success("The key has been successfully stored to your extension.", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setTimeout(() => {setToastMessage(false)}, 200)
    }
  }, [toastMessage])
  const handleKeyNameChange = (event: any) => {
    setkeyName(event.target.value);
    setError({...error, keyNameError: false, keyNameExist: false});
  };

  const handlePrivateKeyChange = (event: any) => {
    setPrivateKey(event.target.value);
    error.privateKeyError && setError({...error, privateKeyError: false});
  };
  const handleImportFormClick = () => {
    if (keyName === '' && privateKey === '') {
      setError({
        ...error,
        keyNameError: true,
        privateKeyError: true
      });
    } else if (keyName === '') {
      setError({
        ...error,
        keyNameError: true,
        privateKeyError: false
      });
    } else if (privateKey === '') {
      setError({
        ...error,
        keyNameError: false,
        privateKeyError: true
      });
    } else {
        let event = new CustomEvent("Store field data as a new key data");
        document.dispatchEvent(event);
    }
    if (props.clear) {
      setkeyName('');
      setPrivateKey('');
      setError({
        ...error, 
        keyNameError : false,
        privateKeyError : false
      });
    }
  };

  document.addEventListener('same key name exist in storage', (e) => {
    setError(prev => ({...prev, keyNameExist: true}))
  });
  const handleClose = () => {
    setkeyName('');
    setPrivateKey('');
    setError({
      ...error, 
      keyNameExist:false,
      keyNameError : false,
      privateKeyError : false
    });
    props.handleClose();
  }

  document.addEventListener('item added to storage', (e) => {
    handleClose()
    setToastMessage(true)
  });

  return (
    <Modal open={props.open}>
      <Box className={styles.importModalStyle}>
        <Box className={styles.modalBody}>
          <Box className={styles.modalTitle}>
            <p className={styles.modalHeading}>Import key</p>
            <p className={styles.userMessage}>
              The users are allowed to add an existing private key.
            </p>
            <Box className={styles.warning}>
              <img src={InstructionSvg} alt="instructions for extension" />
              <p className={styles.warningText}>{importKeyWarning}</p>            
            </Box>
          </Box>
          <Box className={styles.importForm}>
            <TextField
              className={styles.keyNameField}
              id="key-name-field"
              variant="outlined"
              fullWidth
              type={"text"}
              label="Key Name *"
              InputLabelProps={{style: {fontSize:'14px'}}}
              autoComplete="off"
              size="small"
              onChange={handleKeyNameChange}
              value={keyName}
              error={error.keyNameError || error.keyNameExist}
              helperText={error.keyNameError ? 'Please enter the key name' : error.keyNameExist ? 'This key name already exists' : ''}
            />
            <TextField
              multiline
              rows={3}
              className={styles.privateKeyField}
              id="private-key-field"
              variant="outlined"
              fullWidth
              type={"text"}
              label="Private Key *"
              InputLabelProps={{style: {fontSize: '14px'}}}
              autoComplete="off"
              size="small"
              onChange={handlePrivateKeyChange}
              value={privateKey}
              error={error.privateKeyError}
              helperText={error.privateKeyError ? 'Please enter your private key' : ''}
            />
            <Box className={styles.importModalButtons}>
              <Button
                variant="outlined"
                type="button"
                className={styles.cancelButton}
                onClick={handleClose}
              >
                CANCEL
              </Button>
              <Button
                variant="outlined"
                type="button"
                className={styles.importButton}
                onClick={handleImportFormClick}
              >
                IMPORT KEY
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default ImportKeyModal;
