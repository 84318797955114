import { useEffect, useState } from "react";
import {
  failureResponses,
  maturedStoryMessage,
  overallProgress,
  progressSections,
} from "../../../constants/constants";
import styles from "./Summary.module.scss";
import { Box, CircularProgress, Tooltip } from "@mui/material";
import { getApiCall } from "../../../utils/Utils";
import LazyLoader from "../../shared/LazyLoader/LazyLoader";
import { useNavigate } from "react-router";

interface TicketStatus {
  tickets: number;
  story_points: number;
}

interface TotalTicketStatus extends TicketStatus {
  story_defects: number;
  bugs: number;
}

interface PreviousSprintData extends TicketStatus {
  percentage: number;
}

interface SummaryData {
  total: TotalTicketStatus;
  pending: TicketStatus;
  completed: TicketStatus;
  previous_sprint: PreviousSprintData;
  sprint_progress: number;
  dev_progress: any;
  qa_progress: any;
  other_status: any;
  overall_progress: any;
}

interface TicketSummaryProps {
  summaryData: SummaryData;
}

const summaryDataInitialState = {
  total: { tickets: 0, story_points: 0, story_defects: 0, bugs: 0 },
  pending: { tickets: 0, story_points: 0 },
  completed: { tickets: 0, story_points: 0 },
  previous_sprint: { tickets: 0, story_points: 0, percentage: 0 },
  sprint_progress: 0,
  dev_progress: {},
  qa_progress: {},
  other_status: {},
  overall_progress: {},
};

const TicketSummary: React.FC<TicketSummaryProps> = ({ summaryData }) => {
  return (
    <Box className={styles.ticketSummary}>
      <Box className={styles.summaryBox}>
        <p className={styles.ticketTitle}>Total</p>
        <Box className={styles.containorStyles}>
          <Box className={styles.ticketStatus}>
            <p className={styles.ticketTitle}>Tickets</p>
            <p className={styles.ticketCount}>{summaryData.total.tickets}</p>
          </Box>
          <Box className={styles.ticketStatus}>
            <p className={styles.ticketTitle}>Story Points</p>
            <p className={styles.ticketCount}>
              {summaryData.total.story_points}
            </p>
          </Box>
          <Box className={styles.ticketStatus}>
            <p className={styles.ticketTitle}>Story Defects</p>
            <p className={styles.ticketCount}>
              {summaryData.total.story_defects}
            </p>
          </Box>
          <Box className={styles.ticketStatus}>
            <p className={styles.ticketTitle}>Bug Tickets</p>
            <p className={styles.ticketCount}>{summaryData.total.bugs}</p>
          </Box>
        </Box>
      </Box>
      <Box className={styles.summaryBottom}>
        <Box className={styles.summaryBox}>
          <p className={styles.ticketTitle}>Completed</p>
          <Box className={styles.containorStyles}>
            <Box className={styles.ticketStatus}>
              <p className={styles.ticketTitle}>Tickets</p>
              <p className={styles.ticketCount}>
                {summaryData.completed.tickets}
              </p>
            </Box>
            <Box className={styles.ticketStatus}>
              <p className={styles.ticketTitle}>Story Points</p>
              <p className={styles.ticketCount}>
                {summaryData.completed.story_points}
              </p>
            </Box>
          </Box>
        </Box>
        <Box className={styles.summaryBox}>
          <p className={styles.ticketTitle}>Pending</p>
          <Box className={styles.containorStyles}>
            <Box className={styles.ticketStatus}>
              <p className={styles.ticketTitle}>Tickets</p>
              <p className={styles.ticketCount}>
                {summaryData.pending.tickets}
              </p>
            </Box>
            <Box className={styles.ticketStatus}>
              <p className={styles.ticketTitle}>Story Points</p>
              <p className={styles.ticketCount}>
                {summaryData.pending.story_points}
              </p>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const ProgressSection: React.FC<TicketSummaryProps> = ({ summaryData }) => {
  return (
    <>
      {Object.keys(progressSections).map((item: string) => {
        let progressObject: any = {};

        switch (item) {
          case "dev_progress":
            progressObject = summaryData.dev_progress ?? {};
            break;
          case "qa_progress":
            progressObject = summaryData.qa_progress ?? {};
            break;
          case "other_status":
            progressObject = summaryData.other_status ?? {};
            break;
        }

        return (
          Object.keys(progressObject).length > 0 && (
            <Box className={styles.progressBox}>
              <p className={styles.progressBoxText}>{progressSections[item]}</p>
              <Box className={styles.progressBoxContent}>
                {Object.keys(progressObject).map((item: any) => (
                  <p>
                    {overallProgress[item]} -{" "}
                    {item === "tasks"
                      ? `${progressObject[item].completed ?? 0}/${
                          progressObject[item].total ?? 0
                        } (${Math.round(
                          progressObject[item].percentage ?? 0
                        )}%)`
                      : `${progressObject[item].total ?? 0} (${Math.round(
                          progressObject[item].percentage ?? 0
                        )}%)`}
                  </p>
                ))}
              </Box>
            </Box>
          )
        );
      })}
    </>
  );
};

const Summary = (props: any) => {
  const { projectId, sprintId, active } = props;
  const navigate = useNavigate();

  const [loader, setLoader] = useState(false);
  const [summaryData, setSummaryData] = useState<SummaryData>(
    summaryDataInitialState
  );

  // API to get sprint summary
  const getSprintSummary = () => {
    setLoader(true);
    getApiCall(
      `/projects/${projectId}/sprint/${sprintId}/summary/`,
      sprintSummarySuccessCallbackFunction,
      sprintSummaryFailureCallbackFunction
    );
  };

  const sprintSummarySuccessCallbackFunction = (response: any) => {
    setLoader(false);
    Object.keys(response).length > 0
      ? setSummaryData(response)
      : setSummaryData(summaryDataInitialState);
  };

  const sprintSummaryFailureCallbackFunction = (response: any) => {
    setLoader(false);
    if (response === failureResponses.INVALID_TOKEN) navigate("/");
    else if (response === failureResponses.NO_PERMISSION)
      navigate(`/project-details/${props.projectId}`);
  };

  const progressContainerStyle = active
    ? styles.overallProgress
    : styles.overallProgressComplete;

  const roundPercentageFloatValue = (value: number | undefined) => {
    if (value) return Math.round(value);
    else return 0;
  };

  useEffect(() => {
    sprintId !== -1 && getSprintSummary();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sprintId]);

  return (
    <>
      {loader && <LazyLoader />}
      <Box className={styles.summaryContainor}>
        <Box className={styles.ticketsAndGraph}>
          <TicketSummary summaryData={summaryData} />
          <Box className={styles.divider}></Box>
          <Box className={styles.sprintProgress}>
            <p className={styles.ticketTitle}>Sprint Progress</p>
            <CircularProgress
              size={"120px"}
              className={styles.detLoader}
              variant="determinate"
              value={Math.round(summaryData.sprint_progress)}
            />
            <CircularProgress
              size={"120px"}
              className={styles.detLoaderBg}
              variant="determinate"
              value={100}
            />
            <p className={styles.completePercentage}>
              {Math.round(summaryData.sprint_progress) || 0}%{" "}
              <span>completed</span>
            </p>
          </Box>
        </Box>
        {summaryData.previous_sprint.tickets !== 0 && (
          <Box className={styles.carryOverTickets}>
            <p className={styles.carryoverText}>
              Tickets added from previous sprint -{" "}
              {summaryData.previous_sprint.tickets}
            </p>
            <p className={styles.carryoverText}>
              Story points added from previous sprint -{" "}
              {summaryData.previous_sprint.story_points ?? 0} (
              {Math.round(summaryData.previous_sprint.percentage) ?? 0}%)
            </p>
          </Box>
        )}
        <Box className={progressContainerStyle}>
          {active ? (
            <ProgressSection summaryData={summaryData} />
          ) : (
            <Box>
              <p className={styles.ticketTitle}>Overall Progress</p>
              <Box className={styles.overallStatus}>
                <span>
                  Released stories -{" "}
                  {summaryData.overall_progress?.released_stories?.total ?? 0} (
                  {roundPercentageFloatValue(
                    summaryData.overall_progress?.released_stories?.percentage
                  )}
                  %)
                </span>
                <Tooltip
                  title={maturedStoryMessage}
                  placement="bottom-start"
                  arrow
                >
                  <span>
                    Matured stories* -{" "}
                    {summaryData.overall_progress?.matured_stories?.total ?? 0}
                  </span>
                </Tooltip>
                <span>
                  QA completed stories -{" "}
                  {summaryData.overall_progress?.qa_completed_stories?.total ??
                    0}{" "}
                  (
                  {roundPercentageFloatValue(
                    summaryData.overall_progress?.qa_completed_stories
                      ?.percentage
                  )}
                  %)
                </span>
                <span>
                  Sprint Rollover Stories -{" "}
                  {summaryData.overall_progress?.sprint_rollover_stories
                    ?.total ?? 0}
                </span>
                <span>
                  Stories removed from the sprint -{" "}
                  {summaryData.overall_progress?.sprint_removed_stories
                    ?.total ?? 0}{" "}
                  (
                  {roundPercentageFloatValue(
                    summaryData.overall_progress?.sprint_removed_stories
                      ?.percentage
                  )}
                  %)
                </span>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};

export default Summary;
