import React from 'react'
import Button from '@mui/material/Button'
import Draggable from 'react-draggable'
import { Tooltip } from '@mui/material'
import styles from './Extension.module.scss';
import closeIcon from '../../../assets/svg/closeRed.svg'
import extensionModalClose from '../../../assets/svg/extensionModalClose.svg'
import copyIcon from '../../../assets/svg/ContentCopy.svg'
import copiedIcon from '../../../assets/svg/ContentCopied.svg'
import { useNavigate } from 'react-router-dom';
import ImportKeyModal from '../../Keytool/KeytoolDetail/ImportKeyModal';

const DraggableDialog = () => {

  const allowedOrigins = ['http://localhost:3000',
    "https://project-tools.qburst.in",
    "https://calicut.qburst.in"]
  const navigate = useNavigate();

  interface KeyRow {
    key: string,
    lastUsed: string,
    name: string
  };

  const [open, setOpen] = React.useState(false);
  const [keyData, setKeyData] = React.useState<KeyRow[]>([])
  const [copyIndex, setCopyIndex] = React.useState(-1)
  const [importOpen, setImportOpen] = React.useState(false)

  window.addEventListener('message', function (event: any) {
    if(allowedOrigins.includes(event.origin)) {
      if (event.data.type === 'KEY_LIST_FROM_CONTENT_SCRIPT') {
        setKeyData(event.data.keyData)
        setOpen(true)
      }
    }
  })

  document.addEventListener('close draggable popup from app', (e) => {
    handleClose()
  })
  const handleClose = () => {
    setKeyData([])
    setOpen(false);
  };

  const copyContent = (key: string, index: number, id: string, tooltipId: string) => {
    let event = new CustomEvent('Copy this key', {
      detail: {
        index: index
      }
    })
    document.dispatchEvent(event)
    setCopyIndex(index)
    let target = document.getElementById(id)

    target?.setAttribute('src', copiedIcon)
    setTimeout(() => {
      target?.setAttribute('src', copyIcon)
      setCopyIndex(-1)
    }, 2000)
  }

  const lastUsedTime = (usedTIme: Date) => {
    let currentTime = new Date()
    let lastUsedTime = new Date(usedTIme)
    let timeDefference = currentTime.getTime() - lastUsedTime.getTime()
    let hourDefference = timeDefference / (1000 * 60 * 60)
    let minutesDefference = timeDefference / (1000 * 60)
    if (hourDefference > 1) {
      return `${Math.floor(hourDefference)} hrs ${Math.floor(minutesDefference) - (Math.floor(hourDefference) * 60)} min ago`
    }
    else if (hourDefference < 1 && minutesDefference > 0) {
      return `${Math.floor(minutesDefference)} min ago`
    }
    else if (hourDefference < 1 && minutesDefference < 1) {
      return `just now`
    }
    return null
  }

  const removeKey = (index: number) => {
    let event = new CustomEvent('remove this key', {
      detail: {
        index: index
      }
    })
    document.dispatchEvent(event)
  }

  return (
    <>
      {
        open &&
        <Draggable>
          <div className={styles.wraper}>
            <div className={styles.header}>
              <img src={extensionModalClose} alt='' onClick={handleClose} className={styles.crossIcon} />
            </div>
            <div className={styles.subHeader}>
              <p className={styles.allKeys}>All Keys</p>
              <div className={styles.buttonContainer}>
                <Button className={styles.importButton} onClick={() => setImportOpen(true)}>import key</Button>
                <Button className={styles.generateButton} onClick={() => navigate('/settings')}>generate key</Button>
              </div>
            </div>
            <div className={styles.privateKeyContainer}>
              <div className={styles.headerRow}>
                <p>Name</p>
                <p>Blob</p>
                <p>Last Used</p>
                <p className={styles.actions}>Actions</p>
              </div>
              <div className={styles.privateKeyListContainer} id='privateKeyListContainer'>
                {
                  keyData.length === 0 ?
                    <p className={styles.noKeysWarning}>No Keys Added</p> :
                    keyData.map((item: any, index: number) => {
                      return (
                        <div className={`${styles.keyRow} ${index % 2 === 1 ? styles.oddClass : ''}`} id={`${index}`}>
                          <Tooltip title={item.name.length > 9 ? item.name : ''} placement='top' id={`${index}${item.name}tooltip`}>
                            <p>{item.name.length > 9 ? item.name.substring(0, 9) + '...' : item.name}</p>
                          </Tooltip>
                          <div className={styles.keyAndImage}>
                            <Tooltip title={copyIndex === index ? 'Copied' : 'Copy'} placement='top' id={`${index}${item.name}tooltip`}>
                              <img src={copyIcon} alt='' onClick={() => copyContent(item.key, index, `${index}${item.name}`, `${index}${item.name}tooltip`)} id={`${index}${item.name}`} />
                            </Tooltip>
                            <p>{item.key.length > 9 ? item.key.substring(0, 9) + '...' : item.key}</p>
                          </div>
                          <p>{lastUsedTime(item.lastUsed)}</p>
                          <div className={styles.removeIcon}>
                            <img src={closeIcon} alt='' onClick={() => removeKey(index)} />
                          </div>
                        </div>
                      )
                    })
                }
              </div>
            </div>
          </div>
        </Draggable>
      }
      <ImportKeyModal page='extension' open={importOpen} handleClose={() => setImportOpen(false)} />
    </>

  );
};

export default DraggableDialog;