import React from 'react';
import './App.scss';
import RouterConfig from './routes';

function App() {
  return (
    <RouterConfig/>
  );
}

export default App;
