import { Box } from "@mui/material";
import errorImg from '../../assets/svg/error.png';
import styles from './Error.module.scss';
import { useEffect } from "react";

const Error = (props: any) => {
    useEffect(()=>{
        props.setShowSidebar(false);
        return () => {
            props.setShowSidebar(true);
        };
    },[props])
    return (
        <Box className={styles.pageNotFoundContainer}><img src={errorImg} alt="page_not_found" /></Box>
    )
}

export default Error;