import React from "react";
import styles from './Header.module.scss';
import { Box, Typography, Grid, Button, FormControl, Autocomplete, TextField, styled, Modal, Tooltip, Menu, MenuItem, MenuProps, Select } from "@mui/material";
import HeaderSearch from './HeaderSearch'
import backIcon from "../../assets/svg/arrowBack.svg"
import { Link, useNavigate } from 'react-router-dom';
import listViewIconHighlited from "../../assets/svg/listViewIconHighlited.svg"
import gridViewIcon from "../../assets/svg/gridViewIcon.svg"
import listViewIcon from "../../assets/svg/listView.svg"
import gridViewIconHighlited from "../../assets/svg/gridViewHighlited.svg"
import extensionDownload from '../../assets/svg/ExtensionDownload.svg';
import arrowDown from "../../assets/svg/ArrDown.svg";
import { getApiCall } from "../../utils/Utils";
import { toast } from "react-toastify";
import ExtensionInstruction from "../Settings/ExtensionInstruction";
import { failureResponses } from "../../constants/constants";
import LazyLoader from "../shared/LazyLoader/LazyLoader";
import SprintCompletedIcon from "../../assets/svg/checkIcon.svg";
import SprintPendingIcon from "../../assets/svg/sprintPending.svg";
import { sprintStatus } from "../../constants/constants";

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 530,
  bgcolor: '#fff',
  borderRadius: '6px',
  boxShadow: 24,
  padding: 5,
};

const AutoComplete = styled(Autocomplete)(({ theme }) => ({
  ".MuiInputBase-input": {
    display: "flex",
    alignItems: "center",
    height: "26px",
  },
  ".MuiOutlinedInput-root.MuiInputBase-sizeSmall": {
    paddingTop: "0px",
    paddingBottom: "0px",
    marginTop: "5px!important",
  },
  ".MuiInputBase-input-root": {
    padding: "0px"
  },
  ".MuiOutlinedInput-root.MuiInputBase-sizeSmall .MuiAutocomplete-input": {
    paddingLeft: "0px",
    marginRight: "-5px",
    fontSize: "0.9rem"
  }
}));

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: '0 0 4px 4px',
    minWidth: 254,
    color: '#424242',
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '8px 0',
    },
    fontSize: '14px',
  },
}));


const CustomSelect = styled(Select)({
  fontSize: '19px',
  fontWeight: '500',
  lineHeight: '32px',
  color: '#444444',
  backgroundColor: "#F9FAFC",
  
  '& .MuiSelect-select:focus': {
    backgroundColor: '#F9FAFC',
  },
  '& .MuiInputBase-input:focus': {
    backgroundColor: '#F9FAFC',
  },
  '& .MuiInput-input:focus': {
    backgroundColor: '#F9FAFC',
  },
});

const Header = (props: any) => {

  const navigate = useNavigate()
  const [selectedOption, setSelectedOption] = React.useState("");
  const [loader, setLoader] = React.useState(false);  const projectStatusList = [
    { label: "Active", value: "ACTIVE" },
    { label: "Onhold", value: "ON_HOLD" },
    { label: "Closed", value: "CLOSED" }
  ]
  const keytoolTypeList = [{ id: 1, label: "Active", value: "ACTIVE" }, { id: 2, label: "Closed", value: "CLOSED" }]
  const keytoolNameList = ["key1", "key2", "key3"]
  const [publicKeyPopup, setPublicKeyPopup] = React.useState(false)
  const [extensionInstructionPopup, setExtensionInstructionPopup] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const extensionDropdownOpen = Boolean(anchorEl);
  const selectedBrowser = React.useRef('');
  const handleSearchChange = (event: any, option: any) => {
    option === null ? setSelectedOption("") : setSelectedOption(option)
    props.handleSearchQueryChange(option)
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (browser: string) => {
    if (browser === "Chrome") selectedBrowser.current = "Chrome";
    else if (browser === "Firefox") selectedBrowser.current = "Firefox";
    browser && extensionModalOpen();
    setAnchorEl(null);
  };

  const handleAddKey = () => {
    if(props.publicKeyAdded) {
      props.addKeyButtonClick()
    }
    else {
      setPublicKeyPopup(true)
    }
  }
  const truncateText = (text: string) => {
    if (text.length > 100) {
      return text.substring(0, 97) + '...';
    } else {
      return text;
    }
  }

  const successCallBack = (response: any) => {
    setLoader(false);
    setExtensionInstructionPopup(false)
    window.location.href = response.url
    toast.success('Successfully downloaded the extension build file', { position: toast.POSITION.TOP_RIGHT })
  };

  const failureCallBack = (response: any) => {
    setLoader(false);
    if (response === failureResponses.INVALID_TOKEN) navigate('/');
    else navigate('/settings');
  };

  const handleDownloadClick = () => {
    setLoader(true);
    setExtensionInstructionPopup(true);
    let browserParam = "";
    switch (selectedBrowser.current) {
      case "Chrome":
        browserParam = "CHROME";
        break;
      case "Firefox":
        browserParam = "FIREFOX";
        break;
    };
    getApiCall(`/keytools/extension/?browser=${browserParam}`,
      successCallBack,
      failureCallBack);
    handleInstructionClose();
  };

  const handleInstructionClose = () => setExtensionInstructionPopup(false)
  
  const extensionModalOpen = () => {
    setExtensionInstructionPopup(true)
  };

  const handleSelectedSprintChange = (event: any) => {
    props.setSelectedSprint(event.target.value);
  };

  const sprintStatusStyle = props.daysLeft === sprintStatus.COMPLETED ? styles.sprintCompleted : styles.sprintPending;
  const sprintStatusIcon = props.daysLeft === sprintStatus.COMPLETED ? SprintCompletedIcon : SprintPendingIcon;

  return (
    <Box className={styles.headerSection}>
      {loader && <LazyLoader />}
      {props.page === "projectList" &&
        <>
          <Box>
            <Typography className={styles.headerTitle}>Projects</Typography>
          </Box>
          <Box className={styles.headerFunctionsBlock}>
            <HeaderSearch selectedOption={selectedOption}
              searchList={props.searchList}
              handleSearchChange={handleSearchChange}
              page={props.page}
            />
            <Box>
              <FormControl
                size="small"
                className={styles.searchbar}
              >
                <Autocomplete
                  size="small"
                  id="combo-box-demo"
                  getOptionLabel={(option: any) => option.label}
                  options={projectStatusList}
                  onChange={props.onChange}
                  renderInput={(params) => <TextField {...params} label="Status" variant="outlined" />}
                />
              </FormControl>

            </Box>
            <Grid item md={5} container justifyContent="flex-end">
              <Button variant="contained" className={styles.addProjectButton} onClick={props.onClick}>ADD PROJECT</Button>
            </Grid>
          </Box>
        </>
      }
      {
        props.page === "project_details_main" && (
          <>
            <div className={styles.nameAndIcon}>
              <Link to={`/project-list`}>
                <img src={backIcon} alt="back" className={styles.backArrow} />
              </Link>
              <Tooltip title={props.projectName.length > 100 ? props.projectName : ''} placement="top-start" arrow>
              <p className={styles.projectHeading} style={{ display: "inline!important" }}>{truncateText(props.projectName)}</p>
              </Tooltip>

            </div>
            <HeaderSearch selectedOption={selectedOption}
              searchList={props.searchList}
              handleSearchChange={handleSearchChange}
              page={props.page}
            />
          </>
        )
      }
      {
        props.page === "keytool" && (
          <>
            <div className={styles.nameAndIcon}>
              <Link to={`/project-details/${props.projectId}`}>
                <img src={backIcon} alt="back" className={styles.backArrow} />
              </Link>
              <p className={styles.keytoolHeading} style={{ display: "inline!important" }}>Keys {props.count > 0 && `(${props.count})`}</p>


            </div>
            <div className={styles.keytoolRightSideWrapper}>
              <div>
                <img src={props.view === "list" ? listViewIconHighlited : listViewIcon} alt="" onClick={() => props.handleProjectView("list")} className={styles.viewCard} />
                <img src={props.view === "grid" ? gridViewIconHighlited : gridViewIcon} alt="" onClick={() => props.handleProjectView("grid")} className={styles.viewCard} style={{ marginRight: "20px" }} />
              </div>
              <HeaderSearch searchList={keytoolNameList}
                selectedOption={selectedOption}
                handleSearchChange={handleSearchChange}
                page={props.page}
                handleKeyNameSearch={props.handleKeyNameSearch}
                getKeyToolList={props.getKeyToolList}
                setKeyNameEntered={props.setKeyNameEntered}
                keyNameEntered={props.keyNameEntered}
              />
              <AutoComplete
                style={{ marginTop: "-10px" }}
                size="small"
                sx={{ width: "116px", marginLeft: "20px", height: '30px' }}
                getOptionLabel={(option: any) => option.label}
                options={keytoolTypeList}
                renderInput={(params) => <TextField {...params}
                  variant="outlined"
                  label="Status"
                />
                }
                onChange={props.handleStatusChange}

              />
              <Button className={styles.keytoolAddButton} onClick={handleAddKey}>
                ADD KEY
              </Button>
            </div>
            <Modal open = {publicKeyPopup}>
              <Box sx={style}>
                <Grid className={styles.publicKeyGrid}>
                <Typography className={styles.publicKeyModalText}>To include a key, make sure to add a public key through <b>My Settings</b></Typography>
                <Button variant="outlined" className={styles.publicKeyCancelButton} onClick={() => setPublicKeyPopup(false)}>Ok</Button>
                </Grid>
               
              </Box>
            </Modal>
          </>
        )
      }
      {props.page === "settings" &&
        <>
          <p className={styles.settingsHeading} style={{ display: "inline!important" }}>{props.page}</p>
          <Box className={styles.buttonBox}>
            <Button
              id="demo-customized-button"
              className={styles.extensionButton}
              aria-haspopup="true"
              aria-controls={extensionDropdownOpen ? 'basic-menu' : undefined}
              aria-expanded={extensionDropdownOpen ? 'true' : undefined}
              variant="contained"
              onClick={handleClick}
              disableElevation
            >
              <img src={extensionDownload} alt="" className={styles.extensionIcon} />
              <p className={styles.extensionText}>DOWNLOAD EXTENSION</p>
              <Box className={styles.arrowDownBox}><img src={arrowDown} alt="open dropdown list" /></Box>
            </Button>
            <StyledMenu
              id="basic-menu"
              anchorEl={anchorEl}
              open={extensionDropdownOpen}
              MenuListProps={{
                'aria-labelledby': 'demo-customized-button',
              }}
              className={styles.menuDropdown}
              onBlur={() => handleClose('')}
            >
              <MenuItem onMouseDown={() => handleClose('Chrome')}>Chrome Extension</MenuItem>
              <MenuItem onMouseDown={() => handleClose('Firefox')}>Firefox Extension</MenuItem>
            </StyledMenu>
          </Box>
        </>
      }
      {props.page === "Daily Activities" &&
        <>
          <p className={styles.settingsHeading} style={{ display: "inline!important" }}>{props.page}</p>
        </>
      }
      {props.page === "Sprint Analysis" &&
        <>
          <Box className={styles.nameAndIcon}>
            <Link to={`/project-details/${props.projectId}`}>
              <img src={backIcon} alt="back" className={styles.backArrow} />
            </Link>
            {props.sprints.length > 0 ? <FormControl variant="standard">
              <CustomSelect 
                value={props.selectedSprint} 
                className={styles.sprintSelection} 
                onChange={handleSelectedSprintChange}
                disableUnderline
              >
                {props.sprints.map((sprint: any)=>
                  <MenuItem key={sprint.id} value={sprint}>{sprint.title}</MenuItem>)}
              </CustomSelect>
            </FormControl> : <p className={styles.noSprint}>Sprint --</p>}
          </Box>
          {props.sprints.length > 0 && <Box 
            className={`${styles.sprintHeader} ${sprintStatusStyle}`}>
            <img src={sprintStatusIcon} alt="sprint status" />
            <p>{props.daysLeft}</p>
          </Box>}
        </>
      }
      {props.page === "Access Tokens" &&
        <>
          <p className={styles.settingsHeading} style={{ display: "inline!important" }}>{props.page}</p>
        </>
      }
      <ExtensionInstruction
        selectedBrowser={selectedBrowser.current}
        after='download'
        extensionInstructionPopup={extensionInstructionPopup}
        handleInstructionClose={handleInstructionClose}
        handleDownloadConfirmation={handleDownloadClick}
      />
    </Box>
  )
}

export default Header;