// import styles from "./Members.module.scss";
import styles from "../ExternalSystems/ExternalSystems.module.scss";
import stylesActivity from "../ProjectActivity/ProjectActivity.module.scss";
import stylesMember from "../Members/Members.module.scss";
import * as React from "react";
import {
  Box,
  Grid,
  Table,
  TableBody,
  Typography,
  Paper,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  FormControl,
  SelectChangeEvent,
  Select,
  Modal,
  TextField,
  MenuItem,
  InputLabel,
  Link,
  FormHelperText,
  Tooltip,
  IconButton,
} from "@mui/material";
import { useNavigate, Link as RouterLink, useParams } from "react-router-dom";
import Button from "@mui/material/Button";
import {
  getApiCall,
  postApiCall,
  patchApiCall,
  deleteApiCall,
  isJson
} from "../../utils/Utils";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LazyLoader from "../shared/LazyLoader/LazyLoader";
import ProjectContext from "../Helper/ProjectContext";
import { useContext } from "react";
import backArrow from "../../assets/svg/backArrow.svg"
import InfiniteScroll from "react-infinite-scroll-component";
import LastSyncIcon from "../../assets/svg/LastSync.svg";
import { externalSystemTimeDifference } from "../../utils/utilityFunctions";
import passwordShowIcon from '../../assets/svg/passwordSowIcon.svg'
import passwordHideIcon from '../../assets/svg/passwordHideIcon.svg';
import copyIcon from '../../assets/svg/ContentCopy.svg';
import copiedIcon from '../../assets/svg/ContentCopied.svg';
import copy from 'copy-to-clipboard';
import { failureResponses } from "../../constants/constants";
import downArrow from '../../assets/svg/instructionArrowDown.svg';
import upArrow from '../../assets/svg/instructionArrowUp.svg'
import instructionIcon from '../../assets/svg/instructionIcon.svg'
import extensionStyle from '../Settings/Settings.module.scss'
import { externalSystemInstructions } from "../../constants/constants";

const ExternalSystems = (props: any) => {
  const userProjectRole = useContext(ProjectContext);
  const userRole = userProjectRole.role;
  const { projectId } = useParams()

  const navigate = useNavigate();

  let validationErrorsObj = {
    externalSystemNameError: false,
    externalSystemTypeError: false,
    baseUrlError: false,
    projectUrlError: false,
    projectRefError: false,
    extraDataError: false
  };

  const initialValidationErrorsObj = validationErrorsObj;
  const [loader, setLoader] = React.useState(true);
  const [open, setOpen] = React.useState(false);
  const [page, setPage] = React.useState(1);
  const [hasMore, setHasMore] = React.useState(true)
  const [createSystem, setCreateSystem] = React.useState(false)
  const [shouldShowDeleteConfirmation, setShouldShowDeleteConfirmation] =
    React.useState(false);
  const [extSystemId, setExtSystemId] = React.useState("new");
  const [rowsPerPage, setRowsPerPage] = React.useState(100);
  const [totalPage, setTotalPage] = React.useState(0);
  const [externalSystemType, setExternalSystemType] = React.useState("");
  const [externalSystemName, setExternalSystemName] = React.useState("");
  const [accessToken, setAccessToken] = React.useState("");
  const [projectUrl, setProjectUrl] = React.useState("");
  const [projectRef, setProjectRef] = React.useState("");
  const [extraData, setEextraData] = React.useState("");
  const [validationErrors, setValidationErrors] =
    React.useState(validationErrorsObj);
  const [externalSystems, setExternalSystems] = React.useState([]);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [projectUrlErrorText, setProjectUrlErrorText] = React.useState(
    "Please enter the project url"
  );
  const [extraDataErrorText, setExtraDataErrorText] = React.useState("");
  const [externalProjectNameError, setExternalProjectNameError] = React.useState('')
  const [projectReferenceError, setProjectReferenceError] = React.useState('')
  const [accessTokenError, setAccessTokenError] = React.useState('')
  const [externalSystemTypeError, setExternalSystemTypeError] = React.useState('');
  const [invalidUrlError, setInvalidUrlError] = React.useState('');

  const [popupTitle, setPopupTitle] = React.useState("Add External System");
  const [deleteDisabled, setDeleteDisabled] = React.useState(true);
  const [lastSyncTimeArray, setLastSyncTimeArray] = React.useState<any[]>([]);
  const [showToken, setShowToken] = React.useState(false)
  const [copied, setCopied] = React.useState(0);
  const [viewMore, setViewMore] = React.useState(false)

  const handleCopy = (content: string, command: number) => {
    copy(content);
    setCopied(command);
    setTimeout(() => {
      setCopied(0);
    }, 2000);
  };

  const handleExternalSystemTypeChange = (event: SelectChangeEvent) => {
    setExternalSystemType(event.target.value);
  };

  const handleOpen = (extId: any) => {
    (extId === "new") ? setPopupTitle("Add External System") : setPopupTitle("Edit External System");
    setOpen(true);
    setExtSystemId(extId);
  };

  React.useEffect(() => {
    if (extSystemId !== "new") {
      getApiCall(
        `/projects/${projectId}/external_system/${extSystemId}/`,
        getExtDataSuccessCallBackFunction,
        getExtDataFailureCallBackFunction
      );
    } else {
      setExternalSystemName("");
      setExternalSystemType("");
      setAccessToken("");
      setProjectRef("");
      setProjectUrl("");
      setEextraData("");
    }
    // eslint-disable-next-line
  }, [extSystemId]);

  const clickedDelete = (extId: any) => {
    setOpen(true);
    setShouldShowDeleteConfirmation(true);
    setExtSystemId(extId);
  };

  const deleteExternalSystem = () => {
    deleteApiCall(
      `/projects/${projectId}/external_system/${extSystemId}/`,
      deleteSuccessCallBackFunction,
      getExtDataFailureCallBackFunction
    );
  };

  const getExtDataFailureCallBackFunction = (response: any) => {

    toast.error("Oops! Something went wrong,please try again later", {
      position: toast.POSITION.TOP_RIGHT,
    });
    navigate("/");
  };

  const getExtDataSuccessCallBackFunction = (response: any) => {
    setExternalSystemName(response.external_project_name);
    setExternalSystemType(response.external_system);
    setAccessToken(response.access_token);
    setProjectRef(response.project_reference);
    setProjectUrl(response.project_url);
    setEextraData(response.extra_fields);
  };

  const handleClose = () => {
    setViewMore(false)
    setShowToken(false)
    setValidationErrors(initialValidationErrorsObj);
    setOpen(false);
    setShouldShowDeleteConfirmation(false);
    setExtSystemId("new");
    setExternalSystemName('');
    setExternalSystemType('');
    setAccessToken('');
    setProjectRef('');
    setProjectUrl('');
    setEextraData('');
    setExternalProjectNameError('')
    setProjectReferenceError('')
    setAccessTokenError('')
    setExternalSystemTypeError('')
    setInvalidUrlError('')
  };

  const setExternalSystemData = () => {
    let externalSystemData = {
      external_project_name: externalSystemName,
      external_system: externalSystemType,
      project_url: projectUrl,
      project_reference: projectRef,
      extra_fields: extraData,
      ...(accessToken !== "" && { access_token: accessToken }),
    };
    return externalSystemData;
  };

  const saveExternalData = () => {
    validationErrorsObj.externalSystemNameError = externalSystemName === "";
    validationErrorsObj.externalSystemTypeError = externalSystemType === "";
    validationErrorsObj.projectUrlError = projectUrl === "";
    validationErrorsObj.projectRefError = projectRef === "";
    if (extraData !== "" && !isJson(extraData)) {
      setExtraDataErrorText("Please enter valid json");
      validationErrorsObj.extraDataError = true;
    }


    setValidationErrors(validationErrorsObj);
    let status = Object.values(validationErrorsObj).every(
      (item) => item !== true
    );
    if (status) {
      let postData = setExternalSystemData();
      if (extSystemId === "new") {
        postApiCall(
          `/projects/${projectId}/external_systems/`,
          postData,
          saveSuccessCallBackFunction,
          saveFailureCallBackFunction
        );
      } else {
        patchApiCall(
          `/projects/${projectId}/external_system/${extSystemId}/`,
          postData,
          saveSuccessCallBackFunction,
          saveFailureCallBackFunction
        );
      }
      setLoader(true);
    }
  };
  const saveFailureCallBackFunction = (
    errorResponse: any,
    shouldLogout: any
  ) => {
    if (errorResponse.project_reference) {
      setProjectReferenceError(errorResponse.project_reference)
    }
    if(errorResponse.external_project_name) {
      setExternalProjectNameError(errorResponse.external_project_name)
    }
    if (errorResponse.access_token) setAccessTokenError(errorResponse.access_token)      
    if (errorResponse.project_url) setInvalidUrlError(errorResponse.project_url[0])
    if (errorResponse.non_field_errors && errorResponse.non_field_errors[0] === failureResponses.EXTERNAL_SYSTEM_ERROR) setExternalSystemTypeError(failureResponses.EXTERNAL_SYSTEM_ERROR)

    setLoader(false);
    if (shouldLogout) {
      navigate("/");
    } else {
      if (errorResponse.detail) {
        setErrorMessage(errorResponse.detail);
      } else {
        setProjectUrlErrorText(errorResponse.project_url[0]);
        validationErrorsObj.projectUrlError = true;
        setValidationErrors(validationErrorsObj);
      }
    }
  };

  const deleteSuccessCallBackFunction = (response: any) => {
    setPage(1)
    setCreateSystem(true)
    toast.success("The system deleted succesfully!", {
      position: toast.POSITION.TOP_RIGHT,
    });
    handleClose();
    getExternalSystemsList();
  };

  const saveSuccessCallBackFunction = (response: any) => {
    setPage(1)
    setCreateSystem(true)
    setLoader(false);
    let message = (extSystemId !== "new") ? "Success! the system is updated" : "The system added succesfully!"
    toast.success(message, {
      position: toast.POSITION.TOP_RIGHT,
    });
    handleClose();
    getExternalSystemsList();
  };

  const failureCallBackFunction = (response: any) => {
    setLoader(false);
    navigate("/");
  };

  const successCallBackFunction = (response: any) => {
    const pageCount = Math.ceil(response.count / 100)
    if (page < pageCount) {
      setHasMore(true)
      setPage(page + 1)
    }
    else setHasMore(false)
    setTotalPage(response.count);
    const lastSyncArray: React.SetStateAction<any[]> = [];
    response?.results?.forEach((item: any) => {
      item.last_batch_run === 'FIRST_SYNC_FAILED' || item.last_batch_run === null ? 
        lastSyncArray.push(item.last_batch_run) : 
        lastSyncArray.push(externalSystemTimeDifference(item.last_batch_run));
    });
    setLastSyncTimeArray(lastSyncArray);
    setExternalSystems(response.results);
    setLoader(false);
  };

  const getExternalSystemsList = () => {
    const pageSize = rowsPerPage;
    const pageNo = page;
    getApiCall(
      `/projects/${projectId}/external_systems/?page_size=${pageSize}&page=${pageNo}`,
      successCallBackFunction,
      failureCallBackFunction
    );
    setLoader(true);
  };
  const detailsSuccessCallBack = (response: any) => {
    props.setRole(response.role)
  }
  const detailsFailureCallBack = (response: any) => {
    toast.error("Something went wrong. Try again.", {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
  React.useEffect(() => {
    if (createSystem) {
      setExternalSystems([])
      getExternalSystemsList()
    }
    setCreateSystem(false)
  }, [])
  React.useEffect(() => {
    if (userProjectRole.role === "") {
      getApiCall(
        `/projects/${projectId}/details/`,
        detailsSuccessCallBack,
        detailsFailureCallBack
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  React.useEffect(() => {
    getExternalSystemsList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowsPerPage, projectId]);

  const tooltipContent = (row:any, rowIndex: number) => {
    const externalSystemName = row.external_system==='GITLAB'? 'Gitlab' : 'Testmate';
    return lastSyncTimeArray[rowIndex]==='FIRST_SYNC_FAILED' ? `First Sync Failed in ${externalSystemName}` : lastSyncTimeArray[rowIndex]===null ? `First sync pending for ${externalSystemName}` :
    lastSyncTimeArray[rowIndex] > 1.5 && `The last sync occurred ${lastSyncTimeArray[rowIndex]} hours ago in ${externalSystemName}`
  };

  const confrimButtonStyle = deleteDisabled ? `${styles.confirmButtonActive} ${styles.confirmButtonDisabled}` : styles.confirmButtonActive;

  return (
    <>
      {loader && <LazyLoader />}
      <Grid item md={10}>
        <ToastContainer />
        <Box className={stylesMember.headingBlock}>
          <div className={stylesMember.header}>
            <RouterLink to={`/project-details/${projectId}/`}>

              <img src={backArrow} alt="back" />
            </RouterLink>
            <Typography className={stylesMember.headingLabel}>External Systems {totalPage > 0 && `(${totalPage})`}</Typography>
          </div>
          <Grid item md={4} className={stylesMember.addButton}>
            {!(["MEMBER"].includes(userRole)) &&
              <Button variant="outlined" onClick={() => handleOpen("new")} className={stylesMember.buttonAdd}>
                + Create External System
              </Button>
            }
          </Grid>
        </Box>

        <Box>
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer component={Paper}>
              <Table
                stickyHeader
                aria-label="sticky table"
                className={stylesActivity.activityTable}
              >
                {["OWNER"].includes(userRole) ?
                  <colgroup>
                    <col style={{ width: "20%" }} />
                    <col style={{ width: "20%" }} />
                    <col style={{ width: "20%" }} />
                    <col style={{ width: "20%" }} />
                    <col style={{ width: "20%" }} />
                  </colgroup> :
                  <colgroup>
                    <col style={{ width: "25%" }} />
                    <col style={{ width: "25%" }} />
                    <col style={{ width: "25%" }} />
                    <col style={{ width: "25%" }} />
                  </colgroup>
                }
                <TableHead className={stylesActivity.tableHead}>
                  <TableRow>
                    <TableCell
                      // sx={{ backgroundColor: "#F9FAFC" }}
                      className={stylesActivity.tableHeadCell}
                    >
                      <Box
                        component="span"
                        className={stylesActivity.tableHeadTitleCell}
                      >
                        External System
                      </Box>
                    </TableCell>
                    <TableCell
                      sx={{ textAlign: "left" }}
                      className={stylesActivity.tableHeadCell}
                    >
                      <Box
                        component="span"
                        className={stylesActivity.tableHeadTitleCell}
                      >
                        Link
                      </Box>
                    </TableCell>
                    <TableCell
                      sx={{ textAlign: "left" }}
                      className={stylesActivity.tableHeadCell}
                    >
                      <Box
                        component="span"
                        className={stylesActivity.tableHeadTitleCell}
                      >
                        Type
                      </Box>
                    </TableCell>
                    <TableCell
                      sx={{ textAlign: "left" }}
                      className={stylesActivity.tableHeadCell}
                    >
                      <Box
                        component="span"
                        className={stylesActivity.tableHeadTitleCell}
                      >
                        Last Sync
                      </Box>
                    </TableCell>
                    {["OWNER"].includes(userRole) && (
                      <TableCell
                        sx={{ textAlign: "left" }}
                        className={stylesActivity.tableHeadCell}
                      >
                        <Box
                          component="span"
                          className={stylesActivity.tableHeadTitleCell}
                        >
                          Actions
                        </Box>
                      </TableCell>
                    )}
                  </TableRow>
                </TableHead>
              </Table>
            </TableContainer>

            <Box id="scrollableDetailDiv" className={stylesMember.scrollContainer} >
              <InfiniteScroll
                dataLength={externalSystems.length}
                next={() => getExternalSystemsList()}
                hasMore={hasMore}
                loader={loader && <LazyLoader />}
                scrollableTarget="scrollableDetailDiv"
              >
                <Table>

                  {["OWNER"].includes(userRole) ?
                    <colgroup>
                      <col style={{ width: "20%" }} />
                      <col style={{ width: "20%" }} />
                      <col style={{ width: "20%" }} />
                      <col style={{ width: "20%" }} />
                      <col style={{ width: "20%" }} />
                    </colgroup> :
                    <colgroup>
                      <col style={{ width: "25%" }} />
                      <col style={{ width: "25%" }} />
                      <col style={{ width: "25%" }} />
                      <col style={{ width: "25%" }} />
                    </colgroup>
                  }
                  <TableBody>
                    {externalSystems
                      .map((row: any, rowIndex: any) => {
                        return (
                          <TableRow
                            sx={{
                              backgroundColor: rowIndex % 2 === 0 ? "" : "#3A35410A",
                            }} key={row.id}
                          >
                            <TableCell sx={{ padding: "20px" }}>
                              {row.external_project_name}
                            </TableCell>
                            <TableCell sx={{ textAlign: "left" }}>
                              <Link href={row.project_url} target="_blank" style={{ marginLeft: "3px", wordBreak: 'break-all', wordWrap: 'break-word' }}>{row.project_url}</Link>
                            </TableCell>
                            <TableCell sx={{ textAlign: "left" }}>
                              <p style={{ marginLeft: "3px" }}>{row.external_system}</p>
                            </TableCell>
                            <TableCell sx={{ textAlign: "left" }}>
                                <Box className={styles.lastSyncTime}>
                                  { (lastSyncTimeArray[rowIndex]==='FIRST_SYNC_FAILED' || lastSyncTimeArray[rowIndex]===null || lastSyncTimeArray[rowIndex]>1.5) && 
                                    <Tooltip title={tooltipContent(row, rowIndex)} placement="top">
                                      <img src={LastSyncIcon} alt="last sync icon"/>
                                    </Tooltip> 
                                  }
                                  { lastSyncTimeArray[rowIndex]==='FIRST_SYNC_FAILED' || lastSyncTimeArray[rowIndex]===null?
                                    <span>-</span> : 
                                    <span>
                                      {new Date(row.last_batch_run).toLocaleString('en-us', { month: 'long', year: 'numeric', day: 'numeric' })}
                                      <br />
                                      {new Date(row.last_batch_run).toLocaleString('en-us', { hour: 'numeric', minute: 'numeric', second: 'numeric' })}
                                    </span> 
                                }
                                </Box>
                              
                            </TableCell>
                            {["OWNER"].includes(userRole) && (
                              <TableCell sx={{ textAlign: "left" }}>
                                <Box component="span">
                                  <Tooltip title="Edit" placement="bottom-end">
                                    <BorderColorIcon
                                      fontSize="medium"
                                      sx={{ paddingBottom: "3px", cursor: "pointer" }}
                                      color="action"
                                      onClick={() => handleOpen(row.id)}
                                    />
                                  </Tooltip>
                                </Box>
                                <Box component="span" sx={{ padding: "0 0 0 20px", cursor: "pointer" }}>
                                  <Tooltip title="Delete" placement="bottom-end">
                                    <DeleteOutlineIcon
                                      fontSize="medium"
                                      color="error"
                                      onClick={() => clickedDelete(row.id)}
                                    />
                                  </Tooltip>
                                </Box>
                              </TableCell>
                            )}
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </InfiniteScroll>
            </Box>
          </Paper>
        </Box>
        <Modal
          open={open}
          aria-labelledby="parent-modal-title"
          aria-describedby="parent-modal-description"
        >
          <Box
            className={`${styles.commonStyle} 
          ${styles.externalSystemPopup} 
          ${shouldShowDeleteConfirmation ? styles.deletePopupWidth : ''}`}
          >
            {!shouldShowDeleteConfirmation && (
              <Box>
                <Grid container spacing={2}>
                  <Grid item xs={10}>
                    <Typography id="basic-modal-dialog-title" component="h2">
                      <strong>{popupTitle}</strong>
                    </Typography>
                    <br />
                    <Typography className={styles.errorResponse}>
                      {errorMessage}
                    </Typography>
                  </Grid>
                </Grid>
                <div className={styles.instructionContainer}>
                  <div className={extensionStyle.instructionHeader} onClick={() => setViewMore(!viewMore)}>
                    <div className={extensionStyle.instructionHeaderLeft}>
                      <img src={instructionIcon} alt="" />
                      <p>Read Instructions</p>
                    </div>
                    <img src={!viewMore ? downArrow : upArrow} alt="" className={extensionStyle.arrowIcon} />
                  </div>
                  {viewMore &&
                    <div className={styles.stepsContainerDiv}>
                      {externalSystemInstructions.map((item: any, index: number) => (
                        <div className={styles.stepsContainer}>
                          <div className={extensionStyle.left}>
                            <p className={extensionStyle.step}>Step {index + 1}:</p>
                          </div>
                          <div className={styles.stepRight}>
                            {item.heading && <p className={extensionStyle.step} dangerouslySetInnerHTML={{ __html: item.heading }}></p>}
                            {item.subHeading && <p className={extensionStyle.step} dangerouslySetInnerHTML={{ __html: item.subHeading }}></p>}
                            {item?.steps?.map((step: any, index: number) => (
                              <ul>
                                <li className={extensionStyle.instruction} dangerouslySetInnerHTML={{ __html: step }}></li>
                              </ul>
                            ))}

                          </div>
                        </div>
                      ))}
                    </div>}
                </div>
                <br />
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      label="External System Name"
                      fullWidth
                      value={externalSystemName}
                      required
                      helperText={
                        validationErrors.externalSystemNameError
                          ? "Please enter the external system"
                          : externalProjectNameError
                      }
                      error={validationErrors.externalSystemNameError || externalProjectNameError !== ''}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setExternalSystemName(event.target.value);
                        setExternalProjectNameError('')
                      }}
                      autoComplete="off"
                    />
                  </Grid>
                </Grid>
                <br />

                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormControl
                      fullWidth
                      error={validationErrors.externalSystemTypeError || externalSystemTypeError!==''}
                    >
                      <InputLabel
                        id="demo-simple-select-label"
                        className={styles.selectField}
                      >
                        External System Type*
                      </InputLabel>
                      <Select
                        value={externalSystemType}
                        onChange={handleExternalSystemTypeChange}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        displayEmpty
                      >
                        <MenuItem value="TEST_MATE">Testmate</MenuItem>
                        <MenuItem value="GITLAB">Gitlab</MenuItem>
                      </Select>
                      <FormHelperText>
                        {validationErrors.externalSystemTypeError
                          ? "Please enter the external system type"
                          : externalSystemTypeError}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                </Grid>
                <br />
                {externalSystemType !== 'TEST_MATE' &&
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        label="Access Token"
                        fullWidth
                        value={accessToken}
                        error={accessTokenError !== ''}
                        helperText={accessTokenError}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                          setAccessToken(event.target.value);
                          setAccessTokenError('')
                        }}
                        autoComplete="off"
                        type={showToken ? 'text' : 'password'}
                        InputProps={{
                          endAdornment: (
                            <IconButton>
                              <Tooltip placement="bottom-end" title={showToken ? 'Show' : 'Hide'}>
                                <img
                                  src={showToken ? passwordShowIcon : passwordHideIcon}
                                  alt={showToken ? 'Show' : 'Hide'}
                                  className={styles.fieldIcon}
                                  onClick={() => setShowToken(!showToken)}
                                />
                              </Tooltip>
                              <Tooltip placement="bottom-end" title={copied ? 'Copied' : 'Copy'}>
                                <img
                                  src={copied ? copiedIcon : copyIcon}
                                  alt='copy'
                                  className={`${styles.fieldIcon} ${styles.marginLeft}`}
                                  onClick={() => handleCopy(accessToken, 1)}
                                />
                              </Tooltip>
                            </IconButton>
                          )
                        }}
                      />
                    </Grid>
                  </Grid>}
                {externalSystemType !== 'TEST_MATE' && <br />}
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        label="Project Id"
                        fullWidth
                        value={projectRef}
                        required
                        helperText={
                          validationErrors.projectRefError
                            ? "Please enter the project id"
                            : projectReferenceError
                        }
                        error={validationErrors.projectRefError || projectReferenceError !== ''}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          setProjectRef(event.target.value);
                          setProjectReferenceError('')
                        }}
                        autoComplete="off"
                      />
                    </FormControl>
                  </Grid>
                </Grid>

                <br />
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      label="Project Url"
                      fullWidth
                      value={projectUrl}
                      required
                      helperText={
                        validationErrors.projectUrlError
                          ? projectUrlErrorText
                          : invalidUrlError
                      }
                      error={validationErrors.projectUrlError || invalidUrlError !== ''}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setProjectUrl(event.target.value);
                        setProjectUrlErrorText('')
                        validationErrors.projectUrlError = false
                        setValidationErrors(validationErrors)
                      }}
                      autoComplete="off"
                    />
                  </Grid>
                </Grid>

                <br />
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <TextField
                        aria-label="minimum height"
                        rows={3}
                        value={extraData}
                        label="Extra Data"
                        multiline={true}
                        helperText={
                          validationErrors.extraDataError
                            ? extraDataErrorText
                            :
                            ""
                        }
                        error={validationErrors.extraDataError}
                        onChange={(event: any) => {
                          setEextraData(event.target.value);
                        }}
                        autoComplete="off"
                      />
                    </FormControl>
                  </Grid>
                </Grid>

                <br />
                <Grid container spacing={2}>
                  <Grid item xs={12} className={styles.buttonFields}>
                    <Button
                      variant="outlined"
                      className={styles.cancelButton}
                      onClick={handleClose}
                    >
                      Cancel
                    </Button>
                    &nbsp;
                    <Button
                      variant="outlined"
                      type="submit"
                      className={styles.confirmButtonActive}
                      onClick={saveExternalData}
                    >
                      Confirm
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            )}
            <Box>
              {shouldShowDeleteConfirmation && (
                <Grid container spacing={2}>
                  <Grid item xs={12} sx={{ textAlign: "left" }}>
                    Are you sure you want to delete "<strong>{externalSystemName}</strong>"? Deleting the system will result in the permanent loss of all associated data, and this action cannot be reversed.
                    <br />
                    <br />
                    Enter the external system name to confirm:
                    <br />
                    <br />
                    <Grid item xs={12}>
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        fullWidth
                        onChange={(event) => {
                          if (event.target.value === externalSystemName)
                            setDeleteDisabled(false)
                          else setDeleteDisabled(true)
                        }}
                        autoComplete="off"
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} className={styles.buttonFields}>
                    <Button
                      variant="outlined"
                      className={styles.cancelButton}
                      onClick={handleClose}
                    >
                      Cancel
                    </Button>
                    &nbsp;
                    <Button
                      variant="outlined"
                      type="submit"
                      className={confrimButtonStyle}
                      onClick={deleteExternalSystem}
                      disabled={deleteDisabled}
                    >
                      Confirm
                    </Button>
                  </Grid>
                </Grid>
              )}
            </Box>
          </Box>
        </Modal>
      </Grid>
    </>
  );
};

export default ExternalSystems;
