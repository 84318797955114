import React from 'react';
import { Autocomplete, Modal, Box, Grid, Typography, FormControl, TextField, InputLabel, Select, MenuItem, SelectChangeEvent } from "@mui/material";
import stylesMember from "../Members/Members.module.scss";
import Button from "@mui/material/Button";
import styles from "../ProjectActivity/ProjectActivity.module.scss";
import SquadFilter from '../ProjectActivity/Filter/SquadFilter';
import {lastOwnerDeleteMessage, lastOwnerEditMessage} from '../../constants/constants'

interface MemberModalProps {
  open: boolean;
  handleClose?: () => void;
  showAddMembers: boolean;
  showEditMembers: boolean;
  emailValidationErrorMessage: string;
  saveMember?: () => void;
  handleEditMember?: () => void;
  errorMessage: string;
  selectedMemberData: { id: number; name: string; email: string; role: string; ext_user: string }
  memberEmail: string;
  handleEmailValidation?: () => void;
  showDeleteMembers: boolean;
  externalSystems: { id: string, name: string }[];
  externalUser: string;
  handleExternalUserChange?: (event: any, newValue: any) => void;
  externalUserError: string;
  handleDeleteMember?: () => void;
  handleEditRoleChange?: (event: SelectChangeEvent) => void;
  userRole: string;
  handleEmailIdChange?: (event: any) => void;
  projectName: string;
  handleRoleChange: (event: SelectChangeEvent) => void;
  memberRole: string;
  lastOwnerDelete: boolean;
  squadList: { id: number, name: string }[];
  modalSquadSelection: (option: any) => void;
  modalSelectedSquad: string[];
  selectedModalSquadArray: { id: number, name: string }[];
}

const MemberModal: React.FC<MemberModalProps> = ({ open, handleClose, showAddMembers, showEditMembers, emailValidationErrorMessage, saveMember, handleEditMember, errorMessage, selectedMemberData, memberEmail, handleEmailValidation, showDeleteMembers, externalSystems, externalUser, handleExternalUserChange, externalUserError, handleDeleteMember, handleEditRoleChange, userRole, handleEmailIdChange, projectName, handleRoleChange, memberRole, lastOwnerDelete, squadList, modalSquadSelection, modalSelectedSquad, selectedModalSquadArray }) => {
  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 450,
    bgcolor: '#fff',
    borderRadius: '6px',
    boxShadow: 24,
    padding: 2,
  };
  const lastOwnerstyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 450,
    bgcolor: '#fff',
    borderRadius: '6px',
    boxShadow: 24,
    padding: 5,
  };

  const modalSquadList = squadList.filter(item => item.name !== 'No Squads')
  type OptionType = ({ id: number; name: string })[];


  const newSquadChack = (option: OptionType, type: string) => {
    return option.map((obj) => {
      const match = obj.name.match(/"([^"]+)"/);
      if (match) {
        return type === 'obj' ? { id: obj.id, name: match[1] } : match[1];
      } else {
        return type === 'obj' ? obj : obj.name;
      }
    });
  }
  const handleSquadChange = (event: any, option: OptionType) => {
    modalSquadSelection(newSquadChack(option, 'obj'))
  }

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box>
          {(showAddMembers || showEditMembers) && !lastOwnerDelete &&
            <Box sx={{ ...style }}>
              <Grid container spacing={2} className={stylesMember.modalHead}>
                <Grid item xs={10}>
                  <Typography id="basic-modal-dialog-title" className={stylesMember.modalTitle}>
                    {showAddMembers && "Add Members"}
                    {showEditMembers && "Edit members"}
                  </Typography>
                  <br />
                </Grid>
              </Grid>
              <form
                onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
                  event.preventDefault();
                  if (showAddMembers && emailValidationErrorMessage === "")
                    saveMember?.();
                  else if (showEditMembers)
                    handleEditMember?.();
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} sx={{ paddingTop: '5px!important' }}>
                    <FormControl fullWidth>
                      <Box component="span">
                        {" "}
                        <TextField
                          label="Email ID"
                          variant="outlined"
                          error={emailValidationErrorMessage !== "" || errorMessage !== ""}
                          id="outlined-error-helper-text"
                          placeholder="Enter email address"
                          helperText={emailValidationErrorMessage !== "" ? emailValidationErrorMessage : errorMessage}
                          value={showEditMembers ? selectedMemberData.email : memberEmail}
                          fullWidth
                          onChange={handleEmailIdChange}
                          onBlur={handleEmailValidation}
                          disabled={showEditMembers}
                        />
                      </Box>
                    </FormControl>
                  </Grid>
                </Grid>

                <br />
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">Role</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Role"
                        value={showEditMembers ? selectedMemberData.role : memberRole}
                        onChange={showAddMembers ? handleRoleChange : handleEditRoleChange}
                        displayEmpty
                      >
                        {['OWNER'].includes(userRole) && <MenuItem value="OWNER">Owner</MenuItem>}
                        <MenuItem value="PM">PM</MenuItem>
                        <MenuItem value="MEMBER">Member</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <br />
                {
                  userRole &&
                  <SquadFilter
                    squadList={modalSquadList}
                    handleSquadChange={handleSquadChange}
                    selectedSquad={modalSelectedSquad}
                    selectedSquadArray={selectedModalSquadArray}
                    page='member modal'
                  />
                }
                <br />
                <Autocomplete
                  size="medium"
                  id="combo-box-demo"
                  getOptionLabel={(option: any) => option.name}
                  options={externalSystems}
                  value={externalSystems.find((option) => option.name === externalUser)
                    || null
                  }
                  onChange={handleExternalUserChange}
                  renderInput={(params) => <TextField {...params}
                    label="External Users"
                    variant="outlined"
                    error={externalUserError !== ""}
                    helperText={externalUserError} />}
                  ListboxProps={
                    {
                      style: {
                        maxHeight: '150px',
                      }
                    }
                  }
                  renderOption={(props, option) => (
                    <li {...props} key={option.id}>
                      {option.name}
                    </li>
                  )}
                />
                <br />
                <Grid container spacing={2}>
                  <Grid item xs={12} className={stylesMember.buttonFields}>
                    <Button variant="outlined" className={stylesMember.cancelButton} onClick={handleClose}>Cancel</Button>&nbsp;
                    <Button
                      variant="outlined"
                      type="submit" className={stylesMember.confirmButtonActive}
                    >
                      Confirm
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Box>
          }
          {showDeleteMembers && !lastOwnerDelete &&
            <Box sx={{ ...style }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sx={{ textAlign: "center" }}>
                  Are you sure you want to remove <b>{selectedMemberData.name}</b> from <b> {projectName} </b> project?
                </Grid>
                <Grid item xs={12} className={styles.buttonFields}>
                  <Button
                    variant="outlined"
                    className={styles.cancelButton}
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                  &nbsp;
                  <Button
                    variant="outlined"
                    type="submit"
                    className={styles.confirmButtonActive}
                    onClick={handleDeleteMember}
                  >
                    Confirm
                  </Button>
                </Grid>
              </Grid>
            </Box>
          }
          {(showDeleteMembers || showEditMembers) && lastOwnerDelete &&
            <Box sx={{ ...lastOwnerstyle }}>
              {showDeleteMembers && <p className={styles.headingTwo}>{lastOwnerDeleteMessage}</p>}
              {showEditMembers && <p className={styles.headingTwo}>{lastOwnerEditMessage}</p>}
              <div className={styles.buttonContainer}>
                <Button className={styles.okButton} onClick={handleDeleteMember}>OK</Button>
              </div>
            </Box>

          }
        </Box>
      </Modal>
    </div>
  );
};

export default MemberModal;
