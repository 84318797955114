import { Box, Button, Modal } from "@mui/material";
import popupStyle from './DefaultRemoveModal.module.scss';
import React from "react";

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: '#fff',
  borderRadius: '6px',
  boxShadow: 24,
  padding: "35px",
};
const InActivateModal = (props: any) => {

  return (
    <>
      <Modal
        open={props.open}>
        <Box sx={{ ...style }}>
          {props.projectName && props.projectName !== 'no default key' && <p className={popupStyle.headingTwo}>This key is associated with project <b>{props.projectName}</b>. Remove the label to proceed with the inactivation of the key</p>}
          {!props.projectName && <p className={popupStyle.headingTwo}>The key has been marked as Generic default key. Remove the label to proceed with the inactivation of the key</p>}
          {props.projectName === 'no default key' && <p className={popupStyle.headingTwo}>Are you sure about inactivating the key?.Inactivating the key will result in the inability to share keys further</p>}
          {props.projectName !== 'no default key' &&
            <div className={popupStyle.buttonContainer}>
              <Button className={popupStyle.okButton} onClick={props.handleActiveModalClose}>OK</Button>
            </div>
          }
          {props.projectName === 'no default key' &&
            <div className={popupStyle.buttonContainer}>
              <Button className={popupStyle.cancelButton} onClick={props.handleActiveModalClose}>cancel</Button>
              <Button className={popupStyle.removeButton} onClick={props.toggleConfirm}>confirm</Button>
            </div>
          }
        </Box>
      </Modal>
    </>
  )
}
export default InActivateModal;