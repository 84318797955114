/* global google */
import { Box, Card, CardContent, CardMedia, Grid, Typography } from "@mui/material";
import styles from "./Login.module.scss";
import union from "../../assets/svg/union.svg";
import axios from "axios";
import { useEffect, useCallback, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { BACKEND_BASE_URL, GOOGLE_CLIENT_ID, ACCESS_TOKEN, REFRESH_TOKEN, USER_DATA, USER_ID } from "../../config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LazyLoader from "../shared/LazyLoader/LazyLoader";

const Login = () => {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(true);
  const location = useLocation();
  const redirectUrl = location.state ? location.state['redirect'] : '';
  
  const handleResponse = useCallback((response: any) => {

    const data = { token: response.credential };
    axios
      .post(BACKEND_BASE_URL + "/verify-token/", data)
      .then(function (response) {
        localStorage.setItem(
          USER_DATA,
          JSON.stringify(response.data.user_data)
        );
        localStorage.setItem(ACCESS_TOKEN, response.data.user_data.user_token.access_token);
        localStorage.setItem(REFRESH_TOKEN, response.data.user_data.user_token.refresh_token);
        localStorage.setItem(USER_ID, response.data.user_data.user_id);
        toast.success("Login Success", {
          position: toast.POSITION.TOP_RIGHT,
        });
        if (redirectUrl) window.location.pathname = redirectUrl
        else navigate("/project-list");      })
      .catch(function (error) {
        if(error.response.data.error === 'Wrong hosted domain.') {
          toast.error("Invalid User, please check your credentials", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
        else {
          toast.error("Something Went Wrong. Please Try Again.", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
  }, [navigate]);

  useEffect(() => {
    if (localStorage.getItem(USER_DATA) !== null) {
      if (redirectUrl) window.location.pathname = redirectUrl
      else navigate("/project-list");    
    }
    else {
      setTimeout(() => {
        google.accounts.id.initialize({
          client_id:
            GOOGLE_CLIENT_ID,
          callback: handleResponse,
          auto_select: false,
        });
        google.accounts.id.renderButton(
          document.getElementById("signInDiv") as HTMLInputElement,
          {
            theme: "filled_black",
            size: "large",
            type: "standard",
            shape: "circle",
          }
        );
        afterTimeOut()
      }
        , 800);
        const afterTimeOut = () => {
          setLoader(false)
        }
    }
    // eslint-disable-next-line
  }, [handleResponse]);

  return (
    <>
    {loader && <LazyLoader/>}
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      style={{ minHeight: "100vh" }}
      className={styles.loginBox}
    >
      <ToastContainer />
      <Card style={{display:loader ? "none" : ""}}>
        <CardContent className={styles.loginBlock}>
          <Box className={styles.projectTitle}>
            <CardMedia
              sx={{ width: "30px" }}
              height="30"
              component="img"
              image={union}
            />
            <p className={styles.pText}>Project <span className={styles.redText}>Tools</span></p>
          </Box>

          <Typography className={styles.welcomeText}>
            Welcome to Project Tools!
          </Typography>

          <Typography className={styles.signinText}>
            Please sign-in to your account and start the adventure
          </Typography>
          <div className={styles.googleButton}>
            <div id="signInDiv" data-itp_support="true" ></div>
          </div>
        </CardContent>
      </Card>
    </Grid>
    </>
  );
};

export default Login;
