import { createSlice } from '@reduxjs/toolkit';

const dateReducer = createSlice({
  name: 'date',
  initialState: {
    value: [],
  },
  reducers: {
    setDate: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { setDate } = dateReducer.actions;
export default dateReducer;
