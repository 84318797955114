import { Box, Button, TextField, Tooltip } from "@mui/material";
import Header from "../Header/Header";
import styles from "./AccessTokens.module.scss";
import {
  accessTokensWarning,
  failureResponses,
} from "../../constants/constants";
import { useEffect, useReducer, useState } from "react";
import DeleteIcon from "../../assets/svg/Delete.svg";
import { deleteApiCall, getApiCall, postApiCall } from "../../utils/Utils";
import { ToastContainer, toast } from "react-toastify";
import LazyLoader from "../shared/LazyLoader/LazyLoader";
import { useNavigate } from "react-router";

interface State {
  accessTokenName: string;
  accessTokenValue: string;
  hostelDomainUrl: string;
}

interface Token {
  id: number;
  token_name: string;
  hosted_domain_url: string;
  last_used: null | string;
}

const errorState: State = {
  accessTokenName: "",
  accessTokenValue: "",
  hostelDomainUrl: "",
};

const initialState: State = {
  accessTokenName: "",
  accessTokenValue: "",
  hostelDomainUrl: "",
};

// action type for reducer
type Action = { type: string; element: string; value: string };

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "updateAccessTokens":
      return {
        ...state,
        [action.element]: action.value,
      };
    case "resetTokenFields":
      return initialState;
    default:
      return state;
  }
};

const AccessTokens = () => {
  const navigate = useNavigate();

  // state variables
  const [state, dispatch] = useReducer(reducer, initialState);
  const [error, setError] = useState<State>(errorState);
  const [accessTokenList, setAccessTokenList] = useState([]);
  const [loader, setLoader] = useState(false);

  // input fields on change handler
  const handleInputChange = (element: string, value: any) => {
    dispatch({
      type: "updateAccessTokens",
      element,
      value,
    });
    setError((prevErrors) => ({
      ...prevErrors,
      [element]: "",
    }));
  };

  // handle cancel status description
  const handleResetInputs = () => {
    setError(errorState);
    dispatch({
      type: "resetTokenFields",
      element: "",
      value: "",
    });
  };

  // handle submit of access token
  const handleSubmit = () => {
    if (
      state.accessTokenName &&
      state.accessTokenValue &&
      state.hostelDomainUrl &&
      state.accessTokenName.length < 25 &&
      state.accessTokenValue.length < 100
    ) {
      setLoader(true);
      postApiCall(
        `/user/access-token/`,
        {
          token_name: state.accessTokenName,
          access_token: state.accessTokenValue,
          hosted_domain_url: state.hostelDomainUrl,
        },
        accessTokenPostSuccessCallbackFunction,
        accessTokenPostFailureCallbackFunction
      );
    } else {
      setError({
        accessTokenName: !state.accessTokenName
          ? "Please enter the access token name"
          : state.accessTokenName.length > 25
          ? "The access token name exceeds the character limit. Please use a shorter name"
          : "",
        accessTokenValue: !state.accessTokenValue
          ? "Please enter the acccess token value"
          : state.accessTokenValue.length > 100
          ? "The access token exceeds the character limit. Please use a shorter name"
          : "",
        hostelDomainUrl: !state.hostelDomainUrl
          ? "Please enter the hosted domain URL"
          : "",
      });
    }
  };

  const accessTokenPostSuccessCallbackFunction = (response: any) => {
    setLoader(false);
    toast.success("Access token has been successfully added", {
      position: "top-right",
    });
    setError(errorState);
    dispatch({
      type: "resetTokenFields",
      element: "",
      value: "",
    });
    getAccessTokenList();
  };

  const accessTokenPostFailureCallbackFunction = (response: any) => {
    setLoader(false);
    if (response === failureResponses.INVALID_TOKEN) navigate("/");
    setError({
      accessTokenName: response.token_name ? response.token_name : "",
      accessTokenValue: !state.accessTokenValue
        ? "Please enter the acccess token value"
        : "",
      hostelDomainUrl: response.hosted_domain_url
        ? response.hosted_domain_url
        : "",
    });
  };

  // deletion of access token
  const handleDeleteAccesToken = (tokenId: number) => {
    setLoader(true);
    deleteApiCall(
      `/user/access-token/${tokenId}/`,
      deleteTokenSuccessCallbackFunction,
      deleteTokenFailureCallbackFunction
    );
  };

  const deleteTokenSuccessCallbackFunction = (response: any) => {
    setLoader(false);
    toast.success("The access token has successfully been deleted", {
      position: "top-right",
    });
    getAccessTokenList();
  };

  const deleteTokenFailureCallbackFunction = (response: any) => {
    setLoader(false);
    if (response === failureResponses.INVALID_TOKEN) navigate("/");
  };

  // calculate hours since access token is last used
  const lastUsedHours = (lastUsed: string): string => {
    const lastUsedDate = new Date(lastUsed);
    const currentDate = new Date();
    const diffInMs: number = currentDate.getTime() - lastUsedDate.getTime();

    const diffInDays: number = diffInMs / (1000 * 60 * 60 * 24); // Convert milliseconds to hours

    // If more than 24 hours, convert to days
    if (diffInDays > 365) {
      const diffInYears: number = diffInDays / 365;
      return `${diffInYears.toFixed(2)} years ago`;
    } else if (diffInDays >= 1) {
      return `${diffInDays.toFixed(2)} days ago`;
    } else {
      const diffInHours: number = diffInDays * 24;
      return `${diffInHours.toFixed(2)} hours ago`;
    }
  };

  // access token list
  const getAccessTokenList = () => {
    setLoader(true);
    getApiCall(
      `/user/access-token/`,
      accessTokenGetSuccessCallbackFunction,
      accessTokenGetFailureCallbackFunction
    );
  };

  const accessTokenGetSuccessCallbackFunction = (response: any) => {
    setLoader(false);
    setAccessTokenList(response);
  };

  const accessTokenGetFailureCallbackFunction = (response: any) => {
    setLoader(false);
    if (response === failureResponses.INVALID_TOKEN) navigate("/");
  };

  useEffect(() => {
    getAccessTokenList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box className={styles.accessTokensPage}>
      {loader && <LazyLoader />}
      <Header page="Access Tokens" />
      <Box className={styles.inputFields}>
        <p className={styles.warning}>{accessTokensWarning}</p>
        <Box className={styles.inputs}>
          <Box className={styles.accessTokenAttribute}>
            <p className={styles.inputLabel}>Access Token Name</p>
            <TextField
              className={styles.field}
              value={state.accessTokenName}
              error={error.accessTokenName ? true : false}
              helperText={error.accessTokenName}
              autoComplete="off"
              onChange={(event) => {
                handleInputChange("accessTokenName", event.target.value);
              }}
            />
          </Box>
          <Box className={styles.accessTokenAttribute}>
            <p className={styles.inputLabel}>User Access Token</p>
            <TextField
              className={styles.field}
              value={state.accessTokenValue}
              error={error.accessTokenValue ? true : false}
              helperText={error.accessTokenValue}
              onChange={(event) => {
                handleInputChange("accessTokenValue", event.target.value);
              }}
              autoComplete="off"
            />
          </Box>
          <Box className={styles.accessTokenAttribute}>
            <p className={styles.inputLabel}>Hosted Domain URL</p>
            <TextField
              className={styles.field}
              value={state.hostelDomainUrl}
              error={error.hostelDomainUrl ? true : false}
              helperText={error.hostelDomainUrl}
              onChange={(event) => {
                handleInputChange("hostelDomainUrl", event.target.value);
              }}
              autoComplete="off"
            />
          </Box>
        </Box>
        <span className={styles.divider}></span>
        <Box className={styles.buttons}>
          <Button
            className={`${styles.commonButtonStyles} ${styles.reset}`}
            onClick={handleResetInputs}
          >
            RESET
          </Button>
          <Button
            className={`${styles.commonButtonStyles} ${styles.submit}`}
            onClick={handleSubmit}
          >
            SUBMIT
          </Button>
        </Box>
      </Box>
      {accessTokenList.length > 0 && (
        <Box className={styles.previousData}>
          <p className={styles.previousDataTitle}>Previously Added</p>
          <Box className={styles.tokenList}>
            {accessTokenList.map((token: Token) => (
              <Box className={styles.token}>
                <Box className={styles.tokenData}>
                  <p className={styles.tokenName}>{token.token_name}</p>
                  <a
                    href={token.hosted_domain_url}
                    target="_blank"
                    className={styles.link}
                    rel="noreferrer"
                  >
                    {token.hosted_domain_url}
                  </a>
                  {token.last_used && (
                    <p className={styles.lastUsed}>
                      Last used:{" "}
                      <span className={styles.lastUsedTime}>
                        {lastUsedHours(token.last_used)}
                      </span>
                    </p>
                  )}
                </Box>
                <Tooltip title="Delete token" placement="top">
                  <img
                    src={DeleteIcon}
                    alt="delete access token"
                    className={styles.deleteIcon}
                    onClick={() => handleDeleteAccesToken(token.id)}
                  />
                </Tooltip>
              </Box>
            ))}
          </Box>
        </Box>
      )}
      <ToastContainer />
    </Box>
  );
};

export default AccessTokens;
