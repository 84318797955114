import addKeyModalReducer from "./addKeyModalReducer";
import dateReducer from "./dateReducer";
import squadReducer from "./squadReducer";
import { configureStore, combineReducers } from '@reduxjs/toolkit';

const rootReducer = combineReducers({
    modalState: addKeyModalReducer.reducer,
    dateValue: dateReducer.reducer,
    squadValue: squadReducer.reducer
  });
  
  const store = configureStore({
    reducer: rootReducer,
  });
  
  export default store;