import React from 'react';
import {
  Box,
  Grid,
  Button,
  FormControl,
  Modal,
  Typography,
  InputLabel,
  TextField,
  InputAdornment,
} from '@mui/material';
import stylesMember from "../Members/Members.module.scss";

interface StatusLabelModalProps {
  open: boolean;
  handleClose: () => void;
  deleteStatusLabel: boolean;
  selectedStatusLabel: any;
  handleDeleteStatusLabel: () => void;
  editStatusLabel: boolean;
  handleEditStatusLabel: () => void;
  handleSaveStatusLabel: () => void;
  statusLabelData: any;
  fieldRequiredMessage: { labelName: string; color: string; };
  handleStatusLabelNameValidation: (name: string) => void;
  nameValidationError: string;
  style: any;
  colors: any[];
  handleColorSelected: (color: any) => void;
  statusLabelChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  colorValidationError: string;
}

const StatusLabelModal: React.FC<StatusLabelModalProps> = ({
  open,
  handleClose,
  deleteStatusLabel,
  selectedStatusLabel,
  handleDeleteStatusLabel,
  editStatusLabel,
  handleEditStatusLabel,
  handleSaveStatusLabel,
  statusLabelData,
  fieldRequiredMessage,
  handleStatusLabelNameValidation,
  nameValidationError,
  style,
  colors,
  handleColorSelected,
  statusLabelChange,
  colorValidationError
}) => {

  const handleFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
                  if (editStatusLabel) handleEditStatusLabel();
                  else handleSaveStatusLabel();
  }

  const statusLabelNameBlur = () => {
    editStatusLabel ? handleStatusLabelNameValidation(selectedStatusLabel) : handleStatusLabelNameValidation(statusLabelData)
  }

  const renderDeleteStatusLabel = () => (
    <Box sx={{ ...style }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sx={{ textAlign: 'center' }}>
          Are you sure you want to delete <b>{selectedStatusLabel.title}</b>? The label will be permanently deleted from the project.
        </Grid>
        <Grid item xs={12} className={stylesMember.buttonFields}>
          <Button
            variant="outlined"
            className={stylesMember.cancelButton}
            onClick={handleClose}
          >
            Cancel
          </Button>
          &nbsp;
          <Button
            variant="outlined"
            type="submit"
            className={stylesMember.confirmButtonActive}
            onClick={handleDeleteStatusLabel}
          >
            Confirm
          </Button>
        </Grid>
      </Grid>
    </Box>
  );

  const renderAddEditStatusLabel = () => (
    <Box sx={{ ...style }}>
      <Grid container spacing={2} className={stylesMember.modalHead}>
        <Grid item xs={10}>
          <Typography id="basic-modal-dialog-title" className={stylesMember.modalTitle}>
            {editStatusLabel ? 'Edit Status Label' : 'Add Status Label'}
          </Typography>
        </Grid>
      </Grid>
      <form onSubmit={handleFormSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <Box component="span">
                {' '}
                <TextField
                  label="Status Label Name"
                  variant="outlined"
                  error={
                    nameValidationError !== '' || fieldRequiredMessage.labelName !== ''
                  }
                  id="outlined-error-helper-text"
                  helperText={
                    editStatusLabel
                      ? selectedStatusLabel.title === ''
                        ? fieldRequiredMessage.labelName
                        : nameValidationError
                      : statusLabelData.title === ''
                      ? fieldRequiredMessage.labelName
                      : nameValidationError
                  }
                  value={
                    editStatusLabel ? selectedStatusLabel.title : statusLabelData.title
                  }
                  fullWidth
                  autoComplete="off"
                  onChange={statusLabelChange}
                  onBlur={statusLabelNameBlur}
                />
              </Box>
            </FormControl>
          </Grid>
        </Grid>
        <br />
        <Grid container spacing={2}>
          <Grid item xs={12}>
           <FormControl fullWidth>
              <Box component="span">
                <TextField
                  label="Color"
                  variant="outlined"
                  value={editStatusLabel ? selectedStatusLabel.color : statusLabelData.color}
                  error={fieldRequiredMessage.color !== '' || colorValidationError !== ''}
                  id="outlined-error-helper-text"
                  helperText={fieldRequiredMessage.color !== '' ? fieldRequiredMessage.color : colorValidationError}
                  fullWidth
                  autoComplete="off"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <Box
                          sx={
                            editStatusLabel
                              ? {
                                  backgroundColor: selectedStatusLabel.color,
                                  width: '20px',
                                  height: '20px',
                                }
                              : statusLabelData.color === ''
                              ? { border: 'solid 2px gray', width: '20px', height: '20px' }
                              : { backgroundColor: statusLabelData.color, width: '20px', height: '20px' }
                          }
                        ></Box>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            </FormControl>
          </Grid>
        </Grid>
        <br />
        <Grid>
          <InputLabel>Select a color</InputLabel>
          <Box sx={{ marginTop: '10px', display: 'flex', gap: '10px', flexWrap: 'wrap' }}>
            {colors.map((item, index) => (
              <Box
                key={index}
                sx={{ backgroundColor: item, width: '20px', height: '20px', cursor: 'pointer' }}
                onClick={() => {
                  handleColorSelected(item);
                }}
              ></Box>
            ))}
          </Box>
          <br />
        </Grid>
        <br />
        <Grid container spacing={2}>
          <Grid item xs={12} className={stylesMember.buttonFields}>
            <Button variant="outlined" className={stylesMember.cancelButton} onClick={handleClose}>
              Cancel
            </Button>
            &nbsp;
            <Button variant="outlined" type="submit" className={stylesMember.confirmButtonActive}>
              Confirm
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box>
        {deleteStatusLabel ? renderDeleteStatusLabel() : renderAddEditStatusLabel()}
      </Box>
    </Modal>
  );
};

export default StatusLabelModal;
