import styles from './KeytoolDetail.module.scss';
import { Modal, Box, Grid, Typography, Button } from '@mui/material';
import stylesProjectList from "../../ProjectList/ProjectList.module.scss";
import styled from "@emotion/styled";

const CustomCancelButton = styled(Button)({
  color: "#fa1919",
  backgroundColor: "white",
  border: "1px solid #fa1919",

  "&:hover": {
    border: "1px solid #fa1919",
  }
});

const KeyDeleteModal = (props: any) => {
  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 530,
    bgcolor: '#fff',
    borderRadius: '6px',
    boxShadow: 24,
    padding: 2,
  };
  return (
    <Modal
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description">
      <Box sx={{ ...style }}>
        <Grid item md={12}>
          <Typography id="modal-modal-title" variant="h6" component="h2" className={styles.modalTitle}>
            Delete Key
          </Typography>
        </Grid>
        <Grid className={styles.deleteWarningMessage}>
          <p>Are you sure about deleting the <b>{props.keyName}</b>? This action will solely remove the key itself without affecting any existing access that has already been granted.</p>
        </Grid>
        <Grid container justifyContent="center" className={stylesProjectList.buttons}>
          <CustomCancelButton variant="outlined"
            onClick={props.handleClose}>
            Cancel
          </CustomCancelButton>
          <Button variant="contained" className={stylesProjectList.submitButton} sx={{ minWidth: "100px" }} onClick={props.onDeleteClick}>
            Delete
          </Button>
        </Grid>
      </Box>
    </Modal>
  )
}

export default KeyDeleteModal;