import React from 'react'
import styles from "./Settings.module.scss"
import instructionIcon from '../../assets/svg/instructionIcon.svg'
import { Box, Button, Modal } from '@mui/material'
import downArrow from '../../assets/svg/instructionArrowDown.svg';
import upArrow from '../../assets/svg/instructionArrowUp.svg'
import checkBoxIcon from '../../assets/svg/CheckBox.svg';
import unCheckedIcon from '../../assets/svg/CheckBoxOutlineBlank.svg'
import { instructions } from '../../constants/constants';

const extensionInstructionPopupStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: '#FDFDFD',
  borderRadius: '5px',
  boxShadow: 24,
  padding: "28px 20px",
};

export default function ExtensionInstruction(props: any) {
  const {selectedBrowser} = props;  
  const [viewMore, setViewMore] = React.useState(false)
  return (
    <Modal open={props.extensionInstructionPopup}>
      <Box sx={{ ...extensionInstructionPopupStyle }}>
        <div id='userInstructionsForExtension' className={styles.permissionContainer}>
          {props.after === 'generate' &&
          <p className={styles.headerP}>To save the private key in our key extension, click on the <strong>Download Extension</strong> Button</p>
          }
          {props.after !== 'generate' &&
          <p className={styles.headerP}>Before downloading the extension file, please go through the instructions given below.</p>
          }
          <div className={styles.extensionInstructions}>
            <div className={styles.instructionHeader} onClick={() => setViewMore(!viewMore)}>
              <div className={styles.instructionHeaderLeft}>
                <img src={instructionIcon} alt="" />
                <p>Read Instructions</p>
              </div>
              <img src={!viewMore ? downArrow : upArrow} alt="" className={styles.arrowIcon} />
            </div>
            {
              viewMore &&
              <div className={styles.instructionContents}>
                {props.after==="generate" ?
                  Object.keys(instructions).map((item)=>
                  <div  className={styles.gap8}>
                    <p className={styles.browserHeading}>For {instructions[`${item}`].browser} Users:</p>
                    {instructions[`${item}`].steps.map((step: any, stepIndex: number) => (
                      <div key={stepIndex} className={styles.steps}>
                        <div className={styles.left}>
                          <p className={styles.step}>Step {stepIndex + 1}:</p>
                        </div>
                        <div>
                          <p className={styles.instruction} dangerouslySetInnerHTML={{ __html: step.step }}></p>
                          {step.image && <img className={styles.extensionImage} src={step.image} alt='extension instructions'/>}
                        </div>
                      </div>
                    ))}
                </div>):
                  <div  className={styles.gap8}>
                    <p className={styles.browserHeading}>For {instructions[`${selectedBrowser}`].browser} Users:</p>
                    {instructions[`${selectedBrowser}`].steps.map((step: any, stepIndex: number) => (
                      <div key={stepIndex} className={styles.steps}>
                        <div className={styles.left}>
                          <p className={styles.step}>Step {stepIndex + 1}:</p>
                        </div>
                        <div>
                          <p className={styles.instruction} dangerouslySetInnerHTML={{ __html: step.step }}></p>
                          {step.image && <img className={styles.extensionImage} src={step.image} alt='extension instructions'/>}
                        </div>
                      </div>
                    ))}
              </div>}
              </div>
            }
          </div>

          {props.after === 'download' &&
            <div className={styles.buttonContainer}>
              <Button className={styles.modalCloseButton} onClick={() => {
                props.handleInstructionClose()
                setViewMore(false)
              }
              }
              >cancel
              </Button>
              <Button className={styles.modalButton} onClick={() => {
                props.handleDownloadConfirmation()
                setViewMore(false)
              }
              }
              >download
              </Button>
            </div>}

          {props.after === 'generate' &&
            <div className={styles.flagDivContainer}>
              <div className={styles.flagDiv}>
                <img src={props.showAgain ? checkBoxIcon : unCheckedIcon} alt="" onClick={() => props.setShowAgain(!props.showAgain)} />
                <p>Don't show this again</p>
              </div>
            </div>}
          {props.after === 'generate' &&
            <div className={styles.okButtonDiv}>
              <Button className={styles.okButton} onClick={() => {
                setViewMore(false)
                props.handleInstructionClose()
              }
              }>
                ok
              </Button>
            </div>}
        </div>
      </Box>
    </Modal>
  )
}
