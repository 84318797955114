import { Autocomplete, Checkbox, Chip, TextField, createFilterOptions } from '@mui/material'
import styles from '../ProjectActivity.module.scss'
import React from 'react';

interface SquadFilterProps {
  squadList: { id: number; name: string }[];
  selectedSquad: string[];
  selectedSquadArray: any[];
  handleSquadChange: (event: any, option: any) => void;
  page: string
}

const filter = createFilterOptions();

export default function SquadFilter(props: SquadFilterProps) {
  const [updatedSquadList, setUpdatedSquadList] = React.useState(props.squadList)
  const [noOptionText, setNoOptionText] = React.useState('Add a new squad by entering the name')

  const selectedViewLimit = ['activity list', 'member'].includes(props.page) ? 1 : 2
  const renderTags = (tagValue: any[], getTagProps: any) => {
    const filteredTags = tagValue.filter(option => option.name !== 'All Squads');
    if (tagValue.some((item: any) => item.name === 'All Squads')) {
      if (tagValue.some((item: any) => item.name === 'No Squads')) {
        return <p className={styles.squadFilterPTag}>All</p>
      }
      else return <p className={styles.squadFilterPTag}>All Squads</p>
    }
    else if(props.page === 'member' && window.innerWidth < 1600) {
      return (
        <p className={styles.squadFilterPTag}>{props.selectedSquad.length} selected</p>
      )
    }
    else if (filteredTags.length <= selectedViewLimit) {
      return (
        <div className={`${styles.chip} ${['activity list', 'member'].includes(props.page) ? styles.maximumWidth : props.page === 'member modal' ? styles.maximumWidthModal : ''}`}>
          {filteredTags.map((option, index) => (
            <Chip key={option.id} label={option.name} {...getTagProps({ index })} />
          ))}
        </div>
      );
    } else {
      const visibleTags = filteredTags.slice(0, selectedViewLimit);
      const hiddenTags = filteredTags.length - selectedViewLimit;
      return (
        <div className={styles.chipContainer}>
          <div className={`${styles.chip} ${['activity list', 'member'].includes(props.page) ? styles.maximumWidth : props.page === 'member modal' ? styles.maximumWidthModal : ''}`}>
            {visibleTags.map((option, index) => (
              <Chip key={option.id} label={option.name} {...getTagProps({ index })} />
            ))}
          </div>
          <p className={styles.more}>+{hiddenTags} more</p>
        </div>
      );
    }
  };

  const updateSquadOptions = (option: any) => {
    if (option[option.length - 1]?.name.match(/"([^"]+)"/)) {
      const addedSquad = option[option.length - 1]?.name.match(/"([^"]+)"/)
      if (!addedSquad[1].includes(option.filter((item: any) => item.name))) {
        const updatedSquads = [...updatedSquadList, { id: option[option.length - 1]?.id, name: addedSquad[1] }]
        setUpdatedSquadList(updatedSquads)
      }
    }
  }
  const handleInputChange = (event: any, newInputValue: any) => {
    if(newInputValue.length > 12 && props.page === 'member modal') setNoOptionText('Adjust the character limit to maximum of 12')
    else if(['no squads','all squads', 'all squad', 'no squad'].includes(newInputValue.toLowerCase()) && props.page === 'member modal') {
      setNoOptionText('This key word is reserved')
    }
  }
  React.useEffect(() => {
    if(props.page === 'member') setUpdatedSquadList(props.squadList)
  }, [props.squadList])
  return (
    <Autocomplete
      className={props.page === 'activity list' ? styles.squadAutocomplteActivity :
                props.page === 'member' ? window.innerWidth < 1600 ? styles.squadFilterSmall : styles.squadAutocomplteMember : styles.squadAutocomplteMemberModal
                }
      multiple
      disableCloseOnSelect
      disableClearable
      value={props.selectedSquadArray}
      isOptionEqualToValue={(option, value) => option.id === value.id && option.name === value.name}
      id="multiple-limit-tags"
      options={updatedSquadList}
      getOptionLabel={(option: any) => option.name ?? ''}
      onInputChange={handleInputChange}
      renderInput={(params) => (
        <TextField
          {...params}
          type={"text"}
          placeholder={updatedSquadList.length  > 0 ? props.selectedSquad.length > 0 ? '' : props.page === 'member modal' ? 'Select squad' : 'All'  : 'Search squad name'}
        />
      )}
      renderTags={renderTags}
      renderOption={(renderOptionProps, option, { selected }, index) => (
        <li {...renderOptionProps} key={option.id}>
          <>
            {option.name.substring(0, 3) !== 'Add' &&
              <span onClick={(e) => e.preventDefault()}>
                <Checkbox
                  key={option.id}
                  sx={{
                    color: '#3A35418A',
                    padding: "0 4px 0 0",
                    '&.Mui-checked': {
                      color: '#151515',
                    },
                  }}
                  checked={props.selectedSquad.includes(option.name)}
                />
              </span>
            }
            {option.name}
          </>
        </li>
      )}
      onChange={(event, option) => {
        props.handleSquadChange(event, option)
        props.page === 'member modal' && updateSquadOptions(option);
      }
      }
      size={['activity list', 'member'].includes(props.page) ? 'small' : 'medium'}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        if (params.inputValue !== '' && props.page === 'member modal' && !['no squads','all squads', 'all squad', 'no squad'].includes(params.inputValue.toLowerCase()) && params.inputValue.length < 12 && !updatedSquadList.map((item: any) => item.name.toLowerCase()).includes(params.inputValue.toLowerCase())) {
          filtered.push({ id: 0-(updatedSquadList.length+1), name: `Add "${params.inputValue}"` })
        }
        return filtered;
      }}
      noOptionsText= {props.page === 'member modal' ? noOptionText : 'No options'}
    />
  )
}
