import {
    RouterProvider,
    Route,
    createBrowserRouter,
    createRoutesFromElements,
  } from "react-router-dom";

import Login from "../components/Login/Login";
import PmToolMain from "../components/PmToolMain/PmToolMain";
import { BASE_HREF } from "../config";
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route>
        <Route path="/" element={<Login />} />
        <Route path="/*" element={<PmToolMain />} />
      </Route>
    ),
    { basename: BASE_HREF}
    
  );
  const RouterConfig = () => {
    return <RouterProvider router={router} />;
  };

  export default RouterConfig;