import styles from './KeytoolDetail.module.scss';
import popupStyles from '../AddKey/AddKey.module.scss';
import { Modal, Box, Grid, Typography, TextField, Button, Chip, Tooltip } from '@mui/material';
import * as openpgp from 'openpgp';
import stylesProjectList from "../../ProjectList/ProjectList.module.scss";
import React, { useCallback } from 'react';
import { getApiCall, patchApiCall } from '../../../utils/Utils';
import { toast } from "react-toastify";
import LinearProgressWithLabel from '../../shared/ProgressBar/ProgressBar';
import DeletedKeyIcon from "../../../assets/svg/DeletedKey.svg";
import truncate from "lodash/truncate";
import { useTooltipState } from '../tooltipUtils';
import extensionIcon from '../../../assets/svg/extensionButtonIcon.svg';
import { allowedOrigins, failureResponses } from '../../../constants/constants';
import { useNavigate } from 'react-router-dom';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 664,
  bgcolor: '#fff',
  borderRadius: '6px',
  boxShadow: 24,
  padding: 2,
};

const KeyDownloadModal = (props: any) => {

  let validationErrorsObj = {
    privateKeyError: false,
    passwordValueError: false,
    incorrectPrivateKey: false,
    incorrectPassword: false
  };
  const initialValidationErrorsObj = validationErrorsObj;
  const [validationErrors, setValidationErrors] =
    React.useState(validationErrorsObj);
  const [privateKey, setPrivateKey] = React.useState('');
  const [passwordValue, setPasswordValue] = React.useState('');
  const [progress, setProgress] = React.useState(0);
  const [downloadButtonClicked, setDownloadButtonClicked] = React.useState(false);
  const [passwordFieldShow, setPasswordFieldShow] = React.useState(false);
  const [isToastShowing, setIsToastShowing] = React.useState(false);

  const navigate = useNavigate()

  const extensionKeyRef = React.useRef<HTMLParagraphElement>(null);
  const downloadKeyMap = React.useRef<HTMLParagraphElement | null>(null);
  const [mappedKeyError, setMappedKeyError] = React.useState(false);

  const preventMultipleToast = () => {
    setDownloadButtonClicked(false)
    setIsToastShowing(true);
    const updatedValidationErrorsObj = { ...validationErrorsObj, incorrectPrivateKey: true };
    setValidationErrors(updatedValidationErrorsObj)
  }

  const decrypt = async (encryptedText: string) => {
    try {
      const passphrase = passwordValue;
      const privateKeyReaded = await openpgp.readPrivateKey({ armoredKey: privateKey });
      let privateKeyGP;
      if (!privateKeyReaded.isDecrypted()) {
        if (passwordValue === "") {
          setValidationErrors((prev: any) => ({...prev, passwordValueError: true}))
        } else {
          privateKeyGP = await openpgp.decryptKey({
            privateKey: await openpgp.readPrivateKey({ armoredKey: privateKey }),
            passphrase
          });
        }

      } else {
        privateKeyGP = privateKeyReaded;
      }
      const message = await openpgp.readMessage({
        armoredMessage: encryptedText
      });
      const { data: decrypted, signatures } = await openpgp.decrypt({
        message,
        decryptionKeys: privateKeyGP
      });
      return decrypted;
    }
    catch {
      preventMultipleToast();
    }
  }


  const decryptWithProgress = async (encryptedText: string) => {
    const totalSteps = 100;
    for (let step = 0; step < totalSteps; step++) {
      await new Promise((resolve) => setTimeout(resolve, 3));
      const currentProgress = ((step + 1) / totalSteps) * 100;
      setProgress(currentProgress);
    }
    const decryptedText = await decrypt(encryptedText);
    return decryptedText;
  };

  const handleKeyEnter = async (privateKeyValue: string) => {
    const updatedValidationErrorsObj = { ...validationErrorsObj, privateKeyError: false, incorrectPrivateKey: false };
    setValidationErrors(updatedValidationErrorsObj);
    setPrivateKey(privateKeyValue);
    const key = await openpgp.readPrivateKey({ armoredKey: privateKeyValue });
    if (!key.isDecrypted()) {
      setPasswordFieldShow(true);
    }
  }

  const handleDownload = async () => {
    setValidationErrors((prev: any) => ({...prev, privateKeyError: privateKey === ""}))

    if (privateKey !== '') {
      try {

        const passphrase = passwordValue;
        const privateKeyReaded = await openpgp.readPrivateKey({ armoredKey: privateKey });

        let privateKeyGP;

        if (!privateKeyReaded.isDecrypted()) {

          if (passwordValue === "") {
            setValidationErrors((prev: any) => ({...prev, passwordValueError: true}))
            return;
          } else {
            try {
              privateKeyGP = await openpgp.decryptKey({
                privateKey: privateKeyReaded,
                passphrase
              });
            }
            catch {
              const updatedValidationErrorsObj = { ...validationErrorsObj, incorrectPassword: true };
              setValidationErrors(updatedValidationErrorsObj);
            }

          }
        } else {
          privateKeyGP = privateKeyReaded;
        }

        if (privateKeyGP) {
          setDownloadButtonClicked(true);
          getApiCall(`/projects/${props.projectId}/project_key/${props.keyData.keyId}/keydata/`, successCallbackFunction, failureCallbackFunction);

        }
      } catch (error) {
        preventMultipleToast();
      }
    }

  };

  const trackKeySuccessCallBack = (response: any) => {
    //There is nothing to handle here for now
  };

  const trackKeyFailureCallBack = (response: any) => {
    if (response === failureResponses.INVALID_TOKEN) navigate('/')
    toast.success('Something went wrong please try again', { position: toast.POSITION.TOP_RIGHT })
  };

  const successCallbackFunction = async (response: any) => {

    // update private key usage in extension 
    if (extensionKeyRef.current) extensionKeyRef.current.textContent = privateKey;
    let event = new CustomEvent("Update last used of private key");
    document.dispatchEvent(event);

    let decryptedText = await decryptWithProgress(response.password);
    if (decryptedText) {
      const blob = new Blob([decryptedText as BlobPart], { type: 'text/plain' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = response.file_name.replaceAll(' ', '_');
      link.click();
      handleClose();

      // Track key status API call 
      const postData = { "is_downloaded": true }
      patchApiCall(`/projects/${props.projectId}/project_key/${props.keyData.keyId}/update-status/`,
        postData,
        trackKeySuccessCallBack,
        trackKeyFailureCallBack)
    }
  };

  const failureCallbackFunction = (response: any) => {
    toast.error("Something went wrong. Try again.", {
      position: toast.POSITION.TOP_RIGHT,
    });
  }

  const handleClose = () => {
    let event = new CustomEvent('close draggable popup from app')
    document.dispatchEvent(event)
    setPasswordFieldShow(false)
    props.handleClose();
    setPrivateKey('');
    setValidationErrors(initialValidationErrorsObj);
    setProgress(0);
    setDownloadButtonClicked(false);
    setPasswordValue('');
  }

  const { hideParentTooltip, handleChildTooltipMouseOver, handleChildTooltipMouseOut } = useTooltipState();

  const messagePass = () => {
    props.handleKeyExtensionClick('download');
    let event = new CustomEvent('now open modal')
    document.dispatchEvent(event);
  }

  const privateKeyHelperText = () => {
    if(validationErrors.privateKeyError) return "Please enter your private key"
    else if(validationErrors.incorrectPrivateKey) 
      return mappedKeyError? 
        'Unable to map the key due to a mismatch or incorrect private key. Verify the private key and try again.' : 'Invalid private key';
    else return ''
  }

  const passwordHelperText = () => {
    if(validationErrors.passwordValueError) return "Please enter the password of the key"
    else if(validationErrors.incorrectPassword) return "Incorrect password"
    else return ''
  };
  
  const setRef = useCallback((node: HTMLParagraphElement | null) => {
    if (node) {
      downloadKeyMap.current = node;
      let event = new CustomEvent('Public key autofill');
      document.dispatchEvent(event);
    }
  }, []);

  window.addEventListener('message', function (event: any) {
    if(allowedOrigins.includes(event.origin)) {
      if (event.data.type === 'PUBLIC_KEY_MAPPING_HIT') {
        handleKeyEnter(event.data.privateKey);
        setMappedKeyError(true);
      }
    }
  });
    
  return (
    <Modal
      open={props.open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Box sx={{ ...style }}>
        <p ref={setRef} id='extension-mapping' className={styles.extension}>{props.keyData.public_key.name}</p>        
        <p ref={extensionKeyRef} id='extension-storage' className={styles.extension}></p>
        <Grid item md={12} className={popupStyles.popupHeaderContainer}>
          <div className={popupStyles.popupHeader}>
            <Typography id="modal-modal-title" variant="h6" component="h2" className={styles.modalTitle}>
              Decrypt &  Download Key
            </Typography>
            {props.keyData.public_key.name && (
              <Tooltip title={!hideParentTooltip && props.keyData.public_key.name?.length > 50 && props.keyData.public_key.name} placement="top">
                <Chip
                  label={
                    <Box className={popupStyles.chipDeletedIcon}>
                      {
                        props.keyData.public_key.is_deleted &&
                        <Tooltip
                          title={'The key has been deleted from the system'}
                          placement="top"
                          onMouseEnter={handleChildTooltipMouseOver}
                          onMouseLeave={handleChildTooltipMouseOut}
                        >
                          <img src={DeletedKeyIcon} alt="Deleted key icon" />
                        </Tooltip>
                      }
                      {truncate(props.keyData.public_key.name, { length: 50 })}
                    </Box>
                  }
                  variant="filled"
                  size="small"
                  className={`${popupStyles.keyChipDesign} ${popupStyles.sharedKey}`}
                />
              </Tooltip>
            )}
          </div>
          <div className={popupStyles.extensionButton} onClick={messagePass}>
            <img src={extensionIcon} alt="" />
            <p>key extension</p>
          </div>
        </Grid>
        <Grid item md={12} className={styles.downloadKeyMessage}>
          <Typography>
            As you download a decrypted file, your browser is actively involved in decrypting it using the private key. It's important to note that the key is not stored within the system or application files. Once the decryption process is successfully completed, you will be able to access the file without any issues.
          </Typography>
        </Grid>

        <Grid item md={12} className={styles.downloadKeyFields}>
          <TextField
            id="outlined-password-input"
            fullWidth
            multiline
            label="Enter your private key"
            autoComplete="off"
            value={privateKey}
            rows={5}
            onChange={(event)=>{
              handleKeyEnter(event?.target.value);
              setMappedKeyError(false);
            }}
            helperText={privateKeyHelperText()}
            error={validationErrors.privateKeyError || validationErrors.incorrectPrivateKey} />
        </Grid>
        <Grid item md={12} className={styles.downloadKeyFields}>
          {passwordFieldShow &&
            <TextField
              id="outlined-password-input"
              type="password"
              fullWidth
              label="Enter your key password"
              autoComplete="off"
              value={passwordValue}
              onChange={(event) => {
                const updatedValidationErrorsObj = { ...validationErrorsObj, incorrectPassword: false };
                setValidationErrors(updatedValidationErrorsObj);
                setPasswordValue(event.target.value)
              }}
              helperText={passwordHelperText()}
              error={validationErrors.passwordValueError || validationErrors.incorrectPassword} />}
        </Grid>
        {downloadButtonClicked &&
          <Grid item md={12}>
            <LinearProgressWithLabel value={progress} />
          </Grid>
        }
        <Grid container justifyContent="center" className={stylesProjectList.buttons}>
          <Button variant="outlined"
            className={stylesProjectList.cancelButton} onClick={handleClose}>
            Cancel
          </Button>
          {(!downloadButtonClicked) &&
            <Button variant="contained" className={stylesProjectList.submitButton} sx={{ minWidth: "100px" }} onClick={handleDownload}>
              Download
            </Button>
          }
        </Grid>
      </Box>
    </Modal>
  )
}

export default KeyDownloadModal;