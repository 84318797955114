import { BACKEND_BASE_URL, ACCESS_TOKEN, REFRESH_TOKEN, USER_DATA } from "../config";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import axios from "axios";

const toast500 = () => {
  toast.error("Something Went Wrong. Please Try Again.", {
    position: toast.POSITION.TOP_RIGHT,
  });
}
const refreshCall = () => {
  return axios.post(BACKEND_BASE_URL + '/refresh-token/', { refresh: localStorage.getItem(REFRESH_TOKEN) })
}
const keysToRemove = [ACCESS_TOKEN, USER_DATA, REFRESH_TOKEN];

const removeKeys = () => {
  keysToRemove.forEach(key => {
    localStorage.removeItem(key)
  })
}
const getApiCall = (
  endPoint: any,
  successCallback: any,
  failureCallback: any
) => {
  axios
    .get(BACKEND_BASE_URL + endPoint, {
      headers: { Authorization: `Bearer ` + localStorage.getItem(ACCESS_TOKEN) },
    })
    .then(function (response: any) {
      return successCallback(response.data);
    })
    .catch(function (errorResponse: any) {
      if (errorResponse?.response?.status === 401) {
        refreshCall()
          .then(function (response: any) {
            localStorage.setItem(ACCESS_TOKEN, response.data.access)
            axios
              .get(BACKEND_BASE_URL + endPoint, {
                headers: { Authorization: `Bearer ` + localStorage.getItem(ACCESS_TOKEN) },
              })
              .then(function (response: any) {
                return successCallback(response.data);
              }).catch(function (errorResponse: any) {
                if (errorResponse?.response?.status === 500) {
                  toast500()
                  return failureCallback(errorResponse.response.data.detail)
                }
                else if (errorResponse?.response?.status === 403) { return failureCallback(errorResponse.response.data.detail) }
                else if (errorResponse?.response?.status === 404) { return failureCallback(errorResponse.message) }
              })
          }).catch(function (errorResponse: any) {
            if (errorResponse?.response?.status === 401) {
              removeKeys()
              return failureCallback(errorResponse.response.data.detail);
            }
          })
      } else if (errorResponse?.response?.status === 500) {
        toast500()
        return failureCallback(errorResponse.response.data.detail)
      }
      else if (errorResponse?.response?.status === 403 || errorResponse?.response?.status === 400) { return failureCallback(errorResponse.response.data.detail) }
      else if (errorResponse?.response?.status === 404) { return failureCallback(errorResponse.message) }
    });
};

const postApiCall = (
  endPoint: any,
  postData: any,
  successCallback: any,
  failureCallback: any
) => {
  axios
    .post(BACKEND_BASE_URL + endPoint, postData, {
      headers: { Authorization: `Bearer ` + localStorage.getItem(ACCESS_TOKEN) },
    })
    .then(function (response: any) {
      return successCallback(response.data);
    })
    .catch(function (errorResponse: any) {
      if (errorResponse?.response?.status === 401) {
        refreshCall()
          .then(function (response: any) {
            localStorage.setItem(ACCESS_TOKEN, response.data.access)
            axios
              .post(BACKEND_BASE_URL + endPoint, postData, {
                headers: { Authorization: `Bearer ` + localStorage.getItem(ACCESS_TOKEN) },
              })
              .then(function (response: any) {
                return successCallback(response.data);
              }).catch(function (errorResponse: any) {
                if (errorResponse?.response?.status === 400 || errorResponse?.response?.status === 403) {
                  return failureCallback(errorResponse.response.data, false);
                }
                else if (errorResponse?.response?.status === 500) {
                  toast500()
                  return failureCallback(errorResponse.response.data.detail)
                }
              })
          }).catch(function (errorResponse: any) {
            if (errorResponse?.response?.status === 401) {
              removeKeys()
              return failureCallback(errorResponse.response.data.detail, true);
            }
          })
      } else if (errorResponse?.response?.status === 400 || errorResponse?.response?.status === 403 || errorResponse?.response?.status === 422) {
        return failureCallback(errorResponse.response.data, false);
      }
    });
};


const patchApiCall = (
  endPoint: any,
  patchtData: any,
  successCallback: any,
  failureCallback: any
) => {
  axios
    .patch(BACKEND_BASE_URL + endPoint, patchtData, {
      headers: { Authorization: `Bearer ` + localStorage.getItem(ACCESS_TOKEN) },
    })
    .then(function (response: any) {
      return successCallback(response.data);
    })
    .catch(function (errorResponse: any) {
      if (errorResponse?.response?.status === 401) {
        refreshCall()
          .then(function (response: any) {
            localStorage.setItem(ACCESS_TOKEN, response.data.access)
            axios
              .patch(BACKEND_BASE_URL + endPoint, patchtData, {
                headers: { Authorization: `Bearer ` + localStorage.getItem(ACCESS_TOKEN) },
              })
              .then(function (response: any) {
                return successCallback(response.data);
              }).catch(function (errorResponse: any) {
                if (errorResponse?.response?.status === 400 || errorResponse?.response?.status === 403 || errorResponse?.response?.status === 422) {
                  return failureCallback(errorResponse.response.data, false);
                }
                else if (errorResponse?.response?.status === 500) {
                  toast500()
                  return failureCallback(errorResponse.response.data.detail)
                }
              })
          }).catch(function (errorResponse: any) {
            if (errorResponse?.response?.status === 401) {
              removeKeys()
              return failureCallback(errorResponse.response.data, true);
            }
          })
      } else if (errorResponse?.response?.status === 400 || errorResponse?.response?.status === 403 || errorResponse?.response?.status === 422) {
        return failureCallback(errorResponse.response.data, false);
      }
    });
};

const deleteApiCall = (
  endPoint: any,
  successCallback: any,
  failureCallback: any
) => {
  axios
    .delete(BACKEND_BASE_URL + endPoint, {
      headers: { Authorization: `Bearer ` + localStorage.getItem(ACCESS_TOKEN) },
    })
    .then(function (response: any) {
      return successCallback(response.data);
    })
    .catch(function (errorResponse: any) {
      if (errorResponse?.response?.status === 401) {
        refreshCall()
          .then(function (response: any) {
            localStorage.setItem(ACCESS_TOKEN, response.data.access)
            axios
              .delete(BACKEND_BASE_URL + endPoint, {
                headers: { Authorization: `Bearer ` + localStorage.getItem(ACCESS_TOKEN) },
              })
              .then(function (response: any) {
                return successCallback(response.data);
              }).catch(function (errorResponse: any) {
                if (errorResponse?.response?.status === 400 || errorResponse?.response?.status === 403 || errorResponse?.response?.status === 422) {
                  return failureCallback(errorResponse.response.data, false);
                }
                else if (errorResponse?.response?.status === 500) {
                  toast500()
                  return failureCallback(errorResponse.response.data.detail)
                }
              })
          }).catch(function (errorResponse: any) {
            if (errorResponse?.response?.status === 401) {
              removeKeys()
              return failureCallback(errorResponse.response.data.detail);
            }
          })
      } else if (errorResponse?.response?.status === 400 || errorResponse?.response?.status === 403 || errorResponse?.response?.status === 422) {
        return failureCallback(errorResponse.response.data, false);
      }
    });
};

const multiPartPatchApiCall = (
  endPoint: any,
  patchtData: any,
  successCallback: any,
  failureCallback: any
) => {
  axios
    .patch(BACKEND_BASE_URL + endPoint, patchtData, {
      headers: {
        Authorization: `Bearer ` + localStorage.getItem(ACCESS_TOKEN),
        "Content-Type": "multipart/form-data"
      },
    })
    .then(function (response: any) {
      return successCallback(response.data);
    })
    .catch(function (errorResponse: any) {
      if (errorResponse?.response?.status === 401) {
        refreshCall()
          .then(function (response: any) {
            localStorage.setItem(ACCESS_TOKEN, response.data.access)
            axios
              .patch(BACKEND_BASE_URL + endPoint, patchtData, {
                headers: {
                  Authorization: `Bearer ` + localStorage.getItem(ACCESS_TOKEN),
                  "Content-Type": "multipart/form-data"
                },
              })
              .then(function (response: any) {
                return successCallback(response.data);
              }).catch(function (errorResponse: any) {
                if (errorResponse?.response?.status === 400 || errorResponse?.response?.status === 403) {
                  return failureCallback(errorResponse.response.data, false);
                }
                else if (errorResponse?.response?.status === 500) {
                  toast500()
                  return failureCallback(errorResponse.response.data.detail)
                }
              })
          }).catch(function (errorResponse: any) {
            if (errorResponse?.response?.status === 401) {
              removeKeys()
              return failureCallback(errorResponse.response.data.detail);
            }
          })
      } else if (errorResponse?.response?.status === 400 || errorResponse?.response?.status === 403) {
        return failureCallback(errorResponse.response.data, false);
      }
    });
};

const isJson = (str: any) => {
  try {
    JSON.parse(str);
  } catch (e) {
    //Error
    //JSON is not okay
    return false;
  }

  return true;
}

export { getApiCall, postApiCall, patchApiCall, deleteApiCall, multiPartPatchApiCall, isJson };
