import styles from "./Footer.module.scss";
import CopyRightImage from "../../assets/svg/copyright.svg";
import { Box } from "@mui/system";

const Footer = () => {
  return (
    <Box className={styles.footerClass} sx={{ pt: 4 }}>
      <Box className={styles.copyRightClass}>
       <img src={CopyRightImage} alt="CopyRight" />
      </Box>
    </Box>
  );
};

export default Footer;