import { Autocomplete, Box, Button, Checkbox, Modal, TextField, Tooltip } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import styles from './ProjectSettings.module.scss'
import chipIcon from '../../assets/svg/squadChipIcon.svg'
import { getApiCall } from '../../utils/Utils';
import { failureResponses } from '../../constants/constants';
import { useNavigate } from 'react-router-dom';
import truncate from 'lodash/truncate'

const modalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 767,
  bgcolor: '#fff',
  borderRadius: '6px',
  boxShadow: 24,
  padding: 3,
};
export default function SquadModal(props: any) {
  const containerRef = useRef<HTMLDivElement>(null);

  type MemberResponse = {
    id: number;
    name: string;
    email: string;
    role: string;
    profile_pic: string;
    squads: any[];
  }
  const [members, setMembers] = React.useState<MemberResponse[]>([])
  const [totalMembers, setTotalMembers] = React.useState<MemberResponse[]>([])
  const [selectedMembers, setSelectedMembers] = React.useState<string[]>([])
  const [squadName, setSquadName] = React.useState('')
  const [hasMore, setHasMore] = useState(true)

  const navigate = useNavigate()
  useEffect(() => {
    setSelectedMembers(props.squadMembers)
    setSquadName(props.currentSquadName)
  }, [props.squadMembers])

  const memberSuccessCallBackFunction = (response: any) => {
    setMembers(response.filter((item: any) => !props.squadMembers.includes(item.name)))
    setTotalMembers(response)
  }
  const memberFailureCallBackFunction = (response: any) => {
    if (response.detail === failureResponses.INVALID_TOKEN) navigate('/')
  }
  useEffect(() => {
    getApiCall(
      `/projects/${props.projectId}/member`,
      memberSuccessCallBackFunction,
      memberFailureCallBackFunction
    );
  }, [])

  const selectedMembersChange = (option: any, selected: boolean) => {
    if (!selected) setSelectedMembers((prev: any) => [...prev, option.name])
    else setSelectedMembers(selectedMembers.filter((item: any) => item !== option.name))
  }
  const handleMemberRemoval = (name: string) => {
    const updatedSelectedMembers = selectedMembers.filter((item: string) => item !== name)
    setSelectedMembers(updatedSelectedMembers)
    const removedMember = totalMembers.find((item: any) => item.name === name);

    if (removedMember && !members.some((member: any) => member.name === name)) {
      const updatedMembers = [...members, removedMember];
      setMembers(updatedMembers);
    }
  }

  const renderTags = (tagValue: any[], getTagProps: any) => {
    return null
  }

  const handleSubmit = () => {
    if (squadName !== '') {
      const getIds = selectedMembers
        .map((name: string) => {
          const member = totalMembers.find((item: MemberResponse) => item.name === name);
          return member ? member.id : null;
        })
        .filter((id: number | null): id is number => id !== null);
      if (props.squadModalEdit) props.handleSquadEditSubmit(getIds, squadName)
      else props.handleSquadAddSubmit(getIds, squadName)
    }
    else {
      props.setSquadNameError('Enter the squad name')
    }
  }
  return (
    <Modal open={props.open}>
      <Box sx={{ ...modalStyle }}>
        <div className={styles.modalDiv}>
          <div className={styles.modalHeaderDiv}>
            <p className={styles.modalHeading}>{props.squadModalEdit ? 'Edit Squad' : 'Add Squad'}</p>
          </div>
          <div className={styles.textFieldWraper}>
            <div className={styles.textFieldDiv}>
              <p className={styles.inputName}>Squad Name</p>
              <TextField
                fullWidth
                className={styles.fieldWidth}
                value={squadName}
                onChange={(event: any) => {
                  setSquadName(event.target.value)
                  props.setSquadNameError('')
                }}
                placeholder='Enter the squad name'
                error={props.squadNameError !== ''}
                helperText={props.squadNameError} />
            </div>
            <div className={styles.textFieldDiv}>
              <p className={styles.inputName}>Members</p>
              <Autocomplete
                className={styles.fieldWidth}
                fullWidth
                multiple
                disableCloseOnSelect
                disableClearable
                value={members.filter((item: any) => selectedMembers.includes(item?.name))}
                isOptionEqualToValue={(option: any, value) => option.id === value.id && option.name === value.name}
                id="multiple-limit-tags"
                options={members}
                getOptionLabel={(option: any) => option.name ?? ''}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    type={"text"}
                    placeholder='Search for members'
                  />
                )}
                renderTags={renderTags}
                renderOption={(renderOptionProps, option, { selected }, index) => (
                  <li {...renderOptionProps} key={option.id} onClick={() => selectedMembersChange(option, selected)}>
                    <>
                      <span onClick={(e) => e.preventDefault()}>
                        <Checkbox
                          key={option.id}
                          sx={{
                            color: '#3A35418A',
                            padding: "0 4px 0 0",
                            '&.Mui-checked': {
                              color: '#151515',
                            },
                          }}
                          checked={selectedMembers.includes(option.name)}
                        />
                      </span>
                      {option.name}
                    </>
                  </li>
                )}
                size={props.page === 'activity list' ? 'small' : 'medium'}
              />
            </div>
            <div className={styles.plusMoreMargin}>
              <div className={styles.selectedMemberDiv} id='chipContainer' ref={containerRef}>
                {selectedMembers.slice(0, hasMore ? 4 : undefined).map((item: string, index: number) => {
                  return (
                    <div className={styles.memberChip} id={`${index}`}>
                      <Tooltip title={(selectedMembers.length > 3 && item.length > 12) ? item : ''} placement="top">
                        <p>{selectedMembers.length > 3 ? truncate(item, { length: 12 }) : item}</p>
                      </Tooltip>
                      <img src={chipIcon} alt='' className={styles.chipIcon} onClick={() => handleMemberRemoval(item)} />
                    </div>
                  )
                })}
              </div>
              <p className={`${styles.plusMore} ${styles.pointer}`} onClick={() => setHasMore(!hasMore)}>{hasMore ? (selectedMembers.length - 4) > 0 ? `+${selectedMembers.length - 4} more` : '' : 'view less'}</p>
            </div>
          </div>
          <div className={styles.breakLine}></div>
          <div className={styles.buttonDiv}>
            <Button className={styles.modalCloseButton} onClick={props.handleModalClose}>cancel</Button>
            <Button className={styles.addSquadButton} onClick={handleSubmit}>{props.squadModalEdit ? 'save changes' : 'Add Squad'}</Button>
          </div>
        </div>
      </Box>
    </Modal>
  )
}
