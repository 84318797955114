import * as React from "react";
import { styled } from "@mui/material/styles";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { Box, Grid, Typography, Chip, Tooltip, Checkbox } from "@mui/material";
import styles from "./Stories.module.scss";
import ClockIcon from "../../../assets/svg/clock.svg";
import MRIcon from "../../../assets/svg/MRIcon.svg";
import expandIcon from "../../../assets/svg/KeyboardArrowDown.svg";
import { getApiCall } from "../../../utils/Utils";
import { ToastContainer, toast } from "react-toastify";
import InfiniteScroll from "react-infinite-scroll-component";
import LazyLoader from "../../shared/LazyLoader/LazyLoader";
import _, { round } from "lodash";
import {
  failureResponses,
  monthNames,
  sprintStoriesSortOptions,
  ticketIconMap,
} from "../../../constants/constants";
import NoStoriesSVG from "../../../assets/svg/No data-pana 1.svg";
import { useNavigate } from "react-router";
import useOverflowTooltip from "../../../utils/useShowTooltip";
import ArrowDown from "../../../assets/svg/ChevronDown.svg";
import useOutsideClick from "../../../utils/useOutsideClick";

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion
    disableGutters
    elevation={0}
    square
    defaultExpanded={true}
    {...props}
  />
))(() => ({
  borderRadius: "4px !important",
  border: "1px solid #E0E0E0",
  backgroundColor: "#FFF",
  marginBottom: "12px",
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={
      <img src={expandIcon} alt="" style={{ transform: "rotate(180deg)" }} />
    }
    {...props}
  />
))(({ theme }) => ({
  flexDirection: "row-reverse",
  alignItems: "flex-start",
  "& .MuiAccordionSummary-expandIconWrapper": {
    marginTop: "12px",
  },
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(180deg)",
  },
  "& .MuiAccordionSummary-content": {
    maxWidth: "98%",
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
}));

const ParticipantsPopup = (props: any) => {
  const { popupRef, participants } = props;
  return (
    <Box ref={popupRef} className={styles.contributorsList}>
      {participants.map((participant: string, index: number) => (
        <span key={index}>{participant}</span>
      ))}
    </Box>
  );
};

const TicketCard = (props: any) => {
  const { story } = props;

  // variables to handle the contributers list popup
  const popupRef = React.useRef<HTMLDivElement>(null);
  const [visiblePopupId, setVisiblePopupId] = React.useState<number | null>(
    null
  );
  const { elementRef, showTooltip } = useOverflowTooltip(story.title);

  const handleClosePopup = React.useCallback(() => {
    setVisiblePopupId(null);
  }, []);

  useOutsideClick(popupRef, handleClosePopup);

  const ticketContributors =
    story.contributors?.count ?? story.assignee ?? "Unassigned";

  const priorityLabelColor = (priority: string) => {
    switch (priority) {
      case "High":
        return "#FF4C51";
      case "Medium":
        return "#FFB400";
      case "Low":
        return "#00D88A";
    }
  };

  const qaFailedColumnText = (failedCount: number) => {
    if (failedCount === 1) return "1 Time";
    else if (failedCount > 1) return `${failedCount} Times`;
  };

  return (
    <Grid container justifyContent="space-between">
      <Grid item className={styles.ticketMetaData}>
        <Box className={styles.ticketTitleDetails}>
          {story.type && story.type !== "user story" && (
            <Tooltip title={story.type} placement="left">
              <img
                className={styles.ticketIcon}
                src={ticketIconMap[story.type]}
                alt="ticket type"
              />
            </Tooltip>
          )}
          <Tooltip title={showTooltip && story.title} placement="top-start">
            <Box className={styles.ticketTitle} ref={elementRef}>
              <a
                href={story.link}
                className={styles.linkStyles}
                target="_blank"
                rel="noreferrer"
              >
                {story.external_reference} - {story.title}
              </a>
            </Box>
          </Tooltip>
          <Box className={styles.burnedHours}>
            {story.development_duration > 0 && (
              <>
                <img src={ClockIcon} alt="" className={styles.clockIcon} />
                <Typography className={styles.time}>
                  {_.round(story.development_duration, 2)} hrs
                </Typography>
              </>
            )}
          </Box>
        </Box>
        <Box className={styles.ticketStatus}>
          {story.story_point && (
            <Chip
              label={`SP - ${story.story_point}`}
              variant="outlined"
              size="small"
              className={styles.storyPointsChips}
            />
          )}
          {story.priority && (
            <Chip
              label={story.priority}
              variant="filled"
              size="small"
              sx={{
                background: priorityLabelColor(story.priority),
              }}
              className={styles.chipStyles}
            />
          )}
          {story.labels?.map((label: any, index: number) => {
            return (
              <Chip
                key={index}
                label={label.title}
                variant="filled"
                size="small"
                sx={{
                  background: label.color,
                }}
                className={styles.chipStyles}
              />
            );
          })}
          {story.deployed_in && (
            <Box className={styles.MRStatus}>
              <img src={MRIcon} alt="" className={styles.MRIcon} />
              <Typography className={styles.MRBranch}>
                {story.deployed_in}
              </Typography>
            </Box>
          )}
        </Box>
      </Grid>
      <Box
        className={styles.ticketDetails}
        onClick={(e) => e.stopPropagation()}
      >
        <Box>
          <Typography className={styles.ticketDetailsTitle}>
            Tasks Completed
          </Typography>
          <Typography className={styles.ticketDetailsInfo}>
            {story.tasks_completed?.completed || 0} out of{" "}
            {story.tasks_completed?.total_count || 0}
          </Typography>
        </Box>
        <Box>
          <Typography className={styles.ticketDetailsTitle}>
            Test Cases
          </Typography>
          <Typography className={styles.ticketDetailsInfo}>
            {story.test_cases
              ? `${story.test_cases.passed}/${
                  story.test_cases.total_count
                } (${round(story.test_cases.percentage, 1)}%)`
              : "Not Created"}
          </Typography>
        </Box>
        <Box>
          <Typography className={styles.ticketDetailsTitle}>
            QA failed
          </Typography>
          <Typography className={styles.ticketDetailsInfo}>
            {story.qa_failed > 0 ? qaFailedColumnText(story.qa_failed) : "-"}
          </Typography>
        </Box>
        <Box className={styles.contributorsCountBox}>
          <Typography className={styles.ticketDetailsTitle}>
            {story.contributors ? "contributors" : "Assignee"}
          </Typography>
          <Typography className={styles.ticketDetailsInfo}>
            {story.contributors ? (
              <span
                ref={popupRef}
                className={styles.contributorsLink}
                onClick={(event) => {
                  event?.stopPropagation();
                  visiblePopupId === story.external_reference
                    ? handleClosePopup()
                    : setVisiblePopupId(story.external_reference);
                }}
              >
                {ticketContributors}
              </span>
            ) : (
              ticketContributors
            )}
            {visiblePopupId === story.external_reference && (
              <ParticipantsPopup
                participants={story.contributors?.data ?? []}
              />
            )}
          </Typography>
        </Box>
        <Box width="80px">
          <Typography className={styles.ticketDetailsTitle}>
            Due Date
          </Typography>
          <Typography className={styles.ticketDetailsInfo}>
            {story.due_date
              ? new Date(story.due_date).getDate() +
                " " +
                monthNames[new Date(story.due_date).getMonth()] +
                ", " +
                new Date(story.due_date).getFullYear()
              : "-"}
          </Typography>
        </Box>
      </Box>
    </Grid>
  );
};

const SortOptionsDropdown = (props: any) => {
  const {
    popupRef,
    setSelectedSortOption,
    selectedSortOption,
    disableSortOptions,
  } = props;

  const filteredSprintStoriesSortOptions = Object.fromEntries(
    Object.entries(sprintStoriesSortOptions).filter(([key, value]) => {
      if (
        !disableSortOptions.sort_story_point &&
        (value.includes("sp_ascending") || value.includes("sp_descending"))
      ) {
        return false;
      }
      if (
        !disableSortOptions.sort_working_hours &&
        (value.includes("dev_hrs_ascending") ||
          value.includes("dev_hrs_descending"))
      ) {
        return false;
      }
      return true;
    })
  );

  return (
    <Box ref={popupRef} className={styles.sortOptions}>
      {Object.keys(filteredSprintStoriesSortOptions).map(
        (option: string, index: number) => (
          <Box className={styles.sortOption} key={index}>
            <Checkbox
              checked={selectedSortOption === sprintStoriesSortOptions[option]}
              sx={{
                color: "#3A35418A",
                padding: "0 4px 0 0",
                "&.Mui-checked": {
                  color: "#444444",
                },
              }}
              onClick={() => {
                if (selectedSortOption === sprintStoriesSortOptions[option])
                  setSelectedSortOption("");
                else setSelectedSortOption(sprintStoriesSortOptions[option]);
              }}
            />
            <p>{option}</p>
          </Box>
        )
      )}
    </Box>
  );
};

interface EnableSortOptions {
  sort_story_point: boolean;
  sort_working_hours: boolean;
}

const enableSortOptionsInitialState: EnableSortOptions = {
  sort_story_point: true,
  sort_working_hours: true,
};

const Stories = (props: any) => {
  const navigate = useNavigate();
  const [stories, setStories] = React.useState<any[]>([]);
  const [page, setPage] = React.useState(1);
  const [hasMore, setHasMore] = React.useState(true);
  const dropdownRef = React.useRef<HTMLDivElement>(null);
  const [sortOptionsOpen, setSortOptionsOpen] = React.useState(false);
  const [loader, setLoader] = React.useState(false);
  const [selectedSortOption, setSelectedSortOption] = React.useState("");
  const [enableSorting, setEnableSorting] = React.useState<EnableSortOptions>(
    enableSortOptionsInitialState
  );

  const getStories = (resetPage = false) => {
    setLoader(true);
    const pageNumber = resetPage ? 1 : page;
    getApiCall(
      `/project/${props.projectId}/sprint/${props.sprintId}/stories/?page_size=100&page=${pageNumber}&sort_key=${selectedSortOption}`,
      getSuccessCallBackFunction,
      failureCallBackFunction
    );
  };

  const getSuccessCallBackFunction = (response: any) => {
    setLoader(false);
    const pageCount = Math.ceil(response.count / 100);
    setEnableSorting({
      sort_story_point: response.results?.sort_story_point,
      sort_working_hours: response.results?.sort_working_hours,
    });
    if (page < pageCount) {
      setHasMore(true);
      setPage(page + 1);
    } else setHasMore(false);
    setStories((prev) => [...prev, ...response.results?.stories]);
    setSortOptionsOpen(false);
  };

  const failureCallBackFunction = (response: any) => {
    setLoader(false);
    if (response === failureResponses.INVALID_TOKEN) navigate("/");
    else if (response === failureResponses.NO_PERMISSION)
      navigate(`/project-details/${props.projectId}`);
    else
      toast.error("Something went wrong,please try again", {
        position: toast.POSITION.TOP_RIGHT,
      });
  };

  const handleClosePopup = React.useCallback(() => {
    setSortOptionsOpen(false);
  }, []);

  useOutsideClick(dropdownRef, handleClosePopup);

  React.useEffect(() => {
    setStories([]);
    props.sprintId !== -1 && getStories(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.sprintId, selectedSortOption]);

  return (
    <div className={styles.sprintAnalysisStories} id="storiesScrollableDiv">
      <InfiniteScroll
        dataLength={stories.length}
        next={getStories}
        hasMore={hasMore}
        loader={loader && <LazyLoader />}
        scrollableTarget="storiesScrollableDiv"
      >
        <ToastContainer />
        {loader && <LazyLoader />}
        <Box className={styles.storiesHeader}>
          <Typography className={styles.storiesTitle}>Stories</Typography>
          {(enableSorting.sort_story_point ||
            enableSorting.sort_working_hours) && (
            <Box
              className={styles.sortBy}
              onClick={() => setSortOptionsOpen(true)}
            >
              <span>Sort by</span>
              <img src={ArrowDown} alt="open sorting options" />
              {sortOptionsOpen && (
                <SortOptionsDropdown
                  popupRef={dropdownRef}
                  setSelectedSortOption={setSelectedSortOption}
                  selectedSortOption={selectedSortOption}
                  disableSortOptions={enableSorting}
                />
              )}
            </Box>
          )}
        </Box>
        <Box className={styles.storiesBox} id="storiesScrollableDiv">
          {stories.length > 0
            ? stories?.map((story, index) => {
                return story.sub_tickets.length !== 0 ? (
                  <Accordion key={index}>
                    <AccordionSummary
                      aria-controls="panel1d-content"
                      id="panel1d-header"
                    >
                      <TicketCard story={story} />
                    </AccordionSummary>
                    <AccordionDetails>
                      <Box className={styles.subticketSection}>
                        {story.sub_tickets?.map(
                          (subTicket: any, index: any) => {
                            return (
                              <Box className={styles.subTicket} key={index}>
                                <Typography className={styles.subticketTitle}>
                                  {subTicket.external_reference} -{" "}
                                  {subTicket.title}
                                </Typography>
                                {subTicket.labels?.map(
                                  (label: any, index: number) => {
                                    return (
                                      <Chip
                                        key={index}
                                        label={label.title}
                                        variant="outlined"
                                        size="small"
                                        sx={{
                                          border: `1px solid ${label.color}`,
                                          color: label.color,
                                          fontSize: "13px",
                                        }}
                                      />
                                    );
                                  }
                                )}
                              </Box>
                            );
                          }
                        )}
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                ) : (
                  <Box className={styles.mainTicketCard} key={index}>
                    <TicketCard story={story} />
                  </Box>
                );
              })
            : !loader && (
                <Box className={styles.noStory}>
                  <img src={NoStoriesSVG} alt="no stories to display" />
                  <p className={styles.nostoryText}>No stories to display</p>
                </Box>
              )}
        </Box>
      </InfiniteScroll>
    </div>
  );
};

export default Stories;
