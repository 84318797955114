import { Box, Chip, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from "@mui/material";
import React from "react";
import Header from "../../Header/Header";
import downloadIcon from "../../../assets/svg/Download.svg"
import shareIcon from "../../../assets/svg/Share.svg"
import styles from "./Keytool.module.scss";
import popupStyles from "../AddKey/AddKey.module.scss";
import { useParams, useNavigate } from "react-router-dom";
import { getApiCall, patchApiCall } from "../../../utils/Utils";
import { ToastContainer, toast } from "react-toastify";
import AddKey from '../AddKey/AddKey';
import ShareKeyModal from "../ShareKey/ShareKeyModal";
import LazyLoader from "../../shared/LazyLoader/LazyLoader";
import InfiniteScroll from "react-infinite-scroll-component";
import DownloadModal from "../DownloadModal/DownloadModal";
import addedKeyIcon from "../../../assets/svg/addedKeyIcon.svg";
import sharedKeyIcon from "../../../assets/svg/sharedKeyIcon.svg";
import DeletedKeyIcon from "../../../assets/svg/DeletedKey.svg";
import truncate from "lodash/truncate";
import { useTooltipState } from "../tooltipUtils";
import MissingExtensionPopup from "../MissingExtensionPopup/MissingExtensionPopup";
import RevokeIconBlue from "../../../assets/svg/RevokeBlue.svg";
import RevokeAccessModal from "../RevokeAccessModal/RevokeAccessModal";
import { failureResponses } from "../../../constants/constants";

const Keytool = () => {
  const params = useParams();
  const projectId = params.projectId;
  const [view, setView] = React.useState("list")
  const [open, setOpen] = React.useState(false);
  const [rowsPerPage] = React.useState(100)
  const [totalPage, setTotalPage] = React.useState(0)
  const page = React.useRef<number>(1);
  const navigate = useNavigate();
  const [loader, setLoader] = React.useState(true)
  const [downloadModalOpen, setDownloadModal] = React.useState(false)
  const [downloadKeyId, setDownloadKeyId] = React.useState(-1)
  interface KeytoolItem {
    id: number;
    name: string;
    type: string;
    status: string;
    shared_users: string[];
    can_share: boolean;
    is_owner: boolean;
  }

  interface PublicKey {
    public_key: {
      name: string;
      is_deleted: string;
      is_default: string;
    };
  }

  interface PublicKeyData extends PublicKey, KeytoolItem {}

  interface KeyData {
    keyId: number;
    isOwner : boolean;
  }

  interface ShareKeyModalData extends KeyData, PublicKey {}
  interface RevokeSelectedKey {
    keyId: number;
    openModal: boolean;
  }

  const [keytoolData, setKeyToolData] = React.useState<PublicKeyData[]>([]);
  const [shareOpen, setShareOpen] = React.useState(false)
  const [keyStatus, setKeyStatus] = React.useState("")
  const [keyNameEntered, setKeyNameEntered] = React.useState("")
  const [publicKeyAdded, setPublicKeyAdded] = React.useState(false)
  const [hasMore, setHasMore] = React.useState(true)
  const [createKey, setCreateKey] = React.useState(false)
  const [keyData, setKeyData] = React.useState<ShareKeyModalData>({keyId:-1, public_key:{name: "", is_default:'',is_deleted:'' }, isOwner: false});
  const showMissingExtensionPopup = React.useRef(true);
  const [missingModalOpen, setMissingModalOpen] = React.useState(false);
  const [revokeAccessModalOpen, setRevokeAccessModalOpen] = React.useState<RevokeSelectedKey>({keyId:0, openModal:false});

  const componentEvent = new CustomEvent('Component mounted');
  document.dispatchEvent(componentEvent);
  document.addEventListener(`extension connected`, ()=>{
    showMissingExtensionPopup.current = false;
  });
  
  const handleShareModalClose = () => {
    let event = new CustomEvent('close draggable popup from app')
    document.dispatchEvent(event)
    setShareOpen(false)
  }
  const handleShare = (id: number, public_key: any, is_owner: boolean) => {
    setShareOpen(true)
    setKeyData({keyId:id, public_key: public_key, isOwner: is_owner})
  }
  const handleProjectView = (value: string) => {
    setView(value)
  }

  const successCallBackFunction = (response: any) => {
    const pageCount = Math.ceil(response.count / 100)
    if (page.current < pageCount) {      
      setHasMore(true)
      page.current = page.current + 1;
    }
    else setHasMore(false)
    setLoader(false)
    if (pageCount === 1) {
      setKeyToolData(prev => (response.results))
    }
    else {
      setKeyToolData(prev => ([...prev, ...response.results]))
    }

    setTotalPage(response.count)
    setLoader(false);
  }

  const failureCallBackFunction = (response: any) => {
    setLoader(false)
    toast.error("Something went wrong. Try again.", {
      position: toast.POSITION.TOP_RIGHT,
    });
    navigate("/")
  }
  const getData = () => {
    setCreateKey(true)
  }
  React.useEffect(() => {
    if (createKey) {
      page.current === 1 && keyToolListData(true)
    }
    setCreateKey(false)
  }, [page])

  const keyToolListData = (resetPage: boolean) => {
    const pageSize = rowsPerPage;
    let pageNo = page.current;
    if (resetPage) {
      page.current = 1;
      setKeyToolData([]);
      pageNo = 1;
    }
    getApiCall(`/projects/${projectId}/keys/?page_size=${pageSize}&page=${pageNo}&status=${keyStatus}&search=${keyNameEntered}`,
      successCallBackFunction,
      failureCallBackFunction
    )
    setLoader(true);
  }

  const publicKeySuccessCallBack = (response: any) => {
    if (response[0]?.key) {
      setPublicKeyAdded(true)
    }
  }
  const publicKeyFaileureCallBack = (response: any) => {
    toast.error("Something went wrong. Try again.", {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
  React.useEffect(() => {
    getApiCall(`/projects/${projectId}/user/public_keys/`,
      publicKeySuccessCallBack,
      publicKeyFaileureCallBack)
  }, [])

  React.useEffect(() => {
    setKeyToolData([])
    keyToolListData(false)
    // eslint-disable-next-line
  }, [projectId, keyStatus, keyNameEntered])

  const handleModalOpen = () => {
    setOpen(true);
  }

  const handleModalClose = () => {
    setOpen(false)
  };

  const handleDownloadConfirm = () => {
    handleDownloadEncryptedText(downloadKeyId)
    handleDownloadModalClose()
  }
  const handleDownloadModalOpen = (id: any) => {
    setDownloadModal(true)
    setDownloadKeyId(id)
  }
  const handleDownloadModalClose = () => {
    setDownloadKeyId(-1)
    setDownloadModal(false)
  }

  const handleDownloadEncryptedText = (key: number) => {
    getApiCall(`/projects/${projectId}/project_key/${key}/keydata/`, downloadSuccessCallbackfunction, failureCallBackFunction)
  }

  const downloadSuccessCallbackfunction = (response: any) => {
    const blob = new Blob([response.password], { type: "text/plain" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = response.file_name.replaceAll(' ', '_');
    link.click();

    const postData = { "is_downloaded": true }
    patchApiCall(`/projects/${projectId}/project_key/${downloadKeyId}/update-status/`,
      postData,
      trackKeySuccessCallBack,
      trackKeyFailureCallBack)
  };

  const trackKeySuccessCallBack = (response: any) => {
    //There is nothing to handle here for now
  };

  const trackKeyFailureCallBack = (response: any) => {
    if (response === failureResponses.INVALID_TOKEN) navigate('/')
    toast.success('Something went wrong please try again', { position: toast.POSITION.TOP_RIGHT })
  };

  const handleStatusChange = (event: any, status: any) => {
    setKeyToolData([])
    page.current = 1;
    setKeyStatus(status?.label ?? "")
  }
  const handleKeyNameSearch = (name: any) => {
    page.current = 1;
    setKeyNameEntered(name)
  };

  const handleKeyExtensionClick = (popup: string) => {
    if (showMissingExtensionPopup.current === true) {
      setMissingModalOpen(true);
      setShareOpen(false);
    }
  };

  const onGoBackClick = () => {
    setMissingModalOpen(false);
    setShareOpen(true);
  }

  const handleRevokeModalClose = () => {
    setRevokeAccessModalOpen({keyId:0, openModal:false});
  };

  const { hideParentTooltip, handleChildTooltipMouseOver, handleChildTooltipMouseOut } = useTooltipState();

  return (
    <>
      <ToastContainer />
      {loader && <LazyLoader />}
      {view === "list" &&
        <>
          <Box className={styles.keytoolContainer}>
            <Header page="keytool" handleProjectView={handleProjectView} view={view} projectId={projectId} count={totalPage} addKeyButtonClick={handleModalOpen} handleStatusChange={handleStatusChange} handleKeyNameSearch={handleKeyNameSearch} publicKeyAdded={publicKeyAdded} getKeyToolList={keyToolListData} setKeyNameEntered={setKeyNameEntered} keyNameEntered={keyNameEntered}/>
            <Box className={styles.tableBox}>
              <Paper>
                <TableContainer>
                  <Table>
                    <colgroup>
                      <col style={{ width: "25%", maxWidth: "264px" }}></col>
                      <col style={{ width: "13.5%" }}></col>
                      <col style={{ width: "20%" }}></col>
                      <col style={{ width: "15%" }}></col>
                      <col style={{ width: "12%", minWidth: "120px" }}></col>
                    </colgroup>
                    <TableHead className={styles.tableHead}>
                      <TableRow>
                        <TableCell className={styles.centerCellHeader} style={{ textAlign: "left" }}>
                          KEY NAME
                        </TableCell>
                        <TableCell className={styles.centerCellHeader}>
                          TYPE
                        </TableCell>
                        <TableCell className={styles.centerCellHeader}>
                          SHARED WITH
                        </TableCell>
                        <TableCell className={styles.centerCellHeader}>
                          STATUS
                        </TableCell>
                        <TableCell className={styles.centerCellHeader} style={{ textAlign: "left" }} >
                          ACTION ITEMS
                        </TableCell>
                      </TableRow>
                    </TableHead>
                  </Table>
                </TableContainer>
                <Box id="scrollableDetailDiv" className={styles.scrollContainer} >
                  <InfiniteScroll
                    dataLength={keytoolData.length}
                    next={() => keyToolListData(false)}
                    hasMore={hasMore}
                    loader={loader && <LazyLoader />}
                    scrollableTarget="scrollableDetailDiv"
                  >
                    <Table >
                      <colgroup>
                        <col style={{ width: "25%", maxWidth: "264px" }}></col>
                        <col style={{ width: "13.5%" }}></col>
                        <col style={{ width: "20%" }}></col>
                        <col style={{ width: "15%" }}></col>
                        <col style={{ width: "12%", minWidth: "120px" }}></col>
                      </colgroup>
                      <TableBody className={styles.cardContainer}>
                        {
                          keytoolData.map((item, index) => (
                            <TableRow key={index}>

                              <TableCell onClick={() => navigate(`/project-details/${projectId}/key-details/${item.id}/`)} sx={{ cursor: "pointer" }} className={styles.keyNameRow}>
                                <Box className={styles.keyName}>
                                    <Tooltip title={item.is_owner? 'Added Key' : 'Received Key'} placement="top">
                                      <img src={item.is_owner ? addedKeyIcon : sharedKeyIcon} className={styles.keyIcon} alt="key" />
                                    </Tooltip>
                                  <Box className={styles.keyNameTooltip}>
                                    <Tooltip title={item.name.length>17 && item.name} placement="top">
                                      <p>{truncate(item.name, { length: 17 })}</p>                                    
                                    </Tooltip>
                                    {item.public_key.name && (
                                    <Tooltip title={!hideParentTooltip && item.public_key.name?.length>17 && item.public_key.name} placement="top">
                                      <Chip
                                        label={
                                          <Box className={popupStyles.chipDeletedIcon}>
                                            {
                                              item.public_key.is_deleted && 
                                              <Tooltip 
                                                title={'The key has been deleted from the system'} 
                                                placement="top"
                                                onMouseEnter={handleChildTooltipMouseOver}
                                                onMouseLeave={handleChildTooltipMouseOut}  
                                              >
                                                <img src={DeletedKeyIcon} alt="Deleted key icon"/>
                                              </Tooltip>
                                            }
                                            {truncate(item.public_key.name, { length: 17 })}
                                          </Box>
                                        }
                                        variant="filled"
                                        size="small"
                                        className={`${popupStyles.keyChipDesign} 
                                        ${
                                          item.is_owner
                                            ? popupStyles.addedKey
                                            : popupStyles.sharedKey
                                        }`}
                                      />
                                    </Tooltip>                                
                                    )}
                                  </Box>
                                </Box>
                              </TableCell>
                              <TableCell className={styles.centerCell}>
                                {item.type}
                              </TableCell>
                              <TableCell className={styles.centerCell}>
                                {item.shared_users[0]}&nbsp;{item.shared_users.length > 1 ? `+${item.shared_users.length - 1}more` : ""}
                              </TableCell>
                              <TableCell className={styles.centerCell}>
                                {item.status === "Active"
                                  ?
                                  <Chip label="Active" variant="filled" size="small"
                                    sx={{
                                      background: "var(--light-custom-background-success-background, linear-gradient(0deg, rgba(255, 255, 255, 0.88) 0%, rgba(255, 255, 255, 0.88) 100%), #4CAF50)"
                                      , color: "#56CA00", fontSize: "13px"
                                    }} />
                                  :
                                  <Chip label="Closed" variant="filled" size="small"
                                    sx={{
                                      background: "var(--light-custom-background-error-background, linear-gradient(0deg, rgba(255, 255, 255, 0.88) 0%, rgba(255, 255, 255, 0.88) 100%), #F44336)",
                                      color: "var(--light-error-main, #FF4C51)", fontSize: "13px"
                                    }} />
                                }
                              </TableCell>
                              <TableCell className={styles.centerCell} style={{ textAlign: "left" }}>
                                <img src={downloadIcon} alt="download" onClick={() => handleDownloadModalOpen(item.id)} style={{ cursor: "pointer" }} />
                                {item.can_share && <img src={shareIcon} alt="share" className={styles.shareIcon} onClick={() => handleShare(item.id, item.public_key, item.is_owner)} style={{ cursor: "pointer" }} />}
                                {item.is_owner && item.shared_users.length>0 && 
                                  <Tooltip title={'Revoke Access'} placement="top">
                                    <img 
                                    src={RevokeIconBlue} 
                                    className={styles.revokeIcon} 
                                    onClick={()=>setRevokeAccessModalOpen({keyId: item.id, openModal:true})}
                                    alt="revoke access"/>
                                  </Tooltip>}
                              </TableCell>

                            </TableRow>
                          ))}

                      </TableBody>
                    </Table>
                  </InfiniteScroll>
                </Box>
              </Paper>
            </Box>
          </Box>
        </>
      }
      {
        view === "grid" &&
        <>
          <Box>
            <Header page="keytool" projectName="keytool" projectId={projectId} handleProjectView={handleProjectView} view={view} count={totalPage}/>

            No data for now
          </Box>
        </>
      }
      <MissingExtensionPopup
        open={missingModalOpen}
        onGoBackClick={onGoBackClick}
      />
      <AddKey open={open} onClose={handleModalClose} getKeyToolList={keyToolListData} setKeyToolData={setKeyToolData} getData={getData} page={page} />
      <DownloadModal
        open={downloadModalOpen}
        handleModalClose={handleDownloadModalClose}
        handleDownloadConfirm={handleDownloadConfirm}
      />
      <ShareKeyModal
        open={shareOpen}
        handleModalClose={handleShareModalClose}
        keyData={keyData}
        projectId={projectId}
        keyToolListData={keyToolListData}
        handleKeyExtensionClick={handleKeyExtensionClick}
      />
      <RevokeAccessModal
        open={revokeAccessModalOpen.openModal}
        keyId={revokeAccessModalOpen.keyId}
        projectId={projectId}
        handleModalClose={handleRevokeModalClose}
        getKeyToolList={keyToolListData}
      />
    </>
  )
}
export default Keytool;