import styles from "./Sidebar.module.scss";
import React, { useState, useRef } from "react";
import { styled, Box, Toolbar, CardMedia } from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import union from "../../assets/Union.png";
import { useNavigate, useLocation, Link } from 'react-router-dom';
import axios from "axios";
import projectsIcon from '../../assets/svg/Projects.svg';
import projectsIconActive from '../../assets/svg/projectsWhite.svg';
import settingsIcon from '../../assets/svg/Settings.svg';
import settingsIconAcive from '../../assets/svg/settingsWhite.svg';
import overViewIcon from '../../assets/svg/Overview.svg';
import overviewIconACtive from '../../assets/svg/overviewWhite.svg';
import dailyStatusIcon from '../../assets/svg/dailyStatus.svg';
import dailyStatusIconActive from '../../assets/svg/dailyStatusWhite.svg';
import { BACKEND_BASE_URL, ACCESS_TOKEN, USER_DATA, REFRESH_TOKEN } from "../../config";
import DefaultLogo from '../../assets/svg/Avatar.png';
import TokenIconActive from "../../assets/svg/Token.svg";
import TokenIcon from "../../assets/svg/TokenWhite.svg";

const drawerWidth: number = 265;


const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
    "& .MuiDrawer-paper": {
      borderRight: "none"
    }
  },
}));

const Sidebar = ({ sendDataToParent }: any) => {
  const windowWidth = useRef(window.innerWidth);
  const keysToRemove = [ACCESS_TOKEN, USER_DATA, REFRESH_TOKEN];
  let tabOpen = true;
  if (windowWidth.current < 768) {
    tabOpen = false;
  }
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(tabOpen);
  const toggleDrawer = () => {
    if (window.innerWidth < 800) {
      sendDataToParent(false);
      setOpen(false);
    }
    else {
      sendDataToParent(!open);
      setOpen(!open);
    }
  };

  const location = useLocation();
  const [menuList] = useState([
    {
      icon: overViewIcon,
      iconActive: overviewIconACtive,
      title: "Overview",
      route: "overview",
    },
    {
      icon: projectsIcon,
      iconActive: projectsIconActive,
      title: "Project Tools",
      route: "project-list",
    },
    {
      icon: dailyStatusIcon,
      iconActive: dailyStatusIconActive,
      title: "Daily Status",
      route: "daily-status",
    },
    {
      icon: TokenIcon,
      iconActive: TokenIconActive,
      title: "Access Tokens",
      route: "access-tokens"
    },
    {
      icon: settingsIcon,
      iconActive: settingsIconAcive,
      title: "My Settings",
      route: "settings",
    },
  ]);

  const logoutSuccess = () => {
    keysToRemove.forEach(k => {
      localStorage.removeItem(k)
    })
    navigate('/');
  }

  const handleLogout = () => {
    axios
      .post(BACKEND_BASE_URL + '/logout/',   {refresh_token: localStorage.getItem(REFRESH_TOKEN) },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`
        }
      })
      .then(function () {
        logoutSuccess();
      })
      .catch(function (error) {
        console.log(error);
        error.response.status === 401 && logoutSuccess();
      });
  };

  const [showDropdown, setShowDropdown] = useState(false);

  const [selectedIndex, selectedMenuIndex] = useState(1);

  let userData: any = localStorage.getItem(USER_DATA);
  const profilePic: any = JSON.parse(userData) && JSON.parse(userData).picture;

  React.useEffect(() => {
    const activeTab = location.pathname
    if (activeTab.includes("/overview"))
      selectedMenuIndex(0)
    else if (activeTab.includes("/project-details"))
      selectedMenuIndex(1)
    else if (activeTab.includes("/daily-status"))
      selectedMenuIndex(2)
    else if (activeTab.includes("/access-tokens"))
      selectedMenuIndex(3)
    else if (activeTab.includes("/settings"))
      selectedMenuIndex(4)
  }, [location])

  const menuListItems = menuList.map((menu, index) => (
    <Link className={styles.navItem} to={menu.route} key={index} onClick={() => selectedMenuIndex(index)}>
      <Box
        component="span"
        className={
          selectedIndex === index ? styles.MenuItemSelected : styles.MenuItem
        }
      >
        <img src={selectedIndex === index ? menu.iconActive : menu.icon} alt=""></img>
        {open && (
          <Box component="span" sx={{ padding: "0 0 0 15px" }}>
            {menu.title}
          </Box>
        )}
      </Box>
    </Link>
  ));

  return (
    <Grid item xs={2} sx={{ background: "#F4F5FA" }}>
      <Drawer variant="permanent" open={open} PaperProps={{ style: { border: 'none' } }}>
        <Toolbar onClick={toggleDrawer} className={styles.HeaderLeft}>
          {open ? <div className={styles.sideBarLogo}><CardMedia
              sx={{ width: "30px" }}
              height="30"
              component="img"
              image={union}
            />
            <p className={styles.pText}>Project <span className={styles.redText}>Tools</span></p></div>:
            <img src={union} alt="Pm Tool Logo" className={styles.projectLogo} />}
        </Toolbar>
        <Box className={styles.MenuDrawer}>
          <List component="nav" className={styles.MenuList}>
            {menuListItems}
          </List>
        </Box>
        <Box className={`${styles.logout} ${!open && styles.logoutCollapsed}`}>
          <img
            onClick={() => setShowDropdown(!showDropdown)}
            src={profilePic ?? DefaultLogo}
            alt="avatar"
            className={styles.profilePic}
          />
          {open && <p onClick={() => handleLogout()} style={{ cursor: "pointer" }}>Log out</p>}
          {showDropdown && (
            <Box component="span" className={styles.logoutBtn} onClick={() => handleLogout()}>
              Logout
            </Box>
          )}
        </Box>
      </Drawer>
    </Grid>
  );
};

export default Sidebar;
