import { useState } from 'react';

export const useTooltipState = () => {
  const [hideParentTooltip, setHideParentTooltip] = useState(false);

  const handleChildTooltipMouseOver = () => {
    setHideParentTooltip(true);
  };

  const handleChildTooltipMouseOut = () => {
    setHideParentTooltip(false);
  };

  return {
    hideParentTooltip,
    handleChildTooltipMouseOver,
    handleChildTooltipMouseOut,
  };
};