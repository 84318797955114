import { CircularProgress, Stack } from '@mui/material';
import styles from '../LazyLoader/LazyLoader.module.scss';


const LazyLoader = () => {
  return (
    <>
      <div className={styles.overlay}>
        <Stack className={styles.loader} sx={{ color: '#151515', justifyContent: "center", top: '50%', left: '50%', position: 'fixed' }} spacing={2} direction="row">
          <CircularProgress color="error" />
        </Stack>
      </div>
    </>
  )
}

export default LazyLoader;