import styles from "../ProjectActivity/ProjectActivity.module.scss";
import settingsStyle from '../ProjectSettings/ProjectSettings.module.scss';
import stylesMember from "../Members/Members.module.scss";
import React, { useContext } from "react";
import {
  Box,
  Grid,
  Table,
  TableBody,
  Typography,
  Paper,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  SelectChangeEvent,
  Tooltip,
  TextField,
  Autocomplete
} from "@mui/material";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { Link, useNavigate, useParams } from "react-router-dom";
import Button from "@mui/material/Button";
import { getApiCall, postApiCall, deleteApiCall, patchApiCall } from "../../utils/Utils";
import ProjectContext from "../Helper/ProjectContext";
import LazyLoader from "../shared/LazyLoader/LazyLoader";
import { ToastContainer, toast } from "react-toastify";
import MemberModal from "./MemberModal";
import backArrow from "../../assets/svg/arrowBack.svg";
import HeaderSearch from "../Header/HeaderSearch";
import InfiniteScroll from "react-infinite-scroll-component";
import { failureResponses, lastOwnerDeleteMessage } from "../../constants/constants";
import Drag from '../../assets/svg/drag.svg';
import { DndContext, closestCorners } from "@dnd-kit/core";
import { SortableContext, verticalListSortingStrategy, useSortable, arrayMove } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import plusIcon from '../../assets/svg/plus.svg'
import SquadFilter from "../ProjectActivity/Filter/SquadFilter";
import noMembersSvg from "../../assets/svg/noMembers.svg";

const MemberData = (props: any) => {
  const { id, row, memberIndex, userProjectRole, userRole, setShowDeleteMembers, setShowEditMembers, memberDataRef, getOwnersCount } = props;
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id })
  const style = {
    transform: CSS.Transform.toString(transform),
    cursor: isDragging ? 'grabbing' : 'default',
  };

  const cursorStyle = { cursor: isDragging ? 'grabbing' : 'grab' };

  return (
    <TableRow
      key={row.id}
      ref={setNodeRef}
      style={style}
      className={`${stylesMember.tableRow} ${memberIndex % 2 === 0 ? '' : stylesMember.tableRowBackground}`}
    >
      <TableCell>
        <img style={cursorStyle} src={Drag} alt="drag icon" {...listeners} {...attributes} />
      </TableCell>
      <TableCell sx={{ padding: "20px" }}>{row.name}</TableCell>
      <TableCell sx={{ textAlign: "center" }}>
        {row.email}
      </TableCell>
      <TableCell sx={{ textAlign: "center" }}>
        {row?.squads.length === 0 && '---'}
        {`${row?.squads[0]?.name ? row?.squads[0]?.name : ''}
        ${row.squads.length > 1 ? ',' : ''}
        ${row?.squads[1]?.name ? row?.squads[1]?.name : ''}`
        }
        {row?.squads?.length > 2 && <span className={settingsStyle.plusMore}> +{row?.squads.length - 2}more</span>}
      </TableCell>
      <TableCell sx={{ textAlign: "center" }}>
        {row.role}
      </TableCell>
      {userRole &&
        <TableCell sx={{ textAlign: "center" }}>
          {
            !((["OWNER", "PM"].includes(row.role))
              && ["PM"].includes(userProjectRole.role)) &&
            <>
              <Box component="span" sx={{ cursor: "pointer" }}>
                <Tooltip title="Edit" placement="bottom-end">
                  <BorderColorIcon
                    fontSize="medium"
                    sx={{ paddingBottom: "3px" }}
                    color="action"
                    onClick={() => {
                      setShowEditMembers(true);
                      memberDataRef.current = row;
                      getOwnersCount();
                    }}
                  />
                </Tooltip>
              </Box>
              <Box component="span" sx={{ padding: "0 0 0 20px", cursor: "pointer" }}>
                <Tooltip title="Delete" placement="bottom-end">
                  <DeleteOutlineIcon
                    fontSize="medium"
                    color="error"
                    onClick={() => {
                      setShowDeleteMembers(true);
                      memberDataRef.current = row;
                      getOwnersCount();
                    }}
                  />
                </Tooltip>
              </Box>
            </>
          }
        </TableCell>
      }
    </TableRow>
  )
}

const Members = (props: any) => {
  interface MembersListData {
    id: number;
    name: string;
    type: string;
    email: string;
    role: string;
    profile_pic: string;
  };

  const userProjectRole = useContext(ProjectContext);
  const userRole = userProjectRole.role === 'MEMBER' ? false : true;
  const navigate = useNavigate();
  const params = useParams();
  const projectId = params.projectId;
  const [projectName, setProjectName] = React.useState("")
  const [dataType, setDataType] = React.useState("");
  const [loader, setLoader] = React.useState(true);
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);
  const [totalPage, setTotalPage] = React.useState(0);
  const [emailValidationErrorMessage, setEmailValidationErrorMessage] =
    React.useState("");
  const [memberEmail, setMemberEmail] = React.useState("");
  const [memberRole, setMemberRole] = React.useState("MEMBER");
  const [errorMessage, setErrorMessage] = React.useState("");
  const [members, setMembersData] = React.useState<MembersListData[]>([]);
  const [externalSystems, setExternalSystems] = React.useState<{ id: string, name: string }[]>([]);
  const [externalUser, setExternalUser] = React.useState("")
  const [externalUserId, setExternalUserId] = React.useState("")
  const [externalUserNameOfCurrentUser, seExternalUserNameOfCurrentUser] = React.useState("")
  const [externalUserError, setExternalUserError] = React.useState("")
  const [showAddMembers, setShowAddMembers] = React.useState(false);
  const [showEditMembers, setShowEditMembers] = React.useState(false);
  const [showDeleteMembers, setShowDeleteMembers] = React.useState(false);
  const [memberAdd, setMemberAdd] = React.useState(false);
  const [selectedMemberData, setSelectedMemberData] = React.useState<{ id: number; name: string; email: string; role: string; ext_user: string }>(
    {
      id: 0,
      name: '',
      email: '',
      role: '',
      ext_user: ''
    });

  const [open, setOpen] = React.useState(false);
  const [searchMember, setSearchMember] = React.useState<any>('');
  const [initialLoad, setInitialLoad] = React.useState<boolean>(true);
  const [filterRole, setFilterRole] = React.useState('');
  const [hasMore, setHasMore] = React.useState(true)
  const roleList = [
    { label: "Owner", value: "OWNER" },
    { label: "PM", value: "PM" },
    { label: "Member", value: "MEMBER" }
  ]

  type OptionType = ({ id: number; name: string })[];
  const [squadList, setSquadList] = React.useState<OptionType>([])
  const [selectedSquads, setSelectedSquads] = React.useState<string[]>([]);
  const [selectedSquadsArray, setSelectedSquadsArray] = React.useState<OptionType>([]);
  const [modalSelectedSquad, setModalSelectedSquad] = React.useState<string[]>([]);

  const [selectedModalSquadArray, setSelectedModalSquadArray] = React.useState<OptionType>([]);
  const [searchFieldExpanded, setSearchFieldExpanded] = React.useState(false)

  const handleOpen = () => {
    seExternalUserNameOfCurrentUser("")
    setOpen(true);
  };

  // useRef used to overcome asynchronous state update 
  const memberDataRef = React.useRef<{ [key: string]: any }>({});
  const lastOwnerRef = React.useRef<boolean>(false);
  const lastOwnerDeleteRef = React.useRef<boolean>(false);

  const handleClose = () => {
    setSelectedModalSquadArray([])
    setSelectedModalSquadArray([])
    setModalSelectedSquad([])
    lastOwnerDeleteRef.current = false;
    setExternalUserError("")
    seExternalUserNameOfCurrentUser("")
    setOpen(false);
    setExternalUser("")
    setExternalUserId("")

    if (showAddMembers || showEditMembers) {
      setMemberEmail("");
      setMemberRole("MEMBER");
      setErrorMessage("");
    }
    if (showAddMembers) {
      setEmailValidationErrorMessage("")
      setShowAddMembers(false);
    }
    if (showEditMembers) setShowEditMembers(false);
    if (showDeleteMembers) setShowDeleteMembers(false);
    setLoader(false)
  };


  const handleAddMember = () => {
    setDataType("add");
    handleOpen();
    setExternalUser("")
    setShowAddMembers(true);
  }

  const openEditMember = (member: any) => {
    if (member.role === 'OWNER' && lastOwnerRef.current) lastOwnerDeleteRef.current = true;
    else {
      getApiCall(
        `/projects/${projectId}/member/${member.id}/`,
        ExtraDataSuccessCallBackFunction,
        failureCallBackFunction
      );
      setSelectedMemberData(prevState => ({
        id: member.id,
        name: member.name,
        email: member.email,
        role: member.role,
        ext_user: externalUserId
      }))
      setDataType("edit");
    }
    handleOpen();
  }

  const openDeleteMember = (member: any) => {
    if (member.role === 'OWNER' && lastOwnerRef.current) lastOwnerDeleteRef.current = true;
    else {
      setSelectedMemberData(prevState => ({
        id: member.id,
        name: member.name,
        email: member.email,
        role: member.role,
        ext_user: externalUserId
      }))
      setDataType("delete");
    }
    handleOpen();
  }



  const handleEmailValidation = () => {
    // eslint-disable-next-line
    let regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    if (memberEmail === '')
      setEmailValidationErrorMessage("Please enter an email id")
    else if (memberEmail !== "" && !regex.test(memberEmail)) {
      setEmailValidationErrorMessage("Enter Valid Email");
    } else {
      setEmailValidationErrorMessage("");
    }
  };

  const saveMember = () => {
    handleEmailValidation();
    let memberData = {
      email: memberEmail,
      role: memberRole,
      ext_user: externalUserId,
      ...(modalSelectedSquad.length > 0 && { squads: modalSelectedSquad }),
    }
    if (memberEmail !== '') {
      postApiCall(
        `/projects/${projectId}/member/`,
        memberData,
        saveSuccessCallBackFunction,
        saveFailureCallBackFunction
      );
      setLoader(true);
    }
  };

  const handleRoleChange = (event: SelectChangeEvent) => {
    setMemberRole(event.target.value);
  };

  const handleEditRoleChange = (event: SelectChangeEvent) => {
    setSelectedMemberData(prevState => ({
      ...prevState,
      role: event.target.value
    }));
  }

  const handleExternalUserChange = (event: any, newValue: any) => {
    if (newValue?.id) {

      setSelectedMemberData(prevState => ({
        ...prevState,
        ext_user: newValue.id
      }))
      setExternalUserId(newValue.id);
      setExternalUser(newValue.name)
    } else if (!newValue) {
      setSelectedMemberData(prevState => ({
        ...prevState,
        ext_user: '0'
      }))
      setExternalUserId('0')
      setExternalUser('')
    }
    else {
      setExternalUserId('')
    }
  };

  const handleEditMember = () => {
    const currentExternalUserId = externalSystems.find((option) => option.name === externalUserNameOfCurrentUser)?.id
    const editedMemberData = {
      role: selectedMemberData.role,
      ...(currentExternalUserId !== selectedMemberData.ext_user && selectedMemberData.ext_user !== "" && { ext_user: selectedMemberData.ext_user }),
      squads: modalSelectedSquad
    }
    patchApiCall(`/projects/${projectId}/member/${selectedMemberData.id}/`,
      editedMemberData, saveSuccessCallBackFunction, saveFailureCallBackFunction);
    setLoader(true);
  }

  const handleDeleteMember = () => {
    if (lastOwnerDeleteRef.current) {
      handleClose();
      getMembersList();
    }
    else {
      deleteApiCall(`/projects/${projectId}/member/${selectedMemberData.id}/`,
        saveSuccessCallBackFunction, failureCallBackFunction);
      setLoader(true);
    }
    lastOwnerDeleteRef.current = false;
    lastOwnerRef.current = false;
    setShowDeleteMembers(false);
    setShowEditMembers(false);
  }

  const handleLastOwnerSimultaniousOperations = async (operation: string) => {
    handleClose();
    operation === 'Edit' ? await setShowEditMembers(true) : await setShowDeleteMembers(true);
    getOwnersCount();
  }

  const saveFailureCallBackFunction = (
    errorResponse: any,
    shouldLogout: any
  ) => {
    if (shouldLogout) {
      navigate("/");
    } else {
      if (errorResponse[0] === failureResponses.LAST_OWNER) {
        handleLastOwnerSimultaniousOperations('Edit');
      }
      else if (errorResponse.detail) {
        setExternalUserError(errorResponse.detail)
        setErrorMessage("")
      }
      else {
        setErrorMessage(errorResponse.error);
      }
    }
  };

  const saveSuccessCallBackFunction = (response: any) => {
    if (modalSelectedSquad.some(item => !squadList.some((squad: any) => squad.name === item))) {
      getApiCall(`/projects/${projectId}/squads/`,
        squadSuccessCallBack,
        squadFailureCallBack
      )
    }
    setModalSelectedSquad([])
    setPage(1)
    setMemberAdd(true)
    const toastMsg = dataType === "add" ? "The member is successfully added to the project"
      : (dataType === "edit" ? "The member is successfully updated."
        : "The member is successfully deleted.");
    toast.success(toastMsg, {
      position: toast.POSITION.TOP_RIGHT,
    });
    setExternalUserError("")
    handleClose();
    if (page === 1) {
      setMembersData([])
      getMembersList()
    }
    getProjectDetails();
    setLoader(true);
  };

  const failureCallBackFunction = (response: any) => {
    if (response.detail === `"${lastOwnerDeleteMessage}"`) {
      handleLastOwnerSimultaniousOperations('Delete');
    }
    else if (response === failureResponses.INVALID_TOKEN) navigate('/')
    else response === failureResponses.NO_PERMISSION ? navigate(`/project-details/${projectId}`) : navigate("/");

    setLoader(false);
  };

  const successCallBackFunction = (response: any) => {
    setMembersData([])
    const pageCount = Math.ceil(response.count / 100)
    if (page < pageCount) {
      setHasMore(true)
      setPage(page + 1)
    }
    else setHasMore(false)
    setTotalPage(response.count);
    if (filterRole !== "" || searchMember !== "") {
      setMembersData(response.results)
    }
    else setMembersData(prev => ([...prev, ...response.results]));
    setLoader(false);
  };

  const ExtraDataSuccessCallBackFunction = (response: any) => {
    if (response.squads.length > 0) {
      setModalSelectedSquad(response.squads.map((item: any) => item.name))
      setSelectedModalSquadArray(response.squads)
    }
    if (response.ext_user !== "Not added") {
      seExternalUserNameOfCurrentUser(response.ext_user)
    }
    const currentExternalUserId = externalSystems.find((option) => option.name === response.ext_user)?.id
    if (currentExternalUserId) {
      setExternalUserId(currentExternalUserId)
      setSelectedMemberData(previous => ({ ...previous, ext_user: currentExternalUserId }))
    }

    setExternalUser(response.ext_user)
  }

  const getMembersList = () => {
    const pageSize = rowsPerPage;
    let filteredIds = selectedSquadsArray.map((squad: any) => squad.name === 'No Squads' ? 'no_squad' : squad.name);
    let squadsParam = filteredIds.includes('All squads') ? 'all' : filteredIds;
    let pageNo
    if (filterRole !== "" || searchMember !== "") {
      pageNo = 1
    }
    else pageNo = page;
    getApiCall(
      `/projects/${projectId}/member/?search=${searchMember}&role=${filterRole}&page_size=${pageSize}&page=${pageNo}&squads=${squadsParam}`,
      successCallBackFunction,
      failureCallBackFunction
    );
    setLoader(true);
  };

  const getExternalSystemsList = () => {
    getApiCall(
      `/projects/${projectId}/ext-users/`,
      setExternalSystems,
      failureCallBackFunction
    );
  }

  const detailsSuccessCallBackFunction = (response: any) => {
    props.setRole(response.role)
    setProjectName(response.title)
  }

  const detailsFailureCallBackFunction = (response: any) => {
    if (response === failureResponses.INVALID_TOKEN) navigate('/')
    else {
      toast.error("Something went wrong. Try again.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    setLoader(false);
  }

  const getProjectDetails = () => {
    getApiCall(
      `/projects/${projectId}/details/`,
      detailsSuccessCallBackFunction,
      detailsFailureCallBackFunction
    )
  }
  const handleEmailIdChange = (event: any) => {
    setMemberEmail(event.target.value)
  }
  React.useEffect(() => {
    if (memberAdd) {
      setMembersData([])
      getMembersList()
    }
    setMemberAdd(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page])

  const squadSuccessCallBack = (response: any) => {
    if (response.length >= 1) {
      const squads = response
      if (response.length > 1) squads.unshift({ id: -1, name: 'All Squads' })
      squads.unshift({ id: -2, name: 'No Squads' })
      setSquadList(squads)
    }
    else setSquadList(response)
  }

  const squadFailureCallBack = (response: any) => {
    if (response === failureResponses.INVALID_TOKEN) navigate('/');
    else if (response === failureResponses.NO_PERMISSION) navigate(`/project-details/${projectId}`)
    else toast.error('Something went wrong', { position: toast.POSITION.TOP_RIGHT })
  }

  const modalSquadSelection = (option: any) => {
    const removed = selectedModalSquadArray.filter((item: any) => !option.includes(item));
    if (removed[removed.length - 1]?.name === 'All Squads') {
      setModalSelectedSquad([])
      setSelectedModalSquadArray([])
    }
    else if (option[option.length - 1]?.name === 'All Squads') {
      setModalSelectedSquad(squadList.map((obj: any) => obj.name))
      setSelectedModalSquadArray(squadList)
    }
    else {
      if (squadList.filter((item) => !['No Squads', 'All Squads'].includes(item.name)).length === option.filter((item: any) => !['No Squads', 'All Squads'].includes(item.name)).length) {
        setModalSelectedSquad(option.filter((obj: any) => obj.name !== 'No Squads').map((obj: any) => obj.name))
        setSelectedModalSquadArray(option.filter((obj: any) => obj.name !== 'No Squads'))
      }
      else {
        setModalSelectedSquad(option.filter((obj: any) => obj.name !== 'All Squads').map((obj: any) => obj.name))
        setSelectedModalSquadArray(option.filter((obj: any) => obj.name !== 'All Squads'))
      }
    }
  }
  const handleSquadSelection = (event: any, option: any) => {
    const removed = selectedSquadsArray.filter((item: any) => !option.includes(item));
    if (removed[removed.length - 1]?.name === 'All Squads') {
      if(option.some((item: any)=>item.name === 'No Squads')) {
        const updatedSquadList = squadList.filter(obj => obj.name === 'No Squads').map(obj => obj.name);
        setSelectedSquads(updatedSquadList)
        setSelectedSquadsArray(squadList.filter(obj => obj.name === 'No Squads'))
      }
      else {
        setSelectedSquads([])
        setSelectedSquadsArray([])
      }
    }
    else if (!option) {
      setSelectedSquads([])
    }
    else {
      if (option[option.length - 1]?.name === 'All Squads') {
        const updatedSquadList = squadList.filter(obj => obj.name !== 'No Squads').map(obj => obj.name);
        setSelectedSquads(updatedSquadList)
        setSelectedSquadsArray(squadList.filter(obj => obj.name !== 'No Squads'))
      }
      else if (option[option.length - 1]?.name === 'No Squads') {
        if (squadList.filter((item) => !['No Squads', 'All Squads'].includes(item.name)).length !== option.filter((item: any) => !['No Squads', 'All Squads'].includes(item.name)).length) {
          const updatedSquadList = option.filter((obj: any) => obj.name !== 'All Squads').map((obj: any) => obj.name);
          setSelectedSquadsArray(option.filter((obj: any) => obj.name !== 'All Squads'))
          setSelectedSquads(updatedSquadList)
        }
        else {
          const updatedSquadList = option.map((obj: any) => obj.name);
          setSelectedSquads(updatedSquadList)
          setSelectedSquadsArray(option)
        }
      }
      else {
        if (squadList.filter((item) => !['No Squads', 'All Squads'].includes(item.name)).length === option.filter((item: any) => !['No Squads', 'All Squads'].includes(item.name)).length) {
          const updatedSquadList = squadList.filter((obj: any) => obj.name !== 'No Squads').map((obj: any) => obj.name);
          setSelectedSquadsArray(squadList.filter((obj: any) => obj.name !== 'No Squads'))
          setSelectedSquads(updatedSquadList)
        }
        else {
          const updatedSquadList = option.filter((obj: any) => obj.name !== 'All Squads').map((obj: any) => obj.name);
          setSelectedSquadsArray(option.filter((obj: any) => obj.name !== 'All Squads'))
          setSelectedSquads(updatedSquadList)
        }
      }
    }
  }

  React.useEffect(() => {
    setPage(1)
    if (searchMember === '' && !initialLoad)
      getMembersList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchMember, selectedSquadsArray])

  React.useEffect(() => {
    setMembersData([])
    setPage(1)
    getMembersList();
    getExternalSystemsList()
    getProjectDetails()
    setInitialLoad(false)
    getApiCall(`/projects/${projectId}/squads/`,
      squadSuccessCallBack,
      squadFailureCallBack
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterRole]);

  // API call to get number of owners left 
  const getOwnersCount = () => {
    getApiCall(
      `/projects/${projectId}/owners_count/`,
      ownerCountSuccessCallBackFunction,
      ownerCountFailureCallBackFunction
    )
  };

  const ownerCountSuccessCallBackFunction = async (response: any) => {
    lastOwnerRef.current = response.owners_count === 1 ? true : false;
    showDeleteMembers ? openDeleteMember(memberDataRef.current) : openEditMember(memberDataRef.current);
  };

  const ownerCountFailureCallBackFunction = (response: any) => {
    if (response === failureResponses.INVALID_TOKEN) {
      navigate('/');
    }
    else {
      toast.error("Something went wrong. Try again.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const getTaskPos = (id: number) => members.findIndex(member => member.id === id);

  const reorderMemberList = (newPosition: number, activeMemberId: number) => {
    patchApiCall(
      `/projects/${projectId}/member/${activeMemberId}/position-update/`,
      { position_index: newPosition },
      reorderMemberListSuccessCallback,
      reorderMemberListFailureCallback
    );
  };

  const reorderMemberListSuccessCallback = () => {
    // no action to be performed yet
  };

  const reorderMemberListFailureCallback = (response: any) => {
    getMembersList();
    if (response === failureResponses.INVALID_TOKEN) navigate('/')
    else toast.error('Failed to update member list');
  };

  const handleDragEnd = (event: any) => {
    const { active, over } = event;
    if (active.id === over.id) return;
    const originalPos = getTaskPos(active.id);
    const newPos = getTaskPos(over.id);
    setMembersData((members) => {
      return arrayMove(members, originalPos, newPos);
    });
    reorderMemberList(newPos, active.id);
  };

  const handleSearchExpanded = (expanded: boolean) => {
    setTimeout(() => {
      setSearchFieldExpanded(expanded)
    }, 230);
  }
  return (
    <>
      <ToastContainer />
      {loader && <LazyLoader />}
      <Grid item md={10}>
        <Box className={stylesMember.headingBlock}>
          <div className={stylesMember.header}>
            <Link to={`/project-details/${projectId}/`}>
              <img src={backArrow} alt="back" style={{ cursor: "pointer" }} />
            </Link>
            <Typography className={stylesMember.headingLabel}>Members ({totalPage})</Typography>
          </div>
          <Box className={stylesMember.filterblock}>
            <Grid>
              <HeaderSearch
                page="member"
                expanded={handleSearchExpanded}
                onChange={(event: any) => { setSearchMember(event.target.value) }}
                onKeyDown={(event: any) => {
                  if (event.key === 'Enter') getMembersList();
                }}
                onClick={getMembersList}
              />
            </Grid>
            <Grid>
              <Autocomplete
                size="small"
                className={window.innerWidth > 1600 ? styles.squadAutocomplteMember : styles.roleAutocompleteSmall}
                id="combo-box-demo"
                getOptionLabel={(option: any) => option.label}
                options={roleList}
                onChange={(event, newValue) => {
                  if (newValue?.value) {
                    setFilterRole(newValue.value);
                  } else {
                    setFilterRole("");
                  }
                }}
                renderInput={(params) => <TextField {...params} label="Select Role" variant="outlined" />}
              />
            </Grid>
            <Grid>
              {squadList.length > 0 &&
                <SquadFilter
                  squadList={squadList}
                  handleSquadChange={handleSquadSelection}
                  selectedSquad={selectedSquads}
                  selectedSquadArray={selectedSquadsArray}
                  page='member'
                />}
            </Grid>

            <Grid item md={4} className={stylesMember.addButton}>
              {userRole &&
                <Button variant="outlined" onClick={handleAddMember} className={stylesMember.buttonAdd}>
                  {!searchFieldExpanded ? '+ Add Member' : window.innerWidth < 1600 ? <img src={plusIcon} alt="" /> : '+ Add Member'}
                </Button>
              }
            </Grid>
          </Box>
        </Box>
        <Box>
          {members.length > 0 ? 
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer component={Paper}>
              <Table
                stickyHeader
                aria-label="sticky table"
                className={styles.activityTable}
              >
                {userRole &&
                  <colgroup>
                    <col style={{ width: "3%", minWidth: "46px" }}></col>
                    <col style={{ width: "20%", maxWidth: "264px" }}></col>
                    <col style={{ width: "20%" }}></col>
                    <col style={{ width: "22%" }}></col>
                    <col style={{ width: "20%" }}></col>
                    <col style={{ width: "20%" }}></col>
                  </colgroup>}
                {!userRole &&
                  <colgroup>
                    <col style={{ width: "33%", maxWidth: "264px" }}></col>
                    <col style={{ width: "33%" }}></col>
                    <col style={{ width: "33%" }}></col>

                  </colgroup>
                }
                <TableHead className={styles.tableHead}>
                  <TableRow>
                    <TableCell className={styles.tableHeadCell}></TableCell>
                    <TableCell
                      // sx={{ backgroundColor: "#F9FAFC" }}
                      className={styles.tableHeadCell}
                    >
                      <Box component="span" className={styles.tableHeadTitleCell}>
                        Name
                      </Box>
                    </TableCell>
                    <TableCell
                      sx={{ textAlign: "center" }}
                      className={styles.tableHeadCell}
                    >
                      <Box component="span" className={styles.tableHeadTitleCell}>
                        Email
                      </Box>
                    </TableCell>
                    <TableCell
                      sx={{ textAlign: "center" }}
                      className={styles.tableHeadCell}
                    >
                      <Box component="span" className={styles.tableHeadTitleCell}>
                        Squad
                      </Box>
                    </TableCell>
                    <TableCell
                      sx={{ textAlign: "center" }}
                      className={styles.tableHeadCell}
                    >
                      <Box component="span" className={styles.tableHeadTitleCell}>
                        Role
                      </Box>
                    </TableCell>
                    {userRole &&
                      <TableCell
                        sx={{ textAlign: "center" }}
                        className={styles.tableHeadCell}
                      >
                        <Box component="span" className={styles.tableHeadTitleCell}>
                          Actions
                        </Box>
                      </TableCell>
                    }
                  </TableRow>
                </TableHead>
              </Table>
            </TableContainer>
            <Box id="scrollableDetailDiv" className={stylesMember.scrollContainer} >
              <InfiniteScroll
                dataLength={members.length}
                next={() => getMembersList()}
                hasMore={hasMore}
                loader={loader && <LazyLoader />}
                scrollableTarget="scrollableDetailDiv"
              >
                <DndContext collisionDetection={closestCorners} onDragEnd={handleDragEnd}>
                  <Table stickyHeader
                    aria-label="sticky table"
                    className={styles.activityTable}>
                    {userRole &&
                      <colgroup>
                        <col style={{ width: "3%" }}></col>
                        <col style={{ width: "20%", maxWidth: "264px" }}></col>
                        <col style={{ width: "20%" }}></col>
                        <col style={{ width: "22%" }}></col>
                        <col style={{ width: "20%" }}></col>
                        <col style={{ width: "20%" }}></col>
                      </colgroup>}
                    {!userRole &&
                      <colgroup>
                        <col style={{ width: "33%", maxWidth: "264px" }}></col>
                        <col style={{ width: "33%" }}></col>
                        <col style={{ width: "33%" }}></col>

                      </colgroup>
                    }
                    <SortableContext items={members} strategy={verticalListSortingStrategy}>
                      <TableBody className={stylesMember.cardContainer}>
                        {members.map((row: any, memberIndex: any) => {
                          return (
                            <MemberData
                              id={row.id}
                              row={row}
                              setShowDeleteMembers={setShowDeleteMembers}
                              setShowEditMembers={setShowEditMembers}
                              memberDataRef={memberDataRef}
                              userRole={userRole}
                              userProjectRole={userProjectRole}
                              memberIndex={memberIndex}
                              getOwnersCount={getOwnersCount}
                            />
                          );
                        })}
                      </TableBody>
                    </SortableContext>
                  </Table>
                </DndContext>
              </InfiniteScroll>
            </Box>
          </Paper> : 
          !loader && <Box className={stylesMember.noMembersImage}>
            <img src={noMembersSvg} alt="no members for selected filter" />  
            <p className={stylesMember.noMembersText}>No members found</p>
          </Box>}
        </Box>

        <MemberModal
          open={open}
          handleClose={handleClose}
          showAddMembers={showAddMembers}
          showEditMembers={showEditMembers}
          emailValidationErrorMessage={emailValidationErrorMessage}
          saveMember={saveMember}
          handleEditMember={handleEditMember}
          errorMessage={errorMessage}
          selectedMemberData={selectedMemberData}
          memberEmail={memberEmail}
          handleEmailValidation={handleEmailValidation}
          showDeleteMembers={showDeleteMembers}
          externalSystems={externalSystems}
          externalUser={externalUser}
          handleExternalUserChange={handleExternalUserChange}
          externalUserError={externalUserError}
          handleDeleteMember={handleDeleteMember}
          handleEditRoleChange={handleEditRoleChange}
          userRole={userProjectRole.role}
          handleEmailIdChange={handleEmailIdChange}
          projectName={projectName}
          handleRoleChange={handleRoleChange}
          memberRole={memberRole}
          lastOwnerDelete={lastOwnerDeleteRef.current}
          squadList={squadList}
          modalSquadSelection={modalSquadSelection}
          modalSelectedSquad={modalSelectedSquad}
          selectedModalSquadArray={selectedModalSquadArray}
        />
      </Grid>
    </>
  );
};

export default Members;
