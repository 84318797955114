import React, { useState } from "react";
import * as openpgp from 'openpgp';
import Header from "../Header/Header";
import { Box, TextField, Modal, Autocomplete, InputAdornment, IconButton, Tooltip } from "@mui/material";
import styles from "./Settings.module.scss"
import { Button } from "rsuite";
import toggleActive from "../../assets/svg/ToggleOn.svg"
import toggleOff from "../../assets/svg/ToggleOff.svg"
import deleteKey from "../../assets/svg/deleteKey.svg"
import DeleteModal from "./DeleteModal";
import { deleteApiCall, getApiCall, patchApiCall, postApiCall } from "../../utils/Utils";
import { ToastContainer, toast } from "react-toastify";
import LazyLoader from "../shared/LazyLoader/LazyLoader";
import { useNavigate } from "react-router-dom";
import { USER_DATA } from "../../config";
import fluentInfoFilled from "../../assets/svg/fluentInfoFilled.svg"
import helpQuestion from "../../assets/svg/helpQuestion.svg"
import cancelIcon from "../../assets/svg/Cancel.svg"
import DefaultRemoveModal from "./DefaultRemoveModal";
import { useDispatch, useSelector } from "react-redux";
import { setModalOpen } from '../../redux/store/addKeyModalReducer'
import InActivateModal from "./InActivateModal";
import { failureResponses } from "../../constants/constants";
import ExtensionInstruction from "./ExtensionInstruction";
import ShowPassword from "../../assets/svg/passwordSowIcon.svg";
import HidePassword from "../../assets/svg/passwordHideIcon.svg";

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  bgcolor: '#fff',
  borderRadius: '5px',
  boxShadow: 24,
  padding: "32px 26px",
};

const extensionPopupStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: '#FDFDFD',
  borderRadius: '5px',
  boxShadow: 24,
  padding: "28px 20px",
}

const Settings = () => {

  const [keyDetails, setKeyDetails] = React.useState<{ id: number; name: string; key: string; }[]>([]);
  const [keyNameEntered, setKeyNameEntered] = React.useState("")
  const [passwordValue, setPasswordValue] = React.useState('');
  const [nameError, setNameError] = React.useState("")
  const [popupKeyNameError, setPopupKeyNameError] = React.useState('')
  const [popupKeyError, setPopupKeyError] = React.useState('')
  const [popupKeyNameEntered, setPopupKeyNameEntered] = React.useState('')
  const [popupKeyEntered, setPopupKeyEntered] = React.useState('')
  const [passwordValueError, setPasswordValueError] = React.useState("")
  const [keyError, setKeyError] = React.useState("")
  const [open, setOpen] = React.useState(false)
  const modalState = useSelector((state: any) => state.modalState.value);
  const [addKeyOpen, setAddKeyOpen] = React.useState(modalState)
  const [loader, setLoader] = React.useState(true)
  let userData: any = localStorage.getItem(USER_DATA);
  const [dropDownList, setDropDownList] = React.useState([])
  const navigate = useNavigate()
  const [defaultRemoveOpen, setDefaultRemoveOpen] = React.useState(false)
  const [keyFlagArray, setKeyFlagArray] = React.useState<{ id: number, active: boolean, viewAll: boolean }[]>([])
  const [currentKey, setCurrentKey] = React.useState(-2)
  const [inActivateKey, setInActivateKey] = React.useState(-1)
  const [activeModalOpen, setActiveModal] = React.useState(false)
  const [togglingProject, setTogglingProject] = React.useState('')
  const [deleteKeyId, setDeleteKeyId] = React.useState(-1)
  const [deleteKeyProject, setDeleteKeyProject] = React.useState('')
  const privateKeyRef = React.useRef<string | null>(null);
  const [selectedOption, setSelectedOption] = useState(null); 
  const inputRefIndex = React.useRef(0);
  const [extensionPopup, setExtensionPopup] = React.useState(false)
  const [extensionInstructionPopup, setExtensionInstructionPopup] = React.useState(false)
  const [showAgain, setShowAgain] = React.useState(false)
  const [instructionPopup, setInstructionPopupFlag] = React.useState(false)
  const [toastMessage, setToastMessage] = React.useState(false)
  const [keyNameExtensionError, setKeyNameExtensionError] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const dispatch = useDispatch()

  const setPasswordValueEnter = (event: any) => {
    setPasswordValueError("")
    setPasswordValue(event.target.value)

  }
  const handlePopupKeyNameEnter = (event: any) => {
    setPopupKeyNameEntered(event.target.value)
    setPopupKeyNameError('')
  }
  const handlePopupKeynter = (event: any) => {
    setPopupKeyEntered(event.target.value)
    setPopupKeyError('')
  }
  const createKey = () => {
    popupKeyEntered === '' && setPopupKeyError('Enter your key')
    popupKeyNameEntered === '' && setPopupKeyNameError('Enter a key name')
    popupKeyNameEntered.length > 200 && setPopupKeyNameError('Ensure this field has no more than 200 characters')
    if (popupKeyEntered !== '' && popupKeyNameEntered !== '' && popupKeyNameEntered.length <= 200) {
      const keyData = { name: popupKeyNameEntered, key: popupKeyEntered }
      postApiCall("/user/public_keys/",
        keyData,
        addKeySuccessCallBackFunction,
        failureCallBackFunction)
    }
  };

  const addKeySuccessCallBackFunction = () => {
    toast.success("Successfully created public key", {
      position: toast.POSITION.TOP_RIGHT,
    });
    setLoader(false);
    handleAddModalClose()
    setKeyError("")
    setPasswordValue("")
    getPublicKey()
    getKeyList()
  };

  const deleteSuccessCallBack = (response: any) => {
    toast.success("Successfully deleted public key.", {
      position: toast.POSITION.TOP_RIGHT,
    });
    getPublicKey()
    modalClose()
  }
  const deleteFailureCallBack = (response: any) => {
    if (response === failureResponses.INVALID_TOKEN) navigate('/')
  }

  const deleteConfirm = () => {
    deleteApiCall(`/user/public_keys/${deleteKeyId}/`,
      deleteSuccessCallBack,
      deleteFailureCallBack)

  }

  const getPublicKey = () => {
    getApiCall('/user/public_keys/',
      getSuccessCallBackFunction,
      getFailureCallBackFunction
    )
  }

  const handleKeyNameEnter = (event: any) => {
    setNameError("")
    setKeyNameEntered(event.target.value)
  }

  document.addEventListener('Extension context invalidated', function (event) {
    if (!instructionPopup) {
      setExtensionInstructionPopup(true);
      setKeyNameEntered("");
    }
  });
  
  document.addEventListener('enable popup', function (event) {
    enableExtentionPopup()
  });
  
  React.useEffect(() => {
    if (keyNameExtensionError) {
      toast.error('Key name exists in extension storage', { position: toast.POSITION.TOP_RIGHT })
      setKeyNameExtensionError(false)
    }
    // eslint-disable-next-line
  }, [keyNameExtensionError])

  document.addEventListener('This name exist in extension storage', (e) => {
    setKeyNameExtensionError(true)
  })

  const messagePass = () => {
    let nameElement = document.getElementById('#name#')
    let keyElement = document.getElementById('#key#')
    
    if (nameElement) nameElement.textContent = keyNameEntered.trim();
    if (keyElement) keyElement.textContent = privateKeyRef.current

    let event = new CustomEvent('Add generated key to extension')
    document.dispatchEvent(event)
    setKeyNameEntered("")
    privateKeyRef.current = null
    setExtensionPopup(false)
  };

  const successCallBackFunction = (response: any) => {
    !instructionPopup && setExtensionInstructionPopup(true)
    let event = new CustomEvent('open the extension popup')
    document.dispatchEvent(event)

    const blob = new Blob([privateKeyRef.current as BlobPart], { type: 'text/plain' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = keyNameEntered.replaceAll(' ', '_') + "_priv";
    link.click();

    toast.success("Successfully created public key", {
      position: toast.POSITION.TOP_RIGHT,
    });
    setLoader(false);
    handleAddModalClose();
    setKeyError("");
    setPasswordValue("");
    getPublicKey();
    getKeyList();
  };
  
  const failureCallBackFunction = (response: any) => {
    if (response === failureResponses.INVALID_TOKEN) navigate('/')
    if (response?.key) {
      setKeyError(response.key[0])
      setPopupKeyError(response.key[0])
    }
    else if (response?.name[0]) {
      const errorMessage = 'This key name is already taken, please select a different name for your key';
      setPopupKeyNameError(addKeyOpen ? errorMessage : '');
      setNameError(addKeyOpen ? '' : errorMessage);      
    }
    else
      toast.error("Something went wrong. Try again.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    setLoader(false)
  }

  const generateKey = async () => {

    const { privateKey, publicKey } = await openpgp.generateKey({
      type: 'rsa', // Type of the key
      rsaBits: 2048, // RSA key size (defaults to 2048 bits)
      userIDs: [{ name: userData.given_name, email: userData.email }],
      passphrase: passwordValue // protects the private key
    });
    privateKeyRef.current = privateKey;
    
    const keyData = { name: keyNameEntered, key: publicKey }
    postApiCall("/user/public_keys/",
      keyData,
      successCallBackFunction,
      failureCallBackFunction);

  }
  const keyListsuccessCallBackFunction = (response: any) => {
    setLoader(false)
    setDropDownList(response)
  }
  const keyListFailureCallBackFunction = () => {
    setLoader(false)
  }
  const getKeyList = () => {
    setLoader(true)
    getApiCall(`/user/key/project_list/`,
      keyListsuccessCallBackFunction,
      keyListFailureCallBackFunction)
  }

  const defaultPatchSuccessCallBack = (response: any) => {
    autocompleteRefs.current[inputRefIndex.current]?.blur();
    handleActiveModalClose()
    setDefaultRemoveOpen(false)
    setLoader(false)
    getKeyList()
    getPublicKey()
  }
  const defaultPatchFailureCallBack = (response: any) => {
    if (response.detail === failureResponses.INVALID_TOKEN) navigate('/')
    setLoader(false)
  }
  const defaultAdd = (projectId: number, keyId: number) => {
    setSelectedOption(null);
    if (projectId === 0) {
      const data = {
        "linked_project": projectId
      }
      setLoader(true)
      patchApiCall(`/user/public_keys/${keyId}/`,
        data,
        defaultPatchSuccessCallBack,
        defaultPatchFailureCallBack
      )
    }
    if (projectId) {
      const data = {
        "linked_project": projectId
      }
      setLoader(true)
      patchApiCall(`/user/public_keys/${keyId}/`,
        data,
        defaultPatchSuccessCallBack,
        defaultPatchFailureCallBack
      )
    }

  }
  const removeDefault = (keyId: number) => {
    const data = {
      "linked_project": -1
    }
    setLoader(true)
    patchApiCall(`/user/public_keys/${keyId}/`,
      data,
      defaultPatchSuccessCallBack,
      defaultPatchFailureCallBack
    )
  }

  const handleGenerateKey = (event: any) => {
    event.preventDefault()
    if (keyNameEntered === "") {
      keyNameEntered === "" && setNameError("Enter a key name")
    }
    else if(keyNameEntered.length > 200) {
      setNameError("Ensure this field has no more than 200 characters")
    } else {
      setLoader(true);
      setTimeout(function () {
        generateKey();
      }, 200);
    }
  }
  const handleActiveModalClose = () => {
    setInActivateKey(-1)
    setTogglingProject('')
    setActiveModal(false)
  }

  const toggleConfirm = () => {
    const newFlags = keyFlagArray.map((item: any, index: number) => {
      if (item.id === inActivateKey) {
        return { ...item, active: !item?.active }
      }
      else {
        return item
      }
    })
    const indexPassed = newFlags.findIndex((item) => item.id === inActivateKey);
    setKeyFlagArray(newFlags)
    const data = {
      "is_active": !keyFlagArray[indexPassed].active
    }
    setLoader(true)
    patchApiCall(`/user/public_keys/${inActivateKey}/`,
      data,
      defaultPatchSuccessCallBack,
      defaultPatchFailureCallBack
    )
  }
  const handleToggle = (indexPassed: number, keyId: number, defaultProject: boolean, project: string) => {

    if (keyFlagArray[indexPassed].active === true) {
      if (defaultProject || project) {
        if (project) setTogglingProject(project)
        setInActivateKey(keyId)
        setActiveModal(true)
      }
      else {
        setInActivateKey(keyId)
        setActiveModal(true)
        setTogglingProject('no default key')
      }
    }
    else {
      const data = {
        "is_active": !keyFlagArray[indexPassed].active
      }
      setLoader(true)
      patchApiCall(`/user/public_keys/${keyId}/`,
        data,
        defaultPatchSuccessCallBack,
        defaultPatchFailureCallBack
      )
    }

  }
  const handleDelete = (id: number, isDefault: boolean, project: string) => {
    setDeleteKeyId(id)
    setOpen(true)

    if (!isDefault) {
      if (project) setDeleteKeyProject(project)
      else setDeleteKeyProject('no default key')
    }
  }


  const modalClose = () => {
    setDeleteKeyId(-1)
    setDeleteKeyProject('')
    setOpen(false)
  }
  const handleViewAllClick = (indexPassed: number) => {
    const newFlags = keyFlagArray.map((item: any, index: number) => {
      if (indexPassed === index) {
        return { ...item, viewAll: !item.viewAll }
      }
      else {
        return item
      }
    })
    setKeyFlagArray(newFlags)
  }
  const handleDefaultRemove = (keyId: number) => {
    setCurrentKey(keyId)
    setDefaultRemoveOpen(true)
  }

  const getSuccessCallBackFunction = (response: any) => {
    setLoader(false)
    setKeyDetails([...response])
    const keyFlags = response.map((item: any, index: number) => { return { id: item.id, active: item.is_active, viewAll: false } })
    setKeyFlagArray(keyFlags)
  }

  const getFailureCallBackFunction = (response: any) => {
    setLoader(false)
    navigate("/")
  }
  const flagSuccessCallBack = (response: any) => {
    setInstructionPopupFlag(response.disable_download_extension_popup)
    setLoader(false)
  }
  const flagFailureCallBack = () => {
    setLoader(false)
    navigate("/")
  }
  const getUserFlag = () => {
    setLoader(true)
    getApiCall('/user/generate_key/pop_up_status_flag/',
      flagSuccessCallBack,
      flagFailureCallBack)
  }
  React.useEffect(() => {
    getUserFlag()
    getPublicKey()
    getKeyList()
    // eslint-disable-next-line
  }, [])

  const handleModalOpen = () => {
    dispatch(setModalOpen(true))
    setAddKeyOpen(true)
  }
  const handleAddModalClose = () => {
    dispatch(setModalOpen(false));
    setAddKeyOpen(false)
    setPopupKeyError('')
    setPopupKeyNameError('')
    setPopupKeyEntered('')
    setPopupKeyNameEntered('')
  }

  const enableExtentionPopup = () => {
    setExtensionInstructionPopup(false)
    setExtensionPopup(true)
    setInstructionPopupFlag(true)
  }
  const autocompleteRefs = React.useRef<(HTMLInputElement | null)[]>([]);

  const handleInstructionClose = () => {
    if (showAgain) {
      setLoader(true)
      patchApiCall('/user/generate_key/pop_up_status_flag/',
        { disable_download_extension_popup: true },
        flagSuccessCallBack,
        flagFailureCallBack)
    }
    setExtensionInstructionPopup(false);
    setKeyNameEntered("");
  }

  document.addEventListener('item added to storage', (e) => {
    setToastMessage(true)
  })

  React.useEffect(() => {
    if (toastMessage) {
      toast.success("The key has been successfully stored to your extension.", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setTimeout(() => { setToastMessage(false) }, 200)
    }
    // eslint-disable-next-line
  }, [toastMessage])

  return (
    <>
      {loader && <LazyLoader />}
      <ToastContainer />
      <Header page="settings" />
      <Box className={styles.settingsBox}>
        <>
          <div className={styles.keyHeaderContainer}>
            <p className={styles.generateKey}>Generate Keys</p>

            <Button className={styles.headerAddKey} onClick={handleModalOpen}>+ ADD NEW KEY</Button>
          </div>
          <div className={styles.generateKeyText}>
            <p className={styles.textOne}>Generate a new key for secure access of your projects.</p>
            <div className={styles.secondTextWithIcon}>
              <img src={fluentInfoFilled} className={styles.instructionImage} alt=""/>
              <p className={styles.textTwo}>Your key will securely be added/generated, ensuring its protection even in the event of a server breach</p>
            </div>
          </div>
          <div className={styles.textFieldContainer}>
            <form onSubmit={handleGenerateKey}>
              <TextField className={styles.keyNameTextField}
                label="Key Name *"
                value={keyNameEntered}
                onChange={handleKeyNameEnter}
                autoComplete="off"
                error={nameError !== ""}
                helperText={nameError}

              />
              <TextField className={styles.keyTextField} label="Enter password to generate key (optional)"
                type={showPassword ? "text" : "password"}
                value={passwordValue}
                onChange={setPasswordValueEnter}
                autoComplete="off"
                error={passwordValueError !== ""}
                helperText={passwordValueError}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton>
                        <Tooltip title={showPassword ? "Hide Password" : "Show Password"}>
                          <img 
                            src={showPassword ? ShowPassword : HidePassword} 
                            alt="password visibility" 
                            onClick={()=>setShowPassword(!showPassword)}
                            />
                        </Tooltip>
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
              <Button className={keyNameEntered !== "" ? styles.addKeyButton : styles.addKeyButtonDisabled} type="submit">Generate Key</Button>
            </form>
          </div>

          <div>
            <p className={styles.keyHeading}>Your Keys</p>
            <p className={styles.keySubTitle}>Find your key list</p>
          </div>
          {keyDetails.map((item: any, index: number) => (
            <div key={index} className={styles.publicKeyList}>
              <div className={`${styles.publicKeyListHeader} ${keyFlagArray[index].viewAll ? styles.publicKeyListHeaderMargin : null}`}>
                <div className={styles.nameAndLabel}>
                  <p className={styles.publicKeyName}>{item.name}</p>
                  {item.is_default &&
                    <div className={styles.defaultLabel}>
                      <p className={styles.defaultText}>Generic Default key</p>
                      <img src={cancelIcon} alt='remove' onClick={() => handleDefaultRemove(item.id)} />
                    </div>}
                  {item.project !== 'Generic Default Key' && item.project &&
                    <div className={styles.projectDefaultLabel}>
                      <p className={styles.defaultText}>{item.project}</p>
                      <img src={cancelIcon} alt='remove' onClick={() => handleDefaultRemove(item.id)} />
                    </div>
                  }
                </div>
                <div className={styles.publicKeyListHeaderRight}>
                  <Autocomplete
                    sx={{ width: "139px" }}
                    options={dropDownList}
                    getOptionLabel={(option: any) => option.title}
                    getOptionDisabled={(option: any) => option.is_disabled}
                    renderInput={(params) => (
                      <TextField
                        inputRef={(ref) => (autocompleteRefs.current[index] = ref)}
                        className={!item.is_default && !item.project ? styles.defaultDropDown : styles.defaultDropDownLight}
                        {...params}
                        type={"text"}
                        placeholder={item.is_default? 'Generic Key' : item.project ? 'Project Key' : 'Mark as default key'}
                        size="small"
                      />
                    )}
                    size="small"
                    onChange={async (event, option) => {
                      inputRefIndex.current = index;
                      await setSelectedOption(item.id);
                      defaultAdd(option.project_users, item.id);
                    }}
                    value={selectedOption===item.id ? selectedOption : null}
                    disabled={!item.is_active}
                  />
                  <Box className={styles.keyStatusBox}>
                    <img src={keyFlagArray[index]?.active ? toggleActive : toggleOff} alt="active?" style={{ marginLeft: "24px", cursor: "pointer" }} onClick={() => handleToggle(index, item.id, item.is_default, item.project)} />
                    <p className={`${styles.keyStatus} ${!keyFlagArray[index]?.active && styles.inActivateKey}`}>
                      {keyFlagArray[index]?.active ? "Active" : "Inactive"}
                    </p>
                  </Box>
                  <img src={deleteKey} alt="delete" style={{ marginLeft: "24px", cursor: "pointer" }} onClick={() => handleDelete(item.id, item.is_default, item.project)} />
                </div>
              </div>

              <div className={styles.publicKeyValue} style={{ overflowWrap: "anywhere", marginBottom: '8px' }}>
                <span className={`${styles.keyValueContainer} ${keyFlagArray[index].viewAll? styles.keyValueContainerDisplay : null}`}>
                  <p className={`${styles.valueOfKey} ${!keyFlagArray[index].viewAll ? styles.truncatedText : null}`}>{item.key}</p>
                  <p className={styles.viewPublicKey} onClick={() => handleViewAllClick(index)}>&nbsp;{keyFlagArray[index].viewAll ? "view less" : "view all"}</p>
                </span>
              </div>
              <div>
                <div className={styles.keyDetailsFooterContainer}>
                  <div className={styles.keyDetailFooter}>
                    {item.last_usage_time &&
                      <><p className={styles.lastUsed}>Last used:</p><p className={styles.lastUsedValue}> &nbsp;{` ${item.last_usage_time.hours}${item.last_usage_time.hours > 1 ? ' hours' : ' hour'}`}&nbsp;{item.last_usage_time.minutes && `${item.last_usage_time.minutes} ${item.last_usage_time.minutes > 1 ? 'minutes' : 'minute'}`} ago</p></>}
                  </div>
                  <div className={styles.keyDetailFooter}>
                    {/* <p className={styles.lastUsed} style={{ marginLeft: "10px" }}>Expire on:</p><p className={styles.lastUsedValue}>&nbsp; Never</p> */}
                  </div>
                </div>
              </div>
              <hr />

            </div>
          ))}
        </>
        <Modal
          open={addKeyOpen}
        >
          <Box sx={{ ...style }}>
            <p className={styles.addKeyPopupTextFirst}>Add Key</p>
            <p className={styles.addKeyPopupTextSecond}>Add a new key for secure access of your projects.</p>
            <div className={styles.keyNameTextFieldPopup}>
              <TextField
                label="Key Name *"
                value={popupKeyNameEntered}
                onChange={handlePopupKeyNameEnter}
                autoComplete="off"
                fullWidth
                error={popupKeyNameError !== ""}
                helperText={popupKeyNameError}

              />
            </div>
            <div className={styles.keyNameTextFieldPopup}>
              <TextField
                label="Key *"
                value={popupKeyEntered}
                onChange={handlePopupKeynter}
                autoComplete="off"
                multiline
                rows={4}
                fullWidth
                error={popupKeyError !== ""}
                helperText={popupKeyError}

              />
            </div>
            <div className={styles.addKeyPopupBottum}>
              <div className={styles.helperTextContainer}>
                <img src={helpQuestion} alt=""/>
                <p className={styles.helpLink} onClick={() => navigate('/settings/help')}>Need help how to add a key?</p>
              </div>
              <div className={styles.addKeyPopupButtonContainer}>
                <Button className={styles.addKeyPopupCancelButton} onClick={handleAddModalClose}>cancel</Button>
                <Button className={styles.addKeyPopupConfirmButton} type="submit" onClick={createKey}>confirm</Button>
              </div>
            </div>
          </Box>
        </Modal>
        <Modal open={extensionPopup}>
          <Box sx={{ ...extensionPopupStyle }}>
            <div id='userPermission' className={styles.userPermissionContainer}>
              <p>Do you want to add the private key for <strong>{keyNameEntered}</strong> in the Key Extension?</p>
              <div className={styles.extensionButtonContainer}>
                <Button 
                  className={`${styles.no} ${styles.buttonText}`} 
                  onClick={() => {
                    setExtensionPopup(false);
                    setKeyNameEntered("");
                  }}
                >
                  No
                </Button>
                <Button className={`${styles.yes} ${styles.buttonText}`} onClick={messagePass}>Yes</Button>
              </div>

            </div>

          </Box>

        </Modal>
        <ExtensionInstruction
          after='generate'
          extensionInstructionPopup={extensionInstructionPopup}
          handleInstructionClose={handleInstructionClose}
          showAgain={showAgain}
          setShowAgain={setShowAgain} />
      </Box >

      <DefaultRemoveModal
        open={defaultRemoveOpen}
        setDefaultRemoveOpen={setDefaultRemoveOpen}
        removeDefault={removeDefault}
        currentKey={currentKey}
      />
      <InActivateModal
        open={activeModalOpen}
        keyDetails={keyDetails}
        handleActiveModalClose={handleActiveModalClose}
        inActivateKey={inActivateKey}
        projectName={togglingProject}
        toggleConfirm={toggleConfirm}
      />
      <DeleteModal
        open={open}
        projectName={deleteKeyProject}
        handleModalClose={modalClose}
        deleteConfirm={deleteConfirm} />

    </>
  )
}
export default Settings;