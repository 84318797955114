import { Box, Button, Modal } from "@mui/material";
import styles from './DefaultRemoveModal.module.scss'

const style = {
  textAlign: 'center',
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 606,
  bgcolor: '#fff',
  borderRadius: '5px',
  boxShadow: 24,
  padding: "40px 85px",
};
const DefaultRemoveModal = (props: any) => {
  return (
    <>
      <Modal open={props.open}>
        <Box sx={{ ...style }}>
          <p className={styles.headingOne}>Are you sure you want to remove the default key?</p>
          <p className={styles.headingTwo}>Ensure to set another key as the default for your convenience</p>
          <div className={styles.buttonContainer}>
            <Button className={styles.cancelButton} onClick={() => props.setDefaultRemoveOpen(false)}>cancel</Button>
            <Button className={styles.removeButton} onClick={() => props.removeDefault(props.currentKey)}>Remove</Button>
          </div>

        </Box>
      </Modal>
    </>
  )
}
export default DefaultRemoveModal;