import React, { useContext } from "react";
import {
  Grid,
  Box,
  Typography,
  Button,
  Paper,
  TextField,
  InputLabel,
  FormControl,
  MenuItem,
  Select,
  Modal,
  InputAdornment,
  Autocomplete,
  Popper,
  Avatar,
  Tooltip
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import stylesMember from "../Members/Members.module.scss";
import stylesProjectList from "../ProjectList/ProjectList.module.scss";
import styles from "./ProjectSettings.module.scss";
import stylesActivity from "../ProjectActivity/ProjectActivity.module.scss";
import { deleteApiCall, getApiCall, multiPartPatchApiCall, patchApiCall, postApiCall } from "../../utils/Utils";
import { useParams, useNavigate, Link } from "react-router-dom";
import closeIcon from '../../assets/svg/closeIcon.svg';
import LazyLoader from "../shared/LazyLoader/LazyLoader";
import backArrow from "../../assets/svg/arrowBack.svg"
import ProjectContext from "../Helper/ProjectContext";
import { failureResponses } from "../../constants/constants";
import { useSearchDebounce } from "../../utils/useDebounce";
import { getDefaultProjectInitials } from "../../utils/utilityFunctions";
import styled from "@emotion/styled";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import truncate from 'lodash/truncate'
import SquadModal from "./SquadModal";
import deleteIcon from '../../assets/svg/Delete.svg'

const CustomProjectLogo = styled(Avatar)({
  width: "35px",
  height: "35px",
  backgroundColor: "#7F7F80"
});

const ProjectSettings = (props: any) => {
  const userProjectRole = useContext(ProjectContext);
  const userRole = userProjectRole.role;
  const params = useParams();
  const navigate = useNavigate();
  const projectId = params.projectId;
  const [open, setOpen] = React.useState(false);
  const [projectData, setProjectData] = React.useState<{ id: number; title: string; description: string; space_project_details: any; domain: string; status: string, logo: string, standup_time: string }>({
    id: 0,
    title: '',
    description: '',
    space_project_details: { id: 0, title: '' },
    domain: '',
    status: '',
    logo: '',
    standup_time: ''
  })
  const [titleError, setTitleError] = React.useState('');
  const [domainError, setDomainError] = React.useState('')
  const [descriptionError, setDescriptionError] = React.useState('')
  const [projectTitle, setProjectTitle] = React.useState('');
  const [deleteDisabled, setDeleteDisabled] = React.useState(true);
  const [newLogo, setNewLogo] = React.useState<any>(null);
  const [logo, setLogo] = React.useState('');
  const [loader, setLoader] = React.useState(true);
  const [logoError, setLogoError] = React.useState('');
  const [spaceProjectsList, setSpaceProjectsList] = React.useState([]);
  const [spaceProjectSearchValue, setSpaceProjectSearchValue] = React.useState('');

  const fileInputRef = React.createRef<HTMLInputElement>();
  const debouncedSearchTerm = useSearchDebounce(spaceProjectSearchValue, 150);
  const [squadList, setSquadList] = React.useState([])
  const [squadModalOpen, setSquadModalOpen] = React.useState(false)
  const [squadModalEdit, setSquadModalEdit] = React.useState(false)
  const [currentSquadId, setCurrentSquadId] = React.useState(0)
  const [currentSquadName, setCurrentSquadName] = React.useState('')
  const [squadMembers, setSquadMembers] = React.useState([])
  const [squadNameError, setSquadNameError] = React.useState('')
  const [squadDeleteOpen, setSquadDeleteOpen] = React.useState(false)

  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 450,
    bgcolor: '#fff',
    borderRadius: '6px',
    boxShadow: 24,
    padding: 2,
  };
  const squadDeleteStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: '#fff',
    borderRadius: '6px',
    boxShadow: 24,
    padding: 2,
  }

  const handleOpen = () => {
    setOpen(true);
  }

  const handleClose = () => {
    setCurrentSquadName('')
    setCurrentSquadId(0)
    setSquadDeleteOpen(false)
    setDomainError('')
    setOpen(false);
  }

  const getProjectData = () => {
    getApiCall(`/projects/${projectId}`, successCallBackFunction, failureCallBackFunction)
    setLoader(true);
  }

  const handleDeleteProject = () => {
    deleteApiCall(`/projects/${projectId}`, deleteSuccessCallBackFunction, failureCallBackFunction)
    setLoader(true);
  }

  const handleEditProject = () => {
    let logo;
    if (projectData.title !== '') {
      if (newLogo !== null)
        logo = newLogo
      else if (projectData.logo === '')
        logo = '';
      else
        logo = null;
      multiPartPatchApiCall(`/projects/${projectId}`, {
        title: projectData.title,
        description: projectData.description,
        domain: projectData.domain,
        status: projectData.status,
        logo_file: logo,
        standup_time: projectData.standup_time,
        space_project: projectData.space_project_details?.id || ''
      }, saveSuccessCallBackFunction, failureCallBackFunction)
      setLoader(true);
    }
    else {
      setTitleError("Project title cannot be empty")
    }
  }

  const successCallBackFunction = (response: any) => {
    setProjectData(response);
    setLogo(response.logo);
    setProjectTitle(response.title);
    setLoader(false);
  }

  const saveSuccessCallBackFunction = () => {
    navigate(`/project-details/${projectId}`);
    setLoader(false);
    setTitleError('')
    setLogoError('')
  }

  const deleteSuccessCallBackFunction = () => {
    handleClose();
    navigate("/project-list", { state: { message: "Project deleted successfully." } });
    setLoader(false);
  }

  const failureCallBackFunction = (response: any) => {
    setLoader(false)
    if (response.title) {
      setTitleError(response.title)
    }
    if (response?.logo_file) {
      response?.logo_file[0] !== null && setLogoError(response.logo_file[0])
    }
    if (response.domain) {
      setDomainError(response.domain)
    }
    if (response.description) {
      setDescriptionError(response.description)
    }
    else if ((!response.title && !response?.logo_file) || response === failureResponses.INVALID_TOKEN) {
      toast.error("Something went wrong. Try again.", {
        position: toast.POSITION.TOP_RIGHT,
      });
      navigate("/")
    }
      
    setLoader(false)
  }
  const detailsSuccessCallBack = (response: any) => {
    props.setRole(response.role)
  }
  const detailsFailureCallBack = (response: any) => {
    if (response === failureResponses.INVALID_TOKEN) {
      navigate("/")
    }
    else {
      toast.error("Something went wrong. Try again.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }

  }

  const getSpaceProjects = () => {
    getApiCall(
      `/projects/${projectId}/space-projects/?search=${spaceProjectSearchValue}`,
      spaceProjectsSuccessCallBack,
      spaceProjectsFailureCallBack
    );
  }

  const squadSuccessCallBack = (response: any) => {
    setSquadList(response)
  }

  const squadFailureCallBack = (response: any) => {
    if(response.detail === failureResponses.INVALID_TOKEN) navigate('/')
  }
  const getSquadList = () => {
    getApiCall(`/projects/${projectId}/squads/squad-management`,
      squadSuccessCallBack,
      squadFailureCallBack
    )
  }
  React.useEffect(() => {
    getSquadList()
  }, [])
  React.useEffect(() => {
    if (['OWNER','PM'].includes(userRole) && userProjectRole.role !== "") {
      getProjectData();
      getSpaceProjects();
    }
    else if (userProjectRole.role === '') {
      getApiCall(
        `/projects/${projectId}/details/`,
        detailsSuccessCallBack,
        detailsFailureCallBack
      )
    }
    else {
      navigate("/")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userProjectRole.role])

  const handleCancel = () => {
    navigate(`/project-details/${projectId}`)
  }

  React.useEffect(() => {
    if (['OWNER', 'PM'].includes(userRole) && userProjectRole.role !== "") {
      getSpaceProjects();
    }
  }, [debouncedSearchTerm])

  const spaceProjectsSuccessCallBack = (response: any) => {
    setSpaceProjectsList(response);
  }

  const spaceProjectsFailureCallBack = () => {
    navigate("/");
  }

  const dropdownHeight = {
    style: {
      maxHeight: 230,
    },
  };

  const confrimButtonStyle = deleteDisabled ? `${stylesActivity.confirmButtonActive} ${stylesActivity.confirmButtonDisabled}` : stylesActivity.confirmButtonActive;

  const clearFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  }

  const checkFileSize = (event: any) => {
    setNewLogo(event.target.files[0])
    const fileSize = (event.target.files[0].size / 1024) / 1024;
    if (fileSize > 5) {
      setLogoError('File size exceeds limit, please upload a file smaller than 5 MB.');
      clearFileInput();
    }
  }

  const handleSquadModalClose = () => {
    setSquadNameError('')
    setSquadModalEdit(false)
    setSquadModalOpen(false)
    setCurrentSquadId(0)
    setSquadMembers([])
    setCurrentSquadName('')
  }

  const squadEditSuccessCallBack = (response: any) => {
    toast.success('Squad edited successfully', {position: toast.POSITION.TOP_RIGHT})
    handleSquadModalClose()
    getSquadList()
  }
  const squadEditFailureCallBack = (response: any) => {
    if(response?.name) {
      setSquadNameError(response.name[0])
    }
    else if(response.detail === failureResponses.INVALID_TOKEN) navigate('/')
  }
  const handleSquadEditSubmit = (membersList: any, squad: any) => {
    let currentSquadName = ''
    squadList.forEach((item: any) => {
      if(item.id === currentSquadId) currentSquadName=item.name})
    patchApiCall(`/projects/${projectId}/squads/${currentSquadId}/`,
    {...(currentSquadName !== squad && {name: squad}), squad_members: membersList},
    squadEditSuccessCallBack,
    squadEditFailureCallBack)
    
  }
  const squadAddSuccessCallBack = (response: any) => {
    toast.success('Squad added successfully', {position: toast.POSITION.TOP_RIGHT})
    getSquadList()
    handleSquadModalClose()

  }
  const squadAddFailureCallBack = (response: any) => {
    if(response?.name) {
      setSquadNameError(response.name[0])
    }
    else if(response.detail === failureResponses.INVALID_TOKEN) navigate('/')
  }
  const handleSquadAddSubmit = (membersList: any, squad: any) => {
    postApiCall(`/projects/${projectId}/squads/squad-management/`,
    {name: squad, squad_members: membersList},
    squadAddSuccessCallBack,
    squadAddFailureCallBack)
  }

  const squadDeleteSuccessCallBack = (response: any) => {
    getSquadList()
    handleClose()
    toast.success('Squad deleted successfully', {position: toast.POSITION.TOP_RIGHT})
  }
  const SquadDeleteFailureCallBack = (response: any) => {
    if(response === failureResponses.INVALID_TOKEN) navigate('/')
    else toast.error('Something went wrong, unable to delete squad', {position: toast.POSITION.TOP_RIGHT})
  }
  const handleSquadDelete = () => {
    deleteApiCall(`/projects/${projectId}/squads/${currentSquadId}`,
    squadDeleteSuccessCallBack,
    SquadDeleteFailureCallBack)
  }

  return (
    <>
      {loader && <LazyLoader />}
      <Grid item md={10}>
        <ToastContainer />
        <Box className={stylesMember.headingBlock}>
          <div className={stylesMember.header}>
            <Link to={`/project-details/${projectId}/`}>
              <img src={backArrow} alt="back" />
            </Link>
            <Typography className={stylesMember.headingLabel}>Settings</Typography>
          </div>
          <Grid item md={4} className={styles.deleteProject}>
            <CustomProjectLogo src={logo} alt={projectData.title}>{getDefaultProjectInitials(projectData.title)}</CustomProjectLogo>
            {['OWNER'].includes(userRole) && <Button variant="outlined" className={styles.deleteButton} onClick={handleOpen}>
              Delete Project
            </Button>}
          </Grid>
        </Box>
        <Box>
          <Paper sx={{ width: "100%", overflow: "hidden", marginBottom: "40px" }}>
            <Grid container sx={{ padding: "20px" }} xs={12}>
              <Box className={styles.textField}>
                <InputLabel className={styles.inputLabel}>Project Name</InputLabel>
                <Grid item xs={7}>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    value={projectData.title}
                    fullWidth
                    helperText={titleError}
                    error={titleError !== ''}
                    onChange={(event) => {
                      setTitleError('')
                      setProjectData(
                        prevState => ({
                          ...prevState,
                          title: event.target.value
                        })
                      )
                    }}
                    autoComplete="off"
                  />
                </Grid>
              </Box>
              <Box className={styles.textField}>
                <InputLabel className={styles.inputLabel}>Domain</InputLabel>
                <Grid item xs={7}>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    fullWidth
                    value={projectData.domain}
                    helperText={domainError}
                    error={domainError !== ''}
                    onChange={(event) => {
                      setDomainError('')
                      setProjectData(
                        prevState => ({
                          ...prevState,
                          domain: event.target.value
                        })
                      )
                    }}
                    autoComplete="off"
                  />
                </Grid>
              </Box>
              <Box className={styles.textField}>
                <InputLabel className={styles.inputLabelDesc}>Description</InputLabel>
                <Grid item xs={7}>
                  <TextField
                    id="filled-multiline-static"
                    multiline
                    fullWidth
                    rows={4}
                    value={projectData.description}
                    helperText={descriptionError}
                    error={descriptionError !== ''}
                    onChange={(event) => {
                      setDescriptionError('')
                      setProjectData(
                        prevState => ({
                          ...prevState,
                          description: event.target.value
                        })
                      )
                    }}
                    autoComplete="off"
                  />
                </Grid>
              </Box>
              <Box className={styles.textField}>
                <InputLabel className={`${styles.inputLabelDesc} ${styles.spaceLink}`}>Link Space Project</InputLabel>
                <Grid item xs={7}>
                  <Autocomplete
                    disablePortal
                    className={styles.projectList}
                    id="combo-box-demo"
                    options={spaceProjectsList}
                    filterOptions={(options) => options}
                    getOptionLabel={(option: any) => option.title}
                    fullWidth
                    PopperComponent={(props) => <Popper {...props} placement="bottom" />}
                    renderInput={(params) =>
                      <TextField onChange={(event) => setSpaceProjectSearchValue(event.target.value)} placeholder="N/A" {...params} />
                    }
                    value={projectData.space_project_details}
                    ListboxProps={dropdownHeight}
                    clearOnBlur={false}
                    onChange={(_, newValue) =>
                      setProjectData(
                        prevState => ({
                          ...prevState,
                          space_project_details: newValue
                        })
                      )
                    }
                  />
                </Grid>
              </Box>
              <Box className={styles.textField}>
                <InputLabel className={styles.inputLabel}>Status</InputLabel>
                <Grid item xs={2}>
                  <FormControl fullWidth>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      displayEmpty
                      value={projectData.status}
                      onChange={(event) => {
                        setProjectData(
                          prevState => ({
                            ...prevState,
                            status: event.target.value
                          })
                        )
                      }}
                    >
                      <MenuItem value="ACTIVE">Active</MenuItem>
                      <MenuItem value="ON_HOLD">OnHold</MenuItem>
                      <MenuItem value="CLOSED">Closed</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Box className={styles.standupTimeContainer}>
                  <InputLabel className={styles.standupTime} sx={{ marginLeft: "10%" }}>Standup Time</InputLabel>
                  <Grid item xs={7}>
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      type="time"
                      value={projectData.standup_time ?? ""}
                      onChange={(event) => {
                        setProjectData(
                          prevState => ({
                            ...prevState,
                            standup_time: event.target.value
                          })
                        )
                      }}
                      autoComplete="off"
                    />
                  </Grid>
                </Box>
              </Box>
              <Box className={styles.textField}>
                <InputLabel className={styles.inputLabel}>Project Logo</InputLabel>
                <Box sx={{ display: "flex" }}>
                  <Grid item xs={7}>
                    <TextField
                      id="upload-photo"
                      name="upload-photo"
                      placeholder="Upload a logo"
                      value={newLogo ? newLogo?.name : projectData.logo ?? ""}
                      autoComplete="off"
                      helperText={logoError}
                      error={logoError !== ''}
                      InputProps={{
                        endAdornment: (newLogo &&
                          <InputAdornment position='end'>
                            <Box sx={{ cursor: "pointer", minWidth: "10px" }}>
                              <img src={closeIcon} alt=""
                                onClick={() => {
                                  setNewLogo(null);
                                  setLogoError('');
                                  clearFileInput();
                                }} />
                            </Box>
                          </InputAdornment>)
                      }}
                    />
                  </Grid>
                  <Button
                    variant="contained"
                    component="label"
                    className={styles.uploadButton}
                  >
                    Upload File
                    <input
                      type="file"
                      ref={fileInputRef}
                      hidden
                      accept='image/*'
                      onChange={checkFileSize}
                    />
                  </Button>
                </Box>
                <p>The maximum file size allowed is 5MB.</p>
              </Box>
              <Grid sx={{ paddingLeft: "15%", marginBottom: "20px" }} xs={3} sm={4} md={5} lg={5}>
                <Button
                  variant="contained"
                  className={styles.removeButton} onClick={() => setProjectData(
                    prevState => ({
                      ...prevState,
                      logo: ''
                    })
                  )}>
                  Remove Logo
                </Button>
              </Grid>
              <Grid container justifyContent="center" className={stylesProjectList.buttons}>
                <Button variant="outlined"
                  className={stylesProjectList.cancelButton} onClick={handleCancel}>
                  Cancel
                </Button>
                <Button variant="contained" className={`${stylesProjectList.submitButton} ${logoError ? stylesProjectList.defaultCursor : ''}`}
                  onClick={() => { !logoError && handleEditProject(); }}>
                  Save Changes
                </Button>
              </Grid>
              <div className={styles.margin}></div>
              <div className={styles.squadHeader}>
                <p className={styles.squadHeaderText}>Squad Management</p>
                <Button className={styles.addSquadButton} onClick={() => setSquadModalOpen(true)}> Add Squad </Button>
              </div>
              {squadList.length > 0 &&
              <div className={styles.squadTable}>
                <div className={`${styles.squadTableHeader} ${styles.squadRowHeight}`}>
                    <div>Squad name</div>
                    <div>Number of members</div>
                    <div>members</div>
                    <div>ACTION</div>
                </div>
                <div className={styles.squadTableBody}>
                  {squadList.map((item: any, index: number) => (
                    <div key={index} className={`${styles.squadBodyDiv} ${styles.squadRowHeight} ${styles.squadRowTextstyle} ${index % 2 !== 0 ? styles.oddRow : ''}`} id={`${item.id}`}>
                      <div>{item.name}</div>
                      <div>{item.member_count}</div>
                      <div>
                        <Tooltip
                          title={item.squad_members.map((member: any) => member.name).length > 25 ? item.squad_members.map((member: any) => member.name) : ''}
                          placement="bottom-end"
                        >
                          <p>
                            {item.squad_members.map((member: any) => member.name).length === 0 && '---'}
                            {truncate(item.squad_members.map((member: any) => member.name)[0], { length: 25 })}
                            <span className={styles.plusMore}>
                              {item.squad_members.map((member: any) => member.name).length > 1 &&
                                ` +${item.squad_members.map((member: any) => member.name).length - 1} more`}
                            </span>
                          </p>
                        </Tooltip>
                      </div>
                      <div className={styles.actionDiv}>
                        <Tooltip title="Edit" placement="bottom-end">
                          <BorderColorIcon
                            fontSize="medium"
                            className={styles.editIcon}
                            color="action"
                            onClick={() => {
                              setSquadModalEdit(true)
                              setSquadModalOpen(true)
                              setCurrentSquadId(item.id)
                              setCurrentSquadName(item.name)
                              setSquadMembers(item.squad_members.map((member: any) => member.name))
                            }}
                          />
                        </Tooltip>
                        <Tooltip title="Delete" placement="bottom-end">
                          <img src={deleteIcon} className={styles.editIcon} alt=""  onClick={() => {
                            setCurrentSquadId(item.id)
                            setSquadDeleteOpen(true)
                            setCurrentSquadName(item.name)
                          }}/>
                        </Tooltip>
                      </div>
                    </div>
                  ))}
                </div>
              </div>}
            </Grid>
          </Paper>
        </Box>
        {squadModalOpen &&
        <SquadModal
          open={squadModalOpen}
          handleModalClose={handleSquadModalClose}
          squadModalEdit={squadModalEdit}
          squadMembers={squadMembers}
          projectId={projectId}
          currentSquadName={currentSquadName}
          handleSquadAddSubmit={handleSquadAddSubmit}
          handleSquadEditSubmit={handleSquadEditSubmit}
          squadNameError={squadNameError}
          setSquadNameError={setSquadNameError}
        />}
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="parent-modal-title"
          aria-describedby="parent-modal-description"
        >
          <Box>
            <Box sx={{ ...style }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sx={{ textAlign: "left" }}>
                  Are you sure you want to delete the project? The deleted project cannot be restored.
                  <br /><br />
                  Enter the project's name to confirm:
                  <br />
                  <strong>{projectData.title}</strong>
                  <br /><br />
                  <Grid item xs={12}>
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      fullWidth
                      onChange={(event) => {
                        if (event.target.value === projectTitle)
                          setDeleteDisabled(false)
                        else setDeleteDisabled(true)
                      }}
                      autoComplete="off"
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} className={stylesActivity.buttonFields}>
                  <Button
                    variant="outlined"
                    className={stylesActivity.cancelButton}
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                  &nbsp;
                  <Button
                    variant="outlined"
                    type="submit"
                    className={confrimButtonStyle}
                    onClick={handleDeleteProject}
                    disabled={deleteDisabled}
                  >
                    Confirm
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Modal>
        <Modal
        open={squadDeleteOpen}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...squadDeleteStyle}}>
              <Grid container spacing={2}>
                <Grid item xs={12} sx={{ textAlign: "center" }}>
                  <p className={styles.deleteConfirmationHeader}>Are you sure you want to delete squad<b> "{currentSquadName}"</b> ?</p>
                </Grid>
                <Grid item xs={12} sx={{ textAlign: "center" }}>
                  <p className={styles.deleteConfirmationText}>This action will remove all members from the squad, and it cannot be undone.</p>
                </Grid>
                <Grid item xs={12} className={styles.buttonFields}>
                  <Button
                    variant="outlined"
                    className={styles.cancelButton}
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                  &nbsp;
                  <Button
                    variant="outlined"
                    type="submit"
                    className={styles.confirmButtonActive}
                    onClick={handleSquadDelete}
                  >
                    Confirm
                  </Button>
                </Grid>
              </Grid>
            </Box>
            </Modal>
      </Grid >
    </>
  )
}


export default ProjectSettings;