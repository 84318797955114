import { monthNames } from "../constants/constants";

const timeDifferenceInHours = (currentTime: Date, syncTime: Date): number => {
    const timeDifferenceMs = currentTime.getTime() - syncTime.getTime();
    return Math.round((timeDifferenceMs / (1000 * 60 * 60)) * 10) / 10;
} 

export function calculateTimeDifference(gitLabTime:string, testMateTime: string): [number, number] {
    const currentTime = new Date(); 
    const gitLabDate = new Date(gitLabTime);
    const testMateDate = new Date(testMateTime);

    const gitLabTimeDifferenceHours = timeDifferenceInHours(currentTime, gitLabDate);
    const testMateTimeDifferenceHours = timeDifferenceInHours(currentTime, testMateDate);
    
    return [gitLabTimeDifferenceHours, testMateTimeDifferenceHours];
};

export function externalSystemTimeDifference(syncTime:string): number {
    const currentTime = new Date();  
    const syncDate = new Date(syncTime);
    const timeDifferenceHours = timeDifferenceInHours(currentTime, syncDate);
    return timeDifferenceHours;
};

function removeSpecialCharacters(name: string) {
    return name.replace(/[^\w\s]|_/g, '');;
}

export const getDefaultProjectInitials = (projectName: string) => {
    const projectTitle = removeSpecialCharacters(projectName)
    
    let result = '';
    const words = projectTitle.split(' ');
    if (words.length === 1) result = projectTitle.charAt(0);
    else if (words[0].charAt(0) === words[1].charAt(0)) result = words[0].substring(0, 2);
    else result = words[0].charAt(0) + words[1].charAt(0);

    return result.toUpperCase();
};

export const lastSyncMessage = (
    gitLabResponse: string,
    testMateResponse: string,
    setGitlabRun: (arg0: string) => void,
    setTestMateRun: (arg0: string) => void,
    setLastSyncSystem: (arg0: string) => void,
    setHasExternalSystem: ((arg0: boolean) => void)
  ) => {
    const [gitLabLastSyncTime, testMateLastSyncTime] = calculateTimeDifference(
      gitLabResponse,
      testMateResponse
    );
  
    let gitlabMessage = "";
    let testmateMessage = "";
  
    // last sync response handling for Gitlab
    if (gitLabResponse === "FIRST_SYNC_FAILED") {
      gitlabMessage = "First sync failed in Gitlab";
      setGitlabRun("--:--:--");
    } else if (gitLabResponse === null) {
      gitlabMessage = "First sync pending for Gitlab";
      setGitlabRun("--:--:--");
    } else if (gitLabLastSyncTime > 1.5)
      gitlabMessage = `Last sync occured ${gitLabLastSyncTime} hours ago in Gitlab`;
  
    // last sync response handling for Testmate
    if (testMateResponse === "FIRST_SYNC_FAILED") {
      testmateMessage = "First sync failed in Testmate";
      setTestMateRun("--:--:--");
    } else if (testMateResponse === null) {
      testmateMessage = "First sync pending for Testmate";
      setTestMateRun("--:--:--");
    } else if (testMateLastSyncTime > 1.5)
      testmateMessage = `Last sync occured ${testMateLastSyncTime} hours ago in Testmate`;
  
    if (gitlabMessage && testmateMessage)
      setLastSyncSystem(`${gitlabMessage} , ${testmateMessage}`);
    else
      gitlabMessage
        ? setLastSyncSystem(gitlabMessage)
        : setLastSyncSystem(testmateMessage);

    if (testMateResponse && testMateResponse!=='FIRST_SYNC_FAILED' && testMateResponse!==null) setTestMateRun(new Date(testMateResponse).toLocaleString('en-us', { month: 'short', year: 'numeric', day: 'numeric' }) + " " +
      new Date(testMateResponse).toLocaleString('en-us', { hour: 'numeric', minute: 'numeric', second: 'numeric' }))
    if (gitLabResponse && gitLabResponse!=='FIRST_SYNC_FAILED' && gitLabResponse!==null) setGitlabRun(new Date(gitLabResponse).toLocaleString('en-us', { month: 'short', year: 'numeric', day: 'numeric' }) + " " +
      new Date(gitLabResponse).toLocaleString('en-us', { hour: 'numeric', minute: 'numeric', second: 'numeric' }))
    if (testMateResponse || gitLabResponse) setHasExternalSystem(true)
};
  
export const formatTicketDueDate = (dateStr: string) : string => {
  const [year, month, day] = dateStr.split('-');
  const monthAbbreviation = monthNames[parseInt(month, 10) - 1].toUpperCase(); 

  return `${parseInt(day, 10)} ${monthAbbreviation} ${year}`;
};

export const showBurndownChartTooltip = (
  initialStoryPoints: number,
  finalStoryPoints: number
) => {
  return initialStoryPoints === finalStoryPoints;
};
