import React, { useState } from "react";
import Drawer from '@mui/material/Drawer';
import styles from "./ActivityDetailSideBar.module.scss"
import closeIcon from "../../../assets/svg/closeRed.svg"
import radioChecked from "../../../assets/svg/RadioButtonChecked.svg"
import radioUnchecked from "../../../assets/svg/RadioButtonUnchecked.svg"
import gitLabIcon from "../../../assets/svg/gitIcon.svg"
import testmateIcon from "../../../assets/svg/testimateIcon.svg"
import { getApiCall } from "../../../utils/Utils";
import removedIcon from "../../../assets/svg/removed.svg"
import passedIcon from "../../../assets/svg/passed.svg"
import failedIcon from "../../../assets/svg/failed.svg"
import reopenedIcon from "../../../assets/svg/reopened.svg"
import createdIcon from "../../../assets/svg/created.svg"
import completedIcon from "../../../assets/svg/completed.svg"
import { Avatar, Link, MenuItem, Select, SelectChangeEvent, Tooltip, styled, Accordion, AccordionSummary, CircularProgress, Box } from "@mui/material";
import { toast } from "react-toastify";
import { ArrowForward } from "@mui/icons-material";
import pendingIcon from '../../../assets/svg/pendingIcon.svg';
import branchIcon from '../../../assets/svg/branchIcon.svg'
import truncate from 'lodash/truncate';
import { useDebounce } from "../../../utils/useDebounce";
import { failureResponses, iconMap, colorMap } from "../../../constants/constants";
import { useNavigate } from "react-router";
import readyForQAIcon from '../../../assets/svg/QAReady.svg';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DueDateSvg from "../../../assets/svg/DueDate.svg";
import DueDateRedSvg from "../../../assets/svg/DueDatered.svg";
import SPIconSvg from "../../../assets/svg/SPIcon.svg";
import { formatTicketDueDate } from "../../../utils/utilityFunctions";

const CustomDrawer = styled(Drawer)(({ theme }) => ({
  width: "366px",
  flexShrink: 0,
  '& .MuiDrawer-paper': {
    padding: "12px",
    width: "366px",
    boxShadow: "0px 6px 16px -4px rgba(58, 53, 65, 0.10), 0px 2px 12px -4px rgba(58, 53, 65, 0.08), 0px 2px 12px -4px rgba(58, 53, 65, 0.10)",
    backgroundColor: "var(--light-error-contrast, #FFF)",
  },
}));

interface StoryDefectStatus {
  story_defect_count: number,
  story_defect_data: any,
  story_activities: string[]
};

interface QAStatus {
  test_cases_count: number,
  test_case_data: any,
  test_activities: string[]
};

interface Participant {
  name: string,
  image: string
};

const ActivitySideBar = (props: any) => {

  const [taskActions, setTaskActions] = useState<any[]>([])
  const [testActions, setTestActions] = useState<any[]>([])
  const [gitlabFilter, setGitlabFilter] = useState(true)
  const gitlabFilterOptions = ["Created", "Completed", "Removed", "Reopened"]
  const [gitlabFilterSelectedOption, setGitlabFilterSelectOption] = useState("All")
  const [testmateFilter, setTestmateFilter] = useState(false)
  const testmateFilterOptions = ["Created", "Passed", "Failed", "Deleted", "Blocked"]
  const [testmateFilterSelectedOption, setTestmateFilterSelectOption] = useState("All")
  const [externalReferenceLink, setExternalreferenceLink] = useState("")
  const [parentExternalReference, setParentExternalReference] = useState("")
  const [ticketTitle, setTicketTitle] = useState("")
  const [externalReference, setExternalReference] = useState("")
  const [qaStatus, setQAStatus] = useState<QAStatus>({test_cases_count:0, test_case_data:{}, test_activities:[]});
  const [storyDefectStatus, setStoryDefectStatus] = useState<StoryDefectStatus>({story_defect_count:0, story_defect_data:{}, story_activities: []});
  const [participants, setParticipants] = React.useState<Participant[]>([]);
  const [expanded, setExpanded] = React.useState<boolean>(false);
  const [accordionExpanded, setAccordionExpanded] = React.useState<string | boolean>(false);
  const [dueDate, setDuedate] = React.useState('');
  const [dueDateColor, setDuedateColor] = React.useState(false);
  const [storyPoints, setStoryPoints] = React.useState(0);
  const navigate = useNavigate();

  const CustomAccordionSummary = styled(AccordionSummary)({
    padding: "0 0 5px 0",
    minHeight: "0",
    alignItems: gitlabFilter ? 'center' : 'flex-start',
    "& .MuiAccordionSummary-content": {
      margin: "0"
    },
  });

  function formatDueDate(dateStr: string) {
    const lastUsedTime = formatTicketDueDate(dateStr);
    const inputDate: Date = new Date(dateStr);
    const currentDate: Date = new Date();
    inputDate.setHours(0, 0, 0, 0);
    currentDate.setHours(0, 0, 0, 0);
    setDuedateColor(inputDate < currentDate);       
    return lastUsedTime;
  };

  useDebounce(()=> {
    setGitlabFilterSelectOption('All');
    setTestmateFilterSelectOption('All');
    props.setSidebarLoader(true);
    getTicketDetails();
    setExpanded(false);
  },[props.dateRange, props.ticketId, props.ticketClick, props.scenarioId],200);


  interface SubTickets {
    sub_tickets: number,
    open_sub_tickets: number
  };

  interface TaskProgressResponse {
    total_count: number,
    open_tasks: number
  };

  const [subTicketProgress, setSubTicketProgress] = useState<SubTickets>({ sub_tickets: 0, open_sub_tickets: 0, })
  const [taskProgress, setTaskProgress] = useState<TaskProgressResponse>({ total_count: 0, open_tasks: 0 })
  const [statusLabel, setStatusLabel] = useState([])
  const [deployedIn, setDeployedIn] = useState('')
  function formatDate(dateIst: any) {
    const date = new Date(dateIst.getTime() - (5 * 60 * 60 * 1000 + 30 * 60 * 1000));
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }
  const handleTestmateFilter = () => {
    if (testmateFilter === false) {
      setTestmateFilter(true)
      setGitlabFilter(false)
    }
  }
  const handleGitlabFilter = () => {
    if (gitlabFilter === false) {
      setTestmateFilter(false)
      setGitlabFilter(true)
    }
  }
  const handleSideBarClose = () => {
    setTestmateFilter(false)
    setGitlabFilter(true)
    props.handleDrawerClose()
  }

  const handleTypeFilter = (event: SelectChangeEvent) => {
    if (gitlabFilter) {
      setGitlabFilterSelectOption(event.target.value)
    }
    if (testmateFilter) {
      setTestmateFilterSelectOption(event.target.value)
    }
  }

  const ticketDataSuccessCallBack = (response: any) => {
    props.setSidebarLoader(false);
    if (!response?.deployed_in) {
      setDeployedIn('')
    }
    else {
      setDeployedIn(response?.deployed_in)
    }
    setStatusLabel(response?.status_labels ?? [])
    setSubTicketProgress(response?.sub_ticket_progress ?? [])
    setTaskProgress(response?.task_progress ?? [])
    setExternalReference(response.external_reference)
    setParentExternalReference(response.parent__external_reference)
    setTicketTitle(response.title)
    setExternalreferenceLink(response.external_reference_link)
    setTaskActions(response?.task_actions)
    setTestActions(response?.test_actions)
    setParticipants(response.participants)
    response.due_date ? setDuedate(formatDueDate(response?.due_date)) : setDuedate('');
    setStoryPoints(response?.story_point)
    setQAStatus({
      test_cases_count: response.qa_status.test_cases_count,
      test_case_data: response.qa_status.test_case_data,
      test_activities: Object.keys(response.qa_status.test_case_data)
    });
    setStoryDefectStatus({
      story_defect_count: response.sub_ticket_progress.sub_tickets ?? 0,
      story_defect_data: response.sub_ticket_status,
      story_activities: Object.keys(response.sub_ticket_status)
    });    

    if (response?.task_actions.length === 0 && response?.test_actions.length !== 0) {
      setGitlabFilter(false)
      setTestmateFilter(true)
    }
    else if (response?.task_actions.length !== 0) {
      setGitlabFilter(true)
      setTestmateFilter(false)
    }
  };

  const ticketDataFaileureCallBack = (response: any) => {
    if (response === failureResponses.INVALID_TOKEN) navigate("/");
    else if (response === failureResponses.STATUS_404) navigate("/*");
    else toast.error("Something went wrong,please try again", {
        position: toast.POSITION.TOP_RIGHT,
      });
    props.setSidebarLoader(false);
  }

  const getTicketDetails = () => {
    setAccordionExpanded(false);
    setQAStatus({test_cases_count:0, test_case_data:{}, test_activities:[]});
    setStoryDefectStatus({story_defect_count:0, story_defect_data:{}, story_activities: []});
    let startDate;
    let endDate;

    if (props.dateRange.length !== 0) {
      let sDate = new Date(props.dateRange[0]);
      let eDate = new Date(props.dateRange[1]);
      eDate.setDate(eDate.getDate());

      startDate = formatDate(sDate);
      endDate = formatDate(eDate);
    } else {
      let sDate = new Date();
      startDate = formatDate(sDate);
      endDate = formatDate(new Date());
    }
    getApiCall(
      `/projects/${props.projectId}/activities/list/${props.userId}/ticket/${props.ticketId}/?start_date=${startDate}&end_date=${endDate}&view_all=${props.viewAll || ''}&scenario_id=${props.scenarioId}`,
      ticketDataSuccessCallBack,
      ticketDataFaileureCallBack
    )
  };

  const handleAccordionExpand = (isExpanded: boolean, panel: string) => {
    setAccordionExpanded(isExpanded ? panel : false);
  }

  return (

    <>
      {!props.ticketClick &&
        <CustomDrawer
          className={styles.sideBarContainer}
          variant="persistent"
          anchor="right"
          open={props.open}
        >
          {props.sidebarLoader ? 
            <CircularProgress className={styles.loader} color="error"/> :
            <>
              <div className={styles.sideBarHeader}>
                {props.ticketId === 0 && <p className={styles.headerText}>View all independent test cases</p>}
                {props.ticketId !== 0 && <p className={styles.headerText}>{taskActions?.length !== 0 ? testActions?.length !== 0 ? 'View all Tasks/Testcases' : 'View all Tasks' : testActions?.length !== 0 ? 'View all Testcases' : ''}</p>}
                <img src={closeIcon} alt="close" onClick={handleSideBarClose} className={styles.closeIcon}></img>
              </div>
              <div className={styles.taskAndTestContainer}>
                {taskActions && taskActions.map((item: any, index: number) => {
                  return (
                    <div className={styles.testActionsContainer} key={index}>
                      <span className={styles.taskSpan} key={index}>
                        <img src={item.action === "COMPLETE" ? completedIcon :
                          item.action === "CREATE" ? createdIcon :
                            item.action === "DELETE" ? removedIcon :
                              item.action === "REOPEN" ? reopenedIcon
                                : ''
                        } alt={item.action} className={styles.iconSize}></img>
                        {item.is_grouped_tasks ? <p className={styles.taskNameGrouped}> {item.action.charAt(0).toUpperCase() + item.action.slice(1).toLowerCase()}{item.action === "REOPEN" ? "ed" : 'd'} {item.count} tasks</p> : <p className={styles.taskName}>{item.title}</p>}
                      </span>
                      {item.is_grouped_tasks &&
                        <div className={styles.groupedItemContainer} id={item?.action + index + item.grouped_actions[0]}>

                          {item.grouped_actions.map((groupedItem: any, index: number) => {
                            return (
                              <div className={styles.inline}>
                                <ArrowForward className={styles.taskArrow} /> &nbsp;
                                <p key={index} className={styles.groupedItem}> {groupedItem}</p>
                              </div>
                            )
                          })}
                        </div>

                      }
                    </div>
                  )
                })}
                {testActions?.filter((item) => item.action !== "SKIPPED").map((item: any, index: number) => {
                  return (
                    <div className={styles.testActionsContainer} key={index}>

                      <span className={styles.taskSpan} key={index}>
                        <img src={iconMap[item.action]} alt={item.action} className={styles.iconSize} />
                        {item.is_grouped_tasks === true ? <p className={styles.taskNameGrouped}> {item.action.charAt(0).toUpperCase() + item.action.slice(1).toLowerCase()} {item.count} cases</p> : <p className={styles.taskName}>{item.title}</p>}
                      </span>
                      {item.is_grouped_tasks &&
                        <div className={styles.groupedItemContainer} id={item?.action + index + item.grouped_actions[0]}>

                          {item.grouped_actions.map((groupedItem: any, index: number) => {
                            return (
                              <div className={styles.inline}>
                                <ArrowForward className={styles.taskArrow} /> &nbsp;
                                <p key={index} className={styles.groupedItem}> {groupedItem}</p>
                              </div>
                            )
                          })}
                        </div>

                      }
                    </div>
                  )
                })}
              </div>
            </>
          }
        </CustomDrawer>
      }
      {props.ticketClick &&
        <CustomDrawer
          className={styles.sideBarContainer}
          variant="persistent"
          anchor="right"
          open={props.open}
        >
          {props.sidebarLoader ? 
            <CircularProgress className={styles.loader} color="error"/> :
            <>
              <div className={styles.sideBarHeader}>
                <p className={styles.headerText}>
                  <Link href={externalReferenceLink} target="blank">
                    <span className={styles.titleText}>
                      {parentExternalReference ? parentExternalReference + " - " : null}
                      {externalReference} - {ticketTitle}
                    </span>
                  </Link>
                  &nbsp;&nbsp;
                  <span>
                    {statusLabel && statusLabel?.length > 0 &&
                      <div className={styles.label}>
                        {statusLabel[0]}
                      </div>
                    }
                    </span>
                    <span>
                    {deployedIn !== '' &&

                      <div className={styles.branch}>
                        <img src={branchIcon} alt="branch" />
                        <Tooltip title={deployedIn.length > 20 ? deployedIn : ""} placement="bottom-end" >
                          <p>{truncate(deployedIn, { length: 20 })}</p>
                        </Tooltip>
                      </div>
                    }
                  </span>
                  <Box className={styles.dateAndStoryPoints}>
                    {storyPoints && 
                    <Tooltip title={`${storyPoints} Story Points`} placement="top">
                      <Box className={styles.dueDateContainer}>
                        <img src={SPIconSvg} alt="story points" />
                        <span className={styles.storyPoints}>{storyPoints + " SP"}</span>
                      </Box>
                    </Tooltip>}
                    {dueDate && 
                    <Tooltip title="Due date" placement="top">
                      <Box className={styles.dueDateContainer}>
                        <img src={!dueDateColor ? DueDateSvg : DueDateRedSvg} alt="Due date of ticket"/>  
                        <span className={`${styles.dateStyles} ${dueDateColor===true && styles.redDate}`}>{dueDate}</span>
                      </Box>
                    </Tooltip>}
                  </Box>
                </p>

                <img src={closeIcon} alt="close" onClick={handleSideBarClose} className={styles.closeIcon}></img>
              </div>
              <div className={styles.subticketContainer}>
                {subTicketProgress !== null && subTicketProgress !== undefined && Object.keys(subTicketProgress).length !== 0 && subTicketProgress.open_sub_tickets !== 0 &&
                  <div>
                    <img src={pendingIcon} alt="pending" />
                    <p className={styles.pendingText}>{subTicketProgress?.open_sub_tickets}/{subTicketProgress?.sub_tickets} Sub Tickets are Pending</p>
                  </div>}
                {taskProgress !== null && taskProgress !== undefined && Object.keys(taskProgress).length !== 0 && taskProgress.open_tasks !== 0 &&
                  <div>
                    <img src={pendingIcon} alt="pending" />
                    <p className={styles.pendingText}>{taskProgress?.open_tasks}/{taskProgress?.total_count} Tasks are Pending</p>
                  </div>}
              </div>
              <div className={styles.toggleArea}>
                <div className={styles.iconAndName}>
                  <img src={gitlabFilter ? radioChecked : radioUnchecked} alt="checked" onClick={handleGitlabFilter}></img>
                  <img src={gitLabIcon} alt="gitlab" className={styles.gitIcon}></img>
                  <p className={styles.toggleText}>Gitlab</p>
                </div>
                <div className={styles.iconAndName}>
                  <img src={testmateFilter ? radioChecked : radioUnchecked} alt="checked" onClick={handleTestmateFilter}></img>
                  <img src={testmateIcon} alt="testmate" className={styles.gitIcon} ></img>
                  <p className={styles.toggleText}>testMate</p>
                </div>
              </div>
              <div className={styles.externalSystemFilter}>
                <Select
                  value={gitlabFilter ? gitlabFilterSelectedOption : testmateFilterSelectedOption}
                  onChange={handleTypeFilter}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  displayEmpty
                  fullWidth
                >
                  <MenuItem value="All">All</MenuItem>
                  {gitlabFilter ?
                    gitlabFilterOptions.map((item: any, index: number) => {
                      return (
                        <MenuItem value={item} key={index}>{item}</MenuItem>
                      )
                    }) :
                    testmateFilterOptions.map((item: any, index: number) => {
                      return (
                        <MenuItem value={item} key={index}>{item}</MenuItem>
                      )
                    })
                  }

                </Select>
              </div>
              <div className={styles.sidebarBottomScroll}>
                <div className={styles.taskAndTestContainer}>
                  {gitlabFilter && taskActions && taskActions.map((item: any, index: number) => {
                    if (gitlabFilterSelectedOption === "All" ||
                      gitlabFilterSelectedOption === item.action.charAt(0).toUpperCase() + item.action.slice(1).toLowerCase() + "d" ||
                      gitlabFilterSelectedOption === item.action.charAt(0).toUpperCase() + item.action.slice(1).toLowerCase() + "ed" ||
                      (gitlabFilterSelectedOption === "Removed" && item.action === "DELETE")) return (
                        <div className={styles.testActionsContainer} key={index}>
                          {item.is_grouped_tasks ? 
                          <Accordion 
                            className={`${styles.accordionStyles} ${index===taskActions.length-1 && styles.lastAccordionStyle}`} 
                            disableGutters
                            expanded={accordionExpanded === `task${index}`}
                            onChange={(event, isExpanded) => handleAccordionExpand(isExpanded, `task${index}`)}
                          >
                            <CustomAccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1-content"
                                id={item.id}
                            >
                              <span className={styles.taskSpan} key={index}>
                                <img src={iconMap[item.action]} alt={item.action} className={styles.iconSize} />
                                {item.is_grouped_tasks ? <p className={styles.taskNameGrouped}> {item.action.charAt(0).toUpperCase() + item.action.slice(1).toLowerCase()}{item.action === "REOPEN" ? "ed" : 'd'} {item.count} tasks</p> : <p className={styles.taskName}>{item.title}</p>}
                              </span>
                            </CustomAccordionSummary>
                            {item.is_grouped_tasks &&
                              <div className={styles.groupedItemContainer} id={item?.action + index + item.grouped_actions[0]}>
                                {item.grouped_actions.map((groupedItem: any, index: number) => {
                                  return (
                                    <div className={styles.inline}>
                                      <ArrowForward className={styles.taskArrow} /> &nbsp;
                                      <p key={index} className={styles.groupedItem}> {groupedItem}</p>
                                    </div>
                                  )
                                })}
                              </div>
                            }
                          </Accordion> : 
                          <span className={styles.taskSpan} key={index}>
                            <img src={iconMap[item.action]} alt={item.action} className={styles.iconSize} />
                            <p className={styles.taskName}>{item.title}</p>
                          </span>
                          }  
                        </div>
                      )
                    else { return null }
                  })}
                  {testmateFilter && testActions.filter((item) => item.action !== "SKIPPED").map((item: any, index: number) => {
                    if (testmateFilterSelectedOption === "All" ||
                      testmateFilterSelectedOption === item.action.charAt(0).toUpperCase() + item.action.slice(1).toLowerCase()) return (
                        <div className={styles.testActionsContainer} key={index}>
                          {item.is_grouped_tasks ? 
                          <Accordion 
                            className={`${styles.accordionStyles} ${index===testActions.filter((item) => item.action !== "SKIPPED").length-1 && styles.lastAccordionStyle}`} 
                            disableGutters
                            expanded={accordionExpanded === `test${index}`}
                            onChange={(event, isExpanded) => handleAccordionExpand(isExpanded, `test${index}`)}
                          >
                            <CustomAccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1-content"
                              id={item.id}
                            >
                              <span className={styles.taskSpan} key={index}>
                                <img src={iconMap[item.action]} alt={item.action} className={styles.iconSize} />
                                {item.scenario_title ?
                                  <p className={styles.taskNameGrouped}> {item.action.charAt(0).toUpperCase() + item.action.slice(1).toLowerCase()} {item.count} cases in <strong>{item.scenario_title}</strong></p> : 
                                    <p className={styles.taskNameGrouped} > {item.action.charAt(0).toUpperCase() + item.action.slice(1).toLowerCase()} {item.count} cases</p>
                                }
                              </span>
                            </CustomAccordionSummary>
                            {item.is_grouped_tasks &&
                              <div className={styles.groupedItemContainer} id={item?.action + index + item.grouped_actions[0]}>
                                {item.grouped_actions.map((groupedItem: any, index: number) => {
                                  return (
                                    <div className={styles.inline}>
                                      <ArrowForward className={styles.taskArrow} /> &nbsp;
                                      <p key={index} className={styles.groupedItem}> {groupedItem}</p>
                                    </div>
                                  )
                                })}
                              </div>
                            }
                          </Accordion> :
                          <span className={styles.taskSpan} key={index}>
                            <img src={iconMap[item.action]} alt={item.action} className={styles.iconSize} />
                            <p className={styles.taskName}>{item.title}</p>
                          </span>
                        }
                        </div>
                      )
                      else {
                        return null
                      }
                  })}
                </div>
                { (qaStatus.test_cases_count>0 || storyDefectStatus.story_defect_count>0) && 
                  <div className={styles.QAStatusData}>
                    {
                      qaStatus.test_cases_count > 0 && 
                      <div className={styles.QAStatus}>
                        <p className={styles.statusBoxTitle}>QA Status</p>
                        <div className={styles.testData}>
                          {qaStatus.test_activities.map((item:string)=> {
                            return (
                              <p className={styles.dataItem}>
                                <img src={iconMap[item]} alt={item} className={styles.iconSize} />                          
                                <span className={colorMap[item]}>{qaStatus.test_case_data[item]} {qaStatus.test_cases_count>1?'Cases':'Case'} {item.charAt(0).toUpperCase() + item.slice(1).toLowerCase()}</span>
                              </p>
                            )
                          })}
                        </div>
                      </div>
                    }
                    {
                      storyDefectStatus.story_defect_count > 0 && 
                      <div className={styles.storyStatus}>
                        <p className={styles.statusBoxTitle}>Story Defect - <span className={styles.defectCount}>{storyDefectStatus.story_defect_count}</span></p>
                        <div className={styles.storyData}>
                          {storyDefectStatus.story_activities.map((item:string)=> {
                            return (
                              <p className={styles.dataItem}>
                                {item === "QA Passed" && <img src={passedIcon} alt={item} className={styles.iconSize} />}
                                {item === "QA Failed" && <img src={failedIcon} alt={item} className={styles.iconSize} />}
                                {item === "Ready for QA" && <img src={readyForQAIcon} alt={item} className={styles.iconSize} />}                              
                                <span className={colorMap[item]}>{storyDefectStatus.story_defect_data[item]} {item}</span>
                              </p>
                            )
                          })}
                        </div>
                      </div>
                    }
                  </div>
                }

                {/* participants in the ticket  */}
                {participants && <div className={styles.participants}>
                  <p className={styles.participantsTitle}>Participants ({participants?.length})</p>
                  <div className={styles.participantImage}>
                    {participants.length <= 6 ? participants.map((user: Participant)=>{
                      return (
                        <Tooltip title={user.name} placement="top">
                          <Avatar className={styles.avatarSize} src={user.image} alt="user">{user.name.charAt(0).toUpperCase()}</Avatar>
                        </Tooltip>
                      )
                    }) : !expanded ? participants.slice(0,6).map((user: Participant)=>{
                      return (
                        <Tooltip title={user.name} placement="top">
                          <Avatar className={styles.avatarSize} src={user.image} alt="user">{user.name.charAt(0).toUpperCase()}</Avatar>
                        </Tooltip>
                      )
                    }) : participants.map((user: Participant)=>{
                      return (
                        <Tooltip title={user.name} placement="top">
                          <Avatar className={styles.avatarSize} src={user.image} alt="user">{user.name.charAt(0).toUpperCase()}</Avatar>
                        </Tooltip>
                      )
                    })}
                    <p 
                      onClick={()=>{setExpanded(!expanded)}} 
                      className={styles.viewAll}
                    >
                      {participants.length > 6 ? !expanded ? `+${participants.length-6} more` : 'View less' : null}
                    </p>
                  </div>
                </div>}
              </div>
            </>
          }
        </CustomDrawer>
      }
    </>
  )
}
export default ActivitySideBar;