import { Box, Modal } from "@mui/material";
import styles from "./MissingExtensionPopup.module.scss";
import { useNavigate } from "react-router";

const MissingExtensionPopup = (props: any) => {
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate("/settings");
  };

  const handleGoBack = () => {
    props.onGoBackClick();
  };

  return (
    <Modal
      open={props.open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className={styles.missingPopup}>
        <p className={styles.modalText}>
          Before accessing this feature, ensure the key extension is added to
          your browser. Add it via My Settings page or click the button below to
          redirect to the page.
        </p>
        <Box className={styles.buttons}>
          <button
            onClick={handleGoBack}
            className={`${styles.button} ${styles.goback}`}
          >
            {props.popup==='import' ? 'CLOSE' : 'GO BACK'}
          </button>
          <button
            onClick={handleNavigate}
            className={`${styles.button} ${styles.mysettings}`}
          >
            <span>GO TO <strong>MY SETTINGS</strong></span>
          </button>
        </Box>
      </Box>
    </Modal>
  );
};

export default MissingExtensionPopup;
