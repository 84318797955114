// export const REACT_APP_GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
const baseUrl = window.location.protocol+'//'+ window.location.host
const webSocketBaseUrl = window.location.host
export const BACKEND_BASE_URL = baseUrl === 'http://localhost:3000' ? 'https://calicut.qburst.in' + process.env.REACT_APP_BACKEND_BASE_URL : baseUrl + process.env.REACT_APP_BACKEND_BASE_URL || '';
export const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID || '';
export const APP_ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT || '';
export const BASE_HREF = process.env.REACT_APP_BASE_HREF || '';
export const ACCESS_TOKEN = process.env.REACT_APP_ACCESS_TOKEN || '';
export const REFRESH_TOKEN = process.env.REACT_APP_REFRESH_TOKEN || '';
export const USER_DATA = process.env.REACT_APP_USER_DATA || '';
export const USER_ID = process.env.REACT_APP_USER_ID || '';
export const WEB_SOCKET_BASE_URL = webSocketBaseUrl === 'localhost:3000' ? 'wss://calicut.qburst.in' + process.env.REACT_APP_WEB_SOCKET_BASE_URL : 'wss://' + webSocketBaseUrl + process.env.REACT_APP_WEB_SOCKET_BASE_URL || '';