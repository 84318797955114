import { useEffect, useState, useRef } from "react";

interface UseOverflowTooltipReturn {
  elementRef: React.RefObject<HTMLDivElement>;
  showTooltip: boolean;
}

const useOverflowTooltip = (dep: any): UseOverflowTooltipReturn => {
  const [showTooltip, setShowTooltip] = useState(false);
  const elementRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleResize = () => {
      const elem = elementRef.current;
      if (elem) {
        setShowTooltip(elem.scrollWidth > elem.offsetWidth);
      }
    };

    const resizeObserver = new ResizeObserver(handleResize);
    if (elementRef.current) {
      resizeObserver.observe(elementRef.current);
    }

    return () => {
      if (elementRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        resizeObserver.unobserve(elementRef.current);
      }
    };
  }, [dep]);

  return { elementRef, showTooltip };
};

export default useOverflowTooltip;
