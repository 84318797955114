import Header from "../Header/Header";
import styles from "./SprintAnalysis.module.scss";
import { useNavigate, useParams } from "react-router";
import { getApiCall } from "../../utils/Utils";
import { useEffect, useState } from "react";
import { Box, Button } from "@mui/material";
import Summary from "./Summary/Summary";
import BurnDownChart from "./BurnDownChart/BurnDownChart";
import Stories from "./Stories/Stories";
import {
  failureResponses,
  monthNames,
  sprintStatus,
} from "../../constants/constants";
import LazyLoader from "../shared/LazyLoader/LazyLoader";
import NoSprintsAdded from "../../assets/svg/noSprints.svg";

interface SprintList {
  id: number;
  title: string;
  start_date: string;
  end_date: string;
  is_active: boolean;
  sprint_data_exists: boolean;
}

const SprintAnalysis = () => {
  const params = useParams();
  const navigate = useNavigate();

  const projectId = params.projectId;
  const [sprintList, setSprintList] = useState<SprintList[]>([]);
  const [hideSummary, setHideSummary] = useState<boolean>(false);
  const [daysLeft, setDaysLeft] = useState<string | number>("");
  const [loader, setLoader] = useState(false);
  const [selectedSprint, setSelectedSprint] = useState<SprintList>({
    id: -1,
    title: "",
    start_date: "",
    end_date: "",
    is_active: true,
    sprint_data_exists: true,
  });

  // API to get list of sprints in the project
  const getSprintList = () => {
    setLoader(true);
    getApiCall(
      `/project/${projectId}/sprints/`,
      sprintListSuccessCallbackFunction,
      sprintListFailureCallbackFunction
    );
  };

  const convertUTCToISTAndDaysLeft = (utcDateString: string, date: string) => {
    setDaysLeft("");
    const [year, month, day] = utcDateString.split("-").map(Number);
    const targetDate =
      date === "start_date"
        ? new Date(year, month - 1, day, 0o0, 0o0)
        : new Date(year, month - 1, day, 23, 59);

    const now = new Date();
    const timeDifference = targetDate.getTime() - now.getTime();

    const daysLeftCount = timeDifference / (1000 * 60 * 60 * 24);
    const days = Math.ceil(daysLeftCount);

    if (isNaN(daysLeftCount)) {
      setDaysLeft("");
      return;
    }

    if (daysLeftCount > 0) {
      setDaysLeft(
        date === "start_date"
          ? `${days} day(s) for the sprint to start`
          : `${days} day(s) remaining`
      );
    } else {
      date === "start_date"
        ? setDaysLeft(`${Math.abs(days)} day(s) since the sprint started`)
        : setDaysLeft(sprintStatus.EXPIRED);
    }
  };

  const sprintListSuccessCallbackFunction = (response: any) => {
    setLoader(false);
    setSprintList(response);
    if (response.length > 0) setSelectedSprint(response[0]);
  };

  const sprintListFailureCallbackFunction = (response: any) => {
    setLoader(false);
    if (response === failureResponses.INVALID_TOKEN) navigate("/");
    else if (response === failureResponses.NO_PERMISSION)
      navigate(`/project-details/${projectId}`);
  };

  const formatDate = (inputDate: string) => {
    if (inputDate === null) return "";
    const [year, month, day] = inputDate.split("-");
    const monthName = monthNames[parseInt(month, 10) - 1];
    return `${parseInt(day, 10)} ${monthName}, ${year}`;
  };

  useEffect(() => {
    getSprintList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedSprint.id !== -1) {
      if (!selectedSprint.is_active)
        selectedSprint.sprint_data_exists
          ? setDaysLeft(sprintStatus.COMPLETED)
          : setDaysLeft(sprintStatus.NO_DATA);
      else if (!selectedSprint.start_date && !selectedSprint.end_date)
        setDaysLeft("Sprint date not added");
      else {
        const dateType = selectedSprint.end_date ? "end_date" : "start_date";
        const dateValue = selectedSprint.end_date || selectedSprint.start_date;
        convertUTCToISTAndDaysLeft(dateValue, dateType);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSprint]);

  return (
    <>
      {loader && <LazyLoader />}
      <Header
        page="Sprint Analysis"
        sprints={sprintList}
        selectedSprint={selectedSprint}
        setSelectedSprint={setSelectedSprint}
        daysLeft={daysLeft}
        projectId={projectId}
      />
      {sprintList.length > 0 ? (
        <Box className={styles.sprintAnalysisContainor}>
          <Box className={styles.sprintAnalysisContainorHeader}>
            <p className={styles.summaryTitle}>
              Summary{" "}
              <span className={styles.summaryDate}>
                ({formatDate(selectedSprint.start_date)} -{" "}
                {formatDate(selectedSprint.end_date)})
              </span>
            </p>
            <Button
              onClick={() => setHideSummary(!hideSummary)}
              className={styles.hideSummary}
            >
              {!hideSummary ? "HIDE SUMMARY" : "SHOW SUMMARY"}
            </Button>
          </Box>
          {!hideSummary && (
            <Box className={styles.summaryAndChart}>
              <Box className={styles.summary}>
                <Summary
                  projectId={projectId}
                  sprintId={selectedSprint.id}
                  active={
                    selectedSprint.is_active &&
                    daysLeft !== sprintStatus.EXPIRED
                  }
                />
              </Box>
              <Box className={styles.burndownChart}>
                <BurnDownChart
                  projectId={projectId}
                  sprintId={selectedSprint.id}
                  setDaysLeft={setDaysLeft}
                  start_date={selectedSprint.start_date}
                  end_date={selectedSprint.end_date}
                />
              </Box>
            </Box>
          )}
          <Box className={styles.storiesContainer}>
            <Stories projectId={projectId} sprintId={selectedSprint.id} />
          </Box>
        </Box>
      ) : (
        !loader && (
          <Box className={styles.noSprints}>
            <img src={NoSprintsAdded} alt="No sprints added" />
            <p className={styles.sprintText}>
              Waiting for the inclusion of sprints.
            </p>
          </Box>
        )
      )}
    </>
  );
};

export default SprintAnalysis;
