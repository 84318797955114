import { createSlice } from '@reduxjs/toolkit';

const squadReducer = createSlice({
  name: 'squad',
  initialState: {
    value: [],
  },
  reducers: {
    setSquad: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { setSquad } = squadReducer.actions;
export default squadReducer;
