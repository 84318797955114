import { Box, Modal, Tooltip as MuiTooltip } from "@mui/material";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LinearScale,
  Title,
  Tooltip,
  Legend,
} from "chart.js/auto";
import style from "./BurnDownChartModal.module.scss";
import expandIcon from "../../../assets/svg/expand.svg";
import StoryPointsLabel from "../../../assets/svg/story points.svg";
import { burndownChartWarning } from "../../../constants/constants";
import infoIcon from "../../../assets/svg/ChartTooltipIcon.svg";

ChartJS.register(LinearScale, Title, Tooltip, Legend);
const BurnDownChartModal = (props: any) => {
  return (
    <Modal open={props.open} onClose={props.handleModalClose}>
      <Box className={style.modalContainer}>
        <div className={style.headingContainer}>
          <Box className={style.burndownChartHeader}>
            <p className={style.heading}>Burndown Chart</p>
            {props.showModalTooltip && (
              <MuiTooltip title={burndownChartWarning} placement="top-start">
                <img
                  src={infoIcon}
                  className={style.iconSize}
                  alt="tooltip shown for flat graph lines"
                />
              </MuiTooltip>
            )}
          </Box>
        </div>
        <Box className={style.chartContainer}>
          <div className={style.rightHeader}>
            <div className={style.labelContainer}>
              <div className={style.idealLabelContainer}>
                <div className={style.idealLabelIcon}></div>
                <p className={style.labelText}>Ideal</p>
              </div>
              <div className={style.actualLabelContainer}>
                <div className={style.actualLabelIcon}></div>
                <p className={style.labelText}>Actual</p>
              </div>
            </div>
            <div className={style.expandIconContainer}>
              <img
                src={expandIcon}
                alt="expand"
                onClick={props.handleClose}
                style={{ cursor: "pointer" }}
              />
            </div>
          </div>
          <div className={style.chartBox}>
            <img
              className={style.yAxisLabel}
              src={StoryPointsLabel}
              alt="y axis label - story points"
            />
            <Box className={style.chart}>
              <Line data={props.chartData} options={props.chartOptions} />
            </Box>
          </div>
          <p className={style.xAxisLabel}>Days</p>
        </Box>
      </Box>
    </Modal>
  );
};

export default BurnDownChartModal;
