import React, { useEffect } from 'react';
import styles from './Header.module.scss';
import { Autocomplete, Box, TextField } from "@mui/material";
import searchIcon from '../../assets/svg/Search.svg';
import searchHoverIcon from '../../assets/svg/searchHover.svg';

const HeaderSearch = (props: any) => {

  const [searchHover, setSearchHover] = React.useState(false)
  const [searchValue, setSearchValue] = React.useState('')
  const [mouseEnter, setMouseEnter] = React.useState(false)
  const mouseOverIcon = () => {
    setMouseEnter(true)
    setSearchHover(true)
  }

  useEffect(()=>{
    if(searchRef.current && mouseEnter) {
      searchRef.current.focus();
    }
  },[mouseEnter]);

  const mouseOutIcon = () => {
    if(!searchValue) {
      if (props.selectedOption) {
        props.selectedOption === "" && setSearchHover(false)
      }
      else {
        setSearchHover(false)
      }
    }
    setMouseEnter(false);
  }

  const handleSearchValueChange = (input: any) => { 
    props.page === "sessions_listing" && props.setSessionSearch(input.target.value);
    setSearchValue(input.target.value);
    if(input.target.value === "") {
      if(props.page === "keytool") {
        props.setKeyNameEntered("");
        !props.keyNameEntered && props.getKeyToolList();
      }
      !mouseEnter && setSearchHover(false);
    }
  };

  const handleKeytoolIconClick = () => {
    searchValue && props.handleKeyNameSearch(searchValue);
  };

  const searchRef = React.useRef<HTMLInputElement>(null);

  const textFieldWidth = searchHover ? styles['search-text'] : styles['search-text-collapsed'];
  const textFieldDisplay = searchHover ? styles.fieldDisplay : styles.fieldHidden;
  const textFieldVisibility = searchHover ? "" : styles.fieldVisibility;

  React.useEffect(() => {
    if(props.page === 'member') props.expanded(searchHover)
  }, [searchHover])
  return (
    <>
      {props.page === "keytool" &&
        <div className={styles['search-box']} style={{ gap: "4px" }} onMouseOver={mouseOverIcon} onMouseOut={mouseOutIcon}>          
          <Box className={textFieldWidth} >
            <TextField
              inputRef={searchRef}
              type={"text"}
              placeholder="Search"
              className={textFieldDisplay}
              autoComplete='off'
              value={searchValue}
              onChange={(event: any)=> {
                handleSearchValueChange(event);
              }}
              onKeyDown={(event: any) => {
                if (event.key === "Enter") {
                  props.handleKeyNameSearch(event.target.value)
                }
              }}        
              size="small"
            />
          </Box>
          <div className={styles['search-btn']} onClick={handleKeytoolIconClick}>
            <img src={searchHover ? searchHoverIcon : searchIcon} alt="" />
          </div>
        </div>
      }
      {
        (props.page === 'member' || props.page === 'status_label') &&
        <div className={styles['search-box']} style={{ gap: "4px" }} onMouseOver={mouseOverIcon} onMouseOut={mouseOutIcon}>
          <Box className={textFieldWidth} >
            <TextField
              inputRef={searchRef}
              type={"text"}
              placeholder="Search"
              className={textFieldDisplay}
              autoComplete='off'
              onChange={(event: any)=> {
                props.onChange(event);
                handleSearchValueChange(event);
              }}
              onKeyDown={props.onKeyDown}
              value={searchValue}
              size="small"
            />
          </Box>
          <div className={styles['search-btn']} onClick={searchValue?props.onClick:null}>
            <img src={searchHover ? searchHoverIcon : searchIcon} alt="" />
          </div>
        </div>
      }
      {(props.page === 'project_details_main' || props.page === 'projectList') &&
        <div className={styles['search-box']} onMouseOver={mouseOverIcon} onMouseOut={mouseOutIcon}>
          <Autocomplete
            className={textFieldWidth}
            options={props.searchList}
            renderInput={(params) => (
              <TextField
                {...params}
                type={"text"}
                placeholder="Search"
                className={textFieldVisibility}
                onChange={handleSearchValueChange}
              />
            )}
            onChange={(event, option) => props.handleSearchChange(event, option)}
            size='small'
          />
          <div className={styles['search-btn']}>
            <img src={searchHover ? searchHoverIcon : searchIcon} alt="" />
          </div>
        </div>
      }
      {props.page === "activity" &&
        <div className={styles['search-box']} onMouseOver={mouseOverIcon} onMouseOut={mouseOutIcon}>
          <Autocomplete
            className={textFieldWidth}
            options={props.searchList}
            getOptionLabel={(option: any) => option.ticket_no}
            renderInput={(params) => (
              <TextField
                {...params}
                type={"text"}
                placeholder="Search"
                className={textFieldVisibility}
                onChange={handleSearchValueChange}
              />
            )}
            onChange={(event, option) => {
              props.handleSearchChange(event, option);
              setSearchValue(option);
            }}
            size='small'
          />
          <div className={styles['search-btn']}>
            <img src={searchIcon} alt="" />
          </div>
        </div>
      }
      {props.page === "sessions_listing" &&
        <div className={styles['search-box']} onMouseOver={mouseOverIcon} onMouseOut={mouseOutIcon}>
          <Box className={textFieldWidth} >
            <TextField
              inputRef={searchRef}
              type={"text"}
              placeholder="Search"
              className={textFieldDisplay}
              autoComplete='off'
              onChange={handleSearchValueChange}
              onKeyDown={props.onKeyDown}
              value={searchValue}
              size="small"
            />
          </Box>
          <div className={styles['search-btn']}>
            <img src={searchHover ? searchHoverIcon : searchIcon} alt="" />
          </div>
        </div>
      }
    </>
  )
}
export default HeaderSearch;