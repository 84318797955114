import { Box, Tooltip } from "@mui/material";
import styles from './Instructions.module.scss';
import copyContent from '../../assets/svg/ContentCopy.svg'
import contentCopeid from '../../assets/svg/ContentCopied.svg'
import copy from "copy-to-clipboard";
import React from "react";
import { useNavigate } from "react-router-dom";
import { getApiCall } from "../../utils/Utils";

const Instructions = () => {

  const navigate = useNavigate()
  const [copied, setCopied] = React.useState(0);
  const handleCopy = (content: string, command: number) => {
    copy(content);
    setCopied(command);
    setTimeout(() => {
      setCopied(0);
    }, 2000);
  };
  const successCallBack = () => {
    // user is authenticated
  }
  const failureCallBack = (response: any) => {
    navigate('/')
  }
  React.useEffect(() => {
    getApiCall('/user/is_authenticated',
    successCallBack,
    failureCallBack)
  },[])

  return (<>
    <Box className={styles.header}>
      <p className={styles.heading}>My Settings</p>
    </Box>
    <Box className={styles.bodyContainer}>
      <div className={styles.bodyHeader}>
        <p className={styles.instructionHeader}>Instructions to add a key</p>
        <p className={styles.goBack} onClick={() => navigate('/settings')}>Go Back</p>
      </div>
      <p className={styles.guidlineHeading}>Guidelines for generating a key using the command-line interface (CLI):</p>
      <div className={styles.instructionOne}>
        <div className={styles.left}>
          <span className={styles.inline}>
            1.
            <p> Ensure that GPG is installed on your machine. If not, use the following command:</p>
          </span>
        </div>
        <div className={styles.right}>
          <p>sudo apt-get install gnupg</p>
          <Tooltip open={copied === 1} title="Copied" placement="bottom-end">
            <span className={styles.copyButton}
              onClick={() => handleCopy('sudo apt-get install gnupg', 1)}>
              {copied === 1 ? <img src={contentCopeid} alt="" /> : <img src={copyContent} alt="" />}
            </span>
          </Tooltip>

        </div>
      </div>
      <span className={styles.inline}>
        2.
        <p>Restart your machine after installation.</p>
      </span>
      <div className={styles.generateKey}>
        <p>
          To create a new GPG key pair, follow these steps:
        </p>
        <div className={styles.right}>
          <p>gpg --full-generate-key</p>
          <Tooltip open={copied === 2} title="Copied" placement="bottom-end">
            <span className={styles.copyButton}
              onClick={() => handleCopy('gpg --full-generate-key', 2)}>
              {copied === 2 ? <img src={contentCopeid} alt="" /> : <img src={copyContent} alt="" />}
            </span>
          </Tooltip>

        </div>
      </div>
      <div className={styles.promptDiv}>
        <div className={styles.dot}></div>
        <p className={styles.prompt}>It will prompt you to select the type of key</p>
      </div>
      <div className={styles.options}>
        <span className={styles.inline}>
          1.
          <p>RSA and RSA (default)</p>
        </span>
        <span className={styles.inline}>
          2.
          <p>DSA and Elgamal</p>
        </span>
        <span className={styles.inline}>
          3.
          <p>DSA (sign only)</p>
        </span>
        <span className={styles.inline}>
          4.
          <p>RSA (sign only)</p>
        </span>
        <span className={styles.inline}>
          5.
          <p>Existing key from card</p>
        </span>

      </div>
      <div className={styles.promptGuid}>
        <div className={styles.promptDiv}>
          <div className={styles.dot}></div>
          <p className={styles.prompt}>Choose option 1 to enable encryption and signing</p>
        </div>
        <div className={styles.promptDiv}>
          <div className={styles.dot}></div>
          <p className={styles.prompt}>Another prompt to choose the keysize</p>
        </div>
        <div className={styles.promptDiv}>
          <div className={styles.dot}></div>
          <p className={styles.prompt}>Select the key size and validity period.</p>
        </div>
        <div className={styles.promptDiv}>
          <div className={styles.dot}></div>
          <p className={styles.prompt}>GnuPG asks user ID to identify your key - Provide user ID details to identify your key and press enter</p>
        </div>
        <div className={styles.promptDiv}>
          <div className={styles.dot}></div>
          <p className={styles.prompt}>This will generate the keys</p>
        </div>
      </div>
      <div className={styles.generatedKey}>
      <div>
          <div className={styles.left}>
            <p>3. Navigate to the gnupg folder</p>
          </div>
          <div className={styles.right}>
            <p>cd .gnupg</p>
            <Tooltip open={copied === 10} title="Copied" placement="bottom-end">
              <span className={styles.copyButton}
                onClick={() => handleCopy('cd .gnupg', 10)}>
                {copied === 10 ? <img src={contentCopeid} alt="" /> : <img src={copyContent} alt="" />}
              </span>
            </Tooltip>

          </div>
        </div>
        <div>
          <div className={styles.left}>
            <p>4. To list the keys</p>
          </div>
          <div className={styles.right}>
            <p>gpg --list-secret-keys</p>
            <Tooltip open={copied === 3} title="Copied" placement="bottom-end">
              <span className={styles.copyButton}
                onClick={() => handleCopy('gpg --list-secret-keys', 3)}>
                {copied === 3 ? <img src={contentCopeid} alt="" /> : <img src={copyContent} alt="" />}
              </span>
            </Tooltip>

          </div>
        </div>
        <div>
          <div className={styles.left}>
            <p>5. Export the public key to a file inside the folder</p>
          </div>
          <div className={styles.right}>
            <p>
              <code>gpg --export -a &lt;Entered Real name&gt; &gt; &lt;your_key_name&gt;_public.key</code>
            </p>
            <Tooltip open={copied === 4} title="Copied" placement="bottom-end">
              <span className={styles.copyButton}
                onClick={() => handleCopy('gpg --export -a <Entered Real name> > <your_key_name>_public.key', 4)}>
                {copied === 4 ? <img src={contentCopeid} alt="" /> : <img src={copyContent} alt="" />}
              </span>
            </Tooltip>

          </div>
        </div>
        <div>
          <div className={styles.left}>
            <p>6. To view the key</p>
          </div>
          <div className={styles.copyContainer}>
            <div className={styles.right}>
              <p>
                <code>cat &lt;your_key_name&gt;_public.key</code>
              </p>
              <Tooltip open={copied === 5} title="Copied" placement="bottom-end">
                <span className={styles.copyButton}
                  onClick={() => handleCopy('cat <your_key_name>_public.key', 5)}>
                  {copied === 5 ? <img src={contentCopeid} alt="" /> : <img src={copyContent} alt="" />}
                </span>
              </Tooltip>

            </div>
            <p className={styles.copyText}>Copy and paste the entire key in the settings page of the application</p>
          </div>

        </div>
        <div>
          <div className={styles.left}>
            <p>7. Export private key using</p>
          </div>
          <div className={styles.right}>
            <p>
              <code>gpg --export-secret-key -a &lt;Entered Real name&gt; &gt; &lt;your_key_name&gt;_private</code>
            </p>
            <Tooltip open={copied === 6} title="Copied" placement="bottom-end">
              <span className={styles.copyButton}
                onClick={() => handleCopy('gpg --export-secret-key -a <Entered Real name> > <your_key_name>_private', 6)}>
                {copied === 6 ? <img src={contentCopeid} alt="" /> : <img src={copyContent} alt="" />}
              </span>
            </Tooltip>

          </div>
        </div>
        <div>
          <div className={styles.left}>
            <p>8. To view the private key</p>
          </div>
          <div className={styles.copyContainer}>
            <div className={styles.right}>
              <p>
                <code>cat &lt;your_key_name&gt;_private</code>
              </p>
              <Tooltip open={copied === 7} title="Copied" placement="bottom-end">
                <span className={styles.copyButton}
                  onClick={() => handleCopy('cat  <your_key_name>_private', 7)}>
                  {copied === 7? <img src={contentCopeid} alt="" /> : <img src={copyContent} alt="" />}
                </span>
              </Tooltip>

            </div>
            <p className={styles.copyText}>Copy and paste the entire key in the settings page of the application</p>
          </div>

        </div>
      </div>
      

    </Box>
  </>)
}
export default Instructions;