import React from "react";
import Card from "@mui/material/Card"
import { CardContent, Typography, Grid, Box } from "@mui/material";
import activity from "../../assets/svg/activity.svg";
import externalSystems from "../../assets/svg/externalSystems.svg";
import pointingPoker from "../../assets/svg/pointingPoker.svg";
import Keytool from "../../assets/svg/keytool.svg";
import Labels from "../../assets/svg/labels.svg";
import members from "../../assets/svg/members.svg";
import settings from "../../assets/svg/projectSettings.svg";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import { getApiCall } from "../../utils/Utils";
import Header from "../Header/Header";
import LazyLoader from "../shared/LazyLoader/LazyLoader";
import styles from "./ProjectDetailsMain.module.scss";
import { ToastContainer, toast } from "react-toastify";
import sprintAnalysis from '../../assets/svg/sprintAnalysis.svg';
import {useDispatch} from "react-redux"
import {setDate} from "../../redux/store/dateReducer"
import { externalSystemError } from "../../constants/constants";
import { setSquad } from "../../redux/store/squadReducer";

const ProjectDetailsMain = (props: any) => {
  const dispatch = useDispatch()
  const location = useLocation();
  const navigate = useNavigate()
  const [loader, setLoader] = React.useState(true);
  const [projectData, setProjectData] = React.useState({ projectName: "", projectId: "", external_user_count: 0 });
  const [projectRole, setProjectRole] = React.useState("");
  const [externalUserId, setExternalUserId] = React.useState(-1)
  const [searchQuery, setSearchQuery] = React.useState<string[]>([]);
  const [searchList, setSearchList] = React.useState<string[]>([]);
  const [standupTime, setStandupTime] = React.useState('')
  const [isToastShowing, setIsToastShowing] = React.useState(false);

  const handleSearchQueryChange = (option: any) => {
    option !== null ? setSearchQuery([option]) : setSearchQuery(searchList)
  };

  const params = useParams()
  interface ProjectRoleResponse {
    id: number;
    title: string;
    role: string;
    external_user_count: number;
    ext_user_id:number;
    standup_time: null;
  }
  function formatDate(dateIst: any) {
    const date = new Date(dateIst.getTime() - (5 * 60 * 60 * 1000 + 30 * 60 * 1000));
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

  const detailsSuccessCallBack = (response: any) => {
    setStandupTime(response?.standup_time)
    projectDataSetter(response)
    const date = new Date();
    if (date.getDay() === 1) {
      date.setDate(date.getDate() - 3)
    }
    else {
      date.setDate(date.getDate() - 1);
    }
    date.setHours(Number(standupTime ? standupTime.slice(0, 2) : '9'));
    date.setMinutes(Number(standupTime ? standupTime.slice(3, 5) : '0'));
  
    const defaultToDate = new Date()
    const defaultStartDate = date;
    if(response.standup_time) {
      defaultStartDate.setHours(response.standup_time.slice(0, 2))
      defaultStartDate.setMinutes(response.standup_time.slice(3, 5))
      defaultStartDate.setSeconds(0)
    }
    let startDate = formatDate(defaultStartDate)
    let endDate = formatDate(defaultToDate)
    if (response.role!=="MEMBER") {
      getApiCall(
        `/projects/${params.projectId}/dashboard/?start_date=${startDate}&end_date=${endDate}`,
        successCallBackFunction,
        failureCallBackFunction
      );
    } else setLoader(false);
  }

  const detailsFailureCallBack = () => {
    setLoader(false)
    navigate('/')
  }
  const getProjectDetails = () => {
    getApiCall(
      `/projects/${params.projectId}/details/`,
      detailsSuccessCallBack,
      detailsFailureCallBack
    )

    setLoader(true)
  };
  const failureCallBackFunction = (response: any) => {
    setLoader(false)
    navigate("/");
  };
  const searchListSetter = () => {
    projectResources.forEach(item => item.hasPermission && setSearchList((prev) => !prev.includes(item.name) ? [...prev, item.name] : prev))
    projectResources.forEach(item => item.hasPermission && setSearchQuery((prev) => !prev.includes(item.name) ? [...prev, item.name] : prev))
  }

  const projectDataSetter = (response: ProjectRoleResponse) => {
    setProjectRole(response.role);
    props.setRole(response.role)
    setExternalUserId(response.ext_user_id)
    setProjectData(prev => ({
      ...prev,
      projectName: response?.title,
      projectId: response?.id.toString(),
    }));
  }

  const successCallBackFunction = (response: ProjectRoleResponse) => {
    setProjectData(prev => ({
      ...prev,
      external_user_count: response.external_user_count
    }))
    setLoader(false)
  };

  const projectResources = [
    {
      name: "Activities",
      url: activity,
      note: projectRole === 'MEMBER' ? 'View your activities' : `${projectData.external_user_count} ${projectData.external_user_count > 1 ? 'persons' : 'person'}`,
      path: projectRole !== "MEMBER" ? "activity" : `activity/${externalUserId}/details`,
      hasPermission: true,
      color: '#FFEEE0',
      configuration: false
    },
    {
      name: "Keytool",
      url: Keytool,
      note: "Find the keys which are created and shared",
      path: "keytool",
      hasPermission: true,
      color: '#FDE9E7',
      configuration: false
    },
    {
      name: "Pointing Poker",
      url: pointingPoker,
      note: "Estimate each task and set targets",
      path: "pointing-poker",
      hasPermission: true,
      color: '#F3F4F7',
      configuration: false
    },
    {
      name: "Sprint Analysis",
      url: sprintAnalysis,
      note: "Evaluate your sprint progress",
      path: "sprint-analysis",
      hasPermission: ["OWNER", "PM"].includes(projectRole),
      color: '#E2F1FE',
      configuration: false
    },
    {
      name: "External Systems",
      url: externalSystems,
      note: "Find external systems",
      path: "external-system",
      hasPermission: ["OWNER", "PM"].includes(projectRole),
      configuration: true
    },
    {
      name: "Members",
      url: members,
      note: "Find all the members in this project",
      path: "members",
      hasPermission: ["OWNER", "PM"].includes(projectRole),
      configuration: true
    },
    {
      name: "Status Labels",
      url: Labels,
      note: "Find and create labels",
      path: "status-label",
      hasPermission: ["OWNER", "PM"].includes(projectRole),
      configuration: true
    },
    {
      name: "Project Settings",
      url: settings,
      note: "View project's  settings",
      path: "settings",
      hasPermission: ["OWNER", 'PM'].includes(projectRole),
      configuration: true
    }]
  React.useEffect(() => {
    if (projectRole !== "") {
      searchListSetter()
    }
    else {
      getProjectDetails()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectRole])

  React.useEffect(() => {
    if (location.state?.activityMessage !== null) {
      toast.error(location.state?.activityMessage, {
        position: toast.POSITION.TOP_RIGHT,
      });
      location.state = null;
      window.history.replaceState(null, document.title)
    }
  }, [location])
  const handleCardClick = (path: any) => {
    if(!externalUserId && path.includes('activity') && projectRole === 'MEMBER'){
      if (!isToastShowing) {
        setIsToastShowing(true);
        toast.error(externalSystemError, {
          position: toast.POSITION.TOP_RIGHT,
          onClose : ()=>setIsToastShowing(false)
        });
      }
    }
    else navigate(`/project-details/${params.projectId}/${path}`)

  }
  React.useEffect(() => {
    dispatch(setDate([]))
    dispatch(setSquad([]))
  }, [projectData.projectId])

  return (
    <>
      {loader && <LazyLoader />}
      <ToastContainer style={{ width: "500px" }} />
      <Header page="project_details_main"
        projectName={projectData.projectName}
        handleSearchQueryChange={handleSearchQueryChange}
        searchQuery={searchQuery}
        searchList={searchList}
      />
      <Box className={styles.toolsContainer}>
        <Grid container spacing={4} justifyContent="center">
          {projectResources.map((item, index) => {
            return item.hasPermission && searchQuery.includes(item.name) && !item.configuration &&

              <Grid item xs={12} sm={12} md={6} lg={4} xl={4} key={index}>
                  <Card className={styles.toolsCard} onClick={() => handleCardClick(item.path)}>
                    <CardContent className={styles.toolsCardContent}>
                      <div style={{ backgroundColor: item?.color, textAlign: 'center', width: '100%', paddingTop: '16px', paddingBottom: '16px' }}>
                        <img src={item.url} className={styles.toolIcon} alt={item.path} />
                        <Typography variant="h5" component="div" className={styles.toolName}>
                          {item.name}
                        </Typography>
                      </div>
                      <div>
                        <Typography variant="body2" align="center" className={styles.toolNote}>
                          {item.note}
                        </Typography>
                      </div>

                    </CardContent>
                  </Card>
              </Grid>
          })}
        </Grid>

      </Box>
      {['PM', 'OWNER'].includes(projectRole) &&
      <div className={styles.configHeader}>
        <p className={styles.heading}>Configurations</p>
      </div>}
      {['PM', 'OWNER'].includes(projectRole) && 
      <div className={styles.configContainer}>
        <Grid container spacing={4} justifyContent="space-evenly">
          {projectResources.map((item, index) => {
            return item.hasPermission && searchQuery.includes(item.name) && item.configuration &&

              <Grid item xs={12} sm={12} md={6} lg={3} xl={3} key={index}>
                <Link to={`/project-details/${params.projectId}/${item.path}`}
                >
                  <Card className={styles.toolsCard}>
                    <CardContent className={styles.toolsCardContent}>
                      <div style={{ backgroundColor: item?.color, textAlign: 'center', width: '100%', paddingTop: '16px' }}>
                        <img src={item.url} className={styles.toolIcon} alt={item.path} />
                        <Typography variant="h5" component="div" className={styles.toolName}>
                          {item.name}
                        </Typography>
                      </div>
                      <div>
                        <Typography variant="body2" align="center" className={styles.toolNote}>
                          {item.note}
                        </Typography>
                      </div>

                    </CardContent>
                  </Card>
                </Link>
              </Grid>
          })}
        </Grid>
      </div>}
    </>
  )
}
export default ProjectDetailsMain;
